import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

export const iconMap = {
  success: <CheckCircleIcon className="icon" color="inherit" />,
  error: <ErrorIcon className="icon" color="inherit" />,
  warning: <WarningIcon className="icon" color="inherit" />,
  info: <InfoIcon className="icon" color="inherit" />,
};
