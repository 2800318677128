import Stack from '@mui/material/Stack/Stack';
import React from 'react';

import { leftPanelStateSelector } from '../../../features/fetch-target-groups-list/store/left-panel.selectors';
import PipelineNavigation from '../../../modules/navigation/components/pipeline-navigation/pipeline-navigation.component';
import { useAppSelector } from '../../store/utils/redux.hooks';
import {
  LeftSectionContainer,
  RightSectionContainer,
  TwoSectionsScreenLayoutContainer,
} from './two-sections-screen-layout.styles';
import { ITwoSectionsScreenLayoutProps } from './two-sections-screen-layout.types';

function TwoSectionsScreenLayout({
  leftSectionComponent: LeftSectionComponent,
  rightSectionComponent: RightSectionComponent,
  customPadding,
}: ITwoSectionsScreenLayoutProps) {
  const { isOpen } = useAppSelector(leftPanelStateSelector);

  // todo support exception for review page with no panel-left even if with the new version we have the panel
  return (
    <TwoSectionsScreenLayoutContainer className={!isOpen ? 'minimized' : ''}>
      <Stack direction="row" height="100%">
        <LeftSectionContainer className="left-section-container">
          <LeftSectionComponent />
        </LeftSectionContainer>
        <RightSectionContainer className="right-section-container" customPadding={customPadding}>
          <PipelineNavigation />
          <RightSectionComponent />
        </RightSectionContainer>
      </Stack>
    </TwoSectionsScreenLayoutContainer>
  );
}

export default TwoSectionsScreenLayout;
