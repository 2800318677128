import { TargetGroupFormField } from '../../../entities/target-group/domain/target-group.types';
import SelectLocationsWithAutocomplete from '../../../features/select-locations-with-autocomplete/components/select-locations-with-autocomplete.component';
import SelectOwnershipStructures from '../../../features/select-ownership-structures/components/select-ownership-structures.component';
import CurrencyInput from '../components/currency-input/currency-input.component';
import { FormFieldValueType } from '../components/target-definition-form.types';
import TargetDefinitionFormInput from '../components/target-definition-form-input/target-definition-form-input.component';

export const targetDefinitionFormStrategy = {
  [TargetGroupFormField.TARGET_GROUP_NAME]: {
    id: `1_${TargetGroupFormField.TARGET_GROUP_NAME}`,
    label: `${TargetGroupFormField.TARGET_GROUP_NAME} *`,
    name: 'name',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `1_${TargetGroupFormField.TARGET_GROUP_NAME}`,
    placeholder: 'Enter a name for the target group',
    showInfoIcon: false,
  },
  [TargetGroupFormField.SHORT_DESCRIPTION]: {
    id: `2_${TargetGroupFormField.SHORT_DESCRIPTION}`,
    label: 'AI prompt *',
    name: 'description',
    type: 'textarea',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `2_${TargetGroupFormField.SHORT_DESCRIPTION}`,
    minRows: 4,
    placeholder: "I'm looking for...",
    showInfoIcon: true,
  },

  [TargetGroupFormField.LOCATIONS]: {
    id: `4_${TargetGroupFormField.LOCATIONS}`,
    label: TargetGroupFormField.LOCATIONS,
    name: 'locations',
    inputValueType: FormFieldValueType.MULTIPLE,
    component: SelectLocationsWithAutocomplete,
    key: `4_${TargetGroupFormField.LOCATIONS}`,
    placeholder: 'Search or select locations for the target group',
    noOptionsText: 'No locations found..',
  },
  [TargetGroupFormField.OWNERSHIP_STRUCTURES]: {
    id: `5_${TargetGroupFormField.OWNERSHIP_STRUCTURES}`,
    label: 'Ownership structure *',
    name: 'ownershipStructures',
    inputValueType: FormFieldValueType.MULTIPLE,
    component: SelectOwnershipStructures,
    key: `5_${TargetGroupFormField.OWNERSHIP_STRUCTURES}`,
    placeholder: 'Select acceptable ownership structures',
    showInfoIcon: false,
  },
  [TargetGroupFormField.MIN_HEADCOUNT]: {
    id: `6_${TargetGroupFormField.MIN_HEADCOUNT}`,
    name: 'minHeadcount',
    type: 'number',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `5_${TargetGroupFormField.MIN_HEADCOUNT}`,
  },
  [TargetGroupFormField.MAX_HEADCOUNT]: {
    id: `7_${TargetGroupFormField.MAX_HEADCOUNT}`,
    name: 'maxHeadcount',
    type: 'number',
    inputValueType: FormFieldValueType.SOLO,
    component: TargetDefinitionFormInput,
    key: `7_${TargetGroupFormField.MAX_HEADCOUNT}`,
  },
  [TargetGroupFormField.MIN_REVENUE]: {
    id: `8_${TargetGroupFormField.MIN_REVENUE}`,
    name: 'minRevenue',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: CurrencyInput,
    key: `8_${TargetGroupFormField.MIN_REVENUE}`,
  },
  [TargetGroupFormField.MAX_REVENUE]: {
    id: `9_${TargetGroupFormField.MAX_REVENUE}`,
    name: 'maxRevenue',
    type: 'text',
    inputValueType: FormFieldValueType.SOLO,
    component: CurrencyInput,
    key: `9_${TargetGroupFormField.MAX_REVENUE}`,
  },
};
