import React from 'react';

import { LinkText, LinkWrapper } from './left-panel-list-link.styles';
import { ILeftPanelListLinkProps } from './left-panel-list-link.types';

function LeftPanelListLink({
  title,
  onClick,
  isActive = false,
  children,
  sx,
}: ILeftPanelListLinkProps) {
  return (
    <LinkWrapper onClick={onClick} rel="noreferrer" isActive={isActive} sx={sx}>
      <LinkText className="left-panel-list-link-title">{title}</LinkText>
      {children}
    </LinkWrapper>
  );
}

export default LeftPanelListLink;
