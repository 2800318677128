import React from 'react';

import {
  PageContentHeaderActionWrapper,
  PageContentHeaderDescription,
  PageContentHeaderTitle,
  PageContentHeaderTitleWrapper,
  PageContentHeaderWrapper,
} from './page-content-header.styles';
import { IPageContentHeaderProps } from './page-content-header.types';

function PageContentHeader({
  className,
  title,
  description,
  rightComponent,
}: IPageContentHeaderProps) {
  return (
    <PageContentHeaderWrapper className={className}>
      <PageContentHeaderTitleWrapper>
        <PageContentHeaderTitle>{title}</PageContentHeaderTitle>
        {description && <PageContentHeaderDescription>{description}</PageContentHeaderDescription>}
      </PageContentHeaderTitleWrapper>

      {rightComponent && (
        <PageContentHeaderActionWrapper>{rightComponent}</PageContentHeaderActionWrapper>
      )}
    </PageContentHeaderWrapper>
  );
}

export default PageContentHeader;
