import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const MarketMappingLeftSectionContainer = styled(Stack)<StackProps>(() => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',

  '> .fetch-target-groups-list-container': {
    height: '100%',
  },
}));
