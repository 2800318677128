import { useNavigate } from 'react-router-dom';

import Link from '../../../../shared/components/link/base-link/base-link.component';
import { ButtonClickEvent } from '../../../../shared/domain/types/html-elements.types';
import { navigateToLogout } from '../../utils/auth-utils';

function Logout() {
  const navigate = useNavigate();

  function handleLogout(event: ButtonClickEvent) {
    event.preventDefault();
    navigateToLogout(navigate);
  }

  return <Link onClick={handleLogout} title="LOGOUT" icon />;
}

export default Logout;
