import { styled } from '@mui/material/styles';

import BaseInput from '../../../../shared/components/input/base-input/base-input.component';

export const SearchInput = styled(BaseInput)(() => ({
  '&.search-form-input': {
    marginBottom: '10px',
    '& .MuiInputBase-root': {
      padding: '10px 0',

      '& .MuiInputBase-input': {
        paddingLeft: '14px',
      },
    },
  },
}));
