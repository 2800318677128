import { Fragment } from 'react';

import { formatFieldName, renderValue, sortFields } from './utils';

interface CustomFieldsSectionProps {
  customFields?: Record<string, any>;
}

function CustomFieldsSection({ customFields }: CustomFieldsSectionProps) {
  if (!customFields || Object.keys(customFields).length === 0) {
    return null;
  }

  const sortedFields = sortFields(customFields);

  return (
    <div className="flex flex-col mb-8 space-y-6">
      <h2 className="text-2xl font-normal text-gray-900">Custom Research</h2>

      <hr className="-mt-4 border-t border-gray-200" />

      <div className="grid grid-cols-[auto_minmax(0,1fr)] gap-y-5">
        {sortedFields.map(([field, value]) => (
          <Fragment key={field}>
            <div className="pr-4 text-base text-gray-500">{formatFieldName(field)}</div>
            <div className="text-gray-900 text-md">{renderValue(value)}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default CustomFieldsSection;
