import {
  DROPDOWN_OPTIONS_OBJECT,
  URL_SOURCES,
} from '../components/add-list-dialog/add-list-dialog.constants';
import { SourceType } from '../components/add-list-dialog/add-list-dialog.types';

export const getObfuscatedSourceType = (sourceType: SourceType) => {
  return DROPDOWN_OPTIONS_OBJECT[sourceType] || sourceType;
};

export const getFullSourceType = (sourceType: string) => {
  return (
    Object.keys(DROPDOWN_OPTIONS_OBJECT).find(
      key => DROPDOWN_OPTIONS_OBJECT[key as SourceType] === sourceType,
    ) || sourceType
  );
};

export const isUrlSourceType = (sourceType: SourceType) => {
  return URL_SOURCES.includes(getObfuscatedSourceType(sourceType));
};
