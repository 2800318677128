import React, { PropsWithChildren } from 'react';

import {
  TargetDefinitionFormBaseInput,
  TargetDefinitionInput,
} from './target-definition-form-input.styles';
import { ITargetDefinitionFormInputProps } from './target-definition-form-input.types';

function TargetDefinitionFormInput<T extends { [key: string]: any }>({
  id,
  label,
  name,
  handleChange,
  values,
  type,
  minRows,
  disabled,
  isLabelShown = true,
  lastFocusedInput,
  setLastFocusedInput,
  placeholder,
  showInfoIcon,
  sx,
  min,
  max,
}: PropsWithChildren<ITargetDefinitionFormInputProps<T>>) {
  const handleFocus = () => {
    setLastFocusedInput(name, id);
  };

  const inputProps = {
    className: 'target-definition-form-input',
    id,
    label,
    name,
    handleChange,
    values,
    isLabelShown,
    disabled,
    onFocus: handleFocus,
    showInfoIcon: showInfoIcon ?? false,
    isInfoIconActive: lastFocusedInput === name,
    placeholder,
    sx,
    inputProps: {
      autoComplete: 'off',
      'data-1p-ignore': true,
      'data-lpignore': true,
      'data-form-type': 'other',
      min,
      max,
    },
  };

  return type === 'textarea' ? (
    <TargetDefinitionFormBaseInput {...inputProps} multiline minRows={minRows} />
  ) : (
    <TargetDefinitionInput {...inputProps} type={type || 'text'} />
  );
}

export default TargetDefinitionFormInput;
