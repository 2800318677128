import { ReactNode } from 'react';

import {
  ErrorModalContentType,
  InfoModalContentType,
  ModalContentType,
} from '../../feedback-ui/feedback-ui.types';
import ConfirmAction from './modal-content/confirm-action/confirm-action.component';
import { IConfirmActionProps } from './modal-content/confirm-action/confirm-action.types';

export const modalContentStrategy: { [Property in ModalContentType]: (props: any) => ReactNode } = {
  [ErrorModalContentType.SOME_ERROR_HAPPENED]: (props: any) => null,
  [InfoModalContentType.CONFIRM_ACTION]: (props: IConfirmActionProps) => {
    return <ConfirmAction {...props} />;
  },
};
