import React from 'react';

import SingleSectionScreenLayout from '../../app/layouts/single-sections-screen/single-sections-screen.component';
import FetchAllClientsList from '../../features/fetch-all-clients-list/components/fetch-all-clients-list.component';
import { SectionHeaderName } from './clients.styles';

const SECTION_HEADER = 'Clients List';

function Clients() {
  return (
    <SingleSectionScreenLayout>
      <SectionHeaderName>{SECTION_HEADER}</SectionHeaderName>
      <FetchAllClientsList />
    </SingleSectionScreenLayout>
  );
}

export default Clients;
