export function parseRevenue(revenue: string): number | null {
  if (!revenue || !revenue.trim()) {
    return null;
  }

  if (/^\s*[^\d\s]/.test(revenue)) {
    throw new Error(`Invalid revenue format: "${revenue}"`);
  }

  const sanitizedRevenue = revenue.trim().replace(/\s+/g, '').replace(/[,_]/g, '');

  const multipliers: Record<string, number> = {
    k: 1_000,
    m: 1_000_000,
    b: 1_000_000_000,
    t: 1_000_000_000_000,
  };

  const validPattern = /^-?\d+(?:\.\d+)?[kmbt]?$/i;
  if (!validPattern.test(sanitizedRevenue)) {
    throw new Error(`Invalid revenue format: "${revenue}"`);
  }

  const match = sanitizedRevenue.match(/^(-?\d+(?:\.\d+)?)([kmbt])?$/i);
  if (!match) {
    const numericValue = parseFloat(sanitizedRevenue);
    if (Number.isNaN(numericValue)) {
      throw new Error(`Invalid revenue format: "${revenue}"`);
    }
    return numericValue;
  }

  const [, num, suffix] = match;
  const parsedNum = parseFloat(num);
  if (Number.isNaN(parsedNum)) {
    throw new Error(`Invalid revenue format: "${revenue}"`);
  }
  return parsedNum * (suffix ? multipliers[suffix.toLowerCase()] : 1);
}

export function formatRevenue(revenue: number): string {
  const multipliers = [
    { value: 1_000_000_000_000, suffix: 'T' },
    { value: 1_000_000_000, suffix: 'B' },
    { value: 1_000_000, suffix: 'M' },
    { value: 1_000, suffix: 'K' },
  ];

  const matchingMultiplier = multipliers.find(({ value }) => revenue >= value);
  if (matchingMultiplier) {
    return `${(revenue / matchingMultiplier.value).toFixed(2)}${matchingMultiplier.suffix}`;
  }
  return revenue.toString();
}
