import { Divider } from '@mui/material';
import isEqual from 'lodash.isequal';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageContentHeader from '../../../app/layouts/page-content/page-content-header/page-content-header.component';
import { PageContentContainer } from '../../../app/layouts/page-content/page-content-header/page-content-header.styles';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';
import {
  getAllTargetGroupsByClient,
  updateTargetGroup,
} from '../../../entities/target-group/store/target-group.slice';
import { userSelector } from '../../../entities/user/store/user.selectors';
import { getCurrentClient } from '../../../entities/user/store/user.slice';
import DeleteTargetGroup from '../../../features/delete-target-group/components/delete-target-group.component';
import ButtonV2 from '../../../shared/components/button/button-v2/button-v2.component';
import MapperWithVariableComponents from '../../../shared/components/mapper/mapper-with-variable-components/mapper-with-variable-components.component';
import { useForm } from '../../../shared/hooks/use-form.hook';
import { useInputFocus } from '../../../shared/hooks/use-input-focus.hook';
import { useToast } from '../../../shared/hooks/use-toast.hook';
import {
  formCurrentTargetDefinitionFormState,
  formInputsConfig,
} from '../helpers/target-definition-form.helpers';
import { parseRevenue } from './currency-input/currency-input.helpers';
import CurrencyRangeInput from './currency-range-input/currency-range-input.component';
import FormFieldExplanation from './form-field-explanation/form-field-explanation.component';
import RangeInput from './range-input/range-input.component';
import {
  createTargetGroupSlackNotification,
  deleteTargetGroupSlackNotification,
  isHeadcountRangeValid,
  isRangeValid,
  updateTargetGroupSlackNotification,
} from './target-definition-form.helpers';
import {
  ExplanationsColumn,
  FormSection,
  SectionTitle,
  StyledBaseFormWrapper,
} from './target-definition-form.styles';
import TargetDefinitionFormInput from './target-definition-form-input/target-definition-form-input.component';

function TargetDefinitionForm() {
  const dispatch = useAppDispatch();
  const targetGroupFormData = useAppSelector(currentTargetGroupSelector);
  const targetGroupId = targetGroupFormData.id;

  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const {
    lastFocusedInput,
    explanationTop,
    handleInputFocus,
    setLastFocusedElement,
    smoothScroll,
  } = useInputFocus({
    formWrapperClass: 'target-definition-form-wrapper',
    explanationBoxClass: 'target-definition-form-explanation-box',
  });

  const currentTargetDefinitionFormState = formCurrentTargetDefinitionFormState(
    targetGroupFormData as ITargetGroup,
  );

  const useFormResult = useForm(currentTargetDefinitionFormState);
  const { formValues, handleInputChange, handleCustomInputChange } = useFormResult;

  const { clientId } = useParams();

  const user = useAppSelector(userSelector);
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    if (clientId) {
      dispatch(getCurrentClient({ clientId }))
        .unwrap()
        .then(currentClient => {
          setClientName(currentClient?.companyName || '');
        })
        .catch(() => {
          setClientName('');
        });
    }
  }, [clientId, dispatch]);

  const [originalFormValues, setOriginalFormValues] = useState(formValues);

  const inputsSetConfig = formInputsConfig<typeof formValues>(
    formValues,
    handleInputChange,
    handleCustomInputChange,
    true,
    {
      lastFocusedInput,
      setLastFocusedInput: (name, id) => {
        const element = document.getElementById(id);
        if (element) {
          handleInputFocus(name, element as HTMLElement);
        } else {
          throw new Error('Element not found');
        }
      },
    },
  );

  const discardChanges = () => {
    useFormResult.resetFormState(originalFormValues);
  };

  const isEditing = useMemo(() => {
    return Object.keys(formValues).some(
      key =>
        formValues[key as keyof typeof formValues] !==
        originalFormValues[key as keyof typeof originalFormValues],
    );
  }, [formValues, originalFormValues]);

  useEffect(() => {
    const newState = formCurrentTargetDefinitionFormState(targetGroupFormData as ITargetGroup);
    setOriginalFormValues(newState);
    useFormResult.resetFormState(newState);
  }, [targetGroupFormData]);

  const isTargetGroupFormValid = () => {
    const {
      name,
      description,
      locations,
      ownershipStructures,
      minHeadcount,
      maxHeadcount,
      minRevenue,
      maxRevenue,
    } = formValues;

    const parsedMinHeadcount = minHeadcount ? Number(minHeadcount) : NaN;
    const parsedMaxHeadcount = maxHeadcount ? Number(maxHeadcount) : NaN;

    let parsedMinRevenue;
    let parsedMaxRevenue;

    try {
      parsedMinRevenue = minRevenue ? parseRevenue(minRevenue) : undefined;
    } catch (error) {
      return false;
    }

    try {
      parsedMaxRevenue = maxRevenue ? parseRevenue(maxRevenue) : undefined;
    } catch (error) {
      return false;
    }

    const validations = {
      hasName: !!name,
      hasDescription: !!description,
      hasLocations: !!locations?.length,
      hasOwnershipStructures:
        ownershipStructures && Object.values(ownershipStructures).some(Boolean),
      validHeadcountRange: isHeadcountRangeValid(parsedMinHeadcount, parsedMaxHeadcount),
      validRevenueRange: isRangeValid(parsedMinRevenue, parsedMaxRevenue),
    };

    return Object.values(validations).every(Boolean);
  };

  const saveTargetGroup = (newTargetGroup = false) => {
    setIsLoading(true);

    if (!isTargetGroupFormValid()) {
      toast.error({
        message: 'Please ensure that all fields are filled in correctly.',
        duration: 3000,
      });
      setIsLoading(false);
      return;
    }

    const changedFields = Object.entries(formValues).reduce(
      (acc, [key, value]) => {
        const originalValue = originalFormValues[key as keyof typeof originalFormValues];
        if (!isEqual(value, originalValue)) {
          if (key === 'minHeadcount' || key === 'maxHeadcount') {
            acc['minHeadcount'] = formValues.minHeadcount;
            acc['maxHeadcount'] = formValues.maxHeadcount;
          } else if (key === 'minRevenue' || key === 'maxRevenue') {
            acc['minRevenue'] = formValues.minRevenue;
            acc['maxRevenue'] = formValues.maxRevenue;
          } else {
            acc[key] = value;
          }
        }
        return acc;
      },
      {} as Record<string, unknown>,
    );

    if (Object.keys(changedFields).length > 0) {
      dispatch(updateTargetGroup({ targetGroupId, body: changedFields }))
        .then(() => {
          dispatch(getAllTargetGroupsByClient({ clientId }));
        })
        .finally(() => {
          setIsLoading(false);
          if (newTargetGroup)
            createTargetGroupSlackNotification(
              { ...targetGroupFormData, ...changedFields },
              clientName,
              user,
              clientId,
            );
          else
            updateTargetGroupSlackNotification(
              { ...targetGroupFormData, ...changedFields },
              clientName,
              user,
              clientId,
              changedFields,
              originalFormValues,
            );
        });
    } else {
      setIsLoading(false);
    }
  };

  const isNewTargetGroup =
    !currentTargetDefinitionFormState.description &&
    currentTargetDefinitionFormState.name === 'New target group' &&
    currentTargetDefinitionFormState.locations?.length === 0 &&
    !currentTargetDefinitionFormState.ownershipStructures &&
    !currentTargetDefinitionFormState.minHeadcount &&
    !currentTargetDefinitionFormState.maxHeadcount &&
    !currentTargetDefinitionFormState.minRevenue &&
    !currentTargetDefinitionFormState.maxRevenue;

  return (
    <PageContentContainer sx={{ paddingRight: '1rem' }}>
      <PageContentHeader
        title={isNewTargetGroup ? 'New Target Group' : 'Target Definition'}
        description=""
        rightComponent={
          <>
            {targetGroupId ? (
              <DeleteTargetGroup
                targetGroupId={targetGroupId}
                onClick={async () => {
                  await deleteTargetGroupSlackNotification({
                    targetGroupName: targetGroupFormData.name,
                    clientName,
                    userEmail: user.email,
                  });
                }}
              />
            ) : null}
            {targetGroupId && !isNewTargetGroup && <Divider orientation="vertical" flexItem />}
            {!isNewTargetGroup ? (
              <>
                <ButtonV2
                  title="Discard Changes"
                  handleClick={discardChanges}
                  customVariant="primary-small"
                  disabled={!isEditing}
                />
                <ButtonV2
                  title="Save changes"
                  customVariant="secondary-small"
                  handleClick={() => saveTargetGroup(false)}
                  disabled={!isEditing || !isTargetGroupFormValid()}
                  isLoading={isLoading}
                  sx={{ width: '104px', height: '35px' }}
                />
              </>
            ) : null}
          </>
        }
      />

      {!isNewTargetGroup && (
        <TargetDefinitionFormInput
          id="date"
          type="text"
          label="Date"
          name="date"
          values={{
            date: new Date(targetGroupFormData.updatedAt).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }),
          }}
          disabled
          lastFocusedInput={lastFocusedInput}
          setLastFocusedInput={() => {}}
          showInfoIcon={false}
          sx={theme => ({
            input: { backgroundColor: `${theme.palette.neutrals.gray.lower}!important` },
            width: '50%',
            marginBottom: '2rem',
          })}
        />
      )}
      <StyledBaseFormWrapper isHeaderShown={false} className="target-definition-form-wrapper">
        <SectionTitle>Business Model & Capabilities</SectionTitle>
        <ExplanationsColumn className="explanations-column">
          <FormFieldExplanation
            top={explanationTop}
            fieldName={lastFocusedInput}
            smoothScroll={smoothScroll}
          />
        </ExplanationsColumn>

        <FormSection className="full-width-fields">
          <MapperWithVariableComponents config={inputsSetConfig.slice(0, 2)} />
        </FormSection>
        <SectionTitle sx={{ marginTop: -4 }}>Firmographics</SectionTitle>
        <FormSection className="full-width-fields">
          <MapperWithVariableComponents config={inputsSetConfig.slice(2, 4)} />

          <RangeInput
            label="headcount *"
            minFieldId="MIN_HEADCOUNT"
            maxFieldId="MAX_HEADCOUNT"
            minName="minHeadcount"
            maxName="maxHeadcount"
            lastFocusedInput={lastFocusedInput}
            handleInputFocus={handleInputFocus}
            setLastFocusedElement={setLastFocusedElement}
            values={formValues}
            minPlaceholder="e.g. 25"
            maxPlaceholder="e.g. 500"
            handleInputChange={handleInputChange}
          />

          <CurrencyRangeInput
            label="revenue"
            minFieldId="MIN_REVENUE"
            maxFieldId="MAX_REVENUE"
            minName="minRevenue"
            maxName="maxRevenue"
            lastFocusedInput={lastFocusedInput}
            handleInputFocus={handleInputFocus}
            setLastFocusedElement={setLastFocusedElement}
            values={formValues}
            placeholder="revenue"
            handleCustomInputChange={handleCustomInputChange}
          />
        </FormSection>

        {isNewTargetGroup && (
          <ButtonV2
            title="Submit"
            handleClick={() => saveTargetGroup(true)}
            customVariant="secondary-small"
            sx={{ maxWidth: '90px', marginTop: -4 }}
            disabled={!isEditing || !isTargetGroupFormValid()}
            isLoading={isLoading}
          />
        )}
      </StyledBaseFormWrapper>
    </PageContentContainer>
  );
}
export default TargetDefinitionForm;
