import { styled, Typography } from '@mui/material';

export const PageContentContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'visible',
}));

export const PageContentHeaderWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  gap: '1.25rem',
  paddingBlock: '2.5rem',
}));
export const PageContentHeaderTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',

  [theme.breakpoints.down('lg')]: {
    gap: '0.25rem',
  },
}));
export const PageContentHeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '48px',
  fontWeight: 400,
  lineHeight: '38.4px',
  textAlign: 'left',
  textDecorationSkipInk: 'none',

  transition: 'font-size 0.3s ease-in-out',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.5rem',
    lineHeight: '1.2',
  },
}));
export const PageContentHeaderDescription = styled('div')(({ theme }) => ({
  color: theme.palette.neutrals.gray.medium,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '150%',
  textTransform: 'uppercase',
  transition: 'font-size 0.3s ease-in-out',

  [theme.breakpoints.down('lg')]: {
    fontSize: '0.5rem',
  },
}));
export const PageContentHeaderActionWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '0.625rem',
  paddingTop: '0.5rem',
  flex: 1,
  justifyContent: 'flex-end',
}));
