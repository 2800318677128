import React from 'react';

import {
  SectionContainer,
  SingleSectionScreenLayoutContainer,
} from './single-sections-screen-with-message.styles';
import { ISingleSectionScreenLayoutProps } from './single-sections-screen-with-message.types';

function SingleSectionScreenLayout({ message }: ISingleSectionScreenLayoutProps) {
  return (
    <SingleSectionScreenLayoutContainer>
      <SectionContainer>{message}</SectionContainer>
    </SingleSectionScreenLayoutContainer>
  );
}

export default SingleSectionScreenLayout;
