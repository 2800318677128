import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const RunClassificationButton = styled(IconButton)<IconButtonProps & { isRunning: boolean }>(
  ({ theme, isRunning }) => ({
    borderRadius: '0.25rem',
    width: '3.125rem',
    height: '2.25rem',
    backgroundColor: isRunning ? '#FCDADA' : '#DCFCE7',
    '> svg': {
      fill: isRunning ? '#EF4444' : '#15803D',
    },
    '& .MuiCircularProgress-root': { color: theme.palette.warning.light },
    '&:hover': {
      backgroundColor: isRunning ? '#F7C7C7' : '#CFFADA',
    },
  }),
);
