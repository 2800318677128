import { PageContentContainer } from '../../../app/layouts/page-content/page-content-header/page-content-header.styles';
import { IAnalytic } from '../../../entities/target-group/domain/target-group.types';
import TableV2 from '../../../shared/components/table-v2/table-v2.component';

function TargetGroupAnalyticsTable({
  analytics,
  isAnalyticsLoading,
}: {
  analytics: IAnalytic[];
  isAnalyticsLoading: boolean;
}) {
  const columnDefs = [
    { field: 'targetGroupName', headerName: 'Target Group Name' },
    { field: 'companiesFound', headerName: 'Companies Found' },
    { field: 'companiesPreFiltered', headerName: 'Companies Pre-Filtered' },
    { field: 'companiesScraped', headerName: 'Companies Scraped' },
    { field: 'companiesSelected', headerName: 'Companies Selected' },
    { field: 'companiesVerified', headerName: 'Companies Verified' },
    { field: 'peopleFound', headerName: 'People Found' },
    { field: 'peopleVerified', headerName: 'People Verified' },
    { field: 'companiesAtReview', headerName: 'Companies At Review' },
  ];

  return (
    <PageContentContainer>
      <TableV2
        rowData={analytics}
        columnDefs={columnDefs}
        gridOptions={{
          getContextMenuItems: () => ['copy'],
          loading: isAnalyticsLoading,
          defaultColDef: { flex: 1 },
        }}
      />
    </PageContentContainer>
  );
}

export default TargetGroupAnalyticsTable;
