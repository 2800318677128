import { Box, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SingleSectionScreenLayout from '../../app/layouts/single-sections-screen/single-sections-screen.component';
import { useAppDispatch, useAppSelector } from '../../app/store/utils/redux.hooks';
import {
  analyticsSelector,
  targetGroupThunksSelector,
} from '../../entities/target-group/store/target-group.selectors';
import { fetchTargetGroupAnalytics } from '../../entities/target-group/store/target-group.slice';
import { clientsSelector, currentClientSelector } from '../../entities/user/store/user.selectors';
import { getAllClients, getCurrentClient } from '../../entities/user/store/user.slice';
import Logout from '../../features/authentication/components/logout/logout.component';
import DownloadAnalytics from '../../features/download-analytics/components/download-analytics.component';
import { formSelectCardProps } from '../../features/fetch-all-clients-list/helpers/fetch-all-clients-list.helpers';
import {
  LeftPanelIcon,
  SearchInput,
} from '../../features/fetch-target-groups-list/components/fetch-target-groups-list.styles';
import TargetGroupAnalyticsTable from '../../modules/target-group-analytics-table/components/target-group-analytics-table.component';
import Link from '../../shared/components/link/base-link/base-link.component';
import { LinkText } from '../../shared/components/link/base-link/base-link.styles';
import { LinkWrapper } from '../../shared/components/link/left-panel-list-link/left-panel-list-link.styles';
import { NavigationPaths } from '../../shared/domain/constants/routing.constants';
import {
  AnalyticsContainer,
  AnalyticsContent,
  AnalyticsSidebar,
  ClientsListContainer,
  ScrollableTableWrapper,
  SectionHeaderName,
  SidebarFooter,
  SidebarHeader,
} from './analytics.styles';

const SECTION_HEADER = 'Analytics';

export const LogoImage = styled('img')(() => ({
  width: '80px',
  objectFit: 'contain',
}));

function Analytics() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const currentClient = useAppSelector(currentClientSelector);
  const analytics = useAppSelector(analyticsSelector);
  const isAnalyticsLoading =
    useAppSelector(targetGroupThunksSelector).fetchTargetGroupAnalytics.isLoading;
  const clients = useAppSelector(clientsSelector);

  const navigate = useNavigate();

  const filteredClients = clients.filter(
    client =>
      client.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.id.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const clientsForSelect = formSelectCardProps(filteredClients);

  useEffect(() => {
    if (clientId) {
      dispatch(getCurrentClient({ clientId }));
      dispatch(fetchTargetGroupAnalytics({ clientId }));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    dispatch(getAllClients());
  }, [dispatch]);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <SingleSectionScreenLayout>
      <AnalyticsContainer>
        <AnalyticsSidebar className={isMenuOpen ? '' : 'collapsed'}>
          <SidebarHeader>
            <LogoImage className="panel-left-logo-image" src="/revi-logo-black.svg" />
            <LeftPanelIcon
              onClick={handleToggleMenu}
              src="/icons/left-panel-icon.svg"
              className="left-panel-icon"
            />
          </SidebarHeader>
          <Box className="search-clients-wrapper">
            <SearchInput
              id="search-input"
              name="clients"
              placeholder="Search clients..."
              handleChange={e => setSearchQuery(e.target.value)}
              values={{ clients: searchQuery }}
              className="search-clients"
            />
          </Box>
          <Box sx={{ overflowY: 'auto' }}>
            <ClientsListContainer>
              {clientsForSelect.map(client => {
                const isActive = client.clientId === clientId;

                return (
                  <LinkWrapper
                    isActive={isActive}
                    onClick={() => navigate(NavigationPaths.ANALYTICS(client.clientId))}
                  >
                    <LinkText className="left-panel-list-link-title" rel="noreferrer">
                      {client.clientName}
                    </LinkText>
                  </LinkWrapper>
                );
              })}
            </ClientsListContainer>
          </Box>

          <SidebarFooter>
            <Link
              onClick={() => navigate(NavigationPaths.TARGET_REQUIREMENTS(clientId))}
              title="Home"
              icon
            />
            <DownloadAnalytics />
            <Logout />
          </SidebarFooter>
        </AnalyticsSidebar>
        <AnalyticsContent className={isMenuOpen ? '' : 'collapsed'}>
          <SectionHeaderName>{`${currentClient?.companyName ?? ''} ${SECTION_HEADER}`}</SectionHeaderName>

          <ScrollableTableWrapper>
            {currentClient ? (
              <TargetGroupAnalyticsTable
                analytics={analytics}
                isAnalyticsLoading={isAnalyticsLoading}
              />
            ) : (
              <Typography
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.5rem',
                }}
              >
                Client not found!
              </Typography>
            )}
          </ScrollableTableWrapper>
        </AnalyticsContent>{' '}
      </AnalyticsContainer>
    </SingleSectionScreenLayout>
  );
}

export default Analytics;
