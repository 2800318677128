import React, { Fragment } from 'react';

import { FeedbackUiType } from '../feedback-ui.types';
import { infoFeedbackUiKit } from './info-feedback-ui-kit.strategy';
import { IInfoFeedbackUiKitProps } from './info-feedback-ui-kit.types';

function InfoFeedbackUiKit({ feedbackUiType, uiProps }: IInfoFeedbackUiKitProps) {
  return <>{infoFeedbackUiKit[feedbackUiType || FeedbackUiType.MODAL](uiProps)}</>;
}

export default InfoFeedbackUiKit;
