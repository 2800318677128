import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/store/utils/redux.hooks';
import { addToast, clearAllToasts, removeToast, Toast } from '../store/toast.slice';

export function useToast() {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector(state => state.toast.toasts);

  const showToast = useCallback(
    (toast: Omit<Toast, 'id'>) => {
      const action = dispatch(addToast(toast));
      const { id } = action.payload as Toast;

      if (toast.duration !== 0) {
        setTimeout(() => {
          dispatch(removeToast(id));
          toast.onClose?.();
        }, toast.duration || 5000);
      }
    },
    [dispatch],
  );

  const toast = {
    success: (options: Omit<Toast, 'id' | 'type'>) => showToast({ ...options, type: 'success' }),
    error: (options: Omit<Toast, 'id' | 'type'>) => showToast({ ...options, type: 'error' }),
    warning: (options: Omit<Toast, 'id' | 'type'>) => showToast({ ...options, type: 'warning' }),
    info: (options: Omit<Toast, 'id' | 'type'>) => showToast({ ...options, type: 'info' }),
    custom: (options: Omit<Toast, 'id'>) => showToast(options),
    remove: (id: string) => dispatch(removeToast(id)),
    clearAll: () => dispatch(clearAllToasts()),
  };

  return { toast, toasts };
}
