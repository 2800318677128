import { BaseListServerResponse } from '../../../shared/domain/types/base.types';
import {
  IParentCompany,
  IPEBacked,
} from '../../company-target-group/domain/company-target-group.types';
import { IAcquisition, ICompanyTargetGroupLocation } from './company.base.types';

export enum UploadLeadsStrategies {
  onResearch = 'onReasearch',
  onReview = 'onReview',
}

export interface IUploadedCompany {
  id: string;
  name: string;
  website: string;
  linkedIn: string;
  headcount: number;
}

export interface IThunkStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  isStarted: boolean;
  isFinished: boolean;
}

export interface ICompanyThunks {
  uploadCompanies: IThunkStatuses;
  getCompaniesByTargetGroup: IThunkStatuses;
  getNewsEventsByTargetGroup: IThunkStatuses;
  getNewsEventDetails: IThunkStatuses;
}

export interface ICurrentCompany {
  id: string;
  name: string;
}

export interface ICompaniesFile {
  progressPercent: number;
  message: string;
  isFileDataUploaded: boolean;
}

export interface IGetCompaniesByTargetGroupThunkParams {
  targetGroupId: string;
  take: number;
  skip: number;
  search?: string;
  excludedIds?: string[];
}

export interface IUploadedCompaniesListResponse extends BaseListServerResponse<IUploadedCompany> {}

export interface ICompanyState {
  currentCompany: ICurrentCompany;
  uploadedCompanies: IUploadedCompaniesListResponse;
  newsEvents: INewsEventsListResponse;
  companiesFile: ICompaniesFile;
  thunks: ICompanyThunks;
  sorting: { isSorted: boolean; sortBy?: NewsEventsSortBy };
  selectedNewsEventIds: ISelectedNewsEventIds | null;
  currentNewsEventDetails: INewsEventDetails | null;
  newsEventsCategories: INewsEventsCategory[];
  newsEventsFilterByCompanies: INewsEventsCompany[];
  newsEventsFilterByCategories: INewsEventsCategory[];
}

export enum NewsEventCategoryTypes {
  LeadershipChange = 'Leadership Change',
  FinancialEvent = 'Financial Event',
  Expansion = 'Expansion',
  NewOffering = 'New Offering',
  CostCutting = 'Cost Cutting',
  Partnerships = 'Partnerships',
  Awards = 'Awards',
  ContractWon = 'Contract Won',
  CorporateChallenges = 'Corporate Challenges',
  CompetitionNews = 'Competition News',
}

export interface INewsEventsCompany {
  id: string;
  name: string;
}
export interface INewsEventsCategory {
  id: string;
  name: NewsEventCategoryTypes;
  values: string[];
}

export enum NewsEventsSortBy {
  Date = 'eventArticlePublishedAt',
  CompanyName = 'companyName',
}

export interface IGetNewsEventsThunkParams {
  clientId?: string;
  targetGroupId: string;
  companies?: INewsEventsCompany[];
  categories: INewsEventsCategory[];
}

export interface IGetNewsEventDetailsThunkParams extends ISelectedNewsEventIds {}

export interface INewsEvent {
  companyId: string;
  companyName: string;
  companyWebsite: string;
  eventId: string;
  eventType: string;
  eventTitle: string;
  eventUrl: string;
  eventFoundAt: string;
  eventCategories: string;
  eventArticlePublishedAt: string;
  eventArticleTitle: string;
}

export interface INewsEventsListResponse extends BaseListServerResponse<INewsEvent> {}

export interface IOtherNewsEvent {
  id: string;
  eventUrl: string;
  eventTitle: string;
  eventFoundAt: string;
  eventArticlePublishedAt: string;
}

interface ITargetNewsEvent extends IOtherNewsEvent {
  eventCategories: string[];
  summary: string;
  source: string;
}

export interface INewsEventDetails {
  id: string;
  name: string;
  website: string;
  headcount: number;
  sixMonthHeadcountGrowth: number;
  revenue?: string;
  profit?: string;
  revenueEst?: string;
  profitYear?: string;
  revenueYear?: string;
  currency?: string;
  EBITDA: string;
  cleanName: string;
  businessType: string;
  location: ICompanyTargetGroupLocation;
  foundedYear: string;
  acquisitions: IAcquisition[];
  otherNewsEvents: IOtherNewsEvent[];
  targetNewsEvent: ITargetNewsEvent;
  parentCompany?: IParentCompany;
  founderOwned?: boolean;
  PEbackers?: IPEBacked[];
  ownership?: string;
}

export interface ISelectedNewsEventIds {
  companyId: string;
  eventId: string;
}
