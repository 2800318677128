import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const MarketMappingRightSectionContainer = styled(Container, {
  shouldForwardProp: props => props !== 'isTargetGroupChosen',
})<ContainerProps & { isTargetGroupChosen: boolean }>(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
