import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import axios from '../../../app/services/axios.service';
import { generateBaseThunkReducers } from '../../../shared/store/base-thunk-reducer';
import {
  IClient,
  IClientsListResponse,
  IUser,
  NotificationPreferences,
  UserState,
} from '../domain/user.types';

const defaultThunkStatus = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: {},
  isStarted: false,
  isFinished: false,
};

const defaultUser = {
  id: '',
  name: '',
  surname: '',
  email: '',
  roles: [],
  assignedClientId: null,
  createdAt: '',
  updatedAt: '',
};

export const userState: UserState = {
  user: defaultUser,
  currentClient: undefined,
  clients: [],
  selectedClients: [],
  thunks: {
    getAllClients: defaultThunkStatus,
    getCurrentClient: defaultThunkStatus,
    updateNotificationSettings: defaultThunkStatus,
    getUser: defaultThunkStatus,
  },
};

export const getUser = createAsyncThunk('client/user', async (_, { rejectWithValue }) => {
  try {
    const endpoint = 'client/user';
    const { data: user } = await axios.get(endpoint);
    return user;
  } catch (error: any) {
    const rejectionError = error.response.data;
    return rejectWithValue(rejectionError);
  }
});

export const getAllClients = createAsyncThunk(
  'targetGroup/getAllClients',
  async (_, { rejectWithValue }) => {
    try {
      const endpoint = 'client/all';
      const { data: clientsList } = await axios.get(endpoint);
      return clientsList as IClientsListResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCurrentClient = createAsyncThunk(
  'targetGroup/getCurrentClient',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `client/current/${clientId}`;
      const { data: currentClient } = await axios.get(endpoint);
      return currentClient as IClient;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateNotificationSettings = createAsyncThunk(
  'user/updateNotificationSettings',
  async (
    body: { clientId: string; email: string; preference: NotificationPreferences },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `client/notification-settings`;
      const { data } = await axios.put(endpoint, body);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    pushSelectedClientsToFilter: (state, action: PayloadAction<any[]>) => {
      state.selectedClients = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      const user = action.payload;
      state.user.id = user.id;
      state.user.name = user.name;
      state.user.surname = user.surname;
      state.user.email = user.email;
      state.user.roles = user.roles;
      state.user.assignedClientId = userState.user.assignedClientId;
      state.user.createdAt = user.createdAt;
      state.user.updatedAt = user.updatedAt;
    },
    resetUser: state => {
      state.user.id = userState.user.id;
      state.user.name = userState.user.name;
      state.user.surname = userState.user.surname;
      state.user.email = userState.user.email;
      state.user.roles = userState.user.roles;
      state.user.assignedClientId = userState.user.assignedClientId;
      state.user.createdAt = userState.user.createdAt;
      state.user.updatedAt = userState.user.updatedAt;
    },
  },

  extraReducers: builder => {
    generateBaseThunkReducers(builder, getCurrentClient, 'getCurrentClient', 'currentClient');
    generateBaseThunkReducers(builder, getUser, 'getUser', 'user');

    builder.addCase(getAllClients.pending, state => {
      state.thunks.getAllClients.isLoading = true;
      state.thunks.getAllClients.isSuccess = false;
      state.thunks.getAllClients.error = {};
      state.thunks.getAllClients.isError = false;
      state.thunks.getAllClients.isStarted = true;
    });
    builder.addCase(
      getAllClients.fulfilled,
      (state, action: PayloadAction<IClientsListResponse>) => {
        const data = action.payload;
        state.clients = data.items;
        state.thunks.getAllClients.isLoading = false;
        state.thunks.getAllClients.isSuccess = true;
        state.thunks.getAllClients.error = {};
        state.thunks.getAllClients.isError = false;
        state.thunks.getAllClients.isStarted = false;
        state.thunks.getAllClients.isFinished = true;
      },
    );
    builder.addCase(getAllClients.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getAllClients.error = error;
      state.thunks.getAllClients.isError = true;
      state.thunks.getAllClients.isLoading = false;
      state.thunks.getAllClients.isStarted = false;
      state.thunks.getAllClients.isFinished = true;
    });

    generateBaseThunkReducers(
      builder,
      updateNotificationSettings,
      'updateNotificationSettings',
      'currentClient',
    );
  },
});

export const { resetUser, pushSelectedClientsToFilter } = userSlice.actions;

const fieldsToSkip = ['currentClient', 'thunks', 'clients'];
const excludeTransform = createTransform(
  (inboundState, key: any) => (fieldsToSkip.includes(key) ? undefined : inboundState),
  (outboundState, _) => outboundState,
);

export const userReducer = persistReducer(
  {
    key: 'user',
    storage,
    transforms: [excludeTransform],
  },
  userSlice.reducer,
);
