import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const AuthenticationLayoutContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const AuthenticationBackground = styled(Container)<ContainerProps>(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    width: '106rem',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    backgroundSize: 'contain',
  },
}));
