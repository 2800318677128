import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  LinkText,
  LinkWrapper,
} from '../../../../shared/components/link/base-link/base-link.styles';
import { NavigationPaths } from '../../../../shared/domain/constants/routing.constants';
import { IClientCardProps } from './client-card.types';

function ClientCard({ clientId, clientName }: IClientCardProps) {
  const navigate = useNavigate();

  async function handleClientEntityClick() {
    navigate(NavigationPaths.TARGET_REQUIREMENTS(clientId));
  }

  return (
    <>
      <LinkWrapper
        onClick={handleClientEntityClick}
        className="client-card"
        rel="noreferrer"
        sx={theme => ({
          padding: '0.5rem',
          gap: '0.875rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
          borderRadius: '0.5rem',
          transition: 'all 0.3s ease',
          marginBlock: '0.25rem',
          flexShrink: 0,

          '&:hover': {
            borderRadius: '0.5rem',
            backgroundColor: theme.palette.neutrals.gray.low,
          },
        })}
      >
        <LinkText sx={{ fontSize: '1rem' }}>{clientName}</LinkText>
        <ChevronRightIcon sx={{ opacity: 0.5 }} />
      </LinkWrapper>
      <Divider orientation="horizontal" flexItem sx={{ width: '95%', margin: 'auto' }} />
    </>
  );
}

export default React.memo(ClientCard);
