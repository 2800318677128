import React, { PropsWithChildren } from 'react';

import { getValueFromObject } from '../../../helpers/base.helpers';
import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';
import { InfoIcon, Label } from '../../input/base-input/base-input.styles';
import { MuiTextarea, TextareaContainer } from './base-textarea.styles';
import { IBaseTextareaProps } from './base-textarea.types';

function BaseTextarea<T extends { [key: string]: any }>({
  id,
  className,
  label,
  name,
  handleChange,
  values,
  errors,
  maxRows,
  minRows,
  disabled,
  placeholder,
  multiline,
  isLabelShown = false,
  onFocus,
  showInfoIcon = false,
  isInfoIconActive = false,
}: PropsWithChildren<IBaseTextareaProps<T>>) {
  return (
    <TextareaContainer>
      {isLabelShown ? (
        <Label
          shrink
          htmlFor={id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {label ?? ''}

          {showInfoIcon && (
            <InfoIcon
              src={`/icons/info-circle-${isInfoIconActive ? 'fill' : 'outline'}.svg`}
              onClick={onFocus}
            />
          )}
        </Label>
      ) : null}
      <MuiTextarea
        multiline={multiline ?? true}
        id={id}
        label={isLabelShown ? null : label}
        name={name}
        value={getValueFromObject(values, name)}
        onChange={disabled ? undefined : handleChange}
        onFocus={onFocus}
        className={className}
        maxRows={maxRows ?? undefined}
        minRows={minRows ?? undefined}
        hasError={errors?.[name]?.hasError ?? false}
        disabled={disabled ?? false}
        placeholder={placeholder ?? ''}
      />
      {errors?.[name] && (
        <ValidationErrorsHelper
          errors={errors}
          name={name}
          id={id}
          className="textarea-validation-helper"
        />
      )}
    </TextareaContainer>
  );
}

export default BaseTextarea;
