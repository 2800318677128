import { ClientType } from '../components/target-definition-form-input/target-definition-form-input.types';

export const TypeformId: Record<ClientType, string> = {
  [ClientType.CORPORATE_DEVELOPMENT]: 'DA4EzQec',
  [ClientType.INVESTMENT_BANKS]: 'Li05V5S0',
  [ClientType.PRIVATE_EQUITY]: 'cN8IdBVx',
};

export const FIELD_INFO = {
  description: {
    title: 'AI prompt',
    description: `Please enter a concise description of a specific type of business you're interested in. The description should be focused on their business model and capabilities.`,
    examples: [
      `I'm looking for managed IT service providers.`,
      `I'm looking for pharmacovigilance consulting firms.`,
      `I'm looking for revenue cycle management software. I'm not interested in service providers.`,
      `I'm looking for solar installation companies. I'm only interested in companies doing installation, not manufacturing.`,
      `I'm looking for contract development and manufacturing organizations. They must have the capability to work with small molecules.`,
      `I'm looking for business insurance brokers. They must be operating in a B2B model, not B2C.`,
    ],
  },
  minHeadcount: {
    title: 'Headcount range',
    description: `Please select a minimum headcount (at least 5) under which companies will undoubtedly be too small. And a maximum headcount above which companies will undoubtedly be too large.`,
    examples: [],
  },
  maxHeadcount: {
    title: 'Headcount range',
    description: `Please select a minimum headcount (at least 5) under which companies will undoubtedly be too small.
    And a maximum headcount above which companies will undoubtedly be too large.`,
    examples: [],
  },
  minRevenue: {
    title: 'Revenue range',
    description: `*Exact financial data on private companies is only available in certain countries.`,
    examples: ['$5m-$20m', '€1m-€10m', '£50m-£200m'],
  },
  maxRevenue: {
    title: 'Revenue range',
    description:
      '*Exact financial data on private companies is only available in certain countries.',
    examples: ['$5m-$20m', '€1m-€10m', '£50m-£200m'],
  },
};
