import React, { Fragment } from 'react';

import {
  InfoOrErrorFeedbackUiType,
  StatusFeedbackUiType,
} from '../../components/feedback-ui/feedback-ui.types';
import InfoFeedbackUiKit from '../../components/feedback-ui/info/info-feedback-ui-kit.component';
import StatusFeedbackUiKit from '../../components/feedback-ui/status/status-feedback-ui-kit.component';
import { infoConfigDefaults, statusConfigDefaults } from './feedback-ui.constants';
import { IFeedbackUiDecoratorProps } from './feedback-ui.types';

function FeedbackUiDecorator({
  children,
  isChildrenVisible = false,
  statusConfig = statusConfigDefaults,
  infoConfig = infoConfigDefaults,
  checkboxConfig,
}: IFeedbackUiDecoratorProps) {
  const { isLoading, statusUiType, statusUiSubtype, statusTitle, size, className } = statusConfig;

  if ((isLoading && isChildrenVisible) || isLoading)
    return (
      <>
        <StatusFeedbackUiKit
          feedbackUiType={statusUiType as StatusFeedbackUiType}
          uiProps={{
            status: isLoading || false,
            uiSubtype: statusUiSubtype,
            title: statusTitle,
            size,
            className,
          }}
        />
        {isChildrenVisible && children}
      </>
    );

  if ((infoConfig.isInfo && isChildrenVisible) || infoConfig.isInfo)
    return (
      <>
        <InfoFeedbackUiKit
          feedbackUiType={infoConfig.uiType as InfoOrErrorFeedbackUiType}
          uiProps={{
            isUiVisible: infoConfig.isInfo || false,
            uiContentType: infoConfig.uiContentType,
            title: infoConfig.title,
            message: infoConfig.message, // array
            actionTitle: infoConfig.actionTitle,
            cancelTitle: infoConfig.cancelTitle,
            handleAction: infoConfig.handleAction,
            handleClose: infoConfig.handleClose,
            handleCancel: infoConfig.handleCancel,
            isActionLoading: infoConfig.isActionLoading,
            checkboxConfig,
          }}
        />
        {isChildrenVisible && children}
      </>
    );

  return <>{children}</>;
}

export default FeedbackUiDecorator;
