import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const SingleSectionScreenLayoutContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    width: '100%',
    height: '100vh',
    overflow: 'hidden',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  }),
);

export const SectionContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',

  backgroundColor: theme.palette.neutrals.gray.lowest,
  position: 'relative',
  fontSize: '0.8rem',
  fontWeight: 400,
  color: theme.palette.neutrals.gray.highest,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
}));
