import { ValidatorResult } from '../../domain/types/validation.types';
import { BaseValidationErrorMessages } from './base-validation-error-messages.constants';
import {
  CustomFnCheckHelper,
  CustomFnParams,
  CustomFnWithObjectReturnTypeCheckHelper,
} from './validator.types';
import { ValidatorCheckHelpers } from './validator-check.helpers';

class Check {
  constructor(property: string, value: any) {
    this.property = property;
    this.value = value;
  }

  private property: string;

  private value: any;

  private errorsStack: { [key: string]: string[] } = {};

  private checkIsPropertyWithError(): boolean {
    const isPropertyWithError = Boolean(this.errorsStack[this.property]?.length >= 1);
    return isPropertyWithError;
  }

  private passOrStackTheError(
    checkHelperResult: boolean | undefined,
    errorMessage: string = BaseValidationErrorMessages.INCORRECT_VALUE,
  ): Check {
    if (!checkHelperResult) {
      const isPropertyWithError = this.checkIsPropertyWithError();
      if (isPropertyWithError) this.errorsStack[this.property].push(errorMessage);
      else this.errorsStack[this.property] = [errorMessage];
    }
    return this;
  }

  public validate() {
    const isPropertyWithError = this.checkIsPropertyWithError();
    const errorsStacked = this.errorsStack?.[this.property];
    if (isPropertyWithError) return new ValidatorResult({ hasError: true, errors: errorsStacked });
    return new ValidatorResult();
  }

  public notEmpty(errorMessage: string = BaseValidationErrorMessages.NOT_EMPTY): Check {
    return this.passOrStackTheError(
      ValidatorCheckHelpers.checkIsNotEmpty(this.value),
      errorMessage,
    );
  }

  public isString(errorMessage: string = BaseValidationErrorMessages.MUST_BE_STRING): Check {
    return this.passOrStackTheError(ValidatorCheckHelpers.checkIsString(this.value), errorMessage);
  }

  public isEmail(errorMessage: string = BaseValidationErrorMessages.EMAIL_FORMAT_INVALID): Check {
    return this.passOrStackTheError(ValidatorCheckHelpers.checkIsEmail(this.value), errorMessage);
  }

  public isArray(errorMessage: string = BaseValidationErrorMessages.MUST_BE_ARRAY): Check {
    return this.passOrStackTheError(Array.isArray(this.value), errorMessage);
  }

  public isValueIn(values: string[]): Check {
    return this.passOrStackTheError(
      values.includes(this.value),
      BaseValidationErrorMessages.VALUE_MUST_BE_IN(values),
    );
  }

  public matchPattern(
    pattern: any,
    errorMessage: string = BaseValidationErrorMessages.DOES_NOT_MATCH_THE_PATTERN,
  ): Check {
    return this.passOrStackTheError(
      ValidatorCheckHelpers.checkPatternMatch(this.value, pattern),
      errorMessage,
    );
  }

  public length(
    min: number,
    max: number,
    errorMessage: string = BaseValidationErrorMessages.INCORRECT_LENGTH,
  ): Check {
    return this.passOrStackTheError(
      ValidatorCheckHelpers.checkStringLength(this.value, min, max),
      errorMessage,
    );
  }

  public minLength(
    min: number,
    errorMessage: string = BaseValidationErrorMessages.INCORRECT_LENGTH,
  ): Check {
    return this.passOrStackTheError(
      ValidatorCheckHelpers.checkStringMinLength(this.value, min),
      errorMessage,
    );
  }

  public maxLength(
    max: number,
    errorMessage: string = BaseValidationErrorMessages.INCORRECT_LENGTH,
  ): Check {
    return this.passOrStackTheError(
      ValidatorCheckHelpers.checkStringMaxLength(this.value, max),
      errorMessage,
    );
  }

  public customFn(
    customCheckHelper: CustomFnCheckHelper,
    params: CustomFnParams = { errorMessage: BaseValidationErrorMessages.INCORRECT_VALUE },
  ): Check {
    const { errorMessage, ...restParams } = params;
    return this.passOrStackTheError(customCheckHelper(this.value, restParams), errorMessage);
  }

  public customFnWithObjectReturnType(
    customCheckHelper: CustomFnWithObjectReturnTypeCheckHelper,
    params?: { [key: string]: any },
  ): Check {
    const { booleanResult, errorMessage } = customCheckHelper(this.value, params);
    return this.passOrStackTheError(booleanResult, errorMessage);
  }
}

export function check(property: string, value: any): Check {
  return new Check(property, value);
}
