import React, { MouseEvent } from 'react';

import { formatUrlToGuaranteeClickability } from '../../../helpers/base.helpers';
import { LinkText, LinkWrapper } from './base-link.styles';
import { ILinkProps } from './base-link.types';

function Link({ target, url = '#', title, icon = false, onClick, className, sx }: ILinkProps) {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!url || url === '#') {
      e.preventDefault();
    }
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <LinkWrapper
      onClick={handleClick}
      href={url ? formatUrlToGuaranteeClickability(url) || '#' : '#'}
      target={target}
      rel="noreferrer"
      className={className ?? ''}
      sx={sx}
    >
      <LinkText>{title}</LinkText>
      {icon && <LinkText className="link-right-chevron">{'>'}</LinkText>}
    </LinkWrapper>
  );
}

export default Link;
