import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

export const DashboardLayoutContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.neutrals.gray.lowest,
  color: theme.palette.neutrals.gray.highest,

  '&.dashboard-layout-container': {
    padding: '1.25rem 1rem',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const DashboardLayoutBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  backgroundColor: theme.palette.neutrals.gray.lowest,
  boxShadow: 'none',
  position: 'static',
}));

export const LogoImage = styled('img')(() => ({
  width: '68px',
  height: '40px',
}));
