import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../app/services/axios.service';
import { BaseServerResponse } from '../../../shared/domain/types/base.types';
import {
  ValidationResultsObject,
  ValidatorResult,
} from '../../../shared/domain/types/validation.types';
import { formSelectWeekDropdownOptions } from '../../../shared/helpers/select-company-creation-week.helpers';
import { generateBaseThunkReducers } from '../../../shared/store/base-thunk-reducer';
import {
  AnalyticsCompanyTargetGroup,
  ClientsCompanyByTargetGroup,
  CompaniesForResearchFilterCriterion,
  CompaniesForResearchFilterCriterionName,
  CompaniesForReviewFilterCriterion,
  CompaniesForReviewFilterCriterionName,
  CompanyTargetGroupsAnalyticsKpi,
  CompanyTargetGroupsAnalyticsSortBy,
  IBaseCompaniesNewsEventsItemResponse,
  IClientsCompaniesByTargetGroupListResponse,
  ICompaniesForResearchListResponse,
  ICompaniesForReviewListResponse,
  ICompanyForReview,
  ICompanyNewsEventsItemResponse,
  ICompanyTargetGroupDataForResearch,
  ICompanyTargetGroupDataForReview,
  ICompanyTargetGroupResponse,
  ICompanyTargetGroupsForAnalyticsListResponse,
  ICompanyTargetGroupState,
  ICountry,
  ICurrentCompanyForResearchParams,
  ICurrentCompanyForReviewParams,
  ICurrentResearchFilterCriterion,
  ICurrentReviewFilterCriterion,
  ICurrentWeekOfCompaniesCreation,
  IDownloadCompanyTargetGroupsAnalyticsThunkParams,
  IFindLeadEmailParams,
  IGetClientCompaniesListByTargetGroupIdThunkParams,
  IGetCompaniesForResearchThunkParams,
  IGetCompaniesForReviewByTargetGroupIdThunkParams,
  IGetCompaniesForReviewThunkParams,
  IGetCompanyNewsEventsThunkParams,
  IGetCompanyTargetGroupsAnalyticsKpiSetThunkParams,
  IGetCompanyTargetGroupsForAnalyticsThunkParams,
  ILead,
  IMarkAsEmailValidation,
  IMarkAsInvalidEmail,
  IMarkAsNotAFit,
  IMarkAsPaused,
  IMarkAsRejected,
  IMarkAsResearched,
  IMarkAsToResearch,
  IMarkAsValidated,
  IMarkCompanyAsNotAFitThunkParams,
  IMarkCompanyAsResearchedThunkParams,
  IMarkCompanyAsToResearchThunkParams,
  IRelevantCompaniesDataItemResponse,
  IRelevantCompaniesNewsEventsItemResponse,
  ISetRejectionStatusBody,
  IUpdateCompanyForResearchParams,
  IUpdateEmailSequencesItemResponse,
  IUpdateEmailSequencesParams,
  IUpdateRelevantCompaniesThunkParams,
  IUpdateStatusBody,
  IWeeklyIntervalsOfCompaniesCreationListResponse,
} from '../domain/company-target-group.types';
import { updateStateAndHandlePaginatedData } from '../helpers/company-target-group.helpers';

export const defaultEmail = { subject: '', body: '' };

export const defaultCompanyTargetGroupsForAnalytics = {
  isLoading: false,
  data: {} as ICompanyTargetGroupsForAnalyticsListResponse,
  isError: false,
  error: {},
  sorting: { isSorted: false, sortBy: undefined },
};

export const defaultCompanyTargetGroupsAnalyticsKpiSet = {
  isLoading: false,
  data: [] as CompanyTargetGroupsAnalyticsKpi[],
  isError: false,
  error: {},
};

export const defaultClientsCompaniesByTargetGroup = {
  isLoading: false,
  data: {} as IClientsCompaniesByTargetGroupListResponse,
  isError: false,
  error: {},
};

export const defaultCompanyNewsEvents = {
  isLoading: false,
  data: {} as ICompanyNewsEventsItemResponse,
  isError: false,
  error: {},
};

export const defaultRelevantCompaniesNewsEvents = {
  isLoading: false,
  data: {} as IRelevantCompaniesNewsEventsItemResponse,
  isError: false,
  error: {},
};

export const defaultWeekInterval = 'No week intervals';

export const defaultWeekOfCompaniesCreation = {
  id: defaultWeekInterval,
  name: defaultWeekInterval,
  values: { startDate: '', endDate: '', id: defaultWeekInterval, name: defaultWeekInterval },
};

export const defaultResearchFilterCriterion = {
  id: CompaniesForResearchFilterCriterion.TO_RESEARCH,
  name: CompaniesForResearchFilterCriterionName.TO_RESEARCH,
  value: CompaniesForResearchFilterCriterion.TO_RESEARCH,
};

export const defaultReviewFilterCriterion = {
  id: CompaniesForReviewFilterCriterion.TO_VALIDATE,
  name: CompaniesForReviewFilterCriterionName.TO_VALIDATE,
  value: CompaniesForReviewFilterCriterion.TO_VALIDATE,
};

const defaultThunkStatus = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: {},
  isStarted: false,
  isFinished: false,
};

export const defaultCompanyTargetGroupState = {
  companyTargetGroupsForAnalytics: defaultCompanyTargetGroupsForAnalytics,
  companyTargetGroupsAnalyticsKpiSet: defaultCompanyTargetGroupsAnalyticsKpiSet,
  clientsCompaniesByTargetGroup: defaultClientsCompaniesByTargetGroup,
  companyNewsEvents: defaultCompanyNewsEvents, //
  relevantCompaniesNewsEvents: defaultRelevantCompaniesNewsEvents,
  areCompanyTargetGroupsStatusesUpdated: false,
  weeklyIntervalsOfCompaniesCreation: {} as IWeeklyIntervalsOfCompaniesCreationListResponse,
  currentWeekOfCompanyCreation: defaultWeekOfCompaniesCreation,
  currentCompanyForResearchParams: {} as ICurrentCompanyForResearchParams,
  companiesForResearch: {} as ICompaniesForResearchListResponse,
  companiesToResearchCount: 0,
  companiesToReviewCount: 0,
  peopleResearchedCompaniesCount: 0,
  countries: [],
  currentResearchFilterCriterion: defaultResearchFilterCriterion,
  companyForResearch: {
    item: {} as ICompanyTargetGroupDataForResearch,
    markAsToResearch: { isMarkedAsToResearch: false, message: '' },
    markAsResearched: { isMarkedAsResearched: false, message: '' },
    markAsNotAFit: { isMarkedAsNotAFit: false, message: '' },
    markAsEmailValidation: { isMarkedAsEmailValidation: false, message: '' },
    markAsInvalidEmail: { isMarkedAsInvalidEmail: false, message: '' },
    formValidationErrors: {},
    isFirstCompanyLoad: false,
  },
  companiesForReview: {} as ICompaniesForReviewListResponse,
  currentReviewFilterCriterion: defaultReviewFilterCriterion,
  currentCompanyForReviewParams: {} as ICurrentCompanyForReviewParams,
  companyForReview: {
    item: {} as ICompanyTargetGroupDataForReview,
    markAsValidated: { isMarkedAsValidated: false, message: '' },
    markAsRejected: { isMarkedAsRejected: false, message: '' },
    isFirstCompanyLoad: false,
  },
  thunks: {
    getWeeklyIntervalsOfCompaniesCreation: defaultThunkStatus,
    setCompanyTargetGroupRejectionStatus: defaultThunkStatus,
    getCompaniesForResearchFilteredByCriterion: defaultThunkStatus,
    getCompanyForResearch: defaultThunkStatus,
    markCompanyAsToResearch: defaultThunkStatus,
    markCompanyAsResearched: defaultThunkStatus,
    markCompanyAsNotAFit: defaultThunkStatus,
    countCompaniesToResearch: defaultThunkStatus,
    countPeopleResearchedCompanies: defaultThunkStatus,
    updateCompanyForResearch: defaultThunkStatus,
    findLeadEmail: [],
    searchCountries: defaultThunkStatus,
    getCompaniesForReviewByTargetGroupId: defaultThunkStatus,
    getCompaniesForReviewFilteredByCriterion: defaultThunkStatus,
    countCompaniesToReview: defaultThunkStatus,
    getCompanyForReview: defaultThunkStatus,
    updateEmailSequences: defaultThunkStatus,
    validateLead: defaultThunkStatus,
    validateBulkLeads: defaultThunkStatus,
    rejectLead: defaultThunkStatus,
    propagate: defaultThunkStatus,
    createNewLead: defaultThunkStatus,
    deleteLead: defaultThunkStatus,
    selectLeadForSequence: defaultThunkStatus,
    changeTargetGroup: defaultThunkStatus,
    ignoreLead: defaultThunkStatus,
    markCompanyAsPaused: defaultThunkStatus,
    deleteCompanyTargetGroup: defaultThunkStatus,
  },
};

export const companyTargetGroupState: ICompanyTargetGroupState = defaultCompanyTargetGroupState;

export const propagate = createAsyncThunk(
  'companyTargetGroup/propagate',
  async (
    { clientId, targetGroupId }: IGetCompanyTargetGroupsAnalyticsKpiSetThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/propagate/${clientId}/${targetGroupId}`;
      await axios.post(endpoint);
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompanyTargetGroupsForAnalytics = createAsyncThunk(
  'companyTargetGroup/getCompanyTargetGroupsForAnalytics',
  async (
    { clientId, targetGroupId, take, skip, sortBy }: IGetCompanyTargetGroupsForAnalyticsThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = targetGroupId
        ? `/company-target-group/analytics/${clientId}?targetGroupId=${targetGroupId}&take=${take ?? 50}&skip=${
            skip ?? 0
          }${sortBy ? `&sortBy=${sortBy}` : ''}`
        : `/company-target-group/analytics/${clientId}?take=${take ?? 50}&skip=${skip ?? 0}${
            sortBy ? `&sortBy=${sortBy}` : ''
          }`;
      const { data: companyTargetGroupsList } = await axios.get(endpoint);
      const newItems = [...companyTargetGroupsList.items].map(
        (item: any) => new AnalyticsCompanyTargetGroup(item),
      );
      return {
        ...companyTargetGroupsList,
        items: newItems,
      } as ICompanyTargetGroupsForAnalyticsListResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateCompanyTargetGroupStatus = createAsyncThunk(
  'companyTargetGroup/updateCompanyTargetGroupStatus',
  async (body: IUpdateStatusBody, { rejectWithValue }) => {
    try {
      const endpoint = '/company-target-group/analytics/status';
      const { data: companyTargetGroup } = await axios.patch(endpoint, body);
      return companyTargetGroup as ICompanyTargetGroupResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompanyTargetGroupsAnalyticsKpiSet = createAsyncThunk(
  'companyTargetGroup/getCompanyTargetGroupsAnalyticsKpiSet',
  async (
    { clientId, targetGroupId }: IGetCompanyTargetGroupsAnalyticsKpiSetThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = targetGroupId
        ? `/company-target-group/analytics/kpi/${clientId}?targetGroupId=${targetGroupId}`
        : `/company-target-group/analytics/kpi/${clientId}`;
      const { data: analyticsKpiSet } = await axios.get(endpoint);
      return analyticsKpiSet.indicatorsSet as CompanyTargetGroupsAnalyticsKpi[];
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const downloadDataPerClient = createAsyncThunk(
  'companyTargetGroup/downloadDataPerClient',
  async (
    { clientId }: Pick<IDownloadCompanyTargetGroupsAnalyticsThunkParams, 'clientId'>,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/client-data/${clientId}`;
      await axios
        .get(endpoint, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'client-data.csv');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error('Error downloading CSV file:', error);
        });
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const downloadCompanyTargetGroupsAnalytics = createAsyncThunk(
  'companyTargetGroup/downloadCompanyTargetGroupsAnalytics',
  async (
    { clientId, targetGroupId }: IDownloadCompanyTargetGroupsAnalyticsThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = targetGroupId
        ? `/company-target-group/analytics/csv/${clientId}?targetGroupId=${targetGroupId}`
        : `/company-target-group/analytics/csv/${clientId}`;
      await axios
        .get(endpoint, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',

            `analytics_client_${clientId}_target_group_${targetGroupId ? targetGroupId : 'all'}.csv`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error('Error downloading CSV file:', error);
        });
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getClientCompaniesListByTargetGroupId = createAsyncThunk(
  'companyTargetGroup/getClientCompaniesListByTargetGroupId',
  async (
    { clientId, targetGroupId, take, skip }: IGetClientCompaniesListByTargetGroupIdThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = targetGroupId
        ? `/company-target-group/analytics/companies/${clientId}?targetGroupId=${targetGroupId}&take=${
            take ?? 50
          }&skip=${skip ?? 0}`
        : `/company-target-group/analytics/companies/${clientId}?take=${take ?? 50}&skip=${skip ?? 0}`;
      const { data: clientCompaniesList } = await axios.get(endpoint);
      const newItems = [...clientCompaniesList.items].map(
        (item: any) => new ClientsCompanyByTargetGroup(item),
      );
      return {
        ...clientCompaniesList,
        items: newItems,
      } as IClientsCompaniesByTargetGroupListResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompanyNews = createAsyncThunk(
  'company/getCompanyNews',
  async ({ companyId }: IGetCompanyNewsEventsThunkParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/company-tracking/news/${companyId}`;
      const { data: companyNewsItemResponse } = await axios.get(endpoint);
      return companyNewsItemResponse as IBaseCompaniesNewsEventsItemResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateRelevantCompanies = createAsyncThunk(
  'companyTargetGroup/updateRelevantCompanies',
  async (
    { companyTargetGroupId, companyDomains }: IUpdateRelevantCompaniesThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/company-tracking/relevant-companies/${companyTargetGroupId}`;
      const { data: relevantCompaniesData } = await axios.patch(endpoint, { companyDomains });
      return relevantCompaniesData as IRelevantCompaniesDataItemResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const setCompanyTargetGroupRejectionStatus = createAsyncThunk(
  'companyTargetGroup/setCompanyTargetGroupRejectionStatus',
  async (body: ISetRejectionStatusBody, { rejectWithValue }) => {
    try {
      const endpoint = '/company-target-group/rejection-status';
      const { data: companyTargetGroup } = await axios.patch(endpoint, body);
      return companyTargetGroup as ICompanyTargetGroupResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompaniesForResearchFilteredByCriterion = createAsyncThunk(
  'companyTargetGroup/getCompaniesForResearchFilteredByCriterion',
  async (
    { clientId, filterCriterion, targetGroupId, take, skip }: IGetCompaniesForResearchThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/target-research/companies/${clientId}?filterCriterion=${filterCriterion}${
        targetGroupId ? `&targetGroupId=${targetGroupId}` : ''
      }&take=${take ?? 50}&skip=${skip ?? 0}`;
      const { data } = await axios.get(endpoint);
      return data as ICompaniesForResearchListResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompanyForResearch = createAsyncThunk(
  'companyTargetGroup/getCompanyForResearch',
  async ({ clientId, companyTargetGroupId }: any, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/company/${clientId}/${companyTargetGroupId}`;
      const { data } = await axios.get(endpoint);
      return data.item as ICompanyTargetGroupDataForResearch;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateCompanyForResearch = createAsyncThunk(
  'companyTargetGroup/updateCompanyForResearch',
  async (
    { companyTargetGroupId, companyId, leadId, body }: IUpdateCompanyForResearchParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/target-research/company/${companyTargetGroupId}/${companyId}/${leadId}`;
      const { data } = await axios.patch(endpoint, body);
      return data.item as ICompanyTargetGroupDataForResearch;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const findLeadEmail = createAsyncThunk(
  'companyTargetGroup/findLeadEmail',
  async ({ companyId, leadId }: IFindLeadEmailParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/find-lead-email/${companyId}/${leadId}`;
      const { data } = await axios.patch(endpoint);
      return data as ILead[];
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError?.message);
    }
  },
);

export const markCompanyAsToResearch = createAsyncThunk(
  'companyTargetGroup/markCompanyAsToResearch',
  async ({ companyTargetGroupId }: IMarkCompanyAsToResearchThunkParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/company/to-research/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint);
      return data.message as string;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const markCompanyAsResearched = createAsyncThunk(
  'companyTargetGroup/markCompanyAsResearched',
  async ({ companyTargetGroupId }: IMarkCompanyAsResearchedThunkParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/company/researched/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint);
      return data.message as string;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const markCompanyAsNotAFit = createAsyncThunk(
  'companyTargetGroup/markCompanyAsNotAFit',
  async ({ companyTargetGroupId }: IMarkCompanyAsNotAFitThunkParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/company/not-a-fit/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint);
      return data.message as string;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const countCompaniesToResearch = createAsyncThunk(
  'companyTargetGroup/countCompaniesToResearch',
  async ({ clientId }: { clientId: string | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/companies-to-research-count/${clientId}`;
      const { data } = await axios.get(endpoint);
      return data;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const countPeopleResearchedCompanies = createAsyncThunk(
  'companyTargetGroup/countPeopleResearchedCompanies',
  async ({ clientId }: { clientId: string | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/lead/find-people/researched-count/${clientId}`;
      const { data } = await axios.get(endpoint);
      return data;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const countCompaniesToReview = createAsyncThunk(
  'companyTargetGroup/countCompaniesToReview',
  async ({ clientId }: { clientId: string | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/companies-to-review-count/${clientId}`;
      const { data } = await axios.get(endpoint);
      return data;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const searchCountries = createAsyncThunk(
  'companyTargetGroup/searchCountries',
  async ({ search }: { search?: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/countries?search=${search}`;
      const { data } = await axios.get(endpoint);
      return data.items;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompaniesForReviewByTargetGroupId = createAsyncThunk(
  'companyTargetGroup/getCompaniesForReviewByTargetGroupId',
  async (
    { clientId, targetGroupId }: IGetCompaniesForReviewByTargetGroupIdThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/review/${clientId}/${targetGroupId}`;
      const { data } = await axios.get(endpoint);
      return data as ICompanyForReview[];
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompaniesForReviewFilteredByCriterion = createAsyncThunk(
  'companyTargetGroup/getCompaniesForReviewFilteredByCriterion',
  async (
    {
      clientId,
      filterCriterion,
      targetGroupId,
      take,
      skip,
      search,
    }: IGetCompaniesForReviewThunkParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/review/${clientId}?filterCriterion=${filterCriterion}${
        targetGroupId ? `&targetGroupId=${targetGroupId}` : ''
      }&take=${take ?? 50}&skip=${skip ?? 0}&search=${search}`;
      const { data } = await axios.get(endpoint);
      return data as ICompaniesForReviewListResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompanyForReview = createAsyncThunk(
  'companyTargetGroup/getCompanyForReview',
  async ({ clientId, companyTargetGroupId }: any, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/review/company/${clientId}/${companyTargetGroupId}`;
      const { data } = await axios.get(endpoint);
      return data.item as ICompanyTargetGroupDataForReview;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateEmailSequences = createAsyncThunk(
  'companyTargetGroup/updateEmailSequences',
  async ({ companyTargetGroupId, body }: IUpdateEmailSequencesParams, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/review/email-sequences/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint, body);
      return data as IUpdateEmailSequencesItemResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const selectLeadForSequence = createAsyncThunk(
  'companyTargetGroup/selectLeadForSequence',
  async (
    {
      companyTargetGroupId,
      clientId,
      sequenceIndex,
      leadId,
    }: { companyTargetGroupId: string; clientId: string; sequenceIndex: number; leadId: string },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/review/email-sequences-lead/${clientId}/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint, { sequenceIndex, leadId });
      return data.item as ICompanyTargetGroupDataForReview;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const changeTargetGroup = createAsyncThunk(
  'companyTargetGroup/changeTargetGroup',
  async (
    {
      companyTargetGroupId,
      clientId,
      targetGroupId,
    }: { companyTargetGroupId: string; clientId: string; targetGroupId: string },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/review/company/change-target-group/${clientId}/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint, { targetGroupId });
      return data.item as any;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const ignoreLead = createAsyncThunk(
  'companyTargetGroup/ignoreLead',
  async (
    {
      companyTargetGroupId,
      clientId,
      leadId,
    }: { companyTargetGroupId: string; clientId: string; leadId: string },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/review/company/ignore-person/${clientId}/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint, { leadId });
      return data.item as any;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const validateLead = createAsyncThunk(
  'companyTargetGroup/validateLead',
  async ({ companyTargetGroupId }: { companyTargetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/review/lead/validate/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint);
      return data.message as string;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const bulkReviewTargetGroup = createAsyncThunk(
  'companyTargetGroup/bulkReviewTargetGroup',
  async (
    { clientId, targetGroupId }: { clientId: string | undefined; targetGroupId: string },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/review/bulk/validate/${targetGroupId}`;
      const { data } = await axios.patch(endpoint, { clientId });
      return data.message as string;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const createNewLead = createAsyncThunk(
  'lead/create-empty',
  async ({ companyId }: { companyId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/lead/create-empty`;
      const { data } = await axios.post(endpoint, { companyId });
      return data;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteLead = createAsyncThunk(
  'lead/delete',
  async ({ leadId }: { leadId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/lead/${leadId}`;
      const { data } = await axios.delete(endpoint);
      return data;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const rejectLead = createAsyncThunk(
  'companyTargetGroup/rejectLead',
  async ({ companyTargetGroupId }: { companyTargetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/review/lead/reject/${companyTargetGroupId}`;
      const { data } = await axios.patch(endpoint);
      return data.message as string;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteCompanyTargetGroup = createAsyncThunk(
  'companyTargetGroup/deleteCompanyTargetGroup',
  async ({ companyTargetGroupId }: { companyTargetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/${companyTargetGroupId}`;
      const { data } = await axios.delete(endpoint);
      return data.message;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

const companyTargetGroupSlice = createSlice({
  name: 'companyTargetGroup',
  initialState: companyTargetGroupState,
  reducers: {
    setCompanyTargetGroupAnalyticsSorting: (
      state,
      action: PayloadAction<CompanyTargetGroupsAnalyticsSortBy>,
    ) => {
      const sortBy = action.payload;
      state.companyTargetGroupsForAnalytics.sorting.isSorted = true;
      state.companyTargetGroupsForAnalytics.sorting.sortBy = sortBy;
    },
    setCurrentWeekOfCompanyCreation: (
      state,
      action: PayloadAction<ICurrentWeekOfCompaniesCreation>,
    ) => {
      const data = action.payload;
      state.currentWeekOfCompanyCreation = data;
    },
    setCurrentCompanyForResearchParams: (
      state,
      action: PayloadAction<ICurrentCompanyForResearchParams>,
    ) => {
      const data = action.payload;
      state.currentCompanyForResearchParams = data;
    },
    setCurrentResearchFilterCriterion: (
      state,
      action: PayloadAction<ICurrentResearchFilterCriterion>,
    ) => {
      const data = action.payload;
      state.currentResearchFilterCriterion = data;
    },
    setMarkAsToResearch: (state, action: PayloadAction<IMarkAsToResearch>) => {
      const data = action.payload;
      state.companyForResearch.markAsToResearch = data;
    },
    setMarkAsResearched: (state, action: PayloadAction<IMarkAsResearched>) => {
      const data = action.payload;
      state.companyForResearch.markAsResearched = data;
    },
    setMarkAsNotAFit: (state, action: PayloadAction<IMarkAsNotAFit>) => {
      const data = action.payload;
      state.companyForResearch.markAsNotAFit = data;
    },
    setMarkAsEmailValidation: (state, action: PayloadAction<IMarkAsEmailValidation>) => {
      const data = action.payload;
      state.companyForResearch.markAsEmailValidation = data;
    },
    setMarkAsInvalidEmail: (state, action: PayloadAction<IMarkAsInvalidEmail>) => {
      const data = action.payload;
      state.companyForResearch.markAsInvalidEmail = data;
    },
    setMarkAsValidated: (state, action: PayloadAction<IMarkAsValidated>) => {
      const data = action.payload;
      state.companyForReview.markAsValidated = data;
    },
    setMarkAsRejected: (state, action: PayloadAction<IMarkAsRejected>) => {
      const data = action.payload;
      state.companyForReview.markAsRejected = data;
    },
    updateCompanyResearchForm: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const data = action.payload;
      state.companyForResearch.item = {
        ...state.companyForResearch.item,
        [data.name]: data.value,
      };
    },
    setResearchFormValidationErrors: (
      state,
      action: PayloadAction<ValidationResultsObject | undefined>,
    ) => {
      const data = action.payload;
      state.companyForResearch.formValidationErrors = data;
    },
    setIsFirstCompanyLoad: (state, action: PayloadAction<boolean>) => {
      const isFirstCompanyLoad = action.payload;
      state.companyForResearch.isFirstCompanyLoad = isFirstCompanyLoad;
    },
    setCurrentSequenceLead: (
      state,
      action: PayloadAction<{ sequenceIndex: number; leadId: string }>,
    ) => {
      const { sequenceIndex, leadId } = action.payload;
      state.companyForReview.item.emailSequences[sequenceIndex].leadId = leadId;
      state.companyForReview.item.emailSequences[sequenceIndex + 1].leadId = leadId;
    },
    setCurrentReviewFilterCriterion: (
      state,
      action: PayloadAction<ICurrentReviewFilterCriterion>,
    ) => {
      const data = action.payload;
      state.currentReviewFilterCriterion = data;
    },
    setCurrentCompanyForReviewParams: (
      state,
      action: PayloadAction<ICurrentCompanyForReviewParams>,
    ) => {
      const data = action.payload;
      state.currentCompanyForReviewParams = data;
    },
    setIsFirstCompanyForReviewLoad: (state, action: PayloadAction<boolean>) => {
      const isFirstCompanyLoad = action.payload;
      state.companyForReview.isFirstCompanyLoad = isFirstCompanyLoad;
    },
    resetCompanyTargetGroupAnalyticsSorting: state => {
      state.companyTargetGroupsForAnalytics.sorting.isSorted = false;
      state.companyTargetGroupsForAnalytics.sorting.sortBy = undefined;
    },
    resetCompanyTargetGroupForAnalyticsData: state => {
      state.companyTargetGroupsForAnalytics.data = { items: [], pagination: { take: 50, skip: 0 } };
    },
    resetClientsCompaniesByTargetGroupData: state => {
      state.clientsCompaniesByTargetGroup.data = { items: [], pagination: { take: 50, skip: 0 } };
    },
    resetCompaniesForResearch: state => {
      state.companiesForResearch = { items: [], pagination: { take: 50, skip: 0 } };
    },
    resetCompaniesForReview: state => {
      state.companiesForReview = { items: [], pagination: { take: 50, skip: 0 } };
    },
    resetCompanyTargetGroup: state => {
      state.companyTargetGroupsForAnalytics = {
        ...companyTargetGroupState,
      }.companyTargetGroupsForAnalytics;
    },
    resetCurrentCompanyForResearchParams: state => {
      state.currentCompanyForResearchParams = { id: '', name: '' };
    },
    resetValidateLeadThunk: state => {
      state.thunks.validateLead = defaultThunkStatus;
    },
    resetValidateBulkLeadThunk: state => {
      state.thunks.validateBulkLeads = defaultThunkStatus;
    },
    resetPropagateThunk: state => {
      state.thunks.propagate = defaultThunkStatus;
    },
    resetCompanyTargetGroupState: state => {
      state.companyTargetGroupsForAnalytics =
        defaultCompanyTargetGroupState.companyTargetGroupsForAnalytics;
      state.companyTargetGroupsAnalyticsKpiSet =
        defaultCompanyTargetGroupState.companyTargetGroupsAnalyticsKpiSet;
      state.clientsCompaniesByTargetGroup =
        defaultCompanyTargetGroupState.clientsCompaniesByTargetGroup;
      state.companyNewsEvents = defaultCompanyTargetGroupState.companyNewsEvents;
      state.relevantCompaniesNewsEvents =
        defaultCompanyTargetGroupState.relevantCompaniesNewsEvents;
      state.areCompanyTargetGroupsStatusesUpdated =
        defaultCompanyTargetGroupState.areCompanyTargetGroupsStatusesUpdated;
      state.weeklyIntervalsOfCompaniesCreation =
        defaultCompanyTargetGroupState.weeklyIntervalsOfCompaniesCreation;

      state.currentWeekOfCompanyCreation =
        formSelectWeekDropdownOptions(state.weeklyIntervalsOfCompaniesCreation?.items)?.[
          state.weeklyIntervalsOfCompaniesCreation?.items?.length - 1
        ] || defaultWeekOfCompaniesCreation;
      state.currentCompanyForResearchParams =
        defaultCompanyTargetGroupState.currentCompanyForResearchParams;
      state.companiesForResearch = defaultCompanyTargetGroupState.companiesForResearch;
      state.companiesToResearchCount = defaultCompanyTargetGroupState.companiesToResearchCount;
      state.countries = defaultCompanyTargetGroupState.countries;
      state.currentResearchFilterCriterion =
        defaultCompanyTargetGroupState.currentResearchFilterCriterion;
      state.companyForResearch = defaultCompanyTargetGroupState.companyForResearch;
      state.companiesForReview = defaultCompanyTargetGroupState.companiesForReview;
      state.currentReviewFilterCriterion =
        defaultCompanyTargetGroupState.currentReviewFilterCriterion;
      state.currentCompanyForReviewParams =
        defaultCompanyTargetGroupState.currentCompanyForReviewParams;
      state.companyForReview = defaultCompanyTargetGroupState.companyForReview;
      state.thunks = defaultCompanyTargetGroupState.thunks;
    },
  },
  extraReducers: builder => {
    generateBaseThunkReducers(builder, bulkReviewTargetGroup, 'validateBulkLeads');
    builder.addCase(createNewLead.pending, (state, action) => {
      state.thunks.createNewLead.isLoading = true;
    });
    builder.addCase(createNewLead.fulfilled, (state, action) => {
      const newLead = action.payload;
      state.thunks.createNewLead.isLoading = false;
      state.companyForResearch.item.leads.push(newLead);
      state.thunks.findLeadEmail = new Array(state.companyForResearch.item.leads.length).fill({});
    });
    builder.addCase(createNewLead.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.createNewLead.error = error;
      state.thunks.createNewLead.isError = true;
      state.thunks.createNewLead.isLoading = false;
    });
    //
    builder.addCase(deleteLead.pending, (state, action) => {
      state.thunks.deleteLead.isLoading = true;
    });
    builder.addCase(deleteLead.fulfilled, (state, action) => {
      const deletedId = String(action.payload);
      state.thunks.deleteLead.isLoading = false;
      const { leads } = state.companyForResearch.item;
      if (!leads || !leads.length) return;
      const deletedItemIndex = leads.findIndex(({ id }: any) => id === deletedId);
      leads.splice(deletedItemIndex, 1);
      state.thunks.findLeadEmail = new Array(leads.length).fill({});
    });
    builder.addCase(deleteLead.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.deleteLead.error = error;
      state.thunks.deleteLead.isError = true;
      state.thunks.deleteLead.isLoading = false;
    });
    //
    builder.addCase(propagate.pending, (state, action) => {
      state.thunks.propagate.isLoading = true;
    });
    builder.addCase(propagate.fulfilled, (state, action) => {
      state.thunks.propagate.isLoading = false;
    });
    builder.addCase(propagate.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.propagate.error = error;
      state.thunks.propagate.isError = true;
      state.thunks.propagate.isLoading = false;
    });

    builder.addCase(getCompanyTargetGroupsForAnalytics.pending, (state, action) => {
      state.companyTargetGroupsForAnalytics.isLoading = true;
    });
    builder.addCase(getCompanyTargetGroupsForAnalytics.fulfilled, (state, action) => {
      const companyTargetGroupsForAnalytics = action.payload;
      updateStateAndHandlePaginatedData(
        state,
        'companyTargetGroupsForAnalytics',
        companyTargetGroupsForAnalytics,
      );
      state.companyTargetGroupsForAnalytics.isLoading = false;
    });
    builder.addCase(getCompanyTargetGroupsForAnalytics.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyTargetGroupsForAnalytics.error = error;
      state.companyTargetGroupsForAnalytics.isError = true;
      state.companyTargetGroupsForAnalytics.isLoading = false;
    });

    builder.addCase(updateCompanyTargetGroupStatus.pending, (state, action) => {
      state.areCompanyTargetGroupsStatusesUpdated = false;
    });
    builder.addCase(updateCompanyTargetGroupStatus.fulfilled, (state, action) => {
      const companyTargetGroupObject = action.payload;
      state.areCompanyTargetGroupsStatusesUpdated = true;
      const index = state.companyTargetGroupsForAnalytics.data.items.findIndex(
        item => item.id === companyTargetGroupObject.item.id,
      );
      state.companyTargetGroupsForAnalytics.data.items[index].status =
        companyTargetGroupObject.item.status;
    });
    builder.addCase(updateCompanyTargetGroupStatus.rejected, (state, action: any) => {
      state.areCompanyTargetGroupsStatusesUpdated = false;
    });

    builder.addCase(getCompanyTargetGroupsAnalyticsKpiSet.pending, (state, action) => {
      state.companyTargetGroupsAnalyticsKpiSet.isLoading = true;
    });
    builder.addCase(getCompanyTargetGroupsAnalyticsKpiSet.fulfilled, (state, action) => {
      const companyTargetGroupsAnalyticsKpiSet = action.payload;
      state.companyTargetGroupsAnalyticsKpiSet.data = companyTargetGroupsAnalyticsKpiSet;
      state.companyTargetGroupsAnalyticsKpiSet.isLoading = false;
    });
    builder.addCase(getCompanyTargetGroupsAnalyticsKpiSet.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyTargetGroupsAnalyticsKpiSet.error = error;
      state.companyTargetGroupsAnalyticsKpiSet.isError = true;
      state.companyTargetGroupsAnalyticsKpiSet.isLoading = false;
    });

    builder.addCase(getClientCompaniesListByTargetGroupId.pending, (state, action) => {
      state.clientsCompaniesByTargetGroup.isLoading = true;
    });
    builder.addCase(getClientCompaniesListByTargetGroupId.fulfilled, (state, action) => {
      const clientsCompaniesByTargetGroup = action.payload;
      state.clientsCompaniesByTargetGroup.data = {
        ...clientsCompaniesByTargetGroup,
        items: [
          ...(state.clientsCompaniesByTargetGroup.data.items || []),
          ...clientsCompaniesByTargetGroup.items,
        ],
      };
      state.clientsCompaniesByTargetGroup.isLoading = false;
    });
    builder.addCase(getClientCompaniesListByTargetGroupId.rejected, (state, action: any) => {
      const error = action.payload;
      state.clientsCompaniesByTargetGroup.error = error;
      state.clientsCompaniesByTargetGroup.isError = true;
      state.clientsCompaniesByTargetGroup.isLoading = false;
    });

    builder.addCase(getCompanyNews.pending, state => {
      state.companyNewsEvents.isLoading = true;
      state.relevantCompaniesNewsEvents.isLoading = true;
    });
    builder.addCase(
      getCompanyNews.fulfilled,
      (state, action: PayloadAction<IBaseCompaniesNewsEventsItemResponse>) => {
        const companyNewsItemResponse = action.payload;
        const { id, companyId, companyName, companyNewsEvents, ...relevantCompaniesData } =
          companyNewsItemResponse.item;
        const baseItemData = { id, companyId, companyName };
        state.companyNewsEvents.data = { item: { ...baseItemData, companyNewsEvents } };
        state.companyNewsEvents.isLoading = false;
        state.relevantCompaniesNewsEvents.data = {
          item: { ...baseItemData, ...relevantCompaniesData },
        };
        state.relevantCompaniesNewsEvents.isLoading = false;
      },
    );
    builder.addCase(getCompanyNews.rejected, (state, action: PayloadAction<any>) => {
      const error = action.payload;
      state.companyNewsEvents.error = error;
      state.companyNewsEvents.isError = true;
      state.companyNewsEvents.isLoading = false;
      state.relevantCompaniesNewsEvents.error = error;
      state.relevantCompaniesNewsEvents.isError = true;
      state.relevantCompaniesNewsEvents.isLoading = false;
    });

    builder.addCase(updateRelevantCompanies.pending, state => {
      state.relevantCompaniesNewsEvents.isLoading = true;
    });
    builder.addCase(
      updateRelevantCompanies.fulfilled,
      (state, action: PayloadAction<IRelevantCompaniesDataItemResponse>) => {
        const relevantCompaniesDataItemResponse = action.payload;
        state.relevantCompaniesNewsEvents.data = {
          item: {
            ...state.relevantCompaniesNewsEvents.data.item,
            ...relevantCompaniesDataItemResponse.item,
          },
        };
        state.relevantCompaniesNewsEvents.isLoading = false;
      },
    );
    builder.addCase(updateRelevantCompanies.rejected, (state, action: PayloadAction<any>) => {
      const error = action.payload;
      state.relevantCompaniesNewsEvents.error = error;
      state.relevantCompaniesNewsEvents.isError = true;
      state.relevantCompaniesNewsEvents.isLoading = false;
    });

    builder.addCase(setCompanyTargetGroupRejectionStatus.pending, state => {
      state.thunks.setCompanyTargetGroupRejectionStatus.isLoading = true;
      state.thunks.setCompanyTargetGroupRejectionStatus.isSuccess = false;
      state.thunks.setCompanyTargetGroupRejectionStatus.error = {};
      state.thunks.setCompanyTargetGroupRejectionStatus.isError = false;
      state.thunks.setCompanyTargetGroupRejectionStatus.isStarted = true;
    });
    builder.addCase(
      setCompanyTargetGroupRejectionStatus.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupResponse>) => {
        state.thunks.setCompanyTargetGroupRejectionStatus.isLoading = false;
        state.thunks.setCompanyTargetGroupRejectionStatus.isSuccess = true;
        state.thunks.setCompanyTargetGroupRejectionStatus.error = {};
        state.thunks.setCompanyTargetGroupRejectionStatus.isError = false;
        state.thunks.setCompanyTargetGroupRejectionStatus.isStarted = false;
        state.thunks.setCompanyTargetGroupRejectionStatus.isFinished = true;
      },
    );
    builder.addCase(setCompanyTargetGroupRejectionStatus.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.setCompanyTargetGroupRejectionStatus.error = error;
      state.thunks.setCompanyTargetGroupRejectionStatus.isError = true;
      state.thunks.setCompanyTargetGroupRejectionStatus.isLoading = false;
      state.thunks.setCompanyTargetGroupRejectionStatus.isStarted = false;
      state.thunks.setCompanyTargetGroupRejectionStatus.isFinished = true;
    });

    builder.addCase(getCompaniesForResearchFilteredByCriterion.pending, state => {
      state.thunks.getCompaniesForResearchFilteredByCriterion.isLoading = true;
      state.thunks.getCompaniesForResearchFilteredByCriterion.isSuccess = false;
      state.thunks.getCompaniesForResearchFilteredByCriterion.error = {};
      state.thunks.getCompaniesForResearchFilteredByCriterion.isError = false;
      state.thunks.getCompaniesForResearchFilteredByCriterion.isStarted = true;
    });
    builder.addCase(
      getCompaniesForResearchFilteredByCriterion.fulfilled,
      (state, action: PayloadAction<ICompaniesForResearchListResponse>) => {
        const data = action.payload;
        state.companiesForResearch = {
          ...data,
          items: [...(state.companiesForResearch.items || []), ...data.items],
        };
        state.thunks.getCompaniesForResearchFilteredByCriterion.isLoading = false;
        state.thunks.getCompaniesForResearchFilteredByCriterion.isSuccess = true;
        state.thunks.getCompaniesForResearchFilteredByCriterion.error = {};
        state.thunks.getCompaniesForResearchFilteredByCriterion.isError = false;
        state.thunks.getCompaniesForResearchFilteredByCriterion.isStarted = false;
        state.thunks.getCompaniesForResearchFilteredByCriterion.isFinished = true;
      },
    );
    builder.addCase(getCompaniesForResearchFilteredByCriterion.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getCompaniesForResearchFilteredByCriterion.error = error;
      state.thunks.getCompaniesForResearchFilteredByCriterion.isError = true;
      state.thunks.getCompaniesForResearchFilteredByCriterion.isLoading = false;
      state.thunks.getCompaniesForResearchFilteredByCriterion.isStarted = false;
      state.thunks.getCompaniesForResearchFilteredByCriterion.isFinished = true;
    });

    builder.addCase(getCompanyForResearch.pending, state => {
      state.thunks.getCompanyForResearch.isLoading = true;
      state.thunks.getCompanyForResearch.isSuccess = false;
      state.thunks.getCompanyForResearch.error = {};
      state.thunks.getCompanyForResearch.isError = false;
      state.thunks.getCompanyForResearch.isStarted = true;
    });
    builder.addCase(
      getCompanyForResearch.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupDataForResearch>) => {
        const companyForResearch = action.payload;
        state.companyForResearch.item = companyForResearch;
        state.companyForResearch.formValidationErrors = { leads: {} };

        for (const lead of companyForResearch.leads) {
          if (
            lead.emailValidationMeta?.result === 'invalid' &&
            lead.emailValidationMeta?.isCompleted
          ) {
            const message = `Email is invalid (source: ${lead.emailValidationMeta.source})!`;
            (state.companyForResearch.formValidationErrors['leads'] as any)[lead.id] = {
              email: new ValidatorResult({ hasError: true, errors: [message] }),
            };
          } else if (
            lead.emailValidationMeta?.sentToScrubbyAt &&
            lead.emailValidationMeta?.isCompleted === false &&
            lead.emailValidationMeta?.result !== 'invalid' &&
            lead.emailValidationMeta?.result !== 'valid'
          ) {
            const message = 'Awaiting for Scrubby email validation!';
            (state.companyForResearch.formValidationErrors['leads'] as any)[lead.id] = {
              email: new ValidatorResult({ hasError: true, errors: [message] }),
            };
          }
        }
        state.thunks.getCompanyForResearch.isLoading = false;
        state.thunks.getCompanyForResearch.isSuccess = true;
        state.thunks.getCompanyForResearch.error = {};
        state.thunks.getCompanyForResearch.isError = false;
        state.thunks.getCompanyForResearch.isStarted = false;
        state.thunks.getCompanyForResearch.isFinished = true;
        state.thunks.findLeadEmail = Array(companyForResearch.leads.length).fill({});
      },
    );
    builder.addCase(getCompanyForResearch.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getCompanyForResearch.error = error;
      state.thunks.getCompanyForResearch.isError = true;
      state.thunks.getCompanyForResearch.isLoading = false;
      state.thunks.getCompanyForResearch.isStarted = false;
      state.thunks.getCompanyForResearch.isFinished = true;
    });

    builder.addCase(updateCompanyForResearch.pending, state => {
      state.thunks.updateCompanyForResearch.isLoading = true;
      state.thunks.updateCompanyForResearch.isSuccess = false;
      state.thunks.updateCompanyForResearch.error = {};
      state.thunks.updateCompanyForResearch.isError = false;
      state.thunks.updateCompanyForResearch.isStarted = true;
    });
    builder.addCase(
      updateCompanyForResearch.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupDataForResearch>) => {
        const companyForResearch = action.payload;
        state.companyForResearch.item = companyForResearch;
        state.thunks.updateCompanyForResearch.isLoading = false;
        state.thunks.updateCompanyForResearch.isSuccess = true;
        state.thunks.updateCompanyForResearch.error = {};
        state.thunks.updateCompanyForResearch.isError = false;
        state.thunks.updateCompanyForResearch.isStarted = false;
        state.thunks.updateCompanyForResearch.isFinished = true;
      },
    );
    builder.addCase(updateCompanyForResearch.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.updateCompanyForResearch.error = error;
      state.thunks.updateCompanyForResearch.isError = true;
      state.thunks.updateCompanyForResearch.isLoading = false;
      state.thunks.updateCompanyForResearch.isStarted = false;
      state.thunks.updateCompanyForResearch.isFinished = true;
    });

    builder.addCase(findLeadEmail.pending, (state, action) => {
      const leadIdx = state.companyForResearch.item.leads.findIndex(
        lead => lead.id === action.meta.arg.leadId,
      );
      state.thunks.findLeadEmail[leadIdx].isLoading = true;
      state.thunks.findLeadEmail[leadIdx].isSuccess = false;
      state.thunks.findLeadEmail[leadIdx].error = {};
      state.thunks.findLeadEmail[leadIdx].isError = false;
      state.thunks.findLeadEmail[leadIdx].isStarted = true;
    });
    builder.addCase(findLeadEmail.fulfilled, (state, action: PayloadAction<ILead[]>) => {
      const leads = action.payload;
      state.companyForResearch.item.leads = leads;
      state.thunks.findLeadEmail.forEach(findEmailThunk => {
        findEmailThunk.isLoading = false;
        findEmailThunk.isSuccess = true;
        findEmailThunk.error = {};
        findEmailThunk.isError = false;
        findEmailThunk.isStarted = false;
        findEmailThunk.isFinished = true;
      });
    });
    builder.addCase(findLeadEmail.rejected, (state, action) => {
      const leadIdx = state.companyForResearch.item.leads.findIndex(
        lead => lead.id === action.meta.arg.leadId,
      );
      const error = action.payload;
      state.thunks.findLeadEmail[leadIdx].error = error;
      state.thunks.findLeadEmail[leadIdx].isError = true;
      state.thunks.findLeadEmail[leadIdx].isLoading = false;
      state.thunks.findLeadEmail[leadIdx].isStarted = false;
      state.thunks.findLeadEmail[leadIdx].isFinished = true;
    });

    builder.addCase(markCompanyAsToResearch.pending, state => {
      state.thunks.markCompanyAsToResearch.isLoading = true;
      state.thunks.markCompanyAsToResearch.isSuccess = false;
      state.thunks.markCompanyAsToResearch.error = {};
      state.thunks.markCompanyAsToResearch.isError = false;
      state.thunks.markCompanyAsToResearch.isStarted = true;
    });
    builder.addCase(markCompanyAsToResearch.fulfilled, (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.companyForResearch.markAsToResearch.message = message;
      state.companyForResearch.markAsToResearch.isMarkedAsToResearch = true;
      state.thunks.markCompanyAsToResearch.isLoading = false;
      state.thunks.markCompanyAsToResearch.isSuccess = true;
      state.thunks.markCompanyAsToResearch.error = {};
      state.thunks.markCompanyAsToResearch.isError = false;
      state.thunks.markCompanyAsToResearch.isStarted = false;
      state.thunks.markCompanyAsToResearch.isFinished = true;
    });
    builder.addCase(markCompanyAsToResearch.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyForResearch.markAsToResearch.isMarkedAsToResearch = false;
      state.thunks.markCompanyAsToResearch.error = error;
      state.thunks.markCompanyAsToResearch.isError = true;
      state.thunks.markCompanyAsToResearch.isLoading = false;
      state.thunks.markCompanyAsToResearch.isStarted = false;
      state.thunks.markCompanyAsToResearch.isFinished = true;
    });

    builder.addCase(markCompanyAsResearched.pending, state => {
      state.thunks.markCompanyAsResearched.isLoading = true;
      state.thunks.markCompanyAsResearched.isSuccess = false;
      state.thunks.markCompanyAsResearched.error = {};
      state.thunks.markCompanyAsResearched.isError = false;
      state.thunks.markCompanyAsResearched.isStarted = true;
    });
    builder.addCase(markCompanyAsResearched.fulfilled, (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.companyForResearch.markAsResearched.message = message;
      state.companyForResearch.markAsResearched.isMarkedAsResearched = true;
      state.thunks.markCompanyAsResearched.isLoading = false;
      state.thunks.markCompanyAsResearched.isSuccess = true;
      state.thunks.markCompanyAsResearched.error = {};
      state.thunks.markCompanyAsResearched.isError = false;
      state.thunks.markCompanyAsResearched.isStarted = false;
      state.thunks.markCompanyAsResearched.isFinished = true;
    });
    builder.addCase(markCompanyAsResearched.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyForResearch.markAsResearched.isMarkedAsResearched = false;
      if (error?.statusCode === 418) {
        for (const msg of error.message.split('\n')) {
          const [leadId, message] = msg.split('>>');
          if (!state.companyForResearch.formValidationErrors?.['leads'])
            Object.assign(state.companyForResearch.formValidationErrors as any, { leads: {} });
          (state.companyForResearch.formValidationErrors as any).leads = {
            [leadId]: { email: new ValidatorResult({ hasError: true, errors: [message] }) },
          };
          const emailValidation =
            'The email status is uncertain (source: Neverbounce). We have sent a test email to verify it. The results may take up to 72 hours.';
          if (message === emailValidation) {
            state.companyForResearch.markAsEmailValidation.message = '';
            state.companyForResearch.markAsEmailValidation.isMarkedAsEmailValidation = true;
          }

          if (String(message).startsWith('Email is invalid')) {
            state.companyForResearch.markAsInvalidEmail.message = '';
            state.companyForResearch.markAsInvalidEmail.isMarkedAsInvalidEmail = true;
          }
        }
      }
      state.thunks.markCompanyAsResearched.error = error;
      state.thunks.markCompanyAsResearched.isError = true;
      state.thunks.markCompanyAsResearched.isLoading = false;
      state.thunks.markCompanyAsResearched.isStarted = false;
      state.thunks.markCompanyAsResearched.isFinished = true;
    });

    builder.addCase(markCompanyAsNotAFit.pending, state => {
      state.thunks.markCompanyAsNotAFit.isLoading = true;
      state.thunks.markCompanyAsNotAFit.isSuccess = false;
      state.thunks.markCompanyAsNotAFit.error = {};
      state.thunks.markCompanyAsNotAFit.isError = false;
      state.thunks.markCompanyAsNotAFit.isStarted = true;
    });
    builder.addCase(markCompanyAsNotAFit.fulfilled, (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.companyForResearch.markAsNotAFit.message = message;
      state.companyForResearch.markAsNotAFit.isMarkedAsNotAFit = true;
      state.thunks.markCompanyAsNotAFit.isLoading = false;
      state.thunks.markCompanyAsNotAFit.isSuccess = true;
      state.thunks.markCompanyAsNotAFit.error = {};
      state.thunks.markCompanyAsNotAFit.isError = false;
      state.thunks.markCompanyAsNotAFit.isStarted = false;
      state.thunks.markCompanyAsNotAFit.isFinished = true;
    });
    builder.addCase(markCompanyAsNotAFit.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyForResearch.markAsNotAFit.isMarkedAsNotAFit = false;
      state.thunks.markCompanyAsNotAFit.error = error;
      state.thunks.markCompanyAsNotAFit.isError = true;
      state.thunks.markCompanyAsNotAFit.isLoading = false;
      state.thunks.markCompanyAsNotAFit.isStarted = false;
      state.thunks.markCompanyAsNotAFit.isFinished = true;
    });

    generateBaseThunkReducers(
      builder,
      countCompaniesToResearch,
      'countCompaniesToResearch',
      'companiesToResearchCount',
    );

    generateBaseThunkReducers(
      builder,
      countPeopleResearchedCompanies,
      'countPeopleResearchedCompanies',
      'peopleResearchedCompaniesCount',
    );

    generateBaseThunkReducers(
      builder,
      countCompaniesToReview,
      'countCompaniesToReview',
      'companiesToReviewCount',
    );

    builder.addCase(searchCountries.pending, state => {
      state.thunks.searchCountries.isLoading = true;
      state.thunks.searchCountries.isSuccess = false;
      state.thunks.searchCountries.error = {};
      state.thunks.searchCountries.isError = false;
      state.thunks.searchCountries.isStarted = true;
    });
    builder.addCase(searchCountries.fulfilled, (state, action: PayloadAction<ICountry[]>) => {
      const countries = action.payload;
      state.countries = countries;
      state.thunks.searchCountries.isLoading = false;
      state.thunks.searchCountries.isSuccess = true;
      state.thunks.searchCountries.error = {};
      state.thunks.searchCountries.isError = false;
      state.thunks.searchCountries.isStarted = false;
      state.thunks.searchCountries.isFinished = true;
    });
    builder.addCase(searchCountries.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.searchCountries.error = error;
      state.thunks.searchCountries.isError = true;
      state.thunks.searchCountries.isLoading = false;
      state.thunks.searchCountries.isStarted = false;
      state.thunks.searchCountries.isFinished = true;
    });

    builder.addCase(getCompaniesForReviewByTargetGroupId.pending, state => {
      state.thunks.getCompaniesForReviewByTargetGroupId.isLoading = true;
      state.thunks.getCompaniesForReviewByTargetGroupId.isSuccess = false;
      state.thunks.getCompaniesForReviewByTargetGroupId.error = {};
      state.thunks.getCompaniesForReviewByTargetGroupId.isError = false;
      state.thunks.getCompaniesForReviewByTargetGroupId.isStarted = true;
    });
    builder.addCase(
      getCompaniesForReviewByTargetGroupId.fulfilled,
      (state, action: PayloadAction<ICompanyForReview[]>) => {
        const data = action.payload;
        state.companiesForReview = {
          ...state.companiesForReview,
          items: [...data],
        };
        state.thunks.getCompaniesForReviewByTargetGroupId.isLoading = false;
        state.thunks.getCompaniesForReviewByTargetGroupId.isSuccess = true;
        state.thunks.getCompaniesForReviewByTargetGroupId.error = {};
        state.thunks.getCompaniesForReviewByTargetGroupId.isError = false;
        state.thunks.getCompaniesForReviewByTargetGroupId.isStarted = false;
        state.thunks.getCompaniesForReviewByTargetGroupId.isFinished = true;
      },
    );
    builder.addCase(getCompaniesForReviewByTargetGroupId.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getCompaniesForReviewByTargetGroupId.error = error;
      state.thunks.getCompaniesForReviewByTargetGroupId.isError = true;
      state.thunks.getCompaniesForReviewByTargetGroupId.isLoading = false;
      state.thunks.getCompaniesForReviewByTargetGroupId.isStarted = false;
      state.thunks.getCompaniesForReviewByTargetGroupId.isFinished = true;
    });

    builder.addCase(getCompaniesForReviewFilteredByCriterion.pending, state => {
      state.thunks.getCompaniesForReviewFilteredByCriterion.isLoading = true;
      state.thunks.getCompaniesForReviewFilteredByCriterion.isSuccess = false;
      state.thunks.getCompaniesForReviewFilteredByCriterion.error = {};
      state.thunks.getCompaniesForReviewFilteredByCriterion.isError = false;
      state.thunks.getCompaniesForReviewFilteredByCriterion.isStarted = true;
    });
    builder.addCase(
      getCompaniesForReviewFilteredByCriterion.fulfilled,
      (state, action: PayloadAction<ICompaniesForReviewListResponse>) => {
        const data = action.payload;
        state.companiesForReview = {
          ...data,
          items: [...(state.companiesForReview.items || []), ...data.items],
        };
        state.thunks.getCompaniesForReviewFilteredByCriterion.isLoading = false;
        state.thunks.getCompaniesForReviewFilteredByCriterion.isSuccess = true;
        state.thunks.getCompaniesForReviewFilteredByCriterion.error = {};
        state.thunks.getCompaniesForReviewFilteredByCriterion.isError = false;
        state.thunks.getCompaniesForReviewFilteredByCriterion.isStarted = false;
        state.thunks.getCompaniesForReviewFilteredByCriterion.isFinished = true;
      },
    );
    builder.addCase(getCompaniesForReviewFilteredByCriterion.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getCompaniesForReviewFilteredByCriterion.error = error;
      state.thunks.getCompaniesForReviewFilteredByCriterion.isError = true;
      state.thunks.getCompaniesForReviewFilteredByCriterion.isLoading = false;
      state.thunks.getCompaniesForReviewFilteredByCriterion.isStarted = false;
      state.thunks.getCompaniesForReviewFilteredByCriterion.isFinished = true;
    });

    builder.addCase(getCompanyForReview.pending, state => {
      state.thunks.getCompanyForReview.isLoading = true;
      state.thunks.getCompanyForReview.isSuccess = false;
      state.thunks.getCompanyForReview.error = {};
      state.thunks.getCompanyForReview.isError = false;
      state.thunks.getCompanyForReview.isStarted = true;
      state.companyNewsEvents.isLoading = true;
    });
    builder.addCase(
      getCompanyForReview.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupDataForReview>) => {
        const companyForReview = action.payload;
        state.companyForReview.item = companyForReview;
        const { companyNewsEvents } = companyForReview;
        state.companyNewsEvents.data.item = companyNewsEvents;
        state.companyNewsEvents.isLoading = false;
        state.thunks.getCompanyForReview.isLoading = false;
        state.thunks.getCompanyForReview.isSuccess = true;
        state.thunks.getCompanyForReview.error = {};
        state.thunks.getCompanyForReview.isError = false;
        state.thunks.getCompanyForReview.isStarted = false;
        state.thunks.getCompanyForReview.isFinished = true;
      },
    );
    builder.addCase(getCompanyForReview.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyNewsEvents.isLoading = false;
      state.thunks.getCompanyForReview.error = error;
      state.thunks.getCompanyForReview.isError = true;
      state.thunks.getCompanyForReview.isLoading = false;
      state.thunks.getCompanyForReview.isStarted = false;
      state.thunks.getCompanyForReview.isFinished = true;
    });

    builder.addCase(updateEmailSequences.pending, state => {
      state.thunks.updateEmailSequences.isLoading = true;
      state.thunks.updateEmailSequences.isSuccess = false;
      state.thunks.updateEmailSequences.error = {};
      state.thunks.updateEmailSequences.isError = false;
      state.thunks.updateEmailSequences.isStarted = true;
    });
    builder.addCase(
      updateEmailSequences.fulfilled,
      (state, action: PayloadAction<IUpdateEmailSequencesItemResponse>) => {
        state.thunks.updateEmailSequences.isLoading = false;
        state.thunks.updateEmailSequences.isSuccess = true;
        state.thunks.updateEmailSequences.error = {};
        state.thunks.updateEmailSequences.isError = false;
        state.thunks.updateEmailSequences.isStarted = false;
        state.thunks.updateEmailSequences.isFinished = true;
      },
    );
    builder.addCase(updateEmailSequences.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.updateEmailSequences.error = error;
      state.thunks.updateEmailSequences.isError = true;
      state.thunks.updateEmailSequences.isLoading = false;
      state.thunks.updateEmailSequences.isStarted = false;
      state.thunks.updateEmailSequences.isFinished = true;
    });
    builder.addCase(selectLeadForSequence.pending, state => {
      state.thunks.selectLeadForSequence.isLoading = true;
      state.thunks.selectLeadForSequence.isSuccess = false;
      state.thunks.selectLeadForSequence.error = {};
      state.thunks.selectLeadForSequence.isError = false;
      state.thunks.selectLeadForSequence.isStarted = true;
    });
    builder.addCase(
      selectLeadForSequence.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupDataForReview>) => {
        const companyForReview = action.payload;
        state.companyForReview.item = companyForReview;
        state.thunks.selectLeadForSequence.isLoading = false;
        state.thunks.selectLeadForSequence.isSuccess = true;
        state.thunks.selectLeadForSequence.error = {};
        state.thunks.selectLeadForSequence.isError = false;
        state.thunks.selectLeadForSequence.isStarted = false;
        state.thunks.selectLeadForSequence.isFinished = true;
      },
    );
    builder.addCase(selectLeadForSequence.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.selectLeadForSequence.error = error;
      state.thunks.selectLeadForSequence.isError = true;
      state.thunks.selectLeadForSequence.isLoading = false;
      state.thunks.selectLeadForSequence.isStarted = false;
      state.thunks.selectLeadForSequence.isFinished = true;
    });

    builder.addCase(ignoreLead.pending, state => {
      state.thunks.ignoreLead.isLoading = true;
      state.thunks.ignoreLead.isSuccess = false;
      state.thunks.ignoreLead.error = {};
      state.thunks.ignoreLead.isError = false;
      state.thunks.ignoreLead.isStarted = true;
    });
    builder.addCase(
      ignoreLead.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupDataForReview>) => {
        const companyForReview = action.payload;
        state.companyForReview.item = companyForReview;
        const { companyNewsEvents } = companyForReview;
        state.companyNewsEvents.data.item = companyNewsEvents;
        state.thunks.ignoreLead.isLoading = false;
        state.thunks.ignoreLead.isSuccess = true;
        state.thunks.ignoreLead.error = {};
        state.thunks.ignoreLead.isError = false;
        state.thunks.ignoreLead.isStarted = false;
        state.thunks.ignoreLead.isFinished = true;
      },
    );
    builder.addCase(ignoreLead.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.ignoreLead.error = error;
      state.thunks.ignoreLead.isError = true;
      state.thunks.ignoreLead.isLoading = false;
      state.thunks.ignoreLead.isStarted = false;
      state.thunks.ignoreLead.isFinished = true;
    });

    builder.addCase(changeTargetGroup.pending, state => {
      state.thunks.changeTargetGroup.isLoading = true;
      state.thunks.changeTargetGroup.isSuccess = false;
      state.thunks.changeTargetGroup.error = {};
      state.thunks.changeTargetGroup.isError = false;
      state.thunks.changeTargetGroup.isStarted = true;
    });
    builder.addCase(
      changeTargetGroup.fulfilled,
      (state, action: PayloadAction<ICompanyTargetGroupDataForReview>) => {
        const companyForReview = action.payload;
        state.companyForReview.item = companyForReview;
        const { companyNewsEvents } = companyForReview;
        state.companyNewsEvents.data.item = companyNewsEvents;
        state.thunks.changeTargetGroup.isLoading = false;
        state.thunks.changeTargetGroup.isSuccess = true;
        state.thunks.changeTargetGroup.error = {};
        state.thunks.changeTargetGroup.isError = false;
        state.thunks.changeTargetGroup.isStarted = false;
        state.thunks.changeTargetGroup.isFinished = true;
      },
    );
    builder.addCase(changeTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.changeTargetGroup.error = error;
      state.thunks.changeTargetGroup.isError = true;
      state.thunks.changeTargetGroup.isLoading = false;
      state.thunks.changeTargetGroup.isStarted = false;
      state.thunks.changeTargetGroup.isFinished = true;
    });

    builder.addCase(validateLead.pending, state => {
      state.thunks.validateLead.isLoading = true;
      state.thunks.validateLead.isSuccess = false;
      state.thunks.validateLead.error = {};
      state.thunks.validateLead.isError = false;
      state.thunks.validateLead.isStarted = true;
    });
    builder.addCase(validateLead.fulfilled, (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.companyForReview.markAsValidated.message = message;
      state.companyForReview.markAsValidated.isMarkedAsValidated = true;
      state.thunks.validateLead.isLoading = false;
      state.thunks.validateLead.isSuccess = true;
      state.thunks.validateLead.error = {};
      state.thunks.validateLead.isError = false;
      state.thunks.validateLead.isStarted = false;
      state.thunks.validateLead.isFinished = true;
    });
    builder.addCase(validateLead.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyForReview.markAsValidated.isMarkedAsValidated = false;
      state.thunks.validateLead.error = error;
      state.thunks.validateLead.isError = true;
      state.thunks.validateLead.isLoading = false;
      state.thunks.validateLead.isStarted = false;
      state.thunks.validateLead.isFinished = true;
    });

    builder.addCase(rejectLead.pending, state => {
      state.thunks.rejectLead.isLoading = true;
      state.thunks.rejectLead.isSuccess = false;
      state.thunks.rejectLead.error = {};
      state.thunks.rejectLead.isError = false;
      state.thunks.rejectLead.isStarted = true;
    });
    builder.addCase(rejectLead.fulfilled, (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.companyForReview.markAsRejected.message = message;
      state.companyForReview.markAsRejected.isMarkedAsRejected = true;
      state.thunks.rejectLead.isLoading = false;
      state.thunks.rejectLead.isSuccess = true;
      state.thunks.rejectLead.error = {};
      state.thunks.rejectLead.isError = false;
      state.thunks.rejectLead.isStarted = false;
      state.thunks.rejectLead.isFinished = true;
    });
    builder.addCase(rejectLead.rejected, (state, action: any) => {
      const error = action.payload;
      state.companyForReview.markAsRejected.isMarkedAsRejected = false;
      state.thunks.rejectLead.error = error;
      state.thunks.rejectLead.isError = true;
      state.thunks.rejectLead.isLoading = false;
      state.thunks.rejectLead.isStarted = false;
      state.thunks.rejectLead.isFinished = true;
    });
  },
});

export const {
  setCompanyTargetGroupAnalyticsSorting,
  setCurrentWeekOfCompanyCreation,
  resetCompanyTargetGroupAnalyticsSorting,
  resetCompanyTargetGroupForAnalyticsData,
  resetClientsCompaniesByTargetGroupData,
  resetCompanyTargetGroup,
  resetCompaniesForResearch,
  setCurrentCompanyForResearchParams,
  setCurrentResearchFilterCriterion,
  setMarkAsToResearch,
  setMarkAsResearched,
  setMarkAsNotAFit,
  setMarkAsEmailValidation,
  setMarkAsInvalidEmail,
  setMarkAsValidated,
  setMarkAsRejected,
  updateCompanyResearchForm,
  setResearchFormValidationErrors,
  setIsFirstCompanyLoad,
  resetCurrentCompanyForResearchParams,
  resetCompanyTargetGroupState,
  setCurrentReviewFilterCriterion,
  setCurrentSequenceLead,
  setCurrentCompanyForReviewParams,
  resetCompaniesForReview,
  setIsFirstCompanyForReviewLoad,
  resetValidateLeadThunk,
  resetValidateBulkLeadThunk,
  resetPropagateThunk,
} = companyTargetGroupSlice.actions;

export const companyTargetGroupReducer = companyTargetGroupSlice.reducer;
