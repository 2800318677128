import { Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

export const ConfirmActionContainer = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiBackdrop-root.MuiModal-backdrop': {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    width: '500px',
    borderRadius: '.5rem',
    padding: '2rem',
    border: '1px solid',
    borderColor: theme.palette.neutrals.gray.medium,
    boxShadow: 'none',

    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
  },
}));

export const ConfirmActionHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',

  '> svg': {
    transition: 'opacity 0.2s',
    cursor: 'pointer',
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.neutrals.gray.highest,

    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export const ConfirmActionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1.5rem',
  margin: '0',
  color: theme.palette.neutrals.gray.highest,
  lineHeight: 1,
}));

export const ConfirmActionContent = styled(Typography)(({ theme }) => ({
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1.25rem',
  color: theme.palette.neutrals.gray.high,
}));

export const ConfirmActionButtons = styled(DialogActions)<DialogActionsProps>(() => ({
  paddingTop: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  gap: '0.375rem',
  paddingInline: '1rem',
}));
