import { Pencil } from 'lucide-react';
import { useState } from 'react';

import { Button } from '../../../../../components/ui/button';
import { Input } from '../../../../../components/ui/input';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../../components/ui/tooltip';

export type HeaderType = 'required' | 'missing' | 'extra' | 'contact';

interface HeaderBadgeProps {
  header: string;
  type: HeaderType;
  sampleData?: string;
  onRename?: (oldHeader: string, newHeader: string) => void;
}

const getHeaderStyles = (type: HeaderType) => {
  switch (type) {
    case 'required':
      return {
        container:
          'flex items-center border rounded-md text-slate-700 bg-slate-100 border-slate-200',
        button:
          'h-8 px-1.5 border-l border-slate-200 text-slate-400 hover:text-slate-700 hover:bg-slate-200/50 transition-colors cursor-pointer',
      };
    case 'missing':
      return {
        container: 'text-base px-2 py-1 text-red-600 border border-red-100 rounded-md bg-red-50',
        button: '',
      };
    case 'extra':
      return {
        container: 'flex items-center text-blue-600 border border-blue-100 rounded-md bg-blue-50',
        button:
          'h-8 px-1.5 border-l border-blue-100 text-blue-400 hover:text-blue-600 hover:bg-blue-100/80 transition-colors cursor-pointer',
      };
    case 'contact':
      return {
        container:
          'flex items-center text-green-600 border border-green-100 rounded-md bg-green-50',
        button:
          'h-8 px-1.5 border-l border-green-100 text-green-400 hover:text-green-600 hover:bg-green-100/80 transition-colors cursor-pointer',
      };
  }
};

const HeaderBadge = ({ header, type, sampleData, onRename }: HeaderBadgeProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newHeaderName, setNewHeaderName] = useState(header);
  const styles = getHeaderStyles(type);

  const handleStartEdit = () => {
    setIsEditing(true);
    setNewHeaderName(header);
  };

  const handleSaveEdit = () => {
    if (newHeaderName && newHeaderName !== header && onRename) {
      onRename(header, newHeaderName);
    }
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="flex items-center overflow-hidden border rounded-md">
        <Input
          className="border-0 h-8 px-2 text-base min-w-[150px]"
          value={newHeaderName}
          onChange={e => setNewHeaderName(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && handleSaveEdit()}
          autoFocus
        />
        <Button size="sm" variant="ghost" className="h-8 px-2" onClick={handleSaveEdit}>
          Save
        </Button>
      </div>
    );
  }

  // Render header with sample data tooltip if available
  const renderHeader = (children: React.ReactNode) => {
    if (!sampleData) return children;

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent
            side="top"
            className="max-w-sm text-black bg-white border border-gray-200 shadow-sm"
          >
            <div className="space-y-1">
              <p className="text-xs font-medium text-muted-foreground">Sample data:</p>
              <p className="text-sm font-medium truncate">{sampleData}</p>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  // Missing headers don't have rename button
  if (type === 'missing') {
    return renderHeader(<div className={styles.container}>{header}</div>);
  }

  return renderHeader(
    <div className={styles.container}>
      <span className="px-2 py-1 text-base">{header}</span>
      {onRename && (
        <button onClick={handleStartEdit} className={styles.button} title="Rename header">
          <Pencil className="h-3.5 w-3.5" />
        </button>
      )}
    </div>,
  );
};

export default HeaderBadge;
