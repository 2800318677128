import { Fade } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

import { muiThemeOptions } from '../../../../app/styles/global/mui/mui-theme-options';
import { defaultTooltipStyle } from '../../tooltip/base-tooltip.styles';
import { TooltipPlacement } from '../../tooltip/base-tooltip.types';
import {
  FormControlContainerProps,
  InputIconProps,
  LabelProps,
  MuiInputProps,
} from './base-input.types';

export const defaultBaseInputStyleSchema = {
  tooltipStyleSchema: {
    styleSchema: defaultTooltipStyle,
    transition: Fade,
    placement: TooltipPlacement.RIGHT,
  },
};

export const additionalBaseInputStyles = () => {
  return {
    '&::placeholder': {
      color: muiThemeOptions.palette.text.placeholder,
      opacity: 1,
      position: 'absolute',
      top: '13px',
      left: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: 0,
    },
    '&[placeholder]': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    ':focus::-webkit-input-placeholder': { color: 'transparent' },
    ':focus::-moz-placeholder': { color: 'transparent' },
    ':focus::-ms-input-placeholder': { color: 'transparent' },
  };
};

// check this!!!!!
export const FormControlContainer = styled(FormControl)<FormControlContainerProps>(() => ({
  width: '100%',

  '>ul': {
    margin: 0,
    padding: '0 15px',

    li: {
      listStyleType: 'none',
      position: 'relative',
    },

    'li::before': {
      content: '"\u2B24"',
      position: 'absolute',
      left: '-0.8rem',
      top: '0.3rem',
      fontSize: '0.35rem',
      color: '#ea4c89',
    },
  },
}));

export const Label = styled(InputLabel)<LabelProps>(({ theme, mode }) => ({
  all: 'unset',
  color: theme.palette.neutrals.gray.highest,
  fontSize: '0.875rem',
  lineHeight: '130%',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontWeight: 400,

  marginBottom: '8px',
  '&.Mui-focused': {
    color: theme.palette.neutrals.gray.highest,
  },

  '&.Mui-error': {
    color: theme.palette.common.white,
  },

  ...(mode === 'dark' && {
    color: theme.palette.neutrals.gray.lowest,

    '&.Mui-focused': {
      color: theme.palette.common.white,
    },
  }),
}));

export const InputIcon = styled(InputAdornment)<InputIconProps>(({ theme }) => ({
  '&.MuiInputAdornment-positionEnd': {
    color: theme.palette.background.input.endAdornment,
    position: 'absolute',
    top: '50%',
    left: '89%',
    cursor: 'pointer',
    transform: 'translateY(-50%)',

    '> svg': { fontSize: '20px' },

    [theme.breakpoints.down(1600)]: {
      left: '87%',
    },
  },
}));

export const Input = styled(InputBase, {
  shouldForwardProp: props => props !== 'isPasswordField',
})<MuiInputProps>(({ theme, mode, isPasswordField }) => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',

  fontFamily: '"Darker Grotesque", sans-serif',
  fontWeight: 400,

  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.neutrals.gray.lowest,
    color: theme.palette.neutrals.gray.highest,
    borderRadius: '4px',
    minHeight: '36px',
    fontSize: '1rem',

    padding: '6px 12px',
    border: `1px solid ${theme.palette.neutrals.gray.higher}`,

    paddingRight: isPasswordField ? '9.3%' : '4%',
    ...(mode === 'dark' && {
      backgroundColor: theme.palette.neutrals.gray.highest,
      color: theme.palette.neutrals.gray.lowest,
      border: `1px solid ${theme.palette.neutrals.gray.low}`,
    }),

    [theme.breakpoints.down(1600)]: {
      paddingRight: isPasswordField ? '11%' : '5%',
    },
  },

  '&.Mui-error': {
    color: '#ea4c89',
  },
}));

export const InfoIcon = styled('img')(() => ({
  cursor: 'pointer',
}));
