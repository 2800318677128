/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';

import { DeleteIcon, MuiChip } from './base-chip.styles';
import { IBaseChipProps } from './base-chip.types';

function BaseChip({
  label,
  onDeleteCallback,
  className,
  disabled,
  isClickableLink = false,
}: IBaseChipProps) {
  function handleDelete(e: React.MouseEvent) {
    e.stopPropagation();
    onDeleteCallback && onDeleteCallback(label);
  }

  function handleChipClick(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLElement;
    if (!target.closest('.MuiChip-deleteIcon')) {
      if (isClickableLink) {
        window.open(label, '_blank');
      }
    }
  }

  const clickableProperties = isClickableLink ? { component: 'div', clickable: true } : {};

  return (
    <MuiChip
      label={label}
      onDelete={handleDelete}
      deleteIcon={<DeleteIcon />}
      onMouseDown={(e: any) => {
        e.stopPropagation();
      }}
      onClick={handleChipClick}
      className={className}
      disabled={disabled ?? false}
      {...clickableProperties}
    />
  );
}

export default BaseChip;
