import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { Button } from '../../../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/ui/dialog';
import { Input } from '../../../../components/ui/input';
import { Label } from '../../../../components/ui/label';
import {
  resetUploadCompaniesThunk,
  uploadCompaniesForReview,
} from '../../../../entities/company/store/company.slice';
import {
  countCompaniesToReview,
  getCompaniesForReviewByTargetGroupId,
} from '../../../../entities/company-target-group/store/company-target-group.slice';
import { currentTargetGroupParamsSelector } from '../../../../entities/target-group/store/target-group.selectors';
import { UploadParams } from '../../../../features/import-companies/components/import-companies-dropzone/import-companies-dropzone.types';
import { useToast } from '../../../../shared/hooks/use-toast.hook';
import CsvPreview from './csv-preview';
import {
  categorizeHeaders,
  extractContactFieldSamples,
  extractHeadersFromCSV,
  extractSampleDataFromCSV,
  isDynamicContactField,
  saveCSVFile,
  updateCsvHeader,
} from './helpers/csv-helpers';

// Expected headers from the CSV file
const EXPECTED_HEADERS = [
  'Company Name',
  'Company LinkedIn',
  'Domain',
  'Parent Company',
  'Headcount',
  'Revenue',
  'Profit',
  'Short Description',
  'Founded',
  '12 Mo Headcount Growth',
  'Ownership Structure',
  'Company Country',
  'Company State',
  'Company City',
  'PE Backer',
  'PE Backing Date',
  'VC Backer',
  'VC Backing Date',
  'Explanation',
];

// Contact field patterns
const CONTACT_FIELD_PATTERNS = [
  'Contact Name',
  'Contact Email',
  'Contact Title',
  'Contact LinkedIn',
];

interface ImportFileDialogProps {
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason?: string) => void;
}

function ImportFileDialog({ onClose, open }: ImportFileDialogProps) {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState<'upload' | 'preview'>('upload');
  const [finalHeaders, setFinalHeaders] = useState<string[]>([]);
  const [csvContent, setCsvContent] = useState<string>('');

  const { clientId } = useParams();
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  // Function to reset all state
  const resetAllState = () => {
    setFile(null);
    setIsUploading(false);
    setStep('upload');
    setFinalHeaders([]);
    setCsvContent('');
  };

  // Computed header categories
  const headerCategories = useMemo(() => {
    return categorizeHeaders(finalHeaders, EXPECTED_HEADERS, CONTACT_FIELD_PATTERNS);
  }, [finalHeaders]);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(resetUploadCompaniesThunk());
    resetAllState();
    if (onClose) {
      onClose(e, 'backdropClick');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      // Reset state when file changes
      setFinalHeaders([]);
      setCsvContent('');
    }
  };

  const handlePreview = () => {
    if (!file) {
      toast.error({ message: 'Please select a CSV file' });
      return;
    }

    const reader = new FileReader();
    reader.onload = event => {
      try {
        const content = event.target?.result as string;
        setCsvContent(content);

        // Extract headers using our helper function
        const headers = extractHeadersFromCSV(content);
        if (headers.length > 0) {
          setFinalHeaders(headers);
          setStep('preview');
        } else {
          toast.error({ message: 'CSV file appears to be empty or has no headers' });
        }
      } catch (error) {
        toast.error({ message: 'Failed to parse CSV file' });
        console.error('CSV parse error:', error);
      }
    };

    reader.onerror = () => {
      toast.error({ message: 'Failed to read CSV file' });
    };

    reader.readAsText(file);
  };

  const handleHeaderRename = (oldHeader: string, newHeader: string) => {
    // Do nothing if the name didn't actually change
    if (oldHeader === newHeader) return;

    // Update the header in our final headers list
    const updatedHeaders = finalHeaders.map(header => (header === oldHeader ? newHeader : header));

    setFinalHeaders(updatedHeaders);

    // Update the CSV content with the new header
    if (csvContent) {
      const updatedContent = updateCsvHeader(csvContent, oldHeader, newHeader);
      setCsvContent(updatedContent);
    }
  };

  const handleSaveCSV = () => {
    if (!csvContent || !file) return;

    saveCSVFile(csvContent, file.name);
    toast.success({ message: 'CSV file saved to your downloads folder' });
  };

  const handleUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!file) return;

    // Show warning if there are missing headers
    if (headerCategories.missing.length > 0) {
      const confirm = window.confirm(
        `Your CSV is missing ${headerCategories.missing.length} required headers. Uploading without these headers may cause issues. Are you sure you want to continue?`,
      );
      if (!confirm) return;
    }

    // If we've renamed headers, create a new file with the updated content
    let fileToUpload = file;
    if (csvContent) {
      // Create a new file with the updated content
      const blob = new Blob([csvContent], { type: 'text/csv' });
      fileToUpload = new File([blob], file.name, { type: 'text/csv' });
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', fileToUpload);

    const params: UploadParams = {
      clientId,
      targetGroupId,
      formData,
    };

    dispatch(uploadCompaniesForReview(params))
      .unwrap()
      .then(() => {
        dispatch(getCompaniesForReviewByTargetGroupId({ clientId, targetGroupId }));
        toast.success({ message: 'List uploaded successfully' });
        handleClose(e);
        dispatch(countCompaniesToReview({ clientId }));
      })
      .catch(err => {
        toast.error({ message: err.message });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onOpenChange={value => {
        if (!value) {
          dispatch(resetUploadCompaniesThunk());
          resetAllState();
          if (onClose) {
            // Create a synthetic event for the onClose handler
            const syntheticEvent = new MouseEvent('click') as any;
            onClose(syntheticEvent, 'escapeKeyDown');
          }
        }
      }}
    >
      <DialogContent className="sm:max-w-[800px] w-[90vw]">
        <DialogHeader>
          <DialogTitle>{step === 'preview' ? 'CSV Preview' : 'Import List'}</DialogTitle>
          <DialogDescription>
            {step === 'upload'
              ? 'Upload a CSV file with company data'
              : 'Verify the content of your CSV file'}
          </DialogDescription>
        </DialogHeader>

        {step === 'upload' && (
          <div className="grid items-center w-full gap-4">
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="file-upload">CSV File</Label>
              <div className="flex items-center gap-2">
                <Input
                  id="file-upload"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  className="flex-1 cursor-pointer"
                />
              </div>
              <p className="text-xs text-muted-foreground">Only CSV files are accepted</p>
            </div>
          </div>
        )}

        {step === 'preview' && (
          <CsvPreview
            finalHeaders={finalHeaders}
            expectedHeaders={EXPECTED_HEADERS}
            isDynamicContactField={header => isDynamicContactField(header, CONTACT_FIELD_PATTERNS)}
            headerCategories={headerCategories}
            fileName={file?.name || ''}
            headerSamples={{
              ...(csvContent ? extractSampleDataFromCSV(csvContent) : {}),
              ...(csvContent ? extractContactFieldSamples(csvContent, CONTACT_FIELD_PATTERNS) : {}),
            }}
            onHeaderRename={handleHeaderRename}
          />
        )}

        <DialogFooter>
          {step === 'upload' ? (
            <>
              <Button variant="outline" onClick={e => handleClose(e)}>
                Cancel
              </Button>
              <Button onClick={handlePreview} disabled={!file}>
                Preview CSV
              </Button>
            </>
          ) : (
            <div className="flex justify-between w-full">
              <Button
                variant="outline"
                onClick={handleSaveCSV}
                className="text-blue-600 border-blue-200 hover:text-blue-700 hover:border-blue-300 hover:bg-blue-50"
              >
                Save CSV
              </Button>
              <div className="flex gap-2">
                <Button variant="outline" onClick={() => setStep('upload')}>
                  Back
                </Button>
                <Button
                  onClick={handleUpload}
                  disabled={isUploading}
                  className={isUploading ? 'opacity-70 cursor-not-allowed' : ''}
                >
                  {isUploading ? 'Uploading...' : 'Upload'}
                </Button>
              </div>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ImportFileDialog;
