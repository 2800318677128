import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';
import IntelligenceLeftSection from '../../modules/intelligence-left-section/components/intelligence-left-section.component';
import IntelligenceRightSection from '../../modules/intelligence-right-section/components/intelligence-right-section.component';

function Intelligence() {
  return (
    <TwoSectionsScreenLayout
      leftSectionComponent={IntelligenceLeftSection}
      rightSectionComponent={IntelligenceRightSection}
    />
  );
}

export default Intelligence;
