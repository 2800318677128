export function checkIsNotEmptyArray(array: any[]) {
  return array?.length >= 1;
}

export function checkIsEmptyObject(object: any) {
  return Object.keys(object).length === 0;
}

export function formErrorMessagesArray(errorMessage: string | string[] = []) {
  const errorMessagesArray: string[] = [];
  const isArray = Array.isArray(errorMessage);
  if (isArray) {
    errorMessagesArray.push(...errorMessage);
    return errorMessagesArray;
  }
  errorMessagesArray.push(errorMessage);
  return errorMessagesArray;
}

export function formatDateToDDMMYYYY(serverDate: string) {
  const date = new Date(serverDate);
  const ddmmyyyy = date.toLocaleDateString('en-GB');
  return ddmmyyyy;
}

export function scrollToListBottom(listRef: any) {
  if (listRef.current) {
    listRef.current?.scrollTo({
      top: listRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  }
}

export function capitalizeFirstLetter(inputString: string): string {
  if (!inputString) {
    return inputString;
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function startsWithHttpOrHttps(url: string) {
  return url.startsWith('http://') || url.startsWith('https://');
}

export function formatUrlToGuaranteeClickability(url: string) {
  if (!url) return '';
  if (startsWithHttpOrHttps(url)) return url;
  return `http://${url}`;
}

export function composeName<T extends { firstName: string; lastName: string }>(
  objectWithDataForName: T,
) {
  return `${objectWithDataForName.firstName ?? ''} ${objectWithDataForName.lastName ?? ''}`;
}

export function getValueFromObject<T>(object: T, path: string): any {
  const directories = path.split('.');
  const getFieldByKey = (accumulator: any, currentValue: any) => accumulator?.[currentValue];
  return directories.reduce(getFieldByKey, object);
}

export function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;
  const ellipsis = '...';
  const halfLength = Math.floor((maxLength - ellipsis.length) / 2);
  const firstPart = text.slice(0, halfLength);
  const lastPart = text.slice(text.length - halfLength);
  const truncatedText = `${firstPart}${ellipsis}${lastPart}`;
  return truncatedText.length >= text.length ? text : truncatedText;
}
