import { Box, Button, styled } from '@mui/material';

export const ReviewRightSectionFormContainer = styled('div')<{ isCompanyChosen?: boolean }>(
  ({ isCompanyChosen }) => ({
    height: '100%',
    position: 'relative',
    opacity: isCompanyChosen ? 0.4 : 1,
  }),
);

export const ExportButton = styled(Button)(({ theme }) => ({
  fontFamily: 'DM Mono',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  padding: '8px',
  color: theme.palette.neutrals.gray.medium,

  '&:hover': {
    backgroundColor: theme.palette.neutrals.gray.lower,
  },
}));

export const ImportButton = styled(ExportButton)({});

export const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});
