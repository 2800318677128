import React from 'react';

import {
  NoCompanyChosenMessage,
  NoCompanyChosenScreenContainer,
} from './no-company-chosen-screen.styles';

function NoCompanyChosenScreen() {
  return (
    <NoCompanyChosenScreenContainer>
      <NoCompanyChosenMessage>
        Select a target group on the left for the list of companies
      </NoCompanyChosenMessage>
    </NoCompanyChosenScreenContainer>
  );
}

export default NoCompanyChosenScreen;
