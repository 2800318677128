import React from 'react';

import {
  NoTargetGroupChosenMessage,
  NoTargetGroupChosenScreenContainer,
} from './no-target-group-chosen-screen.styles';

function NoTargetGroupChosenScreen() {
  return (
    <NoTargetGroupChosenScreenContainer>
      <NoTargetGroupChosenMessage>
        Create a new target group by clicking on the button "Add a new target group"
      </NoTargetGroupChosenMessage>
    </NoTargetGroupChosenScreenContainer>
  );
}

export default NoTargetGroupChosenScreen;
