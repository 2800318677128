import { useState } from 'react';

import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import PageContentHeader from '../../../app/layouts/page-content/page-content-header/page-content-header.component';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { eventsFilterByCategories } from '../../../entities/company/store/company.selectors';
import { pushSelectedNewsEventsCategoriesToFilter } from '../../../entities/company/store/company.slice';
import {
  currentTargetGroupSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import SelectEventsCategoriesWithAutocomplete from '../../../features/select-events-categories-with-autocomplete/components/select-events-categories-with-autocomplete.component';
import IntelligenceContent from '../../intelligence-content/components/intelligence-content.component';
import NotificationSettings from '../../intelligence-content/components/notification-settings/notification-settings.component';
import { SearchInput } from '../../search-form/components/search-form-input/search-form-input.styles';
import {
  IntelligenceRightSectionContainer,
  IntelligenceRightSectionHeaderComponents,
} from './intelligence-right-section.styles';
import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';

function IntelligenceRightSection() {
  const { id: targetGroupId, name: targetGroupName } = useAppSelector(currentTargetGroupSelector);

  const [search, setSearch] = useState('');

  const isTargetGroupChosen = Boolean(targetGroupId);

  const {
    getTargetGroupById: { isLoading: isTargetGroupLoading },
  } = useAppSelector(targetGroupThunksSelector);
  const selectedCategories = useAppSelector(eventsFilterByCategories);

  const dispatch = useAppDispatch();

  return (
    <IntelligenceRightSectionContainer>
      <PageContentHeader
        title="Intelligence"
        description={isTargetGroupChosen ? targetGroupName : ''}
        rightComponent={
          isTargetGroupChosen ? (
            <IntelligenceRightSectionHeaderComponents>
              <SearchInput
                id="search-companies-intelligence"
                name="search-companies-intelligence"
                type="text"
                values={{ search }}
                handleChange={e => setSearch(e.target.value)}
                placeholder="Search by company"
                sx={theme => ({
                  flex: 1,
                  maxWidth: '324px',
                  input: {
                    borderColor: `${theme.palette.neutrals.gray.low} !important`,
                  },
                })}
              />
              <SelectEventsCategoriesWithAutocomplete
                id="select-events-categories"
                label="Categories"
                name="Categories"
                values={selectedCategories}
                handleChange={value => {
                  dispatch(pushSelectedNewsEventsCategoriesToFilter(value));
                }}
              />
              <NotificationSettings />
            </IntelligenceRightSectionHeaderComponents>
          ) : null
        }
      />
      <RightSectionMain
        isListEntityChosen={isTargetGroupChosen}
        isDataLoading={isTargetGroupLoading}
        content={<IntelligenceContent search={search} />}
        noContent={<NoTargetGroupChosenScreen />}
      />
    </IntelligenceRightSectionContainer>
  );
}

export default IntelligenceRightSection;
