import { Checkbox, Fade, FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import ButtonV2 from '../../../../button/button-v2/button-v2.component';
import {
  ConfirmActionButtons,
  ConfirmActionContainer,
  ConfirmActionContent,
  ConfirmActionHeader,
  ConfirmActionTitle,
} from './confirm-action.styles';
import { IConfirmActionProps } from './confirm-action.types';

export default function ConfirmAction({
  title,
  message,
  cancelTitle,
  actionTitle,
  handleAction,
  handleCancel,
  handleClose,
  isActionLoading,
  checkboxConfig,
}: IConfirmActionProps) {
  const theme = useTheme();

  return (
    <ConfirmActionContainer
      open
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Fade}
      onClick={e => e.stopPropagation()}
    >
      <ConfirmActionHeader>
        <ConfirmActionTitle>{title}</ConfirmActionTitle>
      </ConfirmActionHeader>

      <ConfirmActionContent>{message}</ConfirmActionContent>

      {checkboxConfig && (
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxConfig.isCheckboxChecked}
              onChange={checkboxConfig.toggleCheckbox}
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.neutrals.gray.highest,
                },
              }}
            />
          }
          label={
            <ConfirmActionContent style={{ margin: 0, padding: 0, userSelect: 'none' }}>
              {checkboxConfig.label}
            </ConfirmActionContent>
          }
        />
      )}

      <ConfirmActionButtons>
        <ButtonV2
          customVariant="primary-small"
          title={cancelTitle as string}
          handleClick={handleCancel}
          className="cancel-button"
          sx={{ width: '100%' }}
        />
        <ButtonV2
          customVariant="secondary-small"
          title={actionTitle as string}
          handleClick={handleAction}
          className="action-button"
          isLoading={isActionLoading}
          sx={{ width: '100%' }}
        />
      </ConfirmActionButtons>
    </ConfirmActionContainer>
  );
}
