import { Box, styled } from '@mui/material';

export const StyledTableContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',

  '.ag-cell, .ag-header-cell-comp-wrapper, .ag-menu': {
    fontFamily: '"DM Mono", serif',
    fontWeight: 400,
    fontSize: '12px',
  },

  '.ag-row': {
    transition: 'all 0.3s !important',
    '&-hover': {
      background: `${theme.palette.neutrals.gray.lower} !important`,
      '&::before': {
        display: 'none !important',
      },
    },
  },

  '.ag-menu-option': {
    transition: 'all 0.3s !important',
    '&-active': {
      background: `${theme.palette.neutrals.gray.low} !important`,
    },
  },

  '.ag-header-cell': {
    '&-menu-button': {
      opacity: 0,
      transition: 'all 0.3s !important',
    },

    '&:hover': {
      '.ag-header-cell-menu-button': {
        opacity: 1,
      },
    },
  },
}));
