import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const FetchClientsListContainer = styled(Container)<ContainerProps>(() => ({
  width: '100%',
  height: 'calc(100% - 65px)',
  position: 'relative',
  margin: '2rem',
  borderRadius: '1rem',
  maxHeight: 'max-content',
  overflow: 'hidden',
  maxWidth: '750px !important',

  '> div:first-of-type': {
    position: 'sticky',
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(10px)',
    zIndex: 2,
    padding: '1rem',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    maxWidth: '400px',
    margin: '0 auto',
    marginBottom: '1rem',
  },

  '.select-client': {
    padding: '0 .5rem 1.5rem .5rem',
    position: 'relative',
    zIndex: 1,
    height: 'calc(100% - 80px)',
    overflow: 'auto',
  },

  '.search-clients': {
    position: 'relative',
    zIndex: 1,
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline':
      {
        padding: '0 0.5rem',
      },
  },

  '#search-input': {
    fontSize: '1.25rem',
  },
}));
