import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

import BaseButton from '../base-button/base-button.component';
import { PipelineNavigationBaseButtonProps } from './pipeline-navigation-button.types';

export const PipelineNavigationBaseButton = styled(BaseButton, {
  shouldForwardProp: props => props !== 'isCurrentRoute' && props !== 'isPreviousRoute',
})<PipelineNavigationBaseButtonProps>(({ isCurrentRoute, isPreviousRoute, theme }) => {
  const isHighlighted = isCurrentRoute || isPreviousRoute;

  return {
    transition: theme.transitions.create('background-color'),

    height: '55px',
    padding: '8px 4px',
    border: 'none',
    backgroundColor: 'transparent',
    borderTop: '2px solid',
    borderTopColor: isHighlighted
      ? theme.palette.neutrals.gray.high
      : theme.palette.neutrals.gray.low,
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.neutrals.gray.low,
      color: theme.palette.neutrals.gray.low,
    },

    '& .pipeline-btn-upperline': {
      color: isHighlighted ? theme.palette.neutrals.gray.high : theme.palette.neutrals.gray.medium,
      transition: theme.transitions.create('font-size'),

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.625rem',
      },
    },
    '& .pipeline-btn-bottomline': {
      color: isHighlighted
        ? theme.palette.neutrals.gray.highest
        : theme.palette.neutrals.gray.medium,
      fontWeight: isHighlighted ? 500 : 400,
      transition: theme.transitions.create('font-size'),

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.75rem',
      },
    },

    '& .MuiChip-root': {
      opacity: isHighlighted ? 1 : 0.6,
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },
  };
});

export const UpperLine = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: '"DM Mono", serif',
  color: theme.palette.neutrals.gray.high,
  fontSize: '0.75rem',
  lineHeight: '150%',
  fontWeight: 400,
  textTransform: 'uppercase',
  '&:hover': {},
}));

export const BottomLine = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: '"Darker Grotesque", sans-serif',
  color: theme.palette.neutrals.gray.highest,
  fontSize: '1rem',
  lineHeight: '110%',
  textTransform: 'initial',
  '&:hover': {},
}));
