import { ReactNode } from 'react';

import { BaseLoader } from '../../loader/base-loader/base-loader.component';
import {
  FeedbackUiType,
  IBaseLoaderProps,
  StatusFeedbackUiPropsUnion,
  StatusFeedbackUiType,
} from '../feedback-ui.types';
import { statusFeedbackUiPropsWithDefaults } from './status-feedback-ui-kit.constants';

export const statusFeedbackUiKit: {
  [Property in StatusFeedbackUiType]: (props: StatusFeedbackUiPropsUnion) => ReactNode;
} = {
  [FeedbackUiType.LOADER]: (props: StatusFeedbackUiPropsUnion) => {
    const propsWithDefaults = statusFeedbackUiPropsWithDefaults[FeedbackUiType.LOADER](props);
    return <BaseLoader {...(propsWithDefaults as IBaseLoaderProps)} />;
  },
  [FeedbackUiType.SKELETON]: (props: StatusFeedbackUiPropsUnion) => null,
  [FeedbackUiType.PROGRESS]: (props: StatusFeedbackUiPropsUnion) => null,
};
