import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import BaseInput from '../../../../shared/components/input/base-input/base-input.component';
import BaseTextarea from '../../../../shared/components/textarea/base-textarea/base-textarea.component';

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  textAlign: 'center',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1.5rem',
  fontWeight: 400,
  lineHeight: '80%',
}));
export const Subtitle = styled('p')(({ theme }) => ({
  color: theme.palette.neutrals.gray.high,
  textAlign: 'center',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '130%',
}));

export const EmailInput = styled(BaseInput)(({ theme }) => ({
  '& .MuiInputBase-root .MuiInputBase-input': {
    border: `1px solid ${theme.palette.neutrals.gray.low}`,
  },
  label: {
    color: `${theme.palette.neutrals.gray.high} !important`,
  },
}));

export const NotesInput = styled(BaseTextarea)(() => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    maxWidth: 'calc(100% - 0.625rem)',
    paddingRight: '0.625rem',
  },

  '& .MuiInputBase-root .MuiInputBase-input': {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
}));

export const LabelContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '130%',
}));
