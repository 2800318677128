import './app/styles/global/css/globals.css';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, StyledEngineProvider, Typography } from '@mui/material';
import { enableMapSet } from 'immer';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ErrorBoundaryDecorator } from './app/decorators/error-boundary/error-boundary.decorator';
import { MuiThemeDecorator } from './app/decorators/mui-theme/mui-theme.decorator';
import { router } from './app/routing/router';
import { persistor, setUpInterceptor, store } from './app/store/store';
import { useAppDispatch } from './app/store/utils/redux.hooks';
import { userThunksSelector } from './entities/user/store/user.selectors';
import { getUser } from './entities/user/store/user.slice';
import CustomErrorForErrorBoundary from './shared/components/error/custom-error-for-error-boundary.component';
import { ToastNotifications } from './shared/components/toast/toast-notifications.component';

enableMapSet();

function App() {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, isAuthenticated, logout, isLoading: isAuthLoading } = useAuth0();
  const { isLoading: isUserLoading } = useSelector(userThunksSelector).getUser;
  const [sessionRecoveryAttempted, setSessionRecoveryAttempted] = useState(false);

  // Set up initial interceptor and handle session recovery
  useEffect(() => {
    const initializeApp = async () => {
      if (!isAuthLoading) {
        // Set up interceptor regardless of authentication state
        setUpInterceptor(store, getAccessTokenSilently, logout);

        // Try to recover session if authenticated
        if (isAuthenticated) {
          try {
            await dispatch(getUser()).unwrap();
          } catch (error) {
            console.error('Failed to fetch user:', error);
          }
        }

        setSessionRecoveryAttempted(true);
      }
    };

    initializeApp();
  }, [isAuthenticated, isAuthLoading, getAccessTokenSilently, dispatch, logout]);

  // Show loading state during authentication/initialization
  if (isAuthLoading || (isAuthenticated && isUserLoading && !sessionRecoveryAttempted)) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          flexDirection: 'column-reverse',
        }}
      >
        <Typography fontFamily="DM Mono" fontSize={20} color="#000000">
          Loading...
        </Typography>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  }

  return <RouterProvider router={router} />;
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Auth0Provider
    domain={import.meta.env['VITE_AUTH0_DOMAIN'] ?? ''}
    clientId={import.meta.env['VITE_AUTH0_CLIENT'] ?? ''}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/authentication/success`, // INFO: The FE page the user'll be redirected to after passing login
      audience: import.meta.env['VITE_AUTH0_AUDIENCE'],
    }}
    cacheLocation="localstorage"
  >
    <ErrorBoundaryDecorator
      FallbackComponent={CustomErrorForErrorBoundary}
      onError={(error, errorInfo) => {
        console.error({ error });
        console.error({ errorInfo });
      }}
    >
      <StyledEngineProvider injectFirst>
        <PersistGate persistor={persistor}>
          <Provider store={store}>
            <MuiThemeDecorator>
              <ToastNotifications />
              <App />
            </MuiThemeDecorator>
          </Provider>
        </PersistGate>
      </StyledEngineProvider>
    </ErrorBoundaryDecorator>
  </Auth0Provider>,
);
