export enum FeedbackUiType {
  MODAL = 'modal',
  SNACKBAR = 'snackbar',
  LOADER = 'loader',
  SKELETON = 'skeleton',
  PROGRESS = 'progress',
}

export interface ICheckboxConfig {
  label: string;
  toggleCheckbox: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isCheckboxChecked: boolean;
}

export type InfoOrErrorFeedbackUiType = Extract<
  FeedbackUiType,
  FeedbackUiType.MODAL | FeedbackUiType.SNACKBAR
>;

export type StatusFeedbackUiType = Extract<
  FeedbackUiType,
  FeedbackUiType.LOADER | FeedbackUiType.SKELETON | FeedbackUiType.PROGRESS
>;

export interface IInfoOrErrorFeedbackUiProps<UiContentType> {
  isUiVisible?: boolean;
  uiContentType?: UiContentType;
  title?: string;
  message?: string | string[] | undefined;
  actionTitle?: string | { [key: string]: string };
  cancelTitle?: string | { [key: string]: string };
  handleClose?:
    | (() =>
        | void
        | (() => Promise<void>)
        | { [key: string]: () => void }
        | { [key: string]: () => Promise<void> })
    | ((event: any) => void)
    | ((event: any) => Promise<void>)
    | undefined;
  handleAction?:
    | (() =>
        | void
        | (() => Promise<void>)
        | { [key: string]: () => void }
        | { [key: string]: () => Promise<void> })
    | ((event: any) => void)
    | ((event: any) => Promise<void>)
    | undefined;
  handleCancel?:
    | (() =>
        | void
        | (() => Promise<void>)
        | { [key: string]: () => void }
        | { [key: string]: () => Promise<void> })
    | ((event: any) => void)
    | ((event: any) => Promise<void>)
    | undefined;
  className?: string;
  isActionLoading?: boolean;
}

export interface IStatusFeedbackUiProps<UiSubtype> {
  status?: boolean;
  className?: string;
  size?: number;
  title?: string;
  uiSubtype?: UiSubtype;
}

export interface IBaseModalProps extends IInfoOrErrorFeedbackUiProps<ModalContentType> {
  checkboxConfig?: ICheckboxConfig;
}

export interface IBaseSnackbarProps extends IInfoOrErrorFeedbackUiProps<SnackbarContentType> {}

export type InfoOrErrorFeedbackUiPropsUnion = IBaseModalProps | IBaseSnackbarProps;

export interface IBaseLoaderProps extends IStatusFeedbackUiProps<LoaderSubtype> {}

export interface IBaseWindowLoaderProps
  extends IStatusFeedbackUiProps<LoaderSubtype.WINDOW_LOADER> {}

export interface IBaseContainerLoaderProps
  extends IStatusFeedbackUiProps<LoaderSubtype.CONTAINER_LOADER> {}

export interface IBaseButtonLoaderProps
  extends Omit<IStatusFeedbackUiProps<LoaderSubtype.BUTTON_LOADER>, 'title'> {}

export type BaseLoaderPropsUnion =
  | IBaseButtonLoaderProps
  | IBaseWindowLoaderProps
  | IBaseContainerLoaderProps;

export interface IBaseSkeletonProps extends IStatusFeedbackUiProps<SkeletonSubtype> {}

export interface IBaseProgressProps extends IStatusFeedbackUiProps<ProgressSubtype> {}

export type StatusFeedbackUiPropsUnion = IBaseLoaderProps | IBaseSkeletonProps | IBaseProgressProps;

export enum ErrorModalContentType {
  SOME_ERROR_HAPPENED = 'someErrorHappened',
}

export enum InfoModalContentType {
  CONFIRM_ACTION = 'confirmAction',
}

export type ModalContentType = ErrorModalContentType | InfoModalContentType;

export enum ErrorSnackbarContentType {
  SOME_ERROR_HAPPENED = 'someErrorHappened',
}

export enum InfoSnackbarContentType {
  SOMETHING_HAPPENED = 'somethingHappened',
}

export type SnackbarContentType = ErrorSnackbarContentType | InfoSnackbarContentType;

export enum LoaderSubtype {
  BUTTON_LOADER = 'buttonLoader',
  WINDOW_LOADER = 'windowLoader',
  CONTAINER_LOADER = 'containerLoader',
}

export enum SkeletonSubtype {
  SOMETHING_HAPPENED = 'somethingHappened',
}

export enum ProgressSubtype {
  SOMETHING_HAPPENED = 'somethingHappened',
}

export type StatusUiSubtype = LoaderSubtype | SkeletonSubtype | ProgressSubtype;

export type InfoFeedbackUiContentType = InfoModalContentType | InfoSnackbarContentType;
export type ErrorFeedbackUiContentType = ErrorModalContentType | ErrorSnackbarContentType;
export type InfoOrErrorFeedbackUiContentType = ModalContentType | SnackbarContentType;
