import { TooltipProps } from '@mui/material/Tooltip';

export interface ITooltipStyleSchema {
  backgroundColor?: string;
  textColor?: string;
  border?: string;
}

export type MuiTooltipProps = TooltipProps & {
  styleSchema: ITooltipStyleSchema;
};

export enum TooltipPlacement {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM_END = 'bottom-end',
  BOTTOM_START = 'bottom-start',
  LEFT_END = 'left-end',
  LEFT_START = 'left-start',
  RIGHT_END = 'right-end',
  RIGHT_STRAT = 'right-start',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
}

export type IBaseTooltipProps = TooltipProps & {
  styleSchema?: ITooltipStyleSchema;
  transitionComponent?: any;
};
