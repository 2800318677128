import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { defaultCreateTargetGroupBody } from '../../../entities/target-group/domain/target-group.constants';
import { targetGroupThunksSelector } from '../../../entities/target-group/store/target-group.selectors';
import {
  createTargetGroup,
  getAllTargetGroupsByClient,
  setCurrentTargetGroupParams,
} from '../../../entities/target-group/store/target-group.slice';
import ButtonV2 from '../../../shared/components/button/button-v2/button-v2.component';
import { CreateTargetGroupWrapper } from './create-target-group.styles';

const BUTTON_TITLE = 'Add a new target group';

function CreateTargetGroup() {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  const { isLoading: isTargetGroupCreating } =
    useAppSelector(targetGroupThunksSelector).createTargetGroup;

  async function handleCreateTargetGroup() {
    if (!clientId) return;
    await dispatch(createTargetGroup({ ...defaultCreateTargetGroupBody, clientId }))
      .unwrap()
      .then(async response => {
        dispatch(setCurrentTargetGroupParams({ id: response.id, name: response.name }));
      });
    await dispatch(getAllTargetGroupsByClient({ clientId })).unwrap();
  }

  return (
    <CreateTargetGroupWrapper>
      <ButtonV2
        className="create-target-group-button"
        customVariant="secondary-small"
        title={BUTTON_TITLE}
        handleClick={handleCreateTargetGroup}
        isLoading={isTargetGroupCreating}
      />
    </CreateTargetGroupWrapper>
  );
}

export default CreateTargetGroup;
