import { CURRENCIES } from '@/modules/target-definition-form/components/currency-input/currency-input.constants';

/**
 * Checks if a value is meaningful (not null, undefined, empty, or N/A-like)
 * @param value The value to check
 * @returns boolean indicating if the value is meaningful
 */
export const isMeaningfulValue = (value: any): boolean => {
  if (!value) return false; // Handles null, undefined, empty string
  if (typeof value === 'string' && (value.trim() === '' || value.toLowerCase() === 'n/a'))
    return false;
  return true;
};

const getCurrencySymbol = (currency: string): string => {
  const currencySymbol = CURRENCIES.find(c => c.code === currency)?.symbol;
  return currencySymbol || currency;
};

/**
 * Formats currency values from ISO format (e.g., GBP 3,500,000.00) to display format (e.g., £3.5m)
 * @param value The currency value to format
 * @returns Formatted currency string
 */
export const formatCurrencyValue = (value?: string | null, currency?: string): string => {
  const amount = Number(value);
  if (isNaN(amount)) return '';

  const symbol = getCurrencySymbol(currency || '');

  // Format with appropriate suffix (k, m, b, t)
  if (amount >= 1e12) {
    return `${symbol}${(amount / 1e12).toFixed(1).replace(/\.0$/, '')}t`;
  } else if (amount >= 1e9) {
    return `${symbol}${(amount / 1e9).toFixed(1).replace(/\.0$/, '')}b`;
  } else if (amount >= 1e6) {
    return `${symbol}${(amount / 1e6).toFixed(1).replace(/\.0$/, '')}m`;
  } else if (amount >= 1e3) {
    return `${symbol}${(amount / 1e3).toFixed(1).replace(/\.0$/, '')}k`;
  } else {
    return `${symbol}${amount}`;
  }
};

/**
 * Formats a currency value for table cell display
 * @param params The cell params from ag-grid
 * @returns Formatted currency string
 */
export const formatCurrencyValueForCell = (params: any): string => {
  if (!params.value) return '';
  return formatCurrencyValue(params.value, params.data.currency);
};

/**
 * Checks if financial data has any meaningful values
 * @param financialsData Object containing financial data fields
 * @returns boolean indicating if there's any meaningful financial data
 */
export const hasValidFinancialData = (financialsData: {
  revenue?: string | null;
  profit?: string | null;
  revenueEst?: string | null;
}): boolean => {
  return (
    isMeaningfulValue(financialsData.revenue) ||
    isMeaningfulValue(financialsData.profit) ||
    isMeaningfulValue(financialsData.revenueEst)
  );
};
