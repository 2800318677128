/**
 * Formats a camelCase field name to Title Case with spaces
 */
export const formatFieldName = (fieldName: string): string => {
  return fieldName
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
    .trim();
};

/**
 * Checks if a string is a URL
 */
export const isUrl = (text: string): boolean => {
  try {
    // Try to create a URL - if it succeeds and has http/https protocol, it's likely a URL
    const url = new URL(text);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    // If URL creation fails, check for common URL patterns
    return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(text);
  }
};

/**
 * Renders a value appropriately based on its type
 */
export const renderValue = (value: any): React.ReactNode => {
  if (value === null || value === undefined) return 'N/A';

  // Format boolean values
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  // Handle URLs in strings
  if (typeof value === 'string') {
    if (isUrl(value)) {
      // Ensure URL has protocol
      const url = value.startsWith('http') ? value : `https://${value}`;
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="hover:underline">
          {value}
        </a>
      );
    }
    return value;
  }

  // Format number values
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toString();
  }

  // Handle objects and arrays
  if (typeof value === 'object') {
    try {
      return JSON.stringify(value);
    } catch (e) {
      return String(value);
    }
  }

  return String(value);
};

/**
 * Sorts fields alphabetically for display
 */
export const sortFields = (customFields: Record<string, any>): [string, any][] => {
  return Object.entries(customFields).sort((a, b) =>
    formatFieldName(a[0]).localeCompare(formatFieldName(b[0])),
  );
};

/**
 * Test data for customFields
 */
export const testCustomFields = {
  // Business Overview
  industryCategory: 'Food Processing',
  marketShare: '12%',
  isPubliclyTraded: false,
  primaryProducts: 'Smoked Meats, Cured Meats, Specialty Foods',

  // Financial Information
  annualRevenue: '$5.2M',
  lastValuation: '$25M',
  averageOrderValue: '$42,000',

  // Operations
  productionFacilities: '2',
  distributionChannels: 'Retail, Food Service, Direct-to-Consumer',
  certifications: 'USDA Organic, HACCP Certified, Non-GMO Project Verified',
  exportMarkets: 'Canada, Mexico, Japan',

  // Market Position
  primaryCompetitors: "Boar's Head, Smithfield Foods, Hormel Foods",
  customerRetentionRate: '92%',

  // Company Statistics
  foundingTeamSize: 4,
  patentCount: 8,

  object: {
    key: 'value',
  },

  companyWebsite: 'https://example.com',
  companyBlog: 'blog.example.org',
  linkedInProfile: 'linkedin.com/company/example',
  documentationLink: 'https://docs.example.com/api/v1',
  textWithUrl: 'Check our documentation at docs.example.com for more details',
};
