import React, { SyntheticEvent } from 'react';

import { MuiChip } from './indicator-chip.styles';
import { IIndicatorChipProps } from './indicator-chip.types';

function IndicatorChip({ label, isDimmed, className, sx }: IIndicatorChipProps) {
  return (
    <MuiChip
      label={label}
      onMouseDown={(event: SyntheticEvent) => event.stopPropagation()}
      isDimmed={isDimmed}
      className={className}
      sx={sx}
    />
  );
}

export default IndicatorChip;
