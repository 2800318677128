import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import BaseInput from '../../../../shared/components/input/base-input/base-input.component';
import BaseTextarea from '../../../../shared/components/textarea/base-textarea/base-textarea.component';

export const SourceLinkInput = styled(BaseInput)(({ theme }) => ({
  maxWidth: '50%',
  marginLeft: '1.25rem',
  '& .MuiInputBase-root .MuiInputBase-input': {
    paddingLeft: '0.75rem',
    border: `1px solid ${theme.palette.neutrals.gray.low}`,
  },
}));

export const NotesInput = styled(BaseTextarea)(() => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    maxWidth: 'calc(100% - 0.625rem)',
    paddingRight: '0.625rem',
  },

  '& .MuiInputBase-root .MuiInputBase-input': {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
}));

export const FileUploadContainer = styled(Stack)<StackProps>(() => ({
  marginLeft: '0.625rem',
  width: '50%',
  minWidth: 'calc(50% - 0.625rem)',
}));

export const FormRow = styled(Stack)<StackProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '& label, & input': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));
