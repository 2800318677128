const SILENT_SLACK_NOTIFICATIONS = import.meta.env['VITE_SILENT_SLACK_NOTIFICATIONS'];

export const SLACK_IDS = SILENT_SLACK_NOTIFICATIONS
  ? {}
  : {
      ben: 'U07E424D58T',
      max: 'U04R5NUQL07',
      adam: 'U083YKBCKP1',
      benHolden: 'U07K4J94HMJ',
      jaime: 'U07Q1QH8X7X',
      alex: 'U07KN5S8QJY',
    };
