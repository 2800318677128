import React, { Fragment } from 'react';

import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { IRightSectionMain } from './right-section-main.types';

function RightSectionMain({
  isListEntityChosen,
  isDataLoading,
  content,
  noContent,
}: IRightSectionMain) {
  return isListEntityChosen ? (
    <FeedbackUiDecorator
      statusConfig={{
        isLoading: isDataLoading,
        size: 40,
        className: 'right-section-main-loader',
      }}
    >
      {content}
    </FeedbackUiDecorator>
  ) : (
    <>{noContent}</>
  );
}

export default RightSectionMain;
