import React from 'react';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { downloadDataPerClient } from '../../../entities/company-target-group/store/company-target-group.slice';
import Link from '../../../shared/components/link/base-link/base-link.component';

function DownloadClientData({ clientId }: { clientId: string }) {
  const dispatch = useAppDispatch();

  function downloadFileInCsvFormat() {
    dispatch(downloadDataPerClient({ clientId }));
  }

  return <Link onClick={downloadFileInCsvFormat} title="EXPORT" />;
}

export default DownloadClientData;
