import { styled } from '@mui/material';

import { PageContentContainer } from '../../../app/layouts/page-content/page-content-header/page-content-header.styles';

export const PageContentTableContainer = styled(PageContentContainer)(({ theme }) => ({
  table: {
    tbody: {
      tr: {
        transition: 'background 0.2s ease',
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.neutrals.gray.lower },
      },
    },
  },
}));
