import {
  Component,
  createContext,
  createElement,
  ErrorInfo,
  isValidElement,
  ReactElement,
} from 'react';

import {
  ErrorBoundaryProps,
  IErrorBoundaryContextType,
  IFallbackProps,
  IState,
  Props,
} from './error-boundary.types';
import { hasArrayChanged } from './error-boundary.utils';

const ErrorBoundaryContext = createContext<IErrorBoundaryContextType | null>(null);

const initialState: IState = {
  didCatch: false,
  error: null,
};

export class ErrorBoundaryDecorator extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error) {
    return { didCatch: true, error };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: IState) {
    const { didCatch } = this.state;
    const { resetKeys: newResetKeys } = this.props;
    const { resetKeys: oldResetKeys } = prevProps;
    const isArrayChanged = hasArrayChanged(oldResetKeys, newResetKeys);
    const isErrorInPrevState = prevState.error !== null;

    if (didCatch && isErrorInPrevState && isArrayChanged) {
      const { onReset } = this.props;
      onReset?.({ next: newResetKeys, prev: oldResetKeys, reason: 'keys' });
      this.setState(initialState);
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;
    onError?.(error, info as any);
  }

  resetErrorBoundary = (...args: any[]) => {
    const { error } = this.state;
    if (error !== null) {
      const { onReset } = this.props;
      onReset?.({ args, reason: 'imperative-api' });
      this.setState(initialState);
    }
  };

  render() {
    const { children, fallbackRender, FallbackComponent, fallback } = this.props;
    const { didCatch, error } = this.state;
    const { resetErrorBoundary } = this;

    let childToRender = children;

    if (didCatch) {
      const props: IFallbackProps = { error, resetErrorBoundary };

      if (isValidElement(fallback)) childToRender = fallback;
      else if (typeof fallbackRender === 'function') childToRender = fallbackRender(props);
      else if (FallbackComponent) childToRender = createElement(FallbackComponent, props);
      else
        throw new Error(
          'error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
        );
    }

    return createElement(
      ErrorBoundaryContext.Provider,
      {
        value: {
          didCatch,
          error,
          resetErrorBoundary,
        },
      },
      childToRender,
    ) as ReactElement;
  }
}
