export const Routes = {
  MAIN: '/',
  LOGIN: '/authentication/login',
  LOGOUT: '/authentication/logout',
  CLIENTS: '/dashboard/clients',
  ANALYTICS: '/dashboard/analytics',
  PIPELINE: '/dashboard/pipeline',
  TARGET_DEFINITION: '/dashboard/pipeline/client-requirements',
  MODEL_CALIBRATION: '/dashboard/pipeline/model-calibration',
  SELECTION_ENGINEERING: '/dashboard/pipeline/prompt-engineering',
  MARKET_MAPPING: '/dashboard/pipeline/market-mapping',
  COMPANY_RESEARCH: '/dashboard/pipeline/company-research',
  TARGET_RESEARCH: '/dashboard/pipeline/target-research',
  PEOPLE_RESEARCH: '/dashboard/pipeline/people-research',
  REVIEW: '/dashboard/pipeline/review',
  INTELLIGENCE: '/dashboard/pipeline/intelligence',
  REPORTING: '/dashboard/reporting',
  ANALYTICS_AND_REPORTING: '/dashboard/reporting/analytics-and-reporting',
  COMPANY_TRACKING: '/dashboard/reporting/company-tracking',
  MARKET_TRACKING: '/dashboard/reporting/market-tracking',
  SELECTION: '/dashboard/kpis/selection',
  WEEKLY_KPIS: '/dashboard/kpis/weekly',
  VISIBILITY_KPIS: '/dashboard/kpis/visibility',
};

export const NavigationPaths = {
  LOGIN: '/authentication/login',
  LOGOUT: '/authentication/logout',
  SUCCESS_AUTH: 'authentication/success',
  CLIENTS: '/dashboard/clients',
  ANALYTICS: (clientId: string | undefined) => `/dashboard/analytics/${clientId}`,
  PIPELINES_LIST: '/dashboard/pipeline',
  PIPELINE: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}`,
  TARGET_REQUIREMENTS: (clientId: string | undefined) =>
    `/dashboard/pipeline/${clientId}/client-requirements`,
  MODEL_CALIBRATION: (clientId: string | undefined) =>
    `/dashboard/pipeline/${clientId}/model-calibration`,
  SELECTION_ENGINEERING: (clientId: string | undefined) =>
    `/dashboard/pipeline/${clientId}/prompt-engineering`,
  MARKET_MAPPING: (clientId: string | undefined) =>
    `/dashboard/pipeline/${clientId}/market-mapping`,
  COMPANY_RESEARCH: (clientId: string | undefined) =>
    `/dashboard/pipeline/${clientId}/company-research`,
  PEOPLE_RESEARCH: (clientId: string | undefined) =>
    `/dashboard/pipeline/${clientId}/people-research`,
  REVIEW: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/review`,
  INTELLIGENCE: (clientId: string | undefined) => `/dashboard/pipeline/${clientId}/intelligence`,
  ANALYTICS_AND_REPORTING: (clientId: string | undefined) =>
    `/dashboard/reporting/${clientId}/analytics-and-reporting`,
  MARKET_TRACKING: (clientId: string | undefined) =>
    `/dashboard/reporting/${clientId}/market-tracking`,
  COMPANY_TRACKING: (clientId: string | undefined) =>
    `/dashboard/reporting/${clientId}/company-tracking`,
  SELECTION: (clientId: string | undefined) => `/dashboard/kpis/${clientId}/selection`,
  WEEKLY_KPIS: (clientId: string | undefined) => `/dashboard/kpis/${clientId}/weekly`,
  VISIBILITY_KPIS: (clientId: string | undefined) => `/dashboard/kpis/${clientId}/visibility`,
};
