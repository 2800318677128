import { Box, Typography } from '@mui/material';

import { ICompanyTargetGroupLocation } from '../../../../../../../entities/company/domain/company.base.types';
import {
  IParentCompany,
  IPEBacked,
  IVCBacked,
} from '../../../../../../../entities/company-target-group/domain/company-target-group.types';
import { formatDate } from '../../../../../../intelligence-content/components/intelligence-content-detail/helpers/intelligence-content-detail.helpers';
import { LabelText } from '../../company-details-panel.styles';
import {
  CompanyDetailsBoxContainer,
  LeftColumn,
  LeftColumnRow,
  RightColumn,
  RightColumnRow,
} from './company-details-box.styles';

interface CompanyDetailsBoxProps {
  description?: string;
  location?: ICompanyTargetGroupLocation;
  foundedYear: string;
  headcount: number;
  sixMonthHeadcountGrowth: number;
  PEbackers?: IPEBacked[];
  VCbackers?: IVCBacked[];
  parentCompany?: IParentCompany;
  ownership?: string;
}

function GrowthComponent({ sixMonthHeadcountGrowth }: { sixMonthHeadcountGrowth: number }) {
  const parseGrowthValue = () => {
    if (!sixMonthHeadcountGrowth) return 0;
    return sixMonthHeadcountGrowth;
  };

  const growthNumber = parseGrowthValue();

  let color = 'neutrals.gray.medium';
  if (growthNumber > 0) {
    color = '#15803d';
  } else if (growthNumber < 0) {
    color = '#b91c1c';
  }

  const getFilter = (themeColor: string) => {
    if (themeColor === '#15803d')
      return 'invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(80%) contrast(90%)';
    if (themeColor === '#b91c1c')
      return 'invert(39%) sepia(95%) saturate(7471%) hue-rotate(353deg) brightness(80%) contrast(90%)';
    return 'invert(55%) sepia(12%) saturate(281%) hue-rotate(182deg) brightness(94%) contrast(86%)';
  };

  return (
    <Typography
      fontFamily="DM Mono"
      fontSize={12}
      color={color}
      width="max-content"
      flexShrink={0}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {growthNumber !== 0 && (
        <Box
          component="img"
          src="/icons/triangle.svg"
          alt="growth"
          sx={{
            filter: getFilter(color),
            transform: growthNumber > 0 ? 'rotate(0deg)' : 'rotate(180deg)',
            marginRight: '2px',
          }}
        />
      )}
      {growthNumber.toFixed(2).replace('-', '')}%
    </Typography>
  );
}

function GrowthLabelComponent() {
  return (
    <LabelText color="neutrals.gray.medium">
      <Box
        component="img"
        src="/icons/arrow-down-right.svg"
        alt="growth"
        sx={{
          filter:
            'invert(55%) sepia(12%) saturate(281%) hue-rotate(182deg) brightness(94%) contrast(86%)', // gray
          marginRight: '4px',
        }}
      />
      6 MO HEADCOUNT GROWTH
    </LabelText>
  );
}

function PEBackersComponent({
  PEbackers,
  parentCompany,
}: {
  PEbackers?: IPEBacked[];
  parentCompany?: IParentCompany;
}) {
  const backers = PEbackers?.map(backer =>
    backer.transactions.map(transaction => (
      <Typography color="neutrals.gray.medium" sx={{ display: 'flex', gap: '8px' }}>
        {(PEbackers.length > 1 ||
          backer.transactions.length > 1 ||
          (PEbackers?.length && parentCompany)) &&
          '• '}
        {backer.firm} {transaction.date && `- ${formatDate(transaction.date)}`}
      </Typography>
    )),
  );
  return <Box sx={{ marginTop: '-.25rem' }}>{!!backers?.length && backers}</Box>;
}

function VCBackersComponent({
  VCbackers,
  parentCompany,
}: {
  VCbackers?: IVCBacked[];
  parentCompany?: IParentCompany;
}) {
  const backers = VCbackers?.map(backer =>
    backer.transactions.map(transaction => (
      <Typography color="neutrals.gray.medium" sx={{ display: 'flex', gap: '8px' }}>
        {(VCbackers.length > 1 ||
          backer.transactions.length > 1 ||
          (VCbackers?.length && parentCompany)) &&
          '• '}
        {backer.firm} {transaction.date && `- ${formatDate(transaction.date)}`}
      </Typography>
    )),
  );
  return <Box sx={{ marginTop: '-.25rem' }}>{!!backers?.length && backers}</Box>;
}

function ParentCompanyComponent({
  PEbackers,
  parentCompany,
}: {
  PEbackers?: IPEBacked[];
  parentCompany?: IParentCompany;
}) {
  return (
    <Typography
      color="neutrals.gray.medium"
      sx={{ marginTop: '-.25rem', display: 'flex', gap: '8px' }}
    >
      {PEbackers?.length && parentCompany && '• '}
      {parentCompany?.name}{' '}
      {parentCompany?.transaction?.date && `- ${formatDate(parentCompany?.transaction.date)}`}
    </Typography>
  );
}

function CompanyDetailsBox({
  description,
  location,
  foundedYear,
  headcount,
  sixMonthHeadcountGrowth,
  PEbackers,
  VCbackers,
  parentCompany,
  ownership,
}: CompanyDetailsBoxProps) {
  const leftColumns = [
    { label: 'DESCRIPTION', value: description },
    { label: 'YEAR FOUNDED', value: foundedYear },
    {
      label: 'LOCATION',
      value: [location?.city, location?.state, location?.country_iso || location?.country]
        .filter(Boolean)
        .join(', '),
    },
  ];

  const rightColumns = [
    { label: 'HEADCOUNT', value: headcount },
    {
      label: '6 MO MONTH GROWTH',
      value: sixMonthHeadcountGrowth,
      customLabelComponent: <GrowthLabelComponent />,
      customValueComponent: <GrowthComponent sixMonthHeadcountGrowth={sixMonthHeadcountGrowth} />,
    },
    {
      label: 'OWNERSHIP',
      value: ownership,
    },
    {
      label: 'PE BACKERS',
      value: PEbackers,
      customValueComponent: null,
      customLabelComponent: (
        <PEBackersComponent PEbackers={PEbackers} parentCompany={parentCompany} />
      ),
    },
    {
      label: 'VC BACKERS',
      value: VCbackers,
      customValueComponent: null,
      customLabelComponent: (
        <VCBackersComponent VCbackers={VCbackers} parentCompany={parentCompany} />
      ),
    },
    {
      label: 'PARENT COMPANY',
      value: parentCompany,
      customValueComponent: null,
      customLabelComponent: (
        <ParentCompanyComponent PEbackers={PEbackers} parentCompany={parentCompany} />
      ),
    },
  ];

  const parseValue = (value: string | number | null | undefined) =>
    value === '' || value === null || value === undefined ? 'N/A' : value;

  return (
    <CompanyDetailsBoxContainer>
      <LeftColumn>
        {leftColumns.map(column => (
          <LeftColumnRow>
            <LabelText>{column.label}</LabelText>
            <Typography
              color="neutrals.gray.medium"
              sx={{
                width: '100%',
                lineBreak: 'auto',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
              }}
            >
              {parseValue(column.value)}
            </Typography>
          </LeftColumnRow>
        ))}
      </LeftColumn>

      <RightColumn>
        {rightColumns.map(column => {
          if (column.label === 'PE BACKERS' && !column.value) return null;

          return (
            <RightColumnRow>
              {column.customLabelComponent !== undefined ? (
                column.customLabelComponent
              ) : (
                <LabelText>{column.label}</LabelText>
              )}
              {column.customValueComponent !== undefined ? (
                column.customValueComponent
              ) : (
                <Typography
                  fontFamily="DM Mono"
                  fontSize={12}
                  color="neutrals.gray.medium"
                  flexShrink={0}
                >
                  {parseValue(column.value)}
                </Typography>
              )}
            </RightColumnRow>
          );
        })}
      </RightColumn>
    </CompanyDetailsBoxContainer>
  );
}

export default CompanyDetailsBox;
