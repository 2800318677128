import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UserRole } from '../../entities/user/domain/user.types';
import { userSelector } from '../../entities/user/store/user.selectors';
import SingleSectionScreenLayout from '../layouts/single-sections-screen-with-message/single-sections-screen-with-message.component';
import { useAppSelector } from '../store/utils/redux.hooks';

export enum RejectionFeedbackType {
  REDIRECT = 'redirect',
  REJECTION_SCREEN = 'rejectionScreen',
}

export interface IRequireRoleRoute {
  allowedRoles?: UserRole[];
  children: ReactNode;
  rejectionFeedbackType: RejectionFeedbackType;
  redirectTo?: string;
  rejectionScreenMessage?: string;
}

function RedirectOnRejection(location: any, redirectTo?: string) {
  return <Navigate to={redirectTo ?? '/authentication/login'} state={{ from: location }} replace />;
}

function ShowRejectionScreen(rejectionScreenMessage?: string) {
  return (
    <SingleSectionScreenLayout
      message={rejectionScreenMessage ?? 'You are not allowed to access this screen'}
    />
  );
}

function RequireRoleRoute({
  allowedRoles,
  children,
  rejectionFeedbackType,
  redirectTo,
  rejectionScreenMessage,
}: IRequireRoleRoute) {
  const { roles } = useAppSelector(userSelector);
  const { isAuthenticated } = useAuth0();

  const location = useLocation();

  return Boolean(
    (allowedRoles || Object.values(UserRole)).find(allowedRole => roles.includes(allowedRole)),
  ) && isAuthenticated ? (
    <>{children}</>
  ) : rejectionFeedbackType === RejectionFeedbackType.REDIRECT ? (
    RedirectOnRejection(location, redirectTo)
  ) : (
    ShowRejectionScreen(rejectionScreenMessage)
  );
}

export default RequireRoleRoute;
