import React, { Fragment } from 'react';

import { FeedbackUiType } from '../feedback-ui.types';
import { statusFeedbackUiKit } from './status-feedback-ui-kit.strategy';
import { IStatusFeedbackUiKitProps } from './status-feedback-ui-kit.types';

function StatusFeedbackUiKit({ feedbackUiType, uiProps }: IStatusFeedbackUiKitProps) {
  return <>{statusFeedbackUiKit[feedbackUiType || FeedbackUiType.LOADER](uiProps)}</>;
}

export default StatusFeedbackUiKit;
