import { ICompanyForReview } from '../../../../entities/company-target-group/domain/company-target-group.types';
import {
  formatFieldName,
  renderValue,
} from './company-details-panel/components/custom-fields-section/utils';
import { formatCurrencyValue } from './company-details-panel/utils/financial-utils';

export const exportToCSV = (data: ICompanyForReview[]) => {
  const maxLeadsCount = data.reduce((max, company) => Math.max(max, company.leads?.length || 0), 0);

  const baseHeaders = [
    'Company Name',
    'Domain',
    'Company LinkedIn',
    'Short Description',
    'Revenue',
    'Revenue Year',
    'Revenue (Est.)',
    'Profit',
    'Profit Year',
    'Founded',
    'Ownership',
    'PE Backer',
    'VC Backer',
    'Parent Company',
    'Headcount',
    '6 Mo Headcount Growth',
    'Company City',
    'Company State',
    'Company Country',
  ];

  const contactHeaders: string[] = [];
  for (let i = 1; i <= maxLeadsCount; i++) {
    contactHeaders.push(
      `Contact Title ${i}`,
      `Contact Name ${i}`,
      `Contact Email ${i} `,
      `Contact LinkedIn ${i}`,
    );
  }

  // Find all unique custom field keys across all companies
  const customFieldsSet = new Set<string>();
  data.forEach(company => {
    if (company.customFields) {
      Object.keys(company.customFields).forEach(key => customFieldsSet.add(key));
    }
  });

  // Convert custom field keys to formatted headers
  const customFieldHeaders = Array.from(customFieldsSet).map(key => formatFieldName(key));

  const headers = [...baseHeaders, ...contactHeaders, ...customFieldHeaders];

  const formatField = (field: any) => {
    if (field === undefined || field === null) return '""';

    // Handle arrays and objects
    if (typeof field === 'object') {
      try {
        return `"${JSON.stringify(field).replace(/"/g, '""')}"`;
      } catch {
        return `"${String(field).replace(/"/g, '""')}"`;
      }
    }

    // Handle other types
    return `"${String(field).replace(/"/g, '""')}"`;
  };

  const csvContent = [
    headers.join(','),
    ...data.map(row => {
      const baseRowData = [
        formatField(row.companyName),
        formatField(row.website),
        formatField(row.linkedIn),
        formatField(row.businessType),
        formatField(formatCurrencyValue(row.revenue, row.currency)),
        formatField(row.revenueYear),
        formatField(formatCurrencyValue(row.revenueEst, row.currency)),
        formatField(formatCurrencyValue(row.profit, row.currency)),
        formatField(row.profitYear),
        formatField(row.foundedYear),
        formatField(row.ownership),
        formatField(row.PEbackers?.[0]?.firm),
        formatField(row.VCbackers?.[0]?.firm),
        formatField(row.parentCompany?.name),
        formatField(row.headcount),
        formatField(row.sixMonthHeadcountGrowth ? `${row.sixMonthHeadcountGrowth}%` : ''),
        formatField(row.location?.city),
        formatField(row.location?.state),
        formatField(row.location?.country),
      ];

      const contactData: string[] = [];
      for (let i = 0; i < maxLeadsCount; i++) {
        const lead = row.leads?.[i] || {};
        contactData.push(
          formatField(lead.jobTitle),
          formatField(`${lead.firstName || ''} ${lead.lastName || ''}`.trim()),
          formatField(lead.email),
          formatField(lead.linkedIn),
        );
      }

      // Process custom fields
      const customFieldData: string[] = [];
      const customFieldKeys = Array.from(customFieldsSet);
      customFieldKeys.forEach(key => {
        const value = row.customFields?.[key];
        // Use renderValue to get appropriate string value, but extract the text content
        // This handles URLs, booleans, etc. appropriately
        let renderedValue = renderValue(value);

        // If renderValue returns a React element (e.g. for URLs), extract just the text
        if (renderedValue && typeof renderedValue === 'object' && 'props' in renderedValue) {
          renderedValue = renderedValue.props.children;
        }

        customFieldData.push(formatField(renderedValue));
      });

      return [...baseRowData, ...contactData, ...customFieldData].join(',');
    }),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'companies_export.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
