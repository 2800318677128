import { ComponentType } from 'react';

import { ITargetDefinitionFormInputProps } from './target-definition-form-input/target-definition-form-input.types';

export enum FormFieldValueType {
  SOLO = 'solo',
  MULTIPLE = 'multiple',
}

export interface ITargetDefinitionFormFieldConfig
  extends Omit<ITargetDefinitionFormInputProps<any>, 'handleChange' | 'values' | 'errors'> {
  inputValueType: FormFieldValueType;
  component: ComponentType<any>;
}

export type TargetDefinitionFieldsEntries = [string, ITargetDefinitionFormFieldConfig];

export enum TypeformFieldType {
  NUMBER = 'number',
}

export type PromptType = 'save' | 'discard';

export enum TargetGroupFormField {
  TARGET_GROUP_NAME = 'Target Group Name',
  TARGET_GROUP_DESCRIPTION = 'Target Group Description',
  EXCLUDE_BUSINESS_TYPES = 'Exclude Business Types',
  LOCATIONS = 'Headquarters Location',
  OWNERSHIP_STRUCTURE = 'Ownership Structure',
  MIN_HEADCOUNT = 'Min Headcount',
  MAX_HEADCOUNT = 'Max Headcount',
  MIN_REVENUE = 'Min Revenue',
  MAX_REVENUE = 'Max Revenue',
}
