import { AnimatePresence } from 'framer-motion';

import { useToast } from '../../hooks/use-toast.hook';
import { Toast } from './components/toast/toast.component';
import { ToastContainer } from './toast-notifications.styles';

export function ToastNotifications() {
  const { toasts, toast } = useToast();

  return (
    <ToastContainer className="no-scroll">
      <AnimatePresence mode="popLayout">
        {toasts.map(t => (
          <Toast
            key={t.id}
            title={t.title}
            message={t.message}
            type={t.type}
            onClose={() => toast.remove(t.id)}
            actions={t.actions}
          />
        ))}
      </AnimatePresence>
    </ToastContainer>
  );
}
