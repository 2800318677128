import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import axios from '../../../app/services/axios.service';
import { BaseServerResponse } from '../../../shared/domain/types/base.types';
import { AuthenticationEndpoint } from '../api/authentication-endpoint.constants';
import {
  IAuthenticationState,
  ILoginBody,
  IUserAuthedWithToken,
  IUserAuthedWithTokenResponse,
} from '../domain/types/authentication.types';

export const authenticationState: IAuthenticationState = {
  accessToken: '',
  isAuthenticated: false,
  login: { isLoading: false, data: {} as IUserAuthedWithToken, isError: false, error: {} },
  logout: { isLoading: false, data: {} as BaseServerResponse, isError: false, error: {} },
};

export const login = createAsyncThunk(
  'authentication/login',
  async (body: ILoginBody, { rejectWithValue }) => {
    try {
      const { data: userAuthedWithToken } = await axios.post(AuthenticationEndpoint.LOGIN, body);
      return userAuthedWithToken as IUserAuthedWithTokenResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const logout = createAsyncThunk(
  'authentication/logout',
  async (body: void, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(AuthenticationEndpoint.LOGOUT);
      return data as BaseServerResponse;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: authenticationState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<string>) => {
      const accessToken = action.payload;
      state.accessToken = accessToken;
      state.isAuthenticated = true;
    },
    resetAuthentication: state => {
      state.accessToken = authenticationState.accessToken;
      state.isAuthenticated = authenticationState.isAuthenticated;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.login.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const userAuthedWithToken = action.payload.item;
      const { accessToken, ...userAuthed } = userAuthedWithToken;
      state.login.data = userAuthed;
      state.login.isLoading = false;
      state.isAuthenticated = true;
      state.accessToken = accessToken;
    });
    builder.addCase(login.rejected, (state, action: any) => {
      const error = action.payload;
      state.login.error = error;
      state.login.data = {} as IUserAuthedWithToken;
      state.login.isError = true;
      state.login.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = '';
    });

    builder.addCase(logout.pending, state => {
      state.logout.isLoading = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      const response = action.payload;
      state.logout.data = response;
      state.logout.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = '';
      state.login.data = {} as IUserAuthedWithToken;
    });
    builder.addCase(logout.rejected, (state, action: any) => {
      const error = action.payload;
      state.logout.error = error;
      state.logout.data = {};
      state.logout.isError = true;
      state.logout.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = '';
      state.login.data = {} as IUserAuthedWithToken;
    });
  },
});

export const { setAuthentication, resetAuthentication } = authenticationSlice.actions;

export const authenticationReducer = persistReducer(
  {
    key: 'authentication',
    storage,
  },
  authenticationSlice.reducer,
);
