import { IGetAllClientsItemDto } from '../../../entities/user/domain/user.types';
import { capitalizeFirstLetter } from '../../../shared/helpers/base.helpers';

function defineClientName(client: IGetAllClientsItemDto): string {
  const companyName = capitalizeFirstLetter(client.companyName || '');
  const userName = `${capitalizeFirstLetter(client.user.name)} ${capitalizeFirstLetter(client.user.surname)}`;
  return companyName || userName;
}

export function formSelectCardProps(clients: IGetAllClientsItemDto[]) {
  const selectCardProps = clients.map(client => {
    const name = defineClientName(client);
    return { clientId: client.id, clientName: name, key: client.id };
  });
  return selectCardProps;
}
