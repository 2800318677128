import { CustomCellRendererProps } from 'ag-grid-react';

import { ColumnCellValue } from '../../../../modules/market-mapping-content/components/market-mapping-content.styles';
import { isUrlSourceType } from '../../../../modules/market-mapping-content/helpers/source-type.helpers';
import Link from '../../link/base-link/base-link.component';

export function LinkCellRenderer(props: { value: string }) {
  const { value } = props;
  return <Link url={value} title={value} target="_blank" sx={{ textDecoration: 'underline' }} />;
}

export function ShortUrlCellRenderer(params: CustomCellRendererProps) {
  const { data } = params;
  if (!isUrlSourceType(data.sourceType)) {
    return <ColumnCellValue sx={{ fontSize: '14px' }}>{data.url}</ColumnCellValue>;
  }

  // this is temporary until we migrate old urls.
  const isShortUrl = !data.url.startsWith('http') && data.url.length === 8;
  const url = isShortUrl ? `${import.meta.env['VITE_BACKEND_ENDPOINT']}/su/${data.url}` : data.url;
  const title = isShortUrl ? `https://revi.ai/${data.url}` : data.url;

  return <Link url={url} title={title} target="_blank" />;
}
