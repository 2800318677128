import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const menuStyles = {
  sx: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff',

      width: '186px',
      maxHeight: '40vh',

      marginTop: '0.5rem',

      borderRadius: '0.375rem',
      border: '0px solid',
      borderColor: '#F4F4F5',
      background: '#fff',
      boxShadow: '0px 0px 1px 0px rgba(9, 9, 11, 0.10), 0px 7px 22px 0px rgba(9, 9, 11, 0.10)',
      backdropFilter: 'blur(40px)',

      '> ul': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        gap: '2px',
      },

      '> ul > li': {
        whiteSpace: 'pre-wrap',
        display: 'block',
        textAlign: 'left',
        width: '100%',
        borderRadius: '5px',
      },
    },
  },
};

export const Label = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  width: '100%',
  color: theme.palette.neutrals.gray.highest,
  fontSize: '0.875rem',
  fontFamily: '"Darker Grotesque",sans-serif',
  fontWeight: 400,
  lineHeight: '130%',
  position: 'relative',
  marginBottom: '0.5rem',
  transform: 'none',

  '&.Mui-focused': {
    color: theme.palette.neutrals.gray.highest,
  },

  '&.Mui-error': {
    color: theme.palette.neutrals.gray.highest,
  },
}));

export const MuiSelect = styled(Select, {
  shouldForwardProp: props => props !== 'isMenuOpen',
})<SelectProps & { isMenuOpen: boolean }>(({ theme }) => ({
  width: '100%',
  color: theme.palette.neutrals.gray.highest,
  background: theme.palette.neutrals.gray.lowest,
  fontSize: '1rem',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontWeight: 500,
  lineHeight: '130%',
  border: `1px solid ${theme.palette.background.lightGrey}`,
  borderRadius: '0.25rem',
  height: '36px',

  '&.custom-select': {
    backgroundColor: theme.palette.neutrals.gray.lowest,
    '> .MuiSelect-select': { padding: '15px 32px 15px 14px ' },
  },

  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },

  '& .MuiSelect-icon': {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: theme.palette.background.mediumGrey,
    right: '15px',
  },
}));

export const MuiSelectItem = styled(MenuItem, {
  shouldForwardProp: props => props !== 'isCurrentlySelectedItem',
})<MenuItemProps & { isCurrentlySelectedItem?: boolean }>(({ isCurrentlySelectedItem, theme }) => ({
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  padding: '0.125rem 0.625rem 0.125rem 1.5rem',
  alignItems: 'center',
  gap: '0.125rem',

  backgroundColor: theme.palette.neutrals.gray.lowest,
  color: isCurrentlySelectedItem
    ? theme.palette.neutrals.gray.highest
    : theme.palette.neutrals.gray.high,
  justifyContent: 'center',
  transition: theme.transitions.create(['color', 'background-color']),
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '130%',

  '&.Mui-selected': {
    backgroundColor: theme.palette.neutrals.gray.low,
    '&:hover': {
      backgroundColor: theme.palette.neutrals.gray.low,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.neutrals.gray.low,
    },
  },

  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.neutrals.gray.low,
  },
  '&:hover': {
    backgroundColor: theme.palette.neutrals.gray.low,
    opacity: 0.8,
  },

  '& .custom-select-check-icon': {
    position: 'absolute',
    left: '0.25rem',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '1rem',
    height: '1rem',
  },
}));
