import { LogoutOptions } from '@auth0/auth0-react';
import { NavigateFunction } from 'react-router-dom';

import { AppDispatch } from '@/app/store/store.types';

import { resetUser } from '../../../entities/user/store/user.slice';
import { NavigationPaths } from '../../../shared/domain/constants/routing.constants';
import { logout } from '../store/authentication.slice';

type Auth0LogoutFunction = (options?: LogoutOptions) => void;

/**
 * Performs a complete logout from both the application and Auth0
 * This function ensures both server-side and client-side state are cleaned up
 * before redirecting the user to the login page
 */
export const performFullLogout = async (
  dispatch: AppDispatch,
  auth0Logout: Auth0LogoutFunction,
  navigate?: NavigateFunction,
) => {
  try {
    // First clear server-side session
    await dispatch(logout()).unwrap();

    // Then reset all application state
    dispatch(resetUser());

    // Finally perform Auth0 logout with redirect
    await auth0Logout({
      logoutParams: {
        returnTo: window.location.origin + NavigationPaths.LOGIN,
      },
    });

    // If navigate is provided, use it (but Auth0 logout should handle redirection)
    if (navigate) {
      navigate(NavigationPaths.LOGIN);
    }
  } catch (error) {
    console.error('Logout error:', error);
    // Still attempt Auth0 logout even if server logout fails
    await auth0Logout({
      logoutParams: {
        returnTo: window.location.origin + NavigationPaths.LOGIN,
      },
    });
  }
};

/**
 * Navigate to the logout route
 * This is a simpler way to trigger a logout without needing to manually perform all logout steps
 */
export const navigateToLogout = (navigate: NavigateFunction) => {
  navigate(NavigationPaths.LOGOUT);
};
