import React from 'react';
import { Outlet } from 'react-router-dom';

import { DashboardLayoutBar, DashboardLayoutContainer } from './dashboard-layout.styles';
import { IDashboardLayoutProps } from './dashboard-layout.types';

function DashboardLayout({ navigationSection, children }: IDashboardLayoutProps) {
  return (
    <DashboardLayoutContainer className="dashboard-layout-container">
      <DashboardLayoutBar>{navigationSection}</DashboardLayoutBar>
      {children ? children : <Outlet />}
    </DashboardLayoutContainer>
  );
}

export default DashboardLayout;
