import { Stack } from '@mui/material';
import React from 'react';

import BaseMapper from '../../../shared/components/mapper/base-mapper/base-mapper.component';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ISelectEntityProps } from './select-entity.types';

function SelectEntity({
  className,
  entityData,
  isLoading,
  entityCard: EntityCard,
  footer,
  noDataMessage,
}: ISelectEntityProps) {
  return (
    <Stack sx={{ width: '100%' }} className={className}>
      <FeedbackUiDecorator
        statusConfig={{ isLoading: isLoading && entityData.length === 0, size: 30 }}
      >
        {entityData.length > 0 ? (
          <BaseMapper component={EntityCard} propsArray={entityData} />
        ) : (
          <p style={{ textAlign: 'center', fontWeight: 400 }}>{noDataMessage}</p>
        )}
      </FeedbackUiDecorator>
      {footer && <div className="select-entity-footer">{footer}</div>}
    </Stack>
  );
}

export default SelectEntity;
