import { styled } from '@mui/material/styles';

export const LinkWrapper = styled('a')(() => ({
  minHeight: '1.125rem',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '.5rem',
  transition: 'opacity 0.3s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
  '&:hover .link-right-chevron': {
    transform: 'translateX(2px)',
  },
}));

export const LinkText = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '110%',
  transition: 'transform 0.2s',
}));
