import React from 'react';

import {
  SectionContainer,
  SingleSectionScreenLayoutContainer,
} from './single-sections-screen.styles';
import { ISingleSectionScreenLayoutProps } from './single-sections-screen.types';

function SingleSectionScreenLayout({ children }: ISingleSectionScreenLayoutProps) {
  return (
    <SingleSectionScreenLayoutContainer>
      <SectionContainer>{children}</SectionContainer>
    </SingleSectionScreenLayoutContainer>
  );
}

export default SingleSectionScreenLayout;
