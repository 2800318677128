import PageContentHeader from '../../../../app/layouts/page-content/page-content-header/page-content-header.component';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { currentTargetGroupSelector } from '../../../../entities/target-group/store/target-group.selectors';
import { PipelineStageName } from '../../../navigation/components/pipeline-navigation/pipeline-navigation.types';

const SECTION_HEADER = PipelineStageName.REVIEW;

function ReviewHeader() {
  const { name: currentTargetGroupName } = useAppSelector(currentTargetGroupSelector);

  return (
    <PageContentHeader
      title={SECTION_HEADER}
      description={currentTargetGroupName === 'All target groups' ? '' : currentTargetGroupName}
    />
  );
}

export default ReviewHeader;
