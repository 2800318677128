import { CheckCircle, InfoIcon, XCircle } from 'lucide-react';

import { Badge } from '../../../../../components/ui/badge';
import { ScrollArea } from '../../../../../components/ui/scroll-area';
import HeaderBadge from '../components/header-badge';
import HeadersSection from '../components/headers-section';
import StatusMessage from '../components/status-message';

export interface CsvPreviewProps {
  finalHeaders: string[];
  expectedHeaders: string[];
  isDynamicContactField: (header: string) => boolean;
  headerCategories: {
    required: string[];
    missing: string[];
    extra: string[];
    contacts: string[];
    contactCount: number;
  };
  fileName: string;
  headerSamples?: Record<string, string>;
  onHeaderRename?: (oldHeader: string, newHeader: string) => void;
}

function CsvPreview({
  finalHeaders,
  expectedHeaders,
  headerCategories,
  fileName,
  headerSamples = {},
  onHeaderRename,
}: CsvPreviewProps) {
  const hasHeaderIssues = headerCategories.missing.length > 0;

  return (
    <div className="w-full py-2 space-y-4">
      {/* File name and header counts */}
      <div className="flex items-center justify-between">
        <p className="text-base text-muted-foreground">
          <span className="font-medium text-foreground">File:</span> {fileName}
        </p>
        <div className="flex gap-2">
          <Badge variant="outline" className="px-2 py-1 text-sm bg-transparent">
            {headerCategories.required.length}/{expectedHeaders.length} Required
          </Badge>
          {headerCategories.extra.length > 0 && (
            <Badge variant="outline" className="px-2 py-1 text-sm bg-transparent">
              {headerCategories.extra.length} Extra
            </Badge>
          )}
          {headerCategories.contacts.length > 0 && (
            <Badge variant="outline" className="px-2 py-1 text-sm bg-transparent">
              {headerCategories.contactCount} Contacts
            </Badge>
          )}
        </div>
      </div>

      {/* Status message */}
      {hasHeaderIssues ? (
        <StatusMessage
          type="error"
          title="Missing required headers"
          message="Your CSV is missing some required headers. Please rename one of the extra headers to match a required one, or go back and upload a corrected CSV file."
          icon={<XCircle className="w-5 h-5 text-red-500" />}
        />
      ) : (
        <StatusMessage
          type="success"
          title="All required headers are present"
          message="Your CSV file is ready to be uploaded."
          icon={<CheckCircle className="w-5 h-5 text-green-500" />}
        />
      )}

      {/* Main content with headers */}
      <div className="w-full border rounded-md">
        <div className="flex items-center justify-between p-3 border-b">
          <span className="text-base font-medium">Headers</span>

          <div className="flex gap-1.5">
            <Badge className="text-xs px-2 py-0.5 bg-slate-100 text-slate-700 border-slate-200 hover:bg-slate-100">
              Present
            </Badge>
            <Badge className="text-xs px-2 py-0.5 bg-red-50 text-red-700 border-red-100 hover:bg-red-50">
              Missing
            </Badge>
            <Badge className="text-xs px-2 py-0.5 bg-blue-50 text-blue-700 border-blue-100 hover:bg-blue-50">
              Extra
            </Badge>
          </div>
        </div>

        <ScrollArea className="h-[380px]">
          <div className="p-4 space-y-5">
            {/* Required headers section */}
            <HeadersSection
              title="REQUIRED HEADERS"
              headerType="required"
              icon={hasHeaderIssues && <XCircle className="w-4 h-4 text-red-500" />}
              isEmpty={
                headerCategories.required.length === 0 && headerCategories.missing.length === 0
              }
            >
              {/* Present required headers */}
              {headerCategories.required.map((header, index) => (
                <HeaderBadge
                  key={`req-${header}-${index}`}
                  header={header}
                  type="required"
                  sampleData={headerSamples[header]}
                  onRename={onHeaderRename}
                />
              ))}

              {/* Missing required headers */}
              {headerCategories.missing.map((header, index) => (
                <HeaderBadge
                  key={`miss-${header}-${index}`}
                  header={header}
                  type="missing"
                  sampleData={headerSamples[header]}
                />
              ))}
            </HeadersSection>

            {/* Extra headers */}
            {headerCategories.extra.length > 0 && (
              <HeadersSection
                title="EXTRA HEADERS"
                headerType="extra"
                icon={<InfoIcon className="w-4 h-4" />}
                subtitle="(saved as custom fields)"
              >
                {headerCategories.extra.map((header, index) => (
                  <HeaderBadge
                    key={`extra-${header}-${index}`}
                    header={header}
                    type="extra"
                    sampleData={headerSamples[header]}
                    onRename={onHeaderRename}
                  />
                ))}
              </HeadersSection>
            )}

            {/* Contact fields */}
            {headerCategories.contacts.length > 0 && (
              <HeadersSection
                title={`CONTACT FIELDS (${headerCategories.contactCount})`}
                headerType="contact"
                icon={<InfoIcon className="w-4 h-4" />}
              >
                {headerCategories.contacts.map((header, index) => (
                  <HeaderBadge
                    key={`contact-${header}-${index}`}
                    header={header}
                    type="contact"
                    sampleData={headerSamples[header]}
                    onRename={onHeaderRename}
                  />
                ))}
              </HeadersSection>
            )}

            {/* Empty state */}
            {finalHeaders.length === 0 && (
              <div className="p-8 text-base text-center text-muted-foreground">
                No headers found in the CSV file.
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}

export default CsvPreview;
