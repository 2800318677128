import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import {
  defaultCurrentTargetGroupParams,
  deleteTargetGroup,
  getAllTargetGroupsByClient,
  setCurrentTargetGroupParams,
} from '../../../entities/target-group/store/target-group.slice';
import ButtonV2 from '../../../shared/components/button/button-v2/button-v2.component';
import FeedbackUiDecorator from '../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../shared/domain/types/html-elements.types';
import { IDeleteTargetGroupProps } from './delete-target-group.types';

function DeleteTargetGroup({ targetGroupId, onClick }: IDeleteTargetGroupProps) {
  const [isPromptedToDelete, setIsPromptedToDelete] = useState(false);

  const { id: selectedTargetGroupId } = useAppSelector(currentTargetGroupParamsSelector);

  const { isLoading: isTargetGroupDeleting } =
    useAppSelector(targetGroupThunksSelector).deleteTargetGroup;
  const { isLoading: areTargetGroupsLoading } =
    useAppSelector(targetGroupThunksSelector).getAllTargetGroupsByClient;

  const dispatch = useAppDispatch();

  const { clientId } = useParams();

  function resetTargetGroupForm() {
    if (selectedTargetGroupId === targetGroupId) {
      dispatch(setCurrentTargetGroupParams(defaultCurrentTargetGroupParams));
    }
  }

  function handleDeleteButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToDelete(true);

    if (onClick) {
      onClick();
    }
  }

  async function handleAction() {
    dispatch(deleteTargetGroup(targetGroupId));
    await dispatch(getAllTargetGroupsByClient({ clientId }))
      .unwrap()
      .then(() => {
        resetTargetGroupForm();
        setIsPromptedToDelete(false);
      });
  }

  function handleClose() {
    setIsPromptedToDelete(false);
  }

  function handleCancel() {
    setIsPromptedToDelete(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToDelete,
    handleAction,
    handleClose,
    handleCancel,
    actionTitle: 'Delete',
    message: "You can't undo this action",
    title: 'Are you sure you want to permanently delete this target group?',
    isActionLoading: isTargetGroupDeleting || areTargetGroupsLoading,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <ButtonV2
        handleClick={handleDeleteButtonClick}
        title="Delete Target Group"
        customVariant="primary-small"
      />
    </FeedbackUiDecorator>
  );
}

export default DeleteTargetGroup;
