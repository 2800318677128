import { Box, debounce } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useState } from 'react';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { updateCompanySource } from '../../../entities/target-group/store/target-group.slice';
import DeleteUploadedFile from '../../../features/display-uploaded-files/components/delete-uploaded-file/delete-uploaded-file.component';
import ButtonV2 from '../../../shared/components/button/button-v2/button-v2.component';
import {
  DialogActionsButtons,
  StyledDialog,
  StyledDialogContent,
  StyledResizeIcon,
} from '../../../shared/components/input/input-expandable/input-expandable.styles';
import BaseTextarea from '../../../shared/components/textarea/base-textarea/base-textarea.component';
import { InputChangeEvent } from '../../../shared/domain/types/html-elements.types';
import RunClassificationOnSource from './run-classification-on-source/run-classification-on-source.component';

export function ActionCellRenderer(params: CustomCellRendererProps) {
  const { data } = params;
  return (
    <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      <RunClassificationOnSource source={data} />
      <DeleteUploadedFile
        fileName={data.name || data.url}
        sourceId={data.id}
        targetGroupId={data.targetGroupId}
      />
    </Box>
  );
}

export function NotesCellRenderer(params: CustomCellRendererProps) {
  const { data } = params;
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState(false);
  const [popupValue, setPopupValue] = useState(data.notes);

  const handleOpen = () => setIsOpened(true);
  const handleClose = () => setIsOpened(false);

  const handleNotesChange = debounce((id: string, value: string) => {
    dispatch(updateCompanySource({ sourceId: id, notes: value }));
  }, 300);

  const handleCancelChanges = () => {
    setPopupValue(data.notes);
    handleClose();
  };

  const handleSaveChanges = () => {
    handleClose();
    handleNotesChange(data.id, popupValue);
  };

  const handleChangePopupBody = (e: InputChangeEvent) => {
    setPopupValue(e.target.value);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <span style={{ width: '90%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {popupValue || data.notes}
      </span>
      <StyledResizeIcon sx={{ right: 0 }} onClick={handleOpen} src="/icons/resize-icon.svg" />
      <StyledDialog
        className="input-expendable-popup"
        title="Note"
        open={isOpened}
        onClose={handleCancelChanges}
      >
        <StyledDialogContent>
          <BaseTextarea
            multiline
            minRows={4}
            id="body"
            handleChange={handleChangePopupBody}
            name="body"
            values={{ body: popupValue }}
          />
          <DialogActionsButtons onClick={(e: any) => e.stopPropagation()}>
            <ButtonV2
              customVariant="secondary-small"
              title="Cancel"
              handleClick={handleCancelChanges}
              className="cancel-button"
            />
            <ButtonV2
              customVariant="primary-small"
              title="Save Changes"
              handleClick={handleSaveChanges}
              className="action-button"
            />
          </DialogActionsButtons>
        </StyledDialogContent>
      </StyledDialog>
    </div>
  );
}
