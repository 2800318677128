import { useAuth0 } from '@auth0/auth0-react';

import { userSelector } from '../../../entities/user/store/user.selectors';
import { useAppSelector } from '../../store/utils/redux.hooks';
import { IProtectDecorator } from './protect.types';

export default function ProtectDecorator({ allowedRoles, children }: IProtectDecorator) {
  const { isAuthenticated } = useAuth0();
  const { roles } = useAppSelector(userSelector);

  const hasRole = roles && roles.some(role => allowedRoles.includes(role));
  const isAllowed = isAuthenticated && hasRole;

  return isAllowed ? children : undefined;
}
