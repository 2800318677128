import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

export const ToastItem = styled(motion.div)<{ type: 'success' | 'error' | 'warning' | 'info' }>(({
  theme,
  type,
}) => {
  const color = type === 'info' ? theme.palette.neutrals.gray.medium : theme.palette[type].main;

  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    borderRadius: '.5rem',
    boxSizing: 'border-box',
    backgroundColor: color + 22,
    backdropFilter: 'blur(30px)',
    color,
    width: '400px',
    pointerEvents: 'auto',

    border: `1px solid ${color + 11}`,

    '& .icon': {
      color,
    },
  };
});

export const ToastTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  lineHeight: 1.2,
  fontSize: '1.25rem',
}));
