import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/store/utils/redux.hooks';
import { performFullLogout } from '../../../features/authentication/utils/auth-utils';

export function LogoutRoute() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { logout: auth0Logout } = useAuth0();

  // Automatically trigger logout when this component mounts
  useEffect(() => {
    const triggerLogout = async () => {
      await performFullLogout(dispatch, auth0Logout, navigate);
    };

    triggerLogout();
  }, [dispatch, auth0Logout, navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        flexDirection: 'column',
        bgcolor: 'background.default',
      }}
    >
      <CircularProgress color="inherit" sx={{ color: 'text.primary' }} size={30} />
      <Typography fontFamily="DM Mono" fontSize={16} color="text.primary">
        Logging you out...
      </Typography>
    </Box>
  );
}
