import { useCallback, useEffect, useState } from 'react';

interface UseInputFocusProps {
  formWrapperClass: string;
  explanationBoxClass: string;
}

export const useInputFocus = ({ formWrapperClass, explanationBoxClass }: UseInputFocusProps) => {
  const [lastFocusedInput, setLastFocusedInput] = useState('name');
  const [explanationTop, setExplanationTop] = useState(66);
  const [lastFocusedElement, setLastFocusedElement] = useState<HTMLElement | null>(null);
  const [smoothScroll, setSmoothScroll] = useState(false);
  const handleInputFocus = useCallback(
    (name: string, element: HTMLElement) => {
      setLastFocusedInput(name);
      setLastFocusedElement(element);

      setTimeout(() => {
        const formWrapper = document.querySelector(`.${formWrapperClass}`);
        const explanationBox = document.querySelector(`.${explanationBoxClass}`);

        if (!formWrapper || !explanationBox) return;

        const wrapperRect = formWrapper.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const explanationHeight = explanationBox.getBoundingClientRect().height;

        const availableSpace = wrapperRect.bottom - elementRect.top;

        let topOffset = elementRect.top - wrapperRect.top - 60;
        if (availableSpace < explanationHeight) {
          topOffset = Math.max(0, topOffset - (explanationHeight - availableSpace));
        }

        setExplanationTop(topOffset);
      }, 0);
    },
    [formWrapperClass, explanationBoxClass],
  );

  useEffect(() => {
    const formWrapper = document.querySelector(`.${formWrapperClass}`);
    if (!formWrapper || !lastFocusedElement) return;

    const handleScroll = () => {
      handleInputFocus(lastFocusedInput, lastFocusedElement);
    };

    formWrapper.addEventListener('scroll', handleScroll);
    return () => formWrapper.removeEventListener('scroll', handleScroll);
  }, [lastFocusedInput, lastFocusedElement, formWrapperClass, handleInputFocus]);

  useEffect(() => {
    const el = document.querySelector(`[name="name"]`) as HTMLElement;
    handleInputFocus('name', el);

    setTimeout(() => {
      setSmoothScroll(true);
    }, 100);
  }, [handleInputFocus]);

  return {
    lastFocusedInput,
    explanationTop,
    lastFocusedElement,
    handleInputFocus,
    setLastFocusedElement,
    smoothScroll,
  };
};
