import { RootState } from '../../../app/store/utils/redux.types';
import {
  IClient,
  IGetAllClientsItemDto,
  INotificationSettings,
  IUser,
  IUserThunks,
  UserState,
} from '../domain/user.types';

export function userStateSelector(state: RootState): UserState {
  return state.user;
}

export function userSelector(state: RootState): IUser {
  return state.user.user;
}

export function userThunksSelector(state: RootState): IUserThunks {
  return state.user.thunks;
}

export function clientsSelector(state: RootState): IGetAllClientsItemDto[] {
  return state.user.clients;
}

export function currentClientSelector(state: RootState): IClient | undefined {
  return state.user.currentClient;
}

export function notificationSettingsSelector(state: RootState): INotificationSettings | undefined {
  return state.user.currentClient?.notificationSettings;
}
