import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import BaseFormWrapper from '../../../shared/components/form/base-form-wrapper/base-form-wrapper.component';

export const StyledBaseFormWrapper = styled(BaseFormWrapper)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingBottom: '40px',
  label: {
    color: theme.palette.neutrals.gray.highest,
    fontSize: '1rem',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.75rem',
  color: theme.palette.neutrals.gray.highest,
  transition: 'font-size 0.3s ease-in-out',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.15rem',
  },
}));

export const FormSection = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gap: '24px',
  marginBottom: '32px',

  '&.full-width-fields': {
    gridTemplateColumns: '1fr',
  },
});

export const ExplanationsColumn = styled('div')(() => ({
  gridRow: 'span 8',
  height: '100%',
}));

export const ExplanationBox = styled('div')<{ top: number; smoothScroll: boolean }>(
  ({ theme, top, smoothScroll }) => ({
    transform: `translateY(${top}px)`,
    width: '100%',
    maxWidth: 'max-content',
    padding: '12px 32px 40px 32px',
    backgroundColor: theme.palette.neutrals.gray.lower,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutrals.gray.low}`,
    color: theme.palette.neutrals.gray.highest,
    transition: smoothScroll ? 'all 0.3s' : 'none',
    opacity: 0,
    animation: 'reveal 0.5s forwards',

    '@keyframes reveal': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },

    '& .examples-title': {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '26px',
      marginBottom: '16px',
      marginTop: '24px',

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.875rem',
        marginBottom: '12px',
        marginTop: '16px',
      },
    },

    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
  }),
);

export const ExplanationTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.75rem',
  fontWeight: 400,
  margin: 0,
  lineHeight: '2.4',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.15rem',
  },
}));

export const ExplanationDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  whiteSpace: 'pre-line',

  [theme.breakpoints.down('lg')]: {
    fontSize: '0.875rem',
  },
}));

export const ExplanationExample = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  fontSize: '1rem',
  color: theme.palette.neutrals.gray.medium,
  marginBottom: '4px',
  '& span': {
    marginBottom: 0,
  },

  [theme.breakpoints.down('lg')]: {
    fontSize: '0.875rem',
  },

  '& b': {
    backgroundColor: theme.palette.neutrals.gray.lowest,
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    marginRight: '4px',
    marginTop: '6px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '13.6px',
    flexShrink: 0,
  },
}));
