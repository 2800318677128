import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import { PipelineStageName } from '../../../modules/navigation/components/pipeline-navigation/pipeline-navigation.types';
import { ITargetGroupCardProps } from '../components/target-group-card/target-group-card.types';

export function formTargetGroupsEntityData(
  targetGroupsEntityDataFromServer: ITargetGroup[],
  currentTargetGroupId: string,
  stageName: PipelineStageName,
): (ITargetGroupCardProps & { key: string })[] {
  return targetGroupsEntityDataFromServer?.map(
    ({
      id: targetGroupId,
      name: targetGroupName,
      description,
      countCompanies: reviewCount,
      companiesToResearchCount,
      leadsCount,
      eventsCount,
    }) => {
      let companiesCount: number | null = null;

      switch (stageName) {
        case PipelineStageName.COMPANY_RESEARCH:
          companiesCount = companiesToResearchCount ?? 0;
          break;
        case PipelineStageName.PEOPLE_RESEARCH:
          companiesCount = leadsCount ?? 0;
          break;
        case PipelineStageName.TARGET_DEFINITION:
          companiesCount = reviewCount === 0 ? 0 : null;
          break;
        case PipelineStageName.REVIEW:
          companiesCount = reviewCount ?? 0;
          break;
        case PipelineStageName.INTELLIGENCE:
          companiesCount = eventsCount ?? 0;
          break;
        default:
          companiesCount = null;
          break;
      }

      return {
        targetGroupName,
        targetGroupId,
        targetGroupDescription: description,
        companiesCount,
        isActiveEntity: currentTargetGroupId === targetGroupId,
        key: `${targetGroupId}_${targetGroupName}`,
        stageName,
      };
    },
  );
}

export function checkIsModificationAllowed(stageName: PipelineStageName) {
  const allowedForStages = [PipelineStageName.TARGET_DEFINITION];
  const isAllowed = allowedForStages.includes(stageName);
  return isAllowed;
}
