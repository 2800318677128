import { CloseRounded } from '@mui/icons-material';
import { Box, Link, styled, Typography } from '@mui/material';

export const CompanyDetailsPanelContainer = styled(Box)<{ loading: boolean }>(({ theme }) => ({
  height: '100%',
  width: '35%',
  minWidth: 'min(max-content, 90%)',
  backgroundColor: 'white',
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: 1000,
  borderLeft: `1px solid ${theme.palette.neutrals.gray.low}`,
  padding: '32px',
  boxShadow: theme.shadows[5],
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  paddingBottom: '7rem !important',

  transform: 'translateX(100%)',
  transition: 'all 0.3s ease-in-out',
  animation: 'slideIn 0.3s forwards',

  [theme.breakpoints.down('xl')]: {
    width: '55%',
    padding: '28px',
  },

  [theme.breakpoints.down('lg')]: {
    width: '70%',
    padding: '24px',
  },

  [theme.breakpoints.down('md')]: {
    width: '80%',
    padding: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: '16px',
    minWidth: '100%',
  },

  '@keyframes slideIn': {
    '0%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },

  '&.closing': {
    animation: 'slideOut 0.3s forwards',
  },

  '@keyframes slideOut': {
    '0%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
  },
}));

export const CloseButton = styled(CloseRounded)({
  cursor: 'pointer',
  fontSize: '1rem',
  color: 'neutrals.gray.medium',
  position: 'absolute',
  top: '24px',
  right: '24px',
});

export const CompanyDetailsGrid = styled(Box)(({ theme }) => ({
  backgroundColor: 'neutrals.gray.lower',
  border: '1px solid',
  borderColor: 'neutrals.gray.low',
  borderRadius: '8px',
  padding: '14px',
  width: '60%',

  [theme.breakpoints.down('lg')]: {
    width: '70%',
  },

  [theme.breakpoints.down('md')]: {
    width: '80%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '12px',
  },
}));

export const LabelText = styled(Typography)({
  fontSize: '12px',
  fontFamily: 'DM Mono',
  display: 'flex',
  alignItems: 'center',
});

export const CompanyHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const CompanyName = styled(Typography)(({ theme }) => ({
  fontSize: '32px',

  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
}));

export const CompanySubtitle = styled(Typography)({
  fontSize: '20px',
  color: 'text.secondary',
});

export const SocialLinks = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
});

export const CompanyDescription = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  maxHeight: '160px',
  fontSize: '20px',
  padding: '2px 0',
  lineHeight: '26px',
  overflowY: 'auto',
  paddingRight: '1rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '24px',
    height: '160px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '22px',
    height: '140px',
  },
}));

export const DetailsBox = styled(Box)({
  display: 'grid',

  gap: '1.5rem',
});

export const ListItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Darker Grotesque',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '19.2px',
  textAlign: 'left',
  textDecorationSkipInk: 'none',
  color: theme.palette.neutrals.gray.highest,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const Overlay = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
  opacity: isVisible ? 1 : 0,
  visibility: isVisible ? 'visible' : 'hidden',
  transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
}));

export const LoadingOverlay = styled(Box)<{ isLoading: boolean }>(({ isLoading }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(2px)',
  zIndex: 1000,
  transition: 'opacity 0.3s ease-in-out',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${isLoading ? 'fadeIn' : 'fadeOut'} 0.3s ease-in-out forwards`,
  pointerEvents: isLoading ? 'auto' : 'none',

  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

  '@keyframes fadeOut': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
}));

export const CompanyDescriptionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
});

export const CompanyDescriptionTitle = styled(Typography)<{ showBorder: boolean }>(
  ({ theme, showBorder = false }) => ({
    fontSize: '1.385rem',
    color: theme.palette.neutrals.gray.highest,

    borderBottom: showBorder ? `1px solid ${theme.palette.neutrals.gray.low}80` : 'none',
    alignContent: 'center',
    paddingBottom: showBorder ? '0.25rem' : '0',
  }),
);

export const CompanyAnalysisSource = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.neutrals.gray.medium,
  fontFamily: 'DM Mono',
  fontSize: '0.75rem',
  marginLeft: '0.15rem',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: theme.palette.neutrals.gray.highest,
  },
}));
