import { ReactNode } from 'react';

type StatusType = 'success' | 'error' | 'warning' | 'info';

interface StatusMessageProps {
  type: StatusType;
  title: string;
  message: string;
  icon?: ReactNode;
}

const getStatusStyles = (type: StatusType) => {
  switch (type) {
    case 'success':
      return 'border-green-100 bg-green-50/30 text-green-700';
    case 'error':
      return 'border-red-100 bg-red-50/30 text-red-700';
    case 'warning':
      return 'border-amber-100 bg-amber-50/30 text-amber-700';
    case 'info':
      return 'border-blue-100 bg-blue-50/30 text-blue-700';
  }
};

const getMessageStyles = (type: StatusType) => {
  switch (type) {
    case 'success':
      return 'text-green-600';
    case 'error':
      return 'text-red-600';
    case 'warning':
      return 'text-amber-600';
    case 'info':
      return 'text-blue-600';
  }
};

const StatusMessage = ({ type, title, message, icon }: StatusMessageProps) => {
  const containerStyles = getStatusStyles(type);
  const messageStyles = getMessageStyles(type);

  return (
    <div className={`flex gap-2 p-3 text-base border rounded-md ${containerStyles}`}>
      {icon && <div className="mt-0.5 flex-shrink-0">{icon}</div>}
      <div>
        <p className="font-medium">{title}</p>
        <p className={`mt-1 text-sm ${messageStyles}`}>{message}</p>
      </div>
    </div>
  );
};

export default StatusMessage;
