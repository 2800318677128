import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { LogoutRoute } from '@/pages/authentication/logout/logout-route.component';
import { SuccessAuth } from '@/pages/authentication/success-auth/success-auth.component';

import { UserRole } from '../../entities/user/domain/user.types';
import Analytics from '../../pages/analytics/analytics.component';
import { Login } from '../../pages/authentication/login/login.component';
import Clients from '../../pages/clients/clients.component';
import Intelligence from '../../pages/intelligence/intelligence.component';
import MarketMapping from '../../pages/market-mapping/market-mapping.component';
import Review from '../../pages/review/review.component';
import TargetDefinition from '../../pages/target-definition/target-definition.component';
import TargetDefinitionPage from '../../pages/target-definition/target-definition-page.component';
import AuthenticationLayout from '../layouts/authentication/authentication-layout.component';
import BaseLayout from '../layouts/base/base-layout.component';
import DashboardLayout from '../layouts/dashboard/dashboard-layout.component';
import RequireAuthRoute from './require-auth-route.component';
import RequireNonAuthRoute from './require-non-auth-route.component';
import RequireRoleRoute, { RejectionFeedbackType } from './require-role-route.component';
import RootRedirect from './root-redirect.component';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '', // Empty string for the default route
        element: (
          <RequireAuthRoute>
            <RootRedirect />
          </RequireAuthRoute>
        ),
      },
      {
        path: 'authentication',
        element: <AuthenticationLayout />,
        children: [
          {
            path: 'login',
            element: (
              <RequireNonAuthRoute>
                <Login />
              </RequireNonAuthRoute>
            ),
          },
          {
            path: 'success',
            element: <SuccessAuth />,
          },
          {
            path: 'logout',
            element: <LogoutRoute />,
          },
        ],
      },
      {
        path: 'tg/:clientId/:targetGroupId',
        element: (
          <RequireRoleRoute
            allowedRoles={[UserRole.EMPLOYEE]}
            rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
          >
            <DashboardLayout>
              <TargetDefinitionPage />
            </DashboardLayout>
          </RequireRoleRoute>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <RequireAuthRoute>
            <Outlet />
          </RequireAuthRoute>
        ),
        children: [
          {
            path: 'clients',
            element: (
              <RequireRoleRoute
                allowedRoles={[UserRole.EMPLOYEE]}
                rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
              >
                <DashboardLayout>
                  <Clients />
                </DashboardLayout>
              </RequireRoleRoute>
            ),
          },
          {
            path: 'analytics/:clientId',
            element: (
              <RequireRoleRoute
                allowedRoles={[UserRole.EMPLOYEE]}
                rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
              >
                <DashboardLayout>
                  <Analytics />
                </DashboardLayout>
              </RequireRoleRoute>
            ),
          },
          {
            path: 'pipeline/:clientId',
            element: <DashboardLayout />,
            children: [
              {
                path: 'client-requirements',
                element: <TargetDefinition />,
              },
              {
                path: 'market-mapping',
                element: (
                  <RequireRoleRoute
                    allowedRoles={[UserRole.EMPLOYEE]}
                    rejectionFeedbackType={RejectionFeedbackType.REJECTION_SCREEN}
                  >
                    <MarketMapping />
                  </RequireRoleRoute>
                ),
              },
              {
                path: 'review',
                element: <Review />,
              },
              {
                path: 'intelligence',
                element: <Intelligence />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
