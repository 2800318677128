import React from 'react';

import {
  NoTargetGroupChosenMessage,
  NoTargetGroupChosenScreenContainer,
} from './no-target-group-chosen-screen.styles';

function NoTargetGroupChosenScreen() {
  return (
    <NoTargetGroupChosenScreenContainer>
      <NoTargetGroupChosenMessage>
        Select a target group on the left to upload companies.
      </NoTargetGroupChosenMessage>
    </NoTargetGroupChosenScreenContainer>
  );
}

export default NoTargetGroupChosenScreen;
