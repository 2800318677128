import { useEffect } from 'react';

import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';
import { useAppDispatch } from '../../app/store/utils/redux.hooks';
import { resetDeleteTargetGroupThunk } from '../../entities/target-group/store/target-group.slice';
import TargetDefinitionLeftSection from '../../modules/target-definition-left-section/components/target-definition-left-section.component';
import TargetDefinitionRightSection from '../../modules/target-definition-right-section/components/target-definition-right-section.component';
import FeedbackUiDecorator from '../../shared/decorators/feedback-ui/feedback-ui.decorator';

function TargetDefinition() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetDeleteTargetGroupThunk());
  }, [dispatch]);

  return (
    <FeedbackUiDecorator isChildrenVisible>
      <TwoSectionsScreenLayout
        leftSectionComponent={TargetDefinitionLeftSection}
        rightSectionComponent={TargetDefinitionRightSection}
      />
    </FeedbackUiDecorator>
  );
}

export default TargetDefinition;
