import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { Box, Typography } from '@mui/material';

export default function UnsupportedScreen() {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100svh',
        width: '100vw',
        textAlign: 'center',
        gap: '.75rem',
        background: `linear-gradient(135deg, ${theme.palette.neutrals.gray.lowest} 0%, ${theme.palette.neutrals.gray.lower} 100%)`,
      })}
    >
      <RemoveCircleTwoToneIcon sx={{ fontSize: '5rem', color: 'neutrals.gray.medium' }} />
      <Typography fontSize="2rem" color="neutrals.gray.high">
        This screen size is not supported!
      </Typography>
      <Typography variant="h1" fontSize="1.5rem" width="50%" color="neutrals.gray.medium">
        Please switch to your desktop or laptop to use revi.
      </Typography>
    </Box>
  );
}
