import { Box, Divider, Typography } from '@mui/material';

import { SectionTitle } from '../../company-details-panel.styles';

function DetailsSection({
  title,
  children,
  count,
}: {
  title: string;
  children: React.ReactNode;
  count?: number;
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <SectionTitle>
        {title}
        {count && (
          <Typography
            fontFamily="DM Mono"
            fontSize={12}
            color="neutrals.gray.medium"
            letterSpacing="-3px"
            sx={{ flexShrink: 0 }}
          >
            ( {count} )
          </Typography>
        )}
      </SectionTitle>
      <Divider />
      {children}
    </Box>
  );
}

export default DetailsSection;
