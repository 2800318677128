import { Check } from '@mui/icons-material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { FormControl } from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';

import IndicatorChip from '../../chip/indicator-chip/indicator-chip.component';
import { InfoIcon } from '../../input/base-input/base-input.styles';
import { Label, menuStyles, MuiSelect, MuiSelectItem } from './base-dropdown-v2.styles';
import { IBaseDropdownV2Props } from './base-dropdown-v2.types';

function BaseDropdownV2<
  T extends {
    name: string;
    id: string;
    chip?: number | string;
    values: Partial<{ id: string; name: string }>;
  },
>({
  id,
  name,
  options,
  selectedOption,
  defaultOption,
  handleOptionChange,
  onMenuOpen,
  onMenuClose,
  label,
  className,
  isMenuOpen,
  disabled,
  showChip,
  multiple,
  customRenderValue,
  children,
  lastFocusedInput,
  setLastFocusedInput,
  placeholder,
  showInfoIcon = false,
  menuProps,
}: PropsWithChildren<IBaseDropdownV2Props<T>>) {
  return (
    <FormControl
      fullWidth
      className={className}
      onClick={() => {
        if (name && id) setLastFocusedInput?.(name, id);
      }}
    >
      {label && (
        <Label
          sx={{
            marginBottom: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '100%',
          }}
          htmlFor={id}
        >
          {label}

          {showInfoIcon && (
            <InfoIcon
              src={`/icons/info-circle-${lastFocusedInput === name ? 'fill' : 'outline'}.svg`}
              sx={{ cursor: 'pointer' }}
            />
          )}
        </Label>
      )}
      <MuiSelect
        id={id}
        multiple={multiple}
        value={selectedOption}
        onChange={handleOptionChange}
        renderValue={selected => {
          if (!selected || (Array.isArray(selected) && selected.length === 0)) {
            return <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{placeholder}</span>;
          }
          return customRenderValue ? customRenderValue(selected) : (selected as ReactNode);
        }}
        className="custom-select"
        displayEmpty
        IconComponent={UnfoldMoreIcon}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        onFocus={() => {
          if (name && id) setLastFocusedInput?.(name, id);
        }}
        MenuProps={{ ...menuStyles, ...menuProps }}
        isMenuOpen={isMenuOpen ?? false}
        disabled={disabled ?? false}
        variant="outlined"
      >
        {defaultOption && (
          <MuiSelectItem
            isCurrentlySelectedItem={selectedOption === defaultOption.name}
            value={JSON.stringify(defaultOption)}
          >
            {defaultOption.name}
          </MuiSelectItem>
        )}
        {options.map((option: T) => {
          const { name: optionName, id: optionId, values } = option;
          const key = `${optionId}_${optionName}`;
          const isCurrentlySelectedItem = selectedOption === optionName;
          return (
            <MuiSelectItem key={key} value={JSON.stringify(values)}>
              <>
                {isCurrentlySelectedItem && <Check className="custom-select-check-icon" />}
                {optionName}
                {option.chip && showChip ? (
                  <IndicatorChip
                    label={option.chip}
                    isDimmed={false}
                    className="dropdown-items-counter"
                  />
                ) : null}
              </>
            </MuiSelectItem>
          );
        })}
        {children && children}
      </MuiSelect>
    </FormControl>
  );
}

export default BaseDropdownV2;
