import { CircularProgress, Stack } from '@mui/material';

import { muiThemeOptions } from '../../../../app/styles/global/mui/mui-theme-options';
import { IBaseWindowLoaderProps } from '../../feedback-ui/feedback-ui.types';

function BaseWindowLoader({ status: isLoading, title, className, size }: IBaseWindowLoaderProps) {
  return isLoading ? (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top={0}
      left={0}
      height="100vh"
      width="100%"
      className={className}
    >
      <CircularProgress
        size={size || 50}
        sx={{ color: muiThemeOptions.palette.neutrals.gray.medium }}
      />
      <span style={{ color: 'black', fontSize: 18 }}>{title || ''}</span>
    </Stack>
  ) : null;
}

export default BaseWindowLoader;
