import { SyntheticEvent, useState } from 'react';

import { InputChangeEvent } from '../domain/types/html-elements.types';
import {
  ValidateObjectFn,
  ValidationResultsObject,
  ValidatorStrategy,
} from '../domain/types/validation.types';

export function useForm<FormValues, Strategy>(
  defaultFormState: FormValues,
  validateObjectFn?: ValidateObjectFn<FormValues, Strategy>,
  validateStrategy?: ValidatorStrategy<Strategy>,
) {
  const [formValues, setFormValues] = useState(defaultFormState);
  const [validationErrors, setValidationErrors] = useState({});

  function updateFormValues(fieldPath: string, value: string): FormValues {
    const [last, ...levels] = fieldPath.split('.').reverse();
    const form = JSON.parse(JSON.stringify(formValues));
    let pointer = form;

    for (const key of levels.reverse()) {
      pointer = pointer[key];
    }

    pointer[last] = value;
    return form;
  }

  function handleInputChange(event: InputChangeEvent): void {
    const { name, value } = event.target;
    setFormValues(updateFormValues(name, value));
  }

  function handleCustomInputChange(name: string, value: any): void {
    setFormValues(updateFormValues(name, value));
  }

  function resetFormState(state?: FormValues): void {
    setFormValues(state || defaultFormState);
  }

  function validateValues(values: FormValues): ValidationResultsObject | undefined {
    if (validateObjectFn && validateStrategy) {
      const validationResult = validateObjectFn(values, validateStrategy);
      setValidationErrors(validationResult);
      return validationResult;
    }
  }

  function validateForm(
    event: SyntheticEvent,
    values: FormValues,
  ): ValidationResultsObject | undefined {
    event.preventDefault();
    return validateValues(values);
  }

  return {
    formValues,
    handleInputChange,
    handleCustomInputChange,
    resetFormState,
    validateValues,
    validateForm,
    validationErrors,
  };
}
