import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../app/services/axios.service';
import { generateBaseThunkReducers } from '../../../shared/store/base-thunk-reducer';
import {
  IPeopleResearchLead,
  IResearchCompany,
} from '../../company-target-group/domain/company-target-group.types';
import {
  ClassificationControlActions,
  CompanySource,
  IAnalytic,
  IClassificationKpi,
  IClientsVisibilityKPIsResponse,
  ICreatePromptParams,
  ICreateTargetGroupBody,
  ICurrentTargetGroupParams,
  IExampleCompany,
  IPrompt,
  IPromptAccuracy,
  IRunSearchFormParams,
  ITargetGroup,
  ITargetGroupKPIsResponse,
  ITargetGroupsListResponse,
  IToggleFavouritePrompt,
  IUpdateExampleCompanyParams,
  IUpdatePromptParams,
  IUpdateTargetGroupParams,
  IUpsertExampleCompanyParams,
  SourceClassificationStatuses,
  TargetGroupState,
} from '../domain/target-group.types';

export const defaultTargetGroupsList = {} as ITargetGroupsListResponse;

export const defaultEmail = { subject: '', body: '' };

export const defaultCurrentTargetGroupParams = {
  id: '',
  name: 'All target groups',
};

export const defaultCampaignOwner = {
  id: 'Select campaign owner',
  name: 'Select campaign owner',
};

export const defaultCurrentTargetGroup = {
  ...defaultCurrentTargetGroupParams,
  clientId: '',
  description: '',
  emailSequences: [defaultEmail, defaultEmail],
  headcount: '',
  revenue: '',
  regions: '',
  keywords: [],
  exampleCompanies: '',
  relevantPastDeals: '',
  relevantAcquirers: '',
  otherRelevantInformation: '',
  campaignOwner: defaultCampaignOwner,
  createdAt: '',
  updatedAt: '',
  companiesSources: [],
};

const defaultClassificationKpi = {
  processed: 0,
  classified: 0,
  skipped: 0,
  remained: 0,
  total: 0,
  crawled: 0,
  errored: 0,
  processedPercentage: 0,
  conversionPercentage: 0,
  crowledPercentage: 0,
  erroredPercentage: 0,
  classifiedPercentage: 0,
} as IClassificationKpi;

export const defaultCampaigns = [];

const defaultThunkStatus = { isLoading: false, isError: false, isSuccess: false, error: {} };

const defaultTargetGroupState = {
  targetGroupsWeeklyKPIs: {} as ITargetGroupKPIsResponse,
  clientsVisibilityKPIs: {} as IClientsVisibilityKPIsResponse,
  allCLientsTGsList: defaultTargetGroupsList,
  targetGroupsList: defaultTargetGroupsList,
  currentTargetGroupParams: defaultCurrentTargetGroupParams,
  currentTargetGroup: defaultCurrentTargetGroup,
  selectedEmailSequenceIndex: 0,
  campaigns: defaultCampaigns,
  companyCountFilter: undefined,
  classificationTriggeredForTGs: new Set<string>(),
  exampleCompanies: [],
  prompts: [],
  promptAccuracies: [],
  classificationKpi: defaultClassificationKpi,
  researchCompanies: [],
  peopleResearchLeads: [],
  analytics: [],
  thunks: {
    getClientsVisibilityKPIs: defaultThunkStatus,
    getTargetGroupsWeeklyKPIs: defaultThunkStatus,
    getForAllClientsTargetGroupsList: defaultThunkStatus,
    getAllTargetGroupsByClient: defaultThunkStatus,
    getTargetGroupById: defaultThunkStatus,
    createTargetGroup: defaultThunkStatus,
    updateTargetGroup: defaultThunkStatus,
    deleteTargetGroup: defaultThunkStatus,
    fetchCampaigns: defaultThunkStatus,
    promptExampleCompanies: defaultThunkStatus,
    enrichExampleCompanies: defaultThunkStatus,
    runSearches: defaultThunkStatus,
    runClassification: defaultThunkStatus,
    deleteUploadedFile: defaultThunkStatus,
    getExampleCompanies: defaultThunkStatus,
    modifyExampleCompany: { ...defaultThunkStatus, companyTargetGroupId: null },
    prompts: defaultThunkStatus,
    getPromptAccuracies: defaultThunkStatus,
    runPrompt: defaultThunkStatus,
    generateKeywords: defaultThunkStatus,
    batchCrawlExampleCompanies: defaultThunkStatus,
    fetchResearchCompaniesList: defaultThunkStatus,
    fetchPeopleResearchLeads: defaultThunkStatus,
    updateResearchCompany: defaultThunkStatus,
    getCompanySourceStatuses: defaultThunkStatus,
    findPeople: defaultThunkStatus,
    pushToReview: defaultThunkStatus,
    updateCompanySource: defaultThunkStatus,
    fetchTargetGroupAnalytics: defaultThunkStatus,
  },
};

export const targetGroupState: TargetGroupState = defaultTargetGroupState;

export const deleteResearchCompany = createAsyncThunk(
  'targetGroup/deleteResearchCompany',
  async ({ companyTargetGroupId }: { companyTargetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/research-company/${companyTargetGroupId}`;
      await axios.delete(endpoint);
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchResearchCompaniesList = createAsyncThunk(
  'targetGroup/fetchResearchCompaniesList',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/target-research/companies-list/${targetGroupId}`;
      const { data: targetGroupsList } = await axios.get(endpoint);
      return targetGroupsList as IResearchCompany[];
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchPeopleResearchLeads = createAsyncThunk(
  'targetGroup/fetchPeopleResearchLeads',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/lead/people-research/list/${targetGroupId}`;
      const { data: targetGroupsList } = await axios.get(endpoint);
      return targetGroupsList as IPeopleResearchLead[];
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateResearchCompany = createAsyncThunk(
  'targetGroup/updateResearchCompany',
  async (
    {
      companyTargetGroupId,
      companyId,
      body,
    }: { companyTargetGroupId: string; companyId: string; body: Partial<IResearchCompany> },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/company-target-group/target-research/research-company/${companyTargetGroupId}/${companyId}`;
      const { data: researchCompany } = await axios.patch(endpoint, body);
      return researchCompany as IResearchCompany;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const controlSourceClassification = createAsyncThunk(
  'targetGroup/controlSourceClassification',
  async (body: { sourceId: string; action: ClassificationControlActions }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`ai-company-selection/classification-controls`, body);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteUploadedFile = createAsyncThunk(
  'targetGroup/deleteUploadedFile',
  async ({ sourceId }: { sourceId: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`raw-companies/source/${sourceId}`);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getClientsVisibilityKPIs = createAsyncThunk(
  'targetGroup/getClientsVisibilityKPIs',
  async ({ clientIds = [] }: { clientIds?: string[] | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/metrics/visibility-kpis?take=500&${clientIds.map(e => `clientId=${e}`).join('&')}`;
      const { data: visibilityKPIs } = await axios.get(endpoint);
      return visibilityKPIs as IClientsVisibilityKPIsResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getTargetGroupsWeeklyKPIs = createAsyncThunk(
  'targetGroup/getTargetGroupsWeeklyKPIs',
  async ({ clientIds = [] }: { clientIds?: string[] | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/metrics/weekly-kpis?take=500&${clientIds.map(e => `clientId=${e}`).join('&')}`;
      const { data: weeklyKPIs } = await axios.get(endpoint);
      return weeklyKPIs as ITargetGroupKPIsResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getForAllClientsTargetGroupsList = createAsyncThunk(
  'targetGroup/getForAllClientsTargetGroupsList',
  async ({ clientIds = [] }: { clientIds?: string[] | undefined }, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/for-clients?take=500&${clientIds.map(e => `clientId=${e}`).join('&')}`;
      const { data: targetGroupsList } = await axios.get(endpoint);
      return targetGroupsList as ITargetGroupsListResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getCompanySourceStatuses = createAsyncThunk(
  'targetGroup/getCompanySourceStatuses',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/raw-companies/sources-statuses-map/${targetGroupId}`;
      const { data: statuses } =
        await axios.get<Record<string, SourceClassificationStatuses>>(endpoint);
      return statuses;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const runSearches = createAsyncThunk(
  'targetGroup/runSearches',
  async (
    {
      targetGroupId,
      runSearchFormParams,
    }: { targetGroupId: string; runSearchFormParams: IRunSearchFormParams },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/raw-companies/run-search/${targetGroupId}`;
      const { data: targetGroup } = await axios.post(endpoint, runSearchFormParams);
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const runClassification = createAsyncThunk(
  'targetGroup/runClassification',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/classify-target-group`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const enrichExampleCompanies = createAsyncThunk(
  'targetGroup/enrichExampleCompanies',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/enrich-example-companies`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const promptExampleCompanies = createAsyncThunk(
  'targetGroup/promptExampleCompanies',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/prompt-example-companies`;
      const { data: targetGroup } = await axios.post(endpoint, { targetGroupId });
      return targetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getAllTargetGroupsByClient = createAsyncThunk(
  'targetGroup/getAllTargetGroupsByClient',
  async (
    {
      clientId,
      filter,
      search,
    }: { clientId: string | undefined; filter?: string; search?: string },
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/target-group/all/${clientId}?${filter ? `&criteria=${filter}` : ''}`;
      const { data: targetGroupsList } = await axios.get(endpoint, { params: { search } });
      return targetGroupsList as ITargetGroupsListResponse;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getTargetGroupById = createAsyncThunk(
  'targetGroup/getTargetGroupById',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}`;
      const { data: targetGroup } = await axios.get(endpoint);
      return targetGroup.item as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const createTargetGroup = createAsyncThunk(
  'targetGroup/createTargetGroup',
  async (body: ICreateTargetGroupBody, { rejectWithValue }) => {
    try {
      const endpoint = '/target-group';
      const { data: targetGroup } = await axios.post(endpoint, body);
      return targetGroup.item as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateTargetGroup = createAsyncThunk(
  'targetGroup/updateTargetGroup',
  async ({ targetGroupId, body }: IUpdateTargetGroupParams, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}`;
      const { data: targetGroup } = await axios.patch(endpoint, body);
      return targetGroup.item as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteAllCompaniesByTargetGroupId = createAsyncThunk(
  'targetGroup/deleteAllCompaniesByTargetGroupId',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}/companies`;
      const { data } = await axios.delete(endpoint);
      return data.message;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteTargetGroup = createAsyncThunk(
  'targetGroup/deleteTargetGroup',
  async (targetGroupId: string, { rejectWithValue }) => {
    try {
      const endpoint = `/target-group/${targetGroupId}`;
      const { data } = await axios.delete(endpoint);
      return data.message;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchCampaigns = createAsyncThunk(
  'targetGroup/fetchCampaigns',
  async (clientId: string | undefined, { rejectWithValue }) => {
    try {
      const endpoint = `client/${clientId}/replyio/campaigns`;
      const { data } = await axios.get(endpoint);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getExampleCompanies = createAsyncThunk(
  'companyTargetGroup/getExampleCompanies',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/example-company/with-prompts/${targetGroupId}`;
      const { data: companies } = await axios.get(endpoint);
      return companies as IExampleCompany[];
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const getPromptAccuracies = createAsyncThunk(
  'companyTargetGroup/getPromptAccuracies',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/example-company/prompt-accuracies/${targetGroupId}`;
      const { data: companies } = await axios.get(endpoint);
      return companies as IPromptAccuracy[];
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const addExampleCompany = createAsyncThunk(
  'targetGroup/addExampleCompany',
  async ({ body, targetGroupId }: IUpsertExampleCompanyParams, { rejectWithValue }) => {
    try {
      const endpoint = `/example-company/${targetGroupId}`;
      const { data: company } = await axios.post(endpoint, body);
      return company as IExampleCompany;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateExampleCompany = createAsyncThunk(
  'targetGroup/updateExampleCompany',
  async (
    { body, exampleCompanyId, companyId }: IUpdateExampleCompanyParams,
    { rejectWithValue },
  ) => {
    try {
      const endpoint = `/example-company/${exampleCompanyId}/${companyId}`;
      const { data: company } = await axios.patch(endpoint, body);
      return company as IExampleCompany;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deleteExampleCompany = createAsyncThunk(
  'targetGroup/deleteExampleCompany',
  async ({ exampleCompanyId }: { exampleCompanyId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/example-company/${exampleCompanyId}`;
      const { data: ctgId } = await axios.delete(endpoint);
      return ctgId;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const findPeople = createAsyncThunk(
  'targetGroup/findPeople',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/find-people/${targetGroupId}`;
      await axios.post(endpoint);
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const pushToReview = createAsyncThunk(
  'targetGroup/pushToReview',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/company-target-group/people-research/push-to-review/${targetGroupId}`;
      await axios.patch(endpoint);
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchClassificationKpi = createAsyncThunk(
  'targetGroup/fetchClassificationKpi',
  async (sourceId: string, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<IClassificationKpi>(
        `/ai-company-selection/classification-kpis/${sourceId}`,
      );
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const batchCrawlExampleCompanies = createAsyncThunk(
  'targetGroup/batchCrawlExampleCompanies',
  async (exampleCompanies: IExampleCompany[], { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/example-company/batch-crawl`, exampleCompanies);
      return data;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const addPrompt = createAsyncThunk(
  'targetGroup/addPrompt',
  async ({ body, targetGroupId }: ICreatePromptParams, { rejectWithValue }) => {
    try {
      const endpoint = `/prompt/${targetGroupId}`;
      const { data: prompt } = await axios.post(endpoint, body);
      return prompt as IPrompt;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updatePrompt = createAsyncThunk(
  'targetGroup/updatePrompt',
  async ({ body, promptId }: IUpdatePromptParams, { rejectWithValue }) => {
    try {
      const endpoint = `/prompt/${promptId}`;
      const { data: prompt } = await axios.patch(endpoint, body);
      return prompt as IPrompt;
    } catch (err: any) {
      const rejectionError = err.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const toggleFavouritePrompt = createAsyncThunk(
  'targetGroup/toggleFavouritePrompt',
  async ({ targetGroupId, promptId }: IToggleFavouritePrompt, { rejectWithValue }) => {
    try {
      const endpoint = `/prompt/${targetGroupId}/favourite/${promptId}`;
      const { data: targetGroup } = await axios.patch(endpoint);
      return targetGroup as ITargetGroup;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchPrompts = createAsyncThunk(
  'targetGroup/fetchPrompts',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `prompt/${targetGroupId}`;
      const { data } = await axios.get(endpoint);
      return data as IPrompt[];
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const runPrompt = createAsyncThunk(
  'targetGroup/runPrompt',
  async ({ promptId }: { promptId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `prompt/run/${promptId}`;
      const { data } = await axios.post(endpoint);
      return data as IExampleCompany[];
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const updateCompanySource = createAsyncThunk(
  'targetGroup/updateCompanySource',
  async ({ sourceId, notes }: { sourceId: string; notes: string }, { rejectWithValue }) => {
    try {
      const endpoint = `raw-companies/update-company-source/${sourceId}`;
      const { data } = await axios.patch(endpoint, { notes });
      return data as CompanySource;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const deletePrompt = createAsyncThunk(
  'targetGroup/deletePrompt',
  async ({ promptId }: { promptId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `prompt/${promptId}`;
      const { data: id } = await axios.delete(endpoint);
      return id as string;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const generateKeywords = createAsyncThunk(
  'targetGroup/generateKeywords',
  async ({ targetGroupId }: { targetGroupId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/ai-company-selection/generate-keywords`;
      const { data: keywords } = await axios.post(endpoint, { targetGroupId });
      return keywords;
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

export const fetchTargetGroupAnalytics = createAsyncThunk(
  'targetGroup/fetchTargetGroupAnalytics',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      const endpoint = `/analytics/${clientId}`;
      const { data: analytics } = await axios.get(endpoint);
      return analytics as IAnalytic[];
    } catch (error: any) {
      const rejectionError = error.response.data;
      return rejectWithValue(rejectionError);
    }
  },
);

const targetGroupSlice = createSlice({
  name: 'targetGroup',
  initialState: targetGroupState,
  reducers: {
    addNewEmailSequenceInCurrentTargetGroup: state => {
      // INFO: I've kept this code in case if we'll need the limit again
      // const isMax = state.currentTargetGroup.emailSequences.length >= 6;
      // if (isMax) return;
      const emptyEmailsPair = [defaultEmail, defaultEmail];
      state.currentTargetGroup.emailSequences.push(...emptyEmailsPair);
    },
    removeNewEmailSequenceInCurrentTargetGroup: (state, action: PayloadAction<number>) => {
      if (state.currentTargetGroup.emailSequences.length <= 2) return;
      if (action.payload <= 1) return;
      if (!state.currentTargetGroup.emailSequences?.length) return;
      if (!state.currentTargetGroup.emailSequences[action.payload]) return;
      state.currentTargetGroup.emailSequences.splice(action.payload, 2);
      state.selectedEmailSequenceIndex = 0;
    },
    setSelectedEmailSequenceIndex: (state, action: PayloadAction<number>) => {
      if (action.payload > state.currentTargetGroup.emailSequences.length / 2) {
        state.selectedEmailSequenceIndex = 0;
      } else {
        state.selectedEmailSequenceIndex = action.payload;
      }
    },
    resetDeleteTargetGroupThunk: state => {
      state.thunks.deleteTargetGroup = { ...defaultThunkStatus, error: {} };
      // state.thunks.deleteTargetGroup = defaultThunkStatus;
    },
    resetFindPeopleThunk: state => {
      state.thunks.findPeople = { ...defaultThunkStatus, error: {} };
    },
    resetPushToReviewThunk: state => {
      state.thunks.pushToReview = { ...defaultThunkStatus, error: {} };
    },
    setCompanyCountFilter: (state, action: PayloadAction<{ filter: string | undefined }>) => {
      state.companyCountFilter = action.payload?.filter;
    },
    setCurrentTargetGroupParams: (state, action: PayloadAction<ICurrentTargetGroupParams>) => {
      const currentTargetGroupParams = action.payload;
      state.currentTargetGroupParams = currentTargetGroupParams;
    },
    setCampaignOwner: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const campaignOwner = action.payload;
      state.currentTargetGroup.campaignOwner = campaignOwner;
    },
    resetTargetGroupState: state => {
      state.targetGroupsList = defaultTargetGroupState.targetGroupsList;
      state.currentTargetGroupParams = defaultTargetGroupState.currentTargetGroupParams;
      state.currentTargetGroup = defaultTargetGroupState.currentTargetGroup;
      state.campaigns = defaultTargetGroupState.campaigns;
      state.thunks = defaultTargetGroupState.thunks;
    },
  },

  extraReducers: builder => {
    generateBaseThunkReducers(builder, findPeople, 'findPeople');
    generateBaseThunkReducers(builder, deleteUploadedFile, 'deleteUploadedFile', undefined, [
      'fulfilled',
    ]);
    generateBaseThunkReducers(
      builder,
      fetchResearchCompaniesList,
      'fetchResearchCompaniesList',
      'researchCompanies',
    );
    generateBaseThunkReducers(
      builder,
      fetchPeopleResearchLeads,
      'fetchPeopleResearchLeads',
      'peopleResearchLeads',
    );
    generateBaseThunkReducers(
      builder,
      updateResearchCompany,
      'updateResearchCompany',
      'researchCompanies',
      ['fulfilled'],
    );
    generateBaseThunkReducers(builder, updateCompanySource, 'updateCompanySource', '', [
      'fulfilled',
    ]);

    builder.addCase(updateCompanySource.fulfilled, (state, action) => {
      const companySource = action.payload;
      state.currentTargetGroup.companiesSources?.forEach(e => {
        if (e.id.toString() === companySource.id.toString()) {
          e.notes = companySource.notes;
        }
      });
      state.thunks.updateCompanySource.isLoading = false;
      state.thunks.updateCompanySource.isSuccess = true;
      state.thunks.updateCompanySource.error = {};
      state.thunks.updateCompanySource.isError = false;
    });

    builder.addCase(updateResearchCompany.fulfilled, (state, action) => {
      const updatedCompany = action.payload;
      const idxToUpdate = state.researchCompanies.findIndex(e => e.id === updatedCompany.id);
      if (idxToUpdate >= 0) {
        state.researchCompanies[idxToUpdate] = updatedCompany;
      }
      const updatedLead = action.payload.leads?.[0];
      if (updatedLead?.id) {
        const idxToUpdateLead = state.peopleResearchLeads.findIndex(e => e.id === updatedLead.id);
        if (idxToUpdateLead >= 0) {
          state.peopleResearchLeads[idxToUpdateLead] = {
            ...updatedLead,
            id: updatedLead.id,
            companyName: updatedCompany.name,
            website: updatedCompany.website,
            companyTargetGroupId: updatedCompany.id,
          };
          state.peopleResearchLeads.forEach(lead => {
            if (lead.companyTargetGroupId === updatedCompany.id) {
              lead.companyName = updatedCompany.name;
              lead.website = updatedCompany.website;
            }
          });
        }
      }
      state.thunks.updateResearchCompany.isLoading = false;
      state.thunks.updateResearchCompany.isSuccess = true;
      state.thunks.updateResearchCompany.error = {};
      state.thunks.updateResearchCompany.isError = false;
    });

    builder.addCase(deleteResearchCompany.fulfilled, (state, action) => {
      const deletedCompanyId = action.meta.arg.companyTargetGroupId;
      state.researchCompanies = state.researchCompanies.filter(({ id }) => id !== deletedCompanyId);
    });

    generateBaseThunkReducers(
      builder,
      getCompanySourceStatuses,
      'getCompanySourceStatuses',
      undefined,
      ['fulfilled'],
    );
    generateBaseThunkReducers(builder, pushToReview, 'pushToReview');

    builder.addCase(getCompanySourceStatuses.fulfilled, (state, action) => {
      const statuses = action.payload;
      state.currentTargetGroup.companiesSources?.forEach(e => {
        e.classificationStatus = statuses[e.id];
      });
      state.thunks.getCompanySourceStatuses.isLoading = false;
      state.thunks.getCompanySourceStatuses.isSuccess = true;
      state.thunks.getCompanySourceStatuses.error = {};
      state.thunks.getCompanySourceStatuses.isError = false;
    });

    builder.addCase(controlSourceClassification.pending, (state, action) => {
      const idxToUpdate = state.currentTargetGroup.companiesSources?.findIndex(
        e => e.id === action.meta.arg.sourceId,
      );
      if (idxToUpdate >= 0) {
        const newStatus = {
          [ClassificationControlActions.run]: SourceClassificationStatuses.running,
          [ClassificationControlActions.pause]: SourceClassificationStatuses.pausing,
          [ClassificationControlActions.retry]: SourceClassificationStatuses.running,
        };
        state.currentTargetGroup.companiesSources[idxToUpdate].classificationStatus =
          newStatus[action.meta.arg.action];
      }
    });

    builder.addCase(deleteUploadedFile.fulfilled, (state, action) => {
      const { companiesSources } = state.currentTargetGroup;
      const isExist = (e: CompanySource) => e.id !== action.meta.arg.sourceId;

      if (companiesSources)
        state.currentTargetGroup.companiesSources = companiesSources.filter(isExist);

      state.thunks.getForAllClientsTargetGroupsList.isLoading = false;
      state.thunks.getForAllClientsTargetGroupsList.isSuccess = true;
      state.thunks.getForAllClientsTargetGroupsList.error = {};
      state.thunks.getForAllClientsTargetGroupsList.isError = false;
      state.thunks.deleteUploadedFile = defaultThunkStatus;
    });

    generateBaseThunkReducers(
      builder,
      getClientsVisibilityKPIs,
      'getClientsVisibilityKPIs',
      'clientsVisibilityKPIs',
    );

    generateBaseThunkReducers(
      builder,
      getTargetGroupsWeeklyKPIs,
      'getTargetGroupsWeeklyKPIs',
      'targetGroupsWeeklyKPIs',
    );

    generateBaseThunkReducers(builder, fetchPrompts, 'prompts', 'prompts');

    generateBaseThunkReducers(
      builder,
      getPromptAccuracies,
      'getPromptAccuracies',
      'promptAccuracies',
    );

    builder.addCase(getForAllClientsTargetGroupsList.pending, (state, action) => {
      state.thunks.getForAllClientsTargetGroupsList.isLoading = true;
      state.thunks.getForAllClientsTargetGroupsList.isSuccess = false;
      state.thunks.getForAllClientsTargetGroupsList.error = {};
      state.thunks.getForAllClientsTargetGroupsList.isError = false;
    });
    builder.addCase(getForAllClientsTargetGroupsList.fulfilled, (state, action) => {
      const targetGroupsList = action.payload;
      state.allCLientsTGsList = targetGroupsList;
      state.thunks.getForAllClientsTargetGroupsList.isLoading = false;
      state.thunks.getForAllClientsTargetGroupsList.isSuccess = true;
      state.thunks.getForAllClientsTargetGroupsList.error = {};
      state.thunks.getForAllClientsTargetGroupsList.isError = false;
    });
    builder.addCase(getForAllClientsTargetGroupsList.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getForAllClientsTargetGroupsList.error = error;
      state.thunks.getForAllClientsTargetGroupsList.isError = true;
      state.thunks.getForAllClientsTargetGroupsList.isLoading = false;
    });

    builder.addCase(getAllTargetGroupsByClient.pending, (state, action) => {
      state.thunks.getAllTargetGroupsByClient.isLoading = true;
      state.thunks.getAllTargetGroupsByClient.isSuccess = false;
      state.thunks.getAllTargetGroupsByClient.error = {};
      state.thunks.getAllTargetGroupsByClient.isError = false;
    });
    builder.addCase(getAllTargetGroupsByClient.fulfilled, (state, action) => {
      const targetGroupsList = action.payload;
      state.targetGroupsList = targetGroupsList;
      state.companyCountFilter = undefined;
      state.thunks.getAllTargetGroupsByClient.isLoading = false;
      state.thunks.getAllTargetGroupsByClient.isSuccess = true;
      state.thunks.getAllTargetGroupsByClient.error = {};
      state.thunks.getAllTargetGroupsByClient.isError = false;
    });
    builder.addCase(getAllTargetGroupsByClient.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getAllTargetGroupsByClient.error = error;
      state.thunks.getAllTargetGroupsByClient.isError = true;
      state.thunks.getAllTargetGroupsByClient.isLoading = false;
    });

    builder.addCase(promptExampleCompanies.pending, (state, action) => {
      state.thunks.promptExampleCompanies.isLoading = true;
      state.thunks.promptExampleCompanies.isSuccess = false;
      state.thunks.promptExampleCompanies.error = {};
      state.thunks.promptExampleCompanies.isError = false;
    });
    builder.addCase(promptExampleCompanies.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.promptExampleCompanies.isLoading = false;
      state.thunks.promptExampleCompanies.isSuccess = true;
      state.thunks.promptExampleCompanies.error = {};
      state.thunks.promptExampleCompanies.isError = false;
    });
    builder.addCase(promptExampleCompanies.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.promptExampleCompanies.error = error;
      state.thunks.promptExampleCompanies.isError = true;
      state.thunks.promptExampleCompanies.isLoading = false;
    });

    builder.addCase(runSearches.pending, (state, action) => {
      state.thunks.runSearches.isLoading = true;
      state.thunks.runSearches.isSuccess = false;
      state.thunks.runSearches.error = {};
      state.thunks.runSearches.isError = false;
    });
    builder.addCase(runSearches.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.runSearches.isLoading = false;
      state.thunks.runSearches.isSuccess = true;
      state.thunks.runSearches.error = {};
      state.thunks.runSearches.isError = false;
    });
    builder.addCase(runSearches.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.runSearches.error = error;
      state.thunks.runSearches.isError = true;
      state.thunks.runSearches.isLoading = false;
    });

    builder.addCase(runClassification.pending, (state, action) => {
      state.classificationTriggeredForTGs.add(action.meta.arg);
      state.thunks.runClassification.isLoading = true;
      state.thunks.runClassification.isSuccess = false;
      state.thunks.runClassification.error = {};
      state.thunks.runClassification.isError = false;
    });
    builder.addCase(runClassification.fulfilled, (state, action) => {
      state.classificationTriggeredForTGs.delete(action.meta.arg);
      state.thunks.runClassification.isLoading = false;
      state.thunks.runClassification.isSuccess = true;
      state.thunks.runClassification.error = {};
      state.thunks.runClassification.isError = false;
    });
    builder.addCase(runClassification.rejected, (state, action: any) => {
      state.classificationTriggeredForTGs.delete(action.meta.arg);
      const error = action.payload;
      state.thunks.runClassification.error = error;
      state.thunks.runClassification.isError = true;
      state.thunks.runClassification.isLoading = false;
    });

    builder.addCase(enrichExampleCompanies.pending, (state, action) => {
      state.thunks.enrichExampleCompanies.isLoading = true;
      state.thunks.enrichExampleCompanies.isSuccess = false;
      state.thunks.enrichExampleCompanies.error = {};
      state.thunks.enrichExampleCompanies.isError = false;
    });
    builder.addCase(enrichExampleCompanies.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.enrichExampleCompanies.isLoading = false;
      state.thunks.enrichExampleCompanies.isSuccess = true;
      state.thunks.enrichExampleCompanies.error = {};
      state.thunks.enrichExampleCompanies.isError = false;
    });
    builder.addCase(enrichExampleCompanies.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.enrichExampleCompanies.error = error;
      state.thunks.enrichExampleCompanies.isError = true;
      state.thunks.enrichExampleCompanies.isLoading = false;
    });

    builder.addCase(getTargetGroupById.pending, (state, action) => {
      state.thunks.getTargetGroupById.isLoading = true;
      state.thunks.getTargetGroupById.isSuccess = false;
      state.thunks.getTargetGroupById.error = {};
      state.thunks.getTargetGroupById.isError = false;
    });
    builder.addCase(getTargetGroupById.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.getTargetGroupById.isLoading = false;
      state.thunks.getTargetGroupById.isSuccess = true;
      state.thunks.getTargetGroupById.error = {};
      state.thunks.getTargetGroupById.isError = false;
    });
    builder.addCase(getTargetGroupById.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.getTargetGroupById.error = error;
      state.thunks.getTargetGroupById.isError = true;
      state.thunks.getTargetGroupById.isLoading = false;
    });

    builder.addCase(createTargetGroup.pending, (state, action) => {
      state.thunks.createTargetGroup.isLoading = true;
      state.thunks.createTargetGroup.isSuccess = false;
      state.thunks.createTargetGroup.error = {};
      state.thunks.createTargetGroup.isError = false;
    });
    builder.addCase(createTargetGroup.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.thunks.createTargetGroup.isLoading = false;
      state.thunks.createTargetGroup.isSuccess = true;
      state.thunks.createTargetGroup.error = {};
      state.thunks.createTargetGroup.isError = false;
    });
    builder.addCase(createTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.createTargetGroup.error = error;
      state.thunks.createTargetGroup.isError = true;
      state.thunks.createTargetGroup.isLoading = false;
    });

    builder.addCase(updateTargetGroup.pending, (state, action) => {
      state.thunks.updateTargetGroup.isLoading = true;
      state.thunks.updateTargetGroup.isSuccess = false;
      state.thunks.updateTargetGroup.error = {};
      state.thunks.updateTargetGroup.isError = false;
    });
    builder.addCase(updateTargetGroup.fulfilled, (state, action) => {
      const currentTargetGroup = action.payload;
      state.currentTargetGroup = currentTargetGroup;
      state.currentTargetGroup.emailSequences = [...(currentTargetGroup.emailSequences || [])];
      state.thunks.updateTargetGroup.isLoading = false;
      state.thunks.updateTargetGroup.isSuccess = true;
      state.thunks.updateTargetGroup.error = {};
      state.thunks.updateTargetGroup.isError = false;
    });
    builder.addCase(updateTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.updateTargetGroup.error = error;
      state.thunks.updateTargetGroup.isError = true;
      state.thunks.updateTargetGroup.isLoading = false;
    });

    builder.addCase(deleteTargetGroup.pending, (state, action) => {
      state.thunks.deleteTargetGroup.isLoading = true;
      state.thunks.deleteTargetGroup.isSuccess = false;
      state.thunks.deleteTargetGroup.error = {};
      state.thunks.deleteTargetGroup.isError = false;
    });
    builder.addCase(deleteTargetGroup.fulfilled, (state, action) => {
      state.thunks.deleteTargetGroup.isLoading = false;
      state.thunks.deleteTargetGroup.isSuccess = true;
      state.thunks.deleteTargetGroup.error = {};
      state.thunks.deleteTargetGroup.isError = false;
    });
    builder.addCase(deleteTargetGroup.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.deleteTargetGroup.error = error;
      state.thunks.deleteTargetGroup.isError = true;
      state.thunks.deleteTargetGroup.isLoading = false;
    });

    builder.addCase(fetchCampaigns.pending, (state, action) => {
      state.thunks.fetchCampaigns.isLoading = true;
      state.thunks.fetchCampaigns.isSuccess = false;
      state.thunks.fetchCampaigns.error = {};
      state.thunks.fetchCampaigns.isError = false;
    });
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      const campaignsArray = action.payload;
      state.campaigns = campaignsArray;
      state.thunks.fetchCampaigns.isLoading = false;
      state.thunks.fetchCampaigns.isSuccess = true;
      state.thunks.fetchCampaigns.error = {};
      state.thunks.fetchCampaigns.isError = false;
    });
    builder.addCase(fetchCampaigns.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.fetchCampaigns.error = error;
      state.thunks.fetchCampaigns.isError = true;
      state.thunks.fetchCampaigns.isLoading = false;
    });

    generateBaseThunkReducers(
      builder,
      getExampleCompanies,
      'getExampleCompanies',
      'exampleCompanies',
    );

    generateBaseThunkReducers(builder, runPrompt, 'runPrompt', 'exampleCompanies');

    builder.addCase(updateExampleCompany.pending, (state, action) => {
      state.thunks.modifyExampleCompany.isLoading = true;
      state.thunks.modifyExampleCompany.isSuccess = false;
      state.thunks.modifyExampleCompany.error = {};
      state.thunks.modifyExampleCompany.isError = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = action.meta.arg.exampleCompanyId;
    });
    builder.addCase(
      updateExampleCompany.fulfilled,
      (state, action: PayloadAction<IExampleCompany>) => {
        const company = action.payload;
        const idxToUpdate = state.exampleCompanies.findIndex(item => item.id === company.id);
        state.exampleCompanies[idxToUpdate] = company;
        state.thunks.modifyExampleCompany.isLoading = false;
        state.thunks.modifyExampleCompany.isSuccess = true;
        state.thunks.modifyExampleCompany.error = {};
        state.thunks.modifyExampleCompany.isError = false;
        state.thunks.modifyExampleCompany.companyTargetGroupId = null;
      },
    );
    builder.addCase(updateExampleCompany.rejected, (state, action) => {
      const error = action.payload;
      state.thunks.modifyExampleCompany.error = error;
      state.thunks.modifyExampleCompany.isError = true;
      state.thunks.modifyExampleCompany.isLoading = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = action.meta.arg.exampleCompanyId;
    });

    builder.addCase(addExampleCompany.pending, (state, action) => {
      state.thunks.modifyExampleCompany.isLoading = true;
      state.thunks.modifyExampleCompany.isSuccess = false;
      state.thunks.modifyExampleCompany.error = {};
      state.thunks.modifyExampleCompany.isError = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = null;
    });
    builder.addCase(addExampleCompany.fulfilled, (state, action) => {
      const company = action.payload;
      state.exampleCompanies.push(company);
      state.thunks.modifyExampleCompany.isLoading = false;
      state.thunks.modifyExampleCompany.isSuccess = true;
      state.thunks.modifyExampleCompany.error = {};
      state.thunks.modifyExampleCompany.isError = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = null;
    });
    builder.addCase(addExampleCompany.rejected, (state, action) => {
      const error = action.payload;
      state.thunks.modifyExampleCompany.error = error;
      state.thunks.modifyExampleCompany.isError = true;
      state.thunks.modifyExampleCompany.isLoading = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = null;
    });

    builder.addCase(deleteExampleCompany.pending, (state, action) => {
      state.thunks.modifyExampleCompany.isLoading = true;
      state.thunks.modifyExampleCompany.isSuccess = false;
      state.thunks.modifyExampleCompany.error = {};
      state.thunks.modifyExampleCompany.isError = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = action.meta.arg.exampleCompanyId;
    });
    builder.addCase(deleteExampleCompany.fulfilled, (state, action) => {
      const ctgId = action.meta.arg.exampleCompanyId;
      state.exampleCompanies = state.exampleCompanies.filter(company => company.id !== ctgId);
      state.thunks.modifyExampleCompany.isLoading = false;
      state.thunks.modifyExampleCompany.isSuccess = true;
      state.thunks.modifyExampleCompany.error = {};
      state.thunks.modifyExampleCompany.isError = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = null;
    });
    builder.addCase(deleteExampleCompany.rejected, (state, action) => {
      const error = action.payload;
      state.thunks.modifyExampleCompany.error = error;
      state.thunks.modifyExampleCompany.isError = true;
      state.thunks.modifyExampleCompany.isLoading = false;
      state.thunks.modifyExampleCompany.companyTargetGroupId = action.meta.arg.exampleCompanyId;
    });

    builder.addCase(
      toggleFavouritePrompt.fulfilled,
      (state, action: PayloadAction<ITargetGroup>) => {
        const targetGroup = action.payload;
        state.currentTargetGroup.favouritePromptId = targetGroup.favouritePromptId;
      },
    );

    builder.addCase(addPrompt.fulfilled, (state, action: PayloadAction<IPrompt>) => {
      const prompt = action.payload;
      state.prompts.push(prompt);
    });

    builder.addCase(updatePrompt.fulfilled, (state, action: PayloadAction<IPrompt>) => {
      const prompt = action.payload;
      const idxToUpdate = state.prompts.findIndex(item => item.id === prompt.id);
      state.prompts[idxToUpdate] = prompt;
    });

    builder.addCase(deletePrompt.fulfilled, (state, action) => {
      const { promptId } = action.meta.arg;
      state.prompts = state.prompts.filter(prompt => prompt.id !== promptId);
    });

    builder.addCase(generateKeywords.pending, (state, action) => {
      state.thunks.generateKeywords.isLoading = true;
      state.thunks.generateKeywords.isSuccess = false;
      state.thunks.generateKeywords.error = {};
      state.thunks.generateKeywords.isError = false;
    });
    builder.addCase(generateKeywords.fulfilled, (state, action) => {
      const keywords = action.payload;
      state.currentTargetGroup.keywords = keywords;
      state.thunks.generateKeywords.isLoading = false;
      state.thunks.generateKeywords.isSuccess = true;
      state.thunks.generateKeywords.error = {};
      state.thunks.generateKeywords.isError = false;
    });
    builder.addCase(generateKeywords.rejected, (state, action: any) => {
      const error = action.payload;
      state.thunks.generateKeywords.error = error;
      state.thunks.generateKeywords.isError = true;
      state.thunks.generateKeywords.isLoading = false;
    });
    builder.addCase(fetchClassificationKpi.fulfilled, (state, action) => {
      state.classificationKpi = action.payload;
    });
    builder.addCase(fetchClassificationKpi.rejected, (state, action) => {
      state.classificationKpi = defaultClassificationKpi;
    });

    builder.addCase(batchCrawlExampleCompanies.pending, (state, action) => {
      state.thunks.batchCrawlExampleCompanies.isLoading = true;
      state.thunks.batchCrawlExampleCompanies.isSuccess = false;
      state.thunks.batchCrawlExampleCompanies.error = {};
      state.thunks.batchCrawlExampleCompanies.isError = false;
    });
    builder.addCase(batchCrawlExampleCompanies.fulfilled, (state, action) => {
      state.thunks.batchCrawlExampleCompanies.isLoading = false;
      state.thunks.batchCrawlExampleCompanies.isSuccess = true;
      state.thunks.batchCrawlExampleCompanies.error = {
        message: `Failed to crawl: ${action.payload.map((exampleCompany: IExampleCompany) => exampleCompany.name)}`,
      };
      state.thunks.batchCrawlExampleCompanies.isError = action.payload.length > 0;
    });
    builder.addCase(fetchTargetGroupAnalytics.pending, state => {
      state.thunks.fetchTargetGroupAnalytics.isLoading = true;
      state.thunks.fetchTargetGroupAnalytics.isSuccess = false;
      state.thunks.fetchTargetGroupAnalytics.error = {};
      state.thunks.fetchTargetGroupAnalytics.isError = false;
    });
    builder.addCase(fetchTargetGroupAnalytics.fulfilled, (state, action) => {
      state.analytics = action.payload;
      state.thunks.fetchTargetGroupAnalytics.isLoading = false;
      state.thunks.fetchTargetGroupAnalytics.isSuccess = true;
      state.thunks.fetchTargetGroupAnalytics.error = {};
      state.thunks.fetchTargetGroupAnalytics.isError = false;
    });
    builder.addCase(fetchTargetGroupAnalytics.rejected, (state, action) => {
      state.thunks.fetchTargetGroupAnalytics.isLoading = false;
      state.thunks.fetchTargetGroupAnalytics.isSuccess = false;
      state.thunks.fetchTargetGroupAnalytics.error = action.payload;
      state.thunks.fetchTargetGroupAnalytics.isError = true;
    });
  },
});

export const {
  setCurrentTargetGroupParams,
  addNewEmailSequenceInCurrentTargetGroup,
  setSelectedEmailSequenceIndex,
  removeNewEmailSequenceInCurrentTargetGroup,
  setCampaignOwner,
  resetTargetGroupState,
  resetDeleteTargetGroupThunk,
  setCompanyCountFilter,
  resetFindPeopleThunk,
  resetPushToReviewThunk,
} = targetGroupSlice.actions;

// export const targetGroupReducer = persistReducer(
//   {
//     key: 'targetGroup',
//     storage,
//     whitelist: ['currentTargetGroup'],
//   },
//   targetGroupSlice.reducer,
// );

export const targetGroupReducer = targetGroupSlice.reducer;
