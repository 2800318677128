import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Autocomplete, Chip, InputLabel, InputLabelProps, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { IOption } from './autocomplete-with-multiple-choice-with-sublabel.types';

export const AutocompleteContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .autocomplete-validation-helper': {
    margin: 0,
    paddingInlineStart: '20px',

    li: {
      p: {
        fontSize: '0.65rem',
        color: theme.palette.text.error,

        [theme.breakpoints.up('xl')]: {
          fontSize: '0.8rem',
        },
      },
    },
  },
}));

export const Label = styled(InputLabel)<InputLabelProps>(({ theme }) => ({
  width: '100%',
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '130%',
  padding: 0,
  marginBottom: '0.5rem',

  '&.Mui-focused': {
    color: theme.palette.neutrals.gray.highest,
  },

  '&.Mui-error': {
    color: theme.palette.neutrals.gray.highest,
  },
}));

export const StyledAutocomplete = styled(Autocomplete<IOption, true, true, false>)(({ theme }) => ({
  display: 'flex',
  minHeight: '36px',
  padding: '0.375rem 0.75rem',
  alignItems: 'center',
  gap: '0.5rem',
  alignSelf: 'stretch',
  borderRadius: '0.25rem',
  border: '1px solid',
  borderColor: theme.palette.neutrals.gray.low,
  background: theme.palette.neutrals.gray.lowest,
}));

export const Input = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    color: theme.palette.common.white,
    fontSize: '0.8rem',

    display: 'flex',
    padding: '0.375rem 0.75rem',
    alignItems: 'center',
    gap: '0.5rem',
    alignSelf: 'stretch',
    borderRadius: '0.25rem',
    border: '1px solid',
    borderColor: theme.palette.neutrals.gray.low,
    background: theme.palette.neutrals.gray.lowest,
    minHeight: 'auto',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      top: 0,
    },
  },

  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: 0,
    minWidth: '100px',
  },

  '& .MuiInputBase-input': {
    color: theme.palette.neutrals.gray.highest,
    fontFamily: '"Darker Grotesque", sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '130%',
    minWidth: '100px',
    padding: 0,
  },

  '&.Mui-error': {
    color: '#ea4c89',
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  padding: '0.25rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.125rem',
  marginTop: '0.25rem',
  background: theme.palette.neutrals.gray.lowest,
  border: '1px solid',
  borderColor: theme.palette.neutrals.gray.low,
  opacity: 0,

  animation: 'reveal 0.2s ease forwards',

  borderRadius: '0.375rem',
  boxShadow: '0px 0px 1px 0px rgba(9, 9, 11, 0.10), 0px 7px 22px 0px rgba(9, 9, 11, 0.10)',

  '& > ul': {
    width: '100%',
    padding: 0,

    '& .MuiAutocomplete-option': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.125rem 0.625rem 0.125rem 1.375rem',
      borderRadius: '5px',

      transition: 'all 0.2s ease',

      color: theme.palette.neutrals.gray.high,
      fontFamily: '"Darker Grotesque", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '130%',

      gap: '0.625rem',

      '&:hover': { background: theme.palette.neutrals.gray.lower },

      '.secondary': {
        color: theme.palette.neutrals.gray.high,
        textAlign: 'right',
        fontFamily: '"DM Mono", serif',
        fontSize: '0.625rem',
        fontWeight: 400,
        lineHeight: '170%',
        textTransform: 'uppercase',
      },
    },
  },

  '&:has(.MuiAutocomplete-noOptions)': {
    display: 'none',
  },

  '@keyframes reveal': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.125rem 0.25rem 0.125rem 0.5rem',
  gap: '0.25rem',
  borderRadius: '100px',
  background: theme.palette.neutrals.gray.lower,
  margin: 0,
  height: 'auto',

  color: theme.palette.neutrals.gray.high,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.5rem',
  fontWeight: 400,
  lineHeight: '170%',
  textTransform: 'uppercase',
  '&.MuiAutocomplete-tag': { margin: 0 },
  '& .MuiChip-label': { padding: 0 },
  '& .MuiChip-deleteIcon': {
    margin: 0,
    fontSize: '0.75rem',
    color: theme.palette.neutrals.gray.high,
    transition: 'color 0.2s ease',
    '&:hover': { color: theme.palette.neutrals.gray.higher },
  },
}));

export const StyledDeleteIcon = styled(CloseRoundedIcon)(() => ({
  width: '0.75rem',
  height: '0.75rem',
}));
