import { styled } from '@mui/material/styles';

import BaseInput from '../../../../shared/components/input/base-input/base-input.component';
import BaseTextarea from '../../../../shared/components/textarea/base-textarea/base-textarea.component';

export const TargetDefinitionFormBaseInput = styled(BaseTextarea)(() => ({
  '&.target-definition-form-input': {
    '& .MuiInputBase-root': {
      padding: '10px 0',

      '& textarea': { padding: '0 0.75rem' },
    },
  },
}));

export const TargetDefinitionInput = styled(BaseInput)(({ theme }) => ({
  '&.target-definition-form-input': {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        paddingLeft: '0,75rem',
        border: `1px solid ${theme.palette.neutrals.gray.low}`,
      },
    },
  },
}));
