import { styled } from '@mui/material/styles';

import BaseTextarea from '../../../shared/components/textarea/base-textarea/base-textarea.component';

export const FetchTargetGroupsListContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  padding: '0.875rem',

  borderRadius: '.5rem',
  border: '1px solid',
  borderColor: theme.palette.neutrals.gray.low,
  backgroundColor: theme.palette.neutrals.gray.lowest,

  '.select-target-group-wrapper': {
    height: '100%',
  },

  '.select-target-group-wrapper > .select-target-group': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    paddingBottom: '1rem',
    overflow: 'auto',
    height: '96%',

    '& a': {
      justifyContent: 'space-between',

      '.left-panel-list-link-title': {
        width: '94%',
      },
    },
  },

  '& a > span': {
    color: theme.palette.neutrals.gray.medium,
  },
}));

export const FetchTargetGroupsListActionTopWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
}));

export const FetchTargetGroupsListActionBottomWrapper = styled('div')(() => ({
  flex: 1,
  justifyContent: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
}));

export const FetchTargetGroupsHeader = styled('div')(() => ({
  height: '2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 .5rem',
}));

export const FetchTargetGroupsListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.neutrals.gray.low,

  '&::before, &::after': {
    content: "''",
    position: 'absolute',
    left: 0,
    right: 0,
    height: '6rem',
    pointerEvents: 'none',
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
    opacity: 1,
    transition: 'opacity 0.2s ease',
    zIndex: 1,
  },

  '&::after': {
    bottom: 0,
    background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
  },

  '&.no-gradient-top::before': {
    opacity: 0,
  },

  '&.no-gradient-bottom::after': {
    opacity: 0,
  },
}));

export const LogoImage = styled('img')(() => ({
  width: '80px',
  objectFit: 'contain',
}));

export const LeftPanelIcon = styled('img')(() => ({
  cursor: 'pointer',
  width: '1rem',
  height: '1rem',
}));

export const SearchInput = styled(BaseTextarea)(({ theme }) => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.neutrals.gray.lower,
  },

  input: {
    fontFamily: 'DM Mono',
    fontSize: '0.75rem !important',
    fontWeight: '400 !important',

    '&[placeholder="SEARCH TARGET GROUPS..."]': {
      textAlign: 'center',

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.5rem !important',
      },
    },
  },
}));
