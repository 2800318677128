import React, { PropsWithChildren, useState } from 'react';

import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';
import BaseTooltip from '../../tooltip/base-tooltip.component';
import {
  additionalBaseInputStyles,
  defaultBaseInputStyleSchema,
  FormControlContainer,
  InfoIcon,
  Input,
  InputIcon,
  Label,
} from './base-input.styles';
import { IBaseInputProps, InputInfoUiType } from './base-input.types';
import PasswordVisibilitySwitcher from './password-visibility-switcher/password-visibility-switcher.component';

function BaseInput<T extends { [key: string]: any }>({
  id,
  className,
  title,
  label,
  name,
  type,
  handleChange,
  values,
  errors,
  styleSchema,
  isLabelShown,
  isPlaceholderShown,
  infoUiType,
  isPasswordField,
  autoFocus,
  disabled,
  mode = 'light',
  inputProps = {},
  onFocus,
  showInfoIcon = false,
  isInfoIconActive = false,
  placeholder,
}: PropsWithChildren<IBaseInputProps<T>> & { inputProps?: any }): JSX.Element {
  const [passwordInputType, setPasswordInputType] = useState('password');

  const { tooltipStyleSchema } = styleSchema || defaultBaseInputStyleSchema; // do i need this?

  const endAdornmentIcon = (
    <InputIcon position="end">
      <PasswordVisibilitySwitcher setPasswordInputType={setPasswordInputType} />
    </InputIcon>
  );

  return (
    <FormControlContainer error={errors?.[name]?.hasError} className={className}>
      {isLabelShown ? (
        <Label
          mode={mode}
          shrink
          htmlFor={id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {label ?? ''}
          {showInfoIcon && (
            <InfoIcon src={`/icons/info-circle-${isInfoIconActive ? 'fill' : 'outline'}.svg`} />
          )}
        </Label>
      ) : null}
      <BaseTooltip
        arrow
        title={<ValidationErrorsHelper errors={errors} name={name} id={id} />}
        placement={
          tooltipStyleSchema?.placement || defaultBaseInputStyleSchema.tooltipStyleSchema.placement
        }
        open={infoUiType === InputInfoUiType.TOOLTIP ? errors?.[name]?.hasError : false}
        styleSchema={
          tooltipStyleSchema?.styleSchema ||
          defaultBaseInputStyleSchema.tooltipStyleSchema.styleSchema
        }
        transitionComponent={
          tooltipStyleSchema?.transition ||
          defaultBaseInputStyleSchema.tooltipStyleSchema.transition
        }
      >
        <Input
          id={id}
          mode={mode}
          aria-describedby={id}
          type={isPasswordField ? passwordInputType : type}
          name={name}
          placeholder={placeholder || (isPlaceholderShown ? title : undefined)}
          value={values[name]}
          onChange={handleChange}
          onFocus={onFocus}
          isPasswordField={isPasswordField || false}
          endAdornment={isPasswordField ? endAdornmentIcon : null}
          inputProps={{
            sx: isPlaceholderShown ? additionalBaseInputStyles() : null,
            ...inputProps,
          }}
          required
          autoFocus={autoFocus || false}
          disabled={disabled || false}
        />
      </BaseTooltip>
      {infoUiType === InputInfoUiType.FORM_HELPER ? (
        <ValidationErrorsHelper errors={errors} name={name} id={id} />
      ) : null}
    </FormControlContainer>
  );
}

export default BaseInput;
