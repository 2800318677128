import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const PipelineNavigationWrapper = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const PipelineNavigationContainer = styled(Stack)<StackProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.625rem',

  '> button': {
    margin: 0,
    minWidth: '100px',
    width: 'unset',
    flex: 1,
    '&:not(style)+:not(style)': { margin: 0 },
  },
}));

export const StyledPipelineNavigationElement = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  paddingTop: '.5rem',
  cursor: 'pointer',
  borderTop: '1px solid',
  borderTopColor: theme.palette.neutrals.gray.highest,
}));
