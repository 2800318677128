/**
 * Extract headers from CSV content
 */
export const extractHeadersFromCSV = (csvContent: string): string[] => {
  const lines = csvContent.split('\n');
  if (lines.length === 0) return [];

  return parseCSVLine(lines[0]);
};

/**
 * Parse a CSV line correctly handling quoted fields
 */
const parseCSVLine = (line: string): string[] => {
  const result: string[] = [];
  let current = '';
  let inQuotes = false;

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"') {
      // Toggle quote state
      inQuotes = !inQuotes;
    } else if (char === ',' && !inQuotes) {
      // End of field, add to result
      result.push(current.trim());
      current = '';
    } else {
      // Add character to current field
      current += char;
    }
  }

  // Add the last field
  if (current.length > 0) {
    result.push(current.trim());
  }

  // Clean quotes from fields
  return result.map(field => field.replace(/^"(.*)"$/, '$1').trim());
};

/**
 * Extract sample data for each header from the CSV, searching multiple rows for non-empty values
 * Uses proper CSV parsing to handle quotes correctly
 */
export const extractSampleDataFromCSV = (csvContent: string): Record<string, string> => {
  const lines = csvContent.split('\n').filter(line => line.trim() !== '');
  if (lines.length < 2) return {}; // Need at least headers and one data row

  // Parse headers
  const headers = parseCSVLine(lines[0]);

  // Create map of header -> sample data
  const sampleData: Record<string, string> = {};

  // Maximum number of rows to check
  const MAX_ROWS_TO_CHECK = 500;
  const rowsToCheck = Math.min(MAX_ROWS_TO_CHECK, lines.length - 1);

  // For each header, look through rows until we find a non-empty value
  headers.forEach((header, headerIndex) => {
    // Check each data row until we find a non-empty value
    for (let rowIndex = 1; rowIndex <= rowsToCheck; rowIndex++) {
      const dataRow = parseCSVLine(lines[rowIndex]);

      if (
        headerIndex < dataRow.length &&
        dataRow[headerIndex] &&
        dataRow[headerIndex].trim() !== ''
      ) {
        sampleData[header] = dataRow[headerIndex];
        break; // Found a value, no need to check more rows for this header
      }
    }
  });

  return sampleData;
};

/**
 * Extract sample data specifically tailored for contact fields in the CSV
 * This creates a map using contact base names (like "Contact Name") as keys
 * so that the correct sample can be shown for missing headers too
 * Checks multiple rows to find non-empty values
 */
export const extractContactFieldSamples = (
  csvContent: string,
  contactFieldPatterns: string[],
): Record<string, string> => {
  const lines = csvContent.split('\n').filter(line => line.trim() !== '');
  if (lines.length < 2) return {}; // Need at least headers and one data row

  // Parse headers
  const headers = parseCSVLine(lines[0]);

  // Create a map of base header -> sample data
  const contactSamples: Record<string, string> = {};

  // Maximum number of rows to check
  const MAX_ROWS_TO_CHECK = 5;
  const rowsToCheck = Math.min(MAX_ROWS_TO_CHECK, lines.length - 1);

  // Find examples for each contact pattern by checking multiple rows
  contactFieldPatterns.forEach(basePattern => {
    // Find all header indices that match this pattern
    const matchingIndices: number[] = [];
    headers.forEach((header, index) => {
      const regex = new RegExp(`^${basePattern} \\d+$`);
      if (regex.test(header)) {
        matchingIndices.push(index);
      }
    });

    // If we found matching headers, look for sample data
    if (matchingIndices.length > 0) {
      let foundSample = false;

      // Check each data row until we find non-empty values
      for (let rowIndex = 1; rowIndex <= rowsToCheck && !foundSample; rowIndex++) {
        const dataRow = parseCSVLine(lines[rowIndex]);

        // Check each matching index in this row
        for (const headerIndex of matchingIndices) {
          if (
            headerIndex < dataRow.length &&
            dataRow[headerIndex] &&
            dataRow[headerIndex].trim() !== ''
          ) {
            // Store sample data for both the full header and base pattern
            contactSamples[headers[headerIndex]] = dataRow[headerIndex];
            contactSamples[basePattern] = dataRow[headerIndex];
            foundSample = true;
            break;
          }
        }
      }
    }
  });

  return contactSamples;
};

/**
 * Check if a header matches any of the contact field patterns
 */
export const isDynamicContactField = (header: string, contactFieldPatterns: string[]): boolean => {
  for (const pattern of contactFieldPatterns) {
    const regex = new RegExp(`^${pattern} \\d+$`);
    if (regex.test(header)) {
      return true;
    }
  }
  return false;
};

/**
 * Categorize headers into required, missing, extra, and contacts
 */
export const categorizeHeaders = (
  headers: string[],
  expectedHeaders: string[],
  contactFieldPatterns: string[],
) => {
  const required = headers.filter(h => expectedHeaders.includes(h));
  const missing = expectedHeaders.filter(header => !headers.includes(header));
  const extra = headers.filter(
    h => !expectedHeaders.includes(h) && !isDynamicContactField(h, contactFieldPatterns),
  );
  const contacts = headers.filter(h => isDynamicContactField(h, contactFieldPatterns));

  // Count unique contact indices rather than raw fields
  const contactIndices = new Set<number>();
  contacts.forEach(header => {
    // Extract the index number from the contact field (e.g., "Contact Name 0" -> 0)
    const match = header.match(/\d+$/);
    if (match) {
      contactIndices.add(parseInt(match[0], 10));
    }
  });

  return {
    required,
    missing,
    extra,
    contacts,
    contactCount: contactIndices.size,
  };
};

/**
 * Update a CSV header in the content
 */
export const updateCsvHeader = (
  csvContent: string,
  oldHeader: string,
  newHeader: string,
): string => {
  const lines = csvContent.split('\n');
  if (lines.length === 0) return csvContent;

  // Use our robust parsing to get headers
  const headers = parseCSVLine(lines[0]);
  const headerIdx = headers.findIndex(h => h === oldHeader);

  if (headerIdx >= 0) {
    // Create a new header line with the updated header
    const rawHeaders = lines[0].split(',');

    // Check if the original header had quotes
    if (
      rawHeaders[headerIdx].trim().startsWith('"') &&
      rawHeaders[headerIdx].trim().endsWith('"')
    ) {
      rawHeaders[headerIdx] = `"${newHeader}"`;
    } else {
      rawHeaders[headerIdx] = newHeader;
    }

    lines[0] = rawHeaders.join(',');
    return lines.join('\n');
  }

  return csvContent;
};

/**
 * Save a CSV file to disk
 */
export const saveCSVFile = (csvContent: string, originalFileName: string): void => {
  // Create a Blob with the updated CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a download link
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  // Set download filename - add 'edited' to the original name
  const filenameParts = originalFileName.split('.');
  const extension = filenameParts.pop() || 'csv';
  const baseName = filenameParts.join('.');
  const newFilename = `${baseName}_edited.${extension}`;

  // Set link attributes and click it to trigger download
  link.setAttribute('href', url);
  link.setAttribute('download', newFilename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
