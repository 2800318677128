import { ReactNode } from 'react';

import { HeaderType } from './header-badge';

interface HeadersSectionProps {
  title: string;
  icon?: ReactNode;
  subtitle?: string;
  headerType: HeaderType;
  isEmpty?: boolean;
  emptyMessage?: string;
  children: ReactNode;
}

const getSectionStyles = (headerType: HeaderType) => {
  switch (headerType) {
    case 'required':
      return 'text-slate-500';
    case 'missing':
      return 'text-red-500';
    case 'extra':
      return 'text-blue-500';
    case 'contact':
      return 'text-green-600';
  }
};

const HeadersSection = ({
  title,
  icon,
  subtitle,
  headerType,
  isEmpty,
  emptyMessage = 'No headers in this section',
  children,
}: HeadersSectionProps) => {
  const titleColor = getSectionStyles(headerType);

  return (
    <div className="space-y-2">
      <h4 className={`flex items-center gap-1 mb-2 text-sm font-medium ${titleColor}`}>
        {icon}
        {title}
        {subtitle && <span className="ml-1 font-normal text-opacity-70">{subtitle}</span>}
      </h4>

      {isEmpty ? (
        <div className="text-sm text-center text-muted-foreground py-2">{emptyMessage}</div>
      ) : (
        <div className="flex flex-wrap gap-2">{children}</div>
      )}
    </div>
  );
};

export default HeadersSection;
