import React from 'react';

import FetchTargetGroupsList from '../../../features/fetch-target-groups-list/components/fetch-target-groups-list.component';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import { MarketMappingLeftSectionContainer } from './market-mapping-left-section.styles';

function MarketMappingLeftSection() {
  return (
    <MarketMappingLeftSectionContainer>
      <FetchTargetGroupsList stageName={PipelineStageName.MARKET_MAPPING} />
    </MarketMappingLeftSectionContainer>
  );
}

export default MarketMappingLeftSection;
