import { Box, styled } from '@mui/material';

export const CompanyDetailsBoxContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1.25fr)',
  gap: '1rem',

  backgroundColor: theme.palette.neutrals.gray.lower,
  padding: '14px',
  border: `1px solid ${theme.palette.neutrals.gray.low}`,
  borderRadius: '8px',

  height: 'max-content',
}));

export const LeftColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '.75rem',
  height: '100%',
  width: '100%',
});

export const LeftColumnRow = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

export const RightColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '.5rem',
  height: '100%',
  width: '100%',
});

export const RightColumnRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
});
