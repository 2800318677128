import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const TargetDefinitionRightSectionFormContainer = styled(Container, {
  shouldForwardProp: props => props !== 'isTargetGroupChosen',
})<ContainerProps & { isTargetGroupChosen: boolean }>(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',

  '.target-definition-form-wrapper': {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1.25rem',
    rowGap: '2rem',
    alignItems: 'flex-end',
  },

  '.edit-save-button-container': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));
