import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';

import { InfoIcon, Label } from '../../../../shared/components/input/base-input/base-input.styles';
import { TargetGroupFormField } from '../target-definition-form.types';
import TargetDefinitionFormInput from '../target-definition-form-input/target-definition-form-input.component';

const HeadcountWarning = styled(Typography)<{ error?: boolean }>(({ theme, error }) => ({
  fontSize: '0.875rem',
  margin: 0,
  position: 'absolute',
  bottom: '-1.675rem',
  left: '.45rem',
  color: error ? theme.palette.error.main : theme.palette.neutrals.gray.medium,
}));

interface RangeInputProps {
  label: string;
  minFieldId: keyof typeof TargetGroupFormField;
  maxFieldId: keyof typeof TargetGroupFormField;
  minName: string;
  maxName: string;
  lastFocusedInput: string;
  handleInputFocus: (name: string, element: HTMLElement) => void;
  setLastFocusedElement: (element: HTMLElement) => void;
  values: Record<string, any>;
  minPlaceholder: string;
  maxPlaceholder: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function RangeInput({
  label,
  minFieldId,
  maxFieldId,
  minName,
  maxName,
  lastFocusedInput,
  handleInputFocus,
  setLastFocusedElement,
  values,
  handleInputChange,
  minPlaceholder,
  maxPlaceholder,
}: RangeInputProps) {
  const showHeadcountWarning = useMemo(() => {
    if (label.toLowerCase() === 'headcount *') {
      const inputValue = values[minName];
      return (
        inputValue !== undefined &&
        inputValue !== null &&
        inputValue !== '' &&
        Number(inputValue) < 5
      );
    }
    return false;
  }, [values, minName, label]);

  return (
    <Box
      sx={{
        position: 'relative',
        label: {
          width: 'max-content',
        },
        marginBottom: showHeadcountWarning ? '1.5rem' : '0',
      }}
    >
      <Label
        sx={{ position: 'absolute', top: '4px', right: 0, zIndex: 1000 }}
        htmlFor={lastFocusedInput === minName ? `8_${maxFieldId}` : `7_${minFieldId}`}
      >
        <InfoIcon
          src={`/icons/info-circle-${
            lastFocusedInput === minName || lastFocusedInput === maxName ? 'fill' : 'outline'
          }.svg`}
        />
      </Label>

      <div style={{ display: 'flex', alignItems: 'center', gap: 16, position: 'relative' }}>
        <TargetDefinitionFormInput
          id={`7_${minFieldId}`}
          name={minName}
          type="number"
          lastFocusedInput={lastFocusedInput}
          setLastFocusedInput={(name, id) => {
            const element = document.getElementById(id);
            if (element) {
              handleInputFocus(name, element);
              setLastFocusedElement(element);
            }
          }}
          values={values}
          isLabelShown
          label={`Minimum ${label}`}
          placeholder={minPlaceholder}
          handleChange={handleInputChange}
          min={0}
        />
        <Divider
          sx={{
            width: '12px',
            borderColor: 'neutrals.gray.low',
            position: 'relative',
            top: '1rem',
          }}
        />
        <TargetDefinitionFormInput
          id={`8_${maxFieldId}`}
          name={maxName}
          type="number"
          lastFocusedInput={lastFocusedInput}
          setLastFocusedInput={(name, id) => {
            const element = document.getElementById(id);
            if (element) {
              handleInputFocus(name, element);
              setLastFocusedElement(element);
            }
          }}
          values={values}
          isLabelShown
          label={`Maximum ${label}`}
          placeholder={maxPlaceholder}
          handleChange={handleInputChange}
          min={1}
        />
        {showHeadcountWarning && (
          <HeadcountWarning error={true}>Minimum headcount should be at least 5</HeadcountWarning>
        )}
      </div>
    </Box>
  );
}

export default RangeInput;
