import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const NoTargetGroupChosenScreenContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    width: '100%',
    height: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 3%',
    textAlign: 'center',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  }),
);

export const NoTargetGroupChosenMessage = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  color: theme.palette.neutrals.gray.highest,

  [theme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
  },
}));
