import { styled } from '@mui/material/styles';

import BaseButton from '../base-button/base-button.component';
import { ButtonV2Props } from './button-v2.types';

export const BaseButtonV2 = styled(BaseButton, {
  shouldForwardProp: props => props !== 'customVariant',
})<ButtonV2Props>(({ theme, customVariant }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: 'initial',
  height: 'initial',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.neutrals.gray.low}`,
  cursor: 'pointer',
  transition: 'all 0.2s',
  textTransform: 'uppercase',

  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.625rem',
  lineHeight: '170%',

  [theme.breakpoints.down('lg')]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '0.5rem',
  },

  '&:hover': {
    backgroundColor: theme.palette.neutrals.gray.lowest,
    color: theme.palette.neutrals.gray.medium,
  },

  '&.Mui-disabled': {
    opacity: 0.6,
    color: theme.palette.neutrals.gray.medium,
  },

  ...(customVariant === 'primary-large' && {
    borderRadius: '8px',
    padding: '14px 28px',
    backgroundColor: theme.palette.neutrals.gray.lowest,
    color: theme.palette.neutrals.gray.highest,
    '& .MuiCircularProgress-root': { color: theme.palette.neutrals.gray.highest },
    '&:hover': {
      backgroundColor: theme.palette.neutrals.gray.low,
      color: theme.palette.neutrals.gray.high,
    },
  }),
  ...(customVariant === 'secondary-large' && {
    borderRadius: '8px',
    padding: '14px 28px',
    border: `1px solid ${theme.palette.neutrals.gray.higher}`,
    backgroundColor: theme.palette.neutrals.gray.highest,
    color: theme.palette.neutrals.gray.lowest,
    '& .MuiCircularProgress-root': { color: theme.palette.neutrals.gray.lowest },
    '&:hover': {
      backgroundColor: theme.palette.neutrals.gray.high,
      color: theme.palette.neutrals.gray.low,
    },
  }),
  ...(customVariant === 'primary-small' && {
    borderRadius: '4px',
    padding: '8px 14px',
    backgroundColor: theme.palette.neutrals.gray.lowest,
    color: theme.palette.neutrals.gray.highest,
    '& .MuiCircularProgress-root': { color: theme.palette.neutrals.gray.highest },
    '&:hover': {
      backgroundColor: theme.palette.neutrals.gray.low,
      color: theme.palette.neutrals.gray.high,
    },
  }),
  ...(customVariant === 'secondary-small' && {
    borderRadius: '4px',
    padding: '8px 14px',
    border: `1px solid ${theme.palette.neutrals.gray.higher}`,
    backgroundColor: theme.palette.neutrals.gray.highest,
    color: theme.palette.neutrals.gray.lowest,
    '& .MuiCircularProgress-root': { color: theme.palette.neutrals.gray.lowest },
    '&:hover': {
      backgroundColor: theme.palette.neutrals.gray.medium,
      color: theme.palette.neutrals.gray.lowest,
    },
  }),
}));
