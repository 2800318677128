import { styled, Typography } from '@mui/material';

export const DrawerHeader = styled('div')(() => ({}));

export const Title = styled('h2')(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '2rem',
  fontWeight: 400,
  lineHeight: '80%',
  margin: 0,
  maxWidth: '95%',
}));

export const Subtitle = styled('h5')(({ theme }) => ({
  color: theme.palette.neutrals.gray.medium,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1.25rem',
  fontWeight: 400,
  lineHeight: '130%',
  margin: 0,
}));

export const SectionTitle = styled('h5')(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1.5rem',
  fontWeight: 400,
  lineHeight: '80%',
  margin: 0,
}));

export const SummaryContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.gray.medium,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '120%',
  margin: 0,
  overflowY: 'auto',
  paddingRight: '0.5rem',
}));

export const SummaryLink = styled('a')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  padding: '0.5rem 0.75rem 0.75rem 0.75rem',
  alignItems: 'flex-start',

  borderRadius: '0.5rem',
  background: theme.palette.neutrals.gray.lower,
  transition: 'background .3s ease-in-out',

  '&:hover': {
    background: theme.palette.neutrals.gray.low,
  },
}));

export const SummaryLinkTitle = styled('h5')(({ theme }) => ({
  color: theme.palette.neutrals.gray.medium,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.75rem',
  fontWeight: 300,
  lineHeight: '150%',
  margin: 0,
}));

export const SummaryLinkContent = styled('p')(({ theme }) => ({
  overflow: 'hidden',
  color: theme.palette.neutrals.gray.high,
  textOverflow: 'ellipsis',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '100%',
  margin: 0,
}));

export const RelatedNewDate = styled('p')(({ theme }) => ({
  color: theme.palette.neutrals.gray.medium,
  textAlign: 'right',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '120%',
  margin: 0,
}));

export const CompanyInformationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0.875rem',
  alignItems: 'flex-start',
  gap: '0.75rem',

  borderRadius: '0.5rem',
  border: '1px solid',
  borderColor: theme.palette.neutrals.gray.low,
  background: theme.palette.neutrals.gray.lower,
}));

export const CompanyInformationTitle = styled('h5')(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '110%',
  textTransform: 'uppercase',
  margin: 0,
}));

export const CompanyInformationValue = styled('p')(({ theme }) => ({
  color: theme.palette.neutrals.gray.medium,
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '120%',
  margin: 0,
}));
