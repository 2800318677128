import { UserRole } from '../../../../entities/user/domain/user.types';
import CountCompaniesToResearch from '../../../../features/count-pipeline-navigation-indicators/components/count-companies-to-research.component';
import CountCompaniesToReview from '../../../../features/count-pipeline-navigation-indicators/components/count-companies-to-review.component';
import CountPeopleResearchedCompanies from '../../../../features/count-pipeline-navigation-indicators/components/count-people-researched-companies.component';
import { NavigationPaths, Routes } from '../../../../shared/domain/constants/routing.constants';
import { IPipelineNavigationStrategy, PipelineStageName } from './pipeline-navigation.types';

export const pipelineNavigationStrategy = (clientId: string): IPipelineNavigationStrategy => {
  return {
    [PipelineStageName.TARGET_DEFINITION]: {
      stageName: PipelineStageName.TARGET_DEFINITION,
      navigateTo: NavigationPaths.TARGET_REQUIREMENTS(clientId),
      currentRoute: Routes.TARGET_DEFINITION,
      key: PipelineStageName.TARGET_DEFINITION,
    },
    // [PipelineStageName.MODEL_CALIBRATION]: {
    //   stageName: PipelineStageName.MODEL_CALIBRATION,
    //   navigateTo: NavigationPaths.MODEL_CALIBRATION(clientId),
    //   currentRoute: Routes.MODEL_CALIBRATION,
    //   key: PipelineStageName.MODEL_CALIBRATION,
    // },
    // [PipelineStageName.SELECTION_ENGINEERING]: {
    //   stageName: PipelineStageName.SELECTION_ENGINEERING,
    //   navigateTo: NavigationPaths.SELECTION_ENGINEERING(clientId),
    //   currentRoute: Routes.SELECTION_ENGINEERING,
    //   key: PipelineStageName.SELECTION_ENGINEERING,
    // },
    [PipelineStageName.MARKET_MAPPING]: {
      stageName: PipelineStageName.MARKET_MAPPING,
      navigateTo: NavigationPaths.MARKET_MAPPING(clientId),
      currentRoute: Routes.MARKET_MAPPING,
      key: PipelineStageName.MARKET_MAPPING,
    },
    [PipelineStageName.REVIEW]: {
      stageName: PipelineStageName.REVIEW,
      navigateTo: NavigationPaths.REVIEW(clientId),
      currentRoute: Routes.REVIEW,
      key: PipelineStageName.REVIEW,
      indicator: CountCompaniesToReview,
    },
    [PipelineStageName.INTELLIGENCE]: {
      stageName: PipelineStageName.INTELLIGENCE,
      navigateTo: NavigationPaths.INTELLIGENCE(clientId),
      currentRoute: Routes.INTELLIGENCE,
      key: PipelineStageName.INTELLIGENCE,
    },
  };
};

export const userRoleStageSkippingConfig: { [key in UserRole]: PipelineStageName[] } = {
  [UserRole.CLIENT]: [
    PipelineStageName.SELECTION_ENGINEERING,
    PipelineStageName.MARKET_MAPPING,
    PipelineStageName.COMPANY_RESEARCH,
    PipelineStageName.PEOPLE_RESEARCH,
  ],
  [UserRole.EMPLOYEE]: [],
};
