import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { UserRole } from '../../entities/user/domain/user.types';
import { userSelector } from '../../entities/user/store/user.selectors';
import { NavigationPaths } from '../../shared/domain/constants/routing.constants';
import { useAppSelector } from '../store/utils/redux.hooks';

function RequireNonAuthRoute({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useAuth0();
  const { roles, assignedClientId } = useAppSelector(userSelector);

  return !isAuthenticated || assignedClientId === null ? (
    children
  ) : (
    <Navigate
      to={
        roles?.includes(UserRole.EMPLOYEE)
          ? NavigationPaths.CLIENTS
          : NavigationPaths.TARGET_REQUIREMENTS(assignedClientId)
      }
      replace
    />
  );
}

export default RequireNonAuthRoute;
