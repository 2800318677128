import { PauseOutlined, PlayArrowOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import {
  ClassificationControlActions,
  SourceClassificationStatuses,
} from '../../../../entities/target-group/domain/target-group.types';
import {
  controlSourceClassification,
  getCompanySourceStatuses,
} from '../../../../entities/target-group/store/target-group.slice';
import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { IInfoConfig } from '../../../../shared/decorators/feedback-ui/feedback-ui.types';
import { ButtonClickEvent } from '../../../../shared/domain/types/html-elements.types';
import { usePolling } from '../../../../shared/hooks/use-pooling.hook';
import { RunClassificationButton } from './run-classification-on-source.styles';
import { IRunClassificationOnSourceProps } from './run-classification-on-source.types';

function RunClassificationOnSource({ source }: IRunClassificationOnSourceProps) {
  const [isPromptedToRun, setIsPromptedToRun] = useState(false);
  const dispatch = useAppDispatch();
  const isRunning = source.classificationStatus === SourceClassificationStatuses.running;
  const isPausing = source.classificationStatus === SourceClassificationStatuses.pausing;

  async function pollingFunction() {
    const response = await dispatch(
      getCompanySourceStatuses({ targetGroupId: source.targetGroupId }),
    ).unwrap();
    return response[source.id];
  }

  function shouldStopPolling(status: SourceClassificationStatuses) {
    if (
      [
        SourceClassificationStatuses.failed,
        SourceClassificationStatuses.done,
        SourceClassificationStatuses.paused,
      ].includes(status)
    ) {
      return true;
    }
    return false;
  }

  const { startPolling } = usePolling(pollingFunction, shouldStopPolling, 3000);

  useEffect(() => {
    if (isRunning || isPausing) {
      startPolling();
    }
  }, []);

  function handleRunButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToRun(true);
  }

  function handleAction() {
    try {
      setIsPromptedToRun(false);
      dispatch(
        controlSourceClassification({
          sourceId: source.id,
          action: isRunning ? ClassificationControlActions.pause : ClassificationControlActions.run,
        }),
      );
      startPolling();
    } catch (e) {
      console.error(e);
    }
  }

  function handleClose() {
    setIsPromptedToRun(false);
  }

  function handleCancel() {
    setIsPromptedToRun(false);
  }

  const fileName = source.name || source.url;
  const promptMessage = isRunning
    ? `Are you sure you want to pause the classification on the '${fileName}'?`
    : `Are you sure you want to run classification on the '${fileName}'?`;

  const promptModalConfig: IInfoConfig = {
    isInfo: isPromptedToRun,
    handleAction,
    handleClose,
    handleCancel,
    title: promptMessage,
    actionTitle: isRunning ? 'Pause' : 'Run',
  };

  function renderButtonIcon() {
    if (isPausing) {
      return <CircularProgress size={14} />;
    }
    if (isRunning) {
      return <PauseOutlined />;
    }
    return <PlayArrowOutlined />;
  }

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <RunClassificationButton
        onClick={handleRunButtonClick}
        size="small"
        disabled={isPausing}
        isRunning={isRunning}
      >
        {renderButtonIcon()}
      </RunClassificationButton>
    </FeedbackUiDecorator>
  );
}

export default RunClassificationOnSource;
