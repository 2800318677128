import { Box, Typography } from '@mui/material';
import React from 'react';

import { ILastFundraise } from '../../../../../../../entities/company-target-group/domain/company-target-group.types';
import { formatDate } from '../../../../../../intelligence-content/components/intelligence-content-detail/helpers/intelligence-content-detail.helpers';
import { CURRENCIES } from '../../../../../../target-definition-form/components/currency-input/currency-input.constants';
import DetailsSection from './details-section.component';

export default function LastFundraiseSection({
  lastFundraise,
}: {
  lastFundraise?: ILastFundraise;
}) {
  if (!lastFundraise) return null;

  const currencyCode = lastFundraise.currency ?? 'USD';

  return (
    <DetailsSection title="Last fundraise">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography color="neutrals.gray.high">
          {CURRENCIES.find(currency => currency.code === currencyCode)?.symbol}{' '}
          {lastFundraise.amount} -{lastFundraise.type}
        </Typography>
        <Typography color="neutrals.gray.high">{formatDate(lastFundraise.date)}</Typography>
      </Box>
    </DetailsSection>
  );
}
