import { Box } from '@mui/material';
import type { ColDef, ColGroupDef, RowClickedEvent } from 'ag-grid-enterprise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import RightSectionMain from '../../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import {
  IPEBacked,
  IVCBacked,
} from '../../../../entities/company-target-group/domain/company-target-group.types';
import {
  companiesForReviewSelector,
  companyTargetGroupThunksSelector,
  currentCompanyForReviewParamsSelector,
} from '../../../../entities/company-target-group/store/company-target-group.selectors';
import {
  countCompaniesToReview,
  getCompaniesForReviewByTargetGroupId,
  getCompanyForReview,
  setCurrentCompanyForReviewParams,
} from '../../../../entities/company-target-group/store/company-target-group.slice';
import { currentTargetGroupParamsSelector } from '../../../../entities/target-group/store/target-group.selectors';
import { deleteAllCompaniesByTargetGroupId } from '../../../../entities/target-group/store/target-group.slice';
import { UserRole } from '../../../../entities/user/domain/user.types';
import { userSelector } from '../../../../entities/user/store/user.selectors';
import { LinkCellRenderer } from '../../../../shared/components/table-v2/renderers/table-v2.renderers.component';
import TableV2 from '../../../../shared/components/table-v2/table-v2.component';
import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { useModal } from '../../../../shared/hooks/use-modal.hook';
import { SearchInput } from '../../../search-form/components/search-form-input/search-form-input.styles';
import ImportFileDialog from '../import-file-dialog/import-file-dialog.component';
import { CompanyDetailsPanel } from './company-details-panel/company-details-panel.component';
import { formatCurrencyValueForCell } from './company-details-panel/utils/financial-utils';
import NoCompanyChosenScreen from './no-company-chosen-screen/no-company-chosen-screen.component';
import { exportToCSV } from './review-main.helpers';
import {
  ButtonContainer,
  ExportButton,
  ImportButton,
  ReviewRightSectionFormContainer,
} from './review-main.styles';

const createColumnDefs = (): (ColDef<any, any> | ColGroupDef<any>)[] =>
  [
    { field: 'companyName', headerName: 'COMPANY' },
    { field: 'businessType', headerName: 'DESCRIPTION' },
    { field: 'headcount', headerName: 'HEADCOUNT' },
    { field: 'website', headerName: 'WEBSITE', cellRenderer: LinkCellRenderer },
    { field: 'location.city', headerName: 'CITY' },
    { field: 'location.state', headerName: 'STATE', hide: true },
    { field: 'location.country', headerName: 'COUNTRY' },
    { field: 'ownership', headerName: 'OWNERSHIP' },
    {
      field: 'revenue',
      headerName: 'REVENUE',
      hide: true,
      valueFormatter: formatCurrencyValueForCell,
    },
    {
      field: 'profit',
      headerName: 'PROFIT',
      hide: true,
      valueFormatter: formatCurrencyValueForCell,
    },
    {
      field: 'revenueEst',
      headerName: 'REVENUE (EST.)',
      hide: true,
      valueFormatter: formatCurrencyValueForCell,
    },
    {
      field: 'PEbackers',
      headerName: 'PE BACKERS',
      valueFormatter: (params: any) => {
        const { PEbackers } = params.data;

        return PEbackers?.map((backer: IPEBacked) => backer.firm).join(', ');
      },
    },
    {
      field: 'VCbackers',
      headerName: 'VC BACKERS',
      valueFormatter: (params: any) => {
        const { VCbackers } = params.data;

        return VCbackers?.map((backer: IVCBacked) => backer.firm).join(', ');
      },
    },
    {
      field: 'parentCompany',
      headerName: 'PARENT COMPANY',
      valueFormatter: (params: any) => params.data.parentCompany?.name,
    },
    { field: 'foundedYear', headerName: 'YEAR FOUNDED' },
    {
      field: 'sixMonthHeadcountGrowth',
      headerName: '6 MO HEADCOUNT GROWTH',
      valueFormatter: (params: any) => (params.value !== null ? `${params.value}%` : ''),
    },
    { field: 'linkedIn', headerName: 'LINKEDIN', cellRenderer: LinkCellRenderer },
    {
      field: 'explanation',
      headerName: 'ANALYSIS',
      valueFormatter: (params: any) => params.data.explanation?.body,
      hide: true,
    },
  ] as (ColDef<any, any> | ColGroupDef<any>)[];

function ReviewMain() {
  const [isClosing, setIsClosing] = useState(false);
  const { isOpened, handleClose, handleOpen } = useModal();
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector(userSelector);
  const currentCompanyForReviewParams = useAppSelector(currentCompanyForReviewParamsSelector);
  const { id: currentCompanyId } = currentCompanyForReviewParams;
  const isCompanySelected = Boolean(currentCompanyId);
  const [isPromptedToPurge, setIsPromptedToPurge] = useState(false);
  const [search, setSearch] = useState('');

  const columnDefsRef = useRef(createColumnDefs());

  const handleDetailsPanelClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setCurrentCompanyForReviewParams({ id: '', name: '' }));
      setIsClosing(false);
    }, 300);
  };

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const { isLoading: isCompanyForReviewLoading } = useAppSelector(
    companyTargetGroupThunksSelector,
  ).getCompanyForReview;
  const { isLoading: isCompaniesForReviewLoading } = useAppSelector(
    companyTargetGroupThunksSelector,
  ).getCompaniesForReviewByTargetGroupId;
  const companiesForReview = useAppSelector(companiesForReviewSelector);

  const { clientId } = useParams();

  const rowData = useMemo(
    () =>
      (companiesForReview.items ?? [])
        .filter(company => company.companyName.toLowerCase().includes(search.toLowerCase().trim()))
        .map(company => {
          const {
            id,
            companyName,
            headcount,
            website,
            linkedIn,
            location,
            foundedYear,
            sixMonthHeadcountGrowth,
            businessType,
            ownership,
            PEbackers,
            VCbackers,
            parentCompany,
            founderOwned,
            explanation,
            revenue,
            revenueEst,
            profit,
            revenueYear,
            profitYear,
            currency,
          } = company;

          return {
            id,
            companyName,
            businessType,
            headcount,
            location,
            website,
            linkedIn,
            ownership,
            foundedYear,
            sixMonthHeadcountGrowth,
            PEbackers,
            VCbackers,
            parentCompany,
            founderOwned,
            explanation,
            revenue,
            revenueYear,
            revenueEst,
            profit,
            profitYear,
            currency,
          };
        }),
    [companiesForReview.items, search],
  );

  const updateCompaniesForReviewCount = useCallback(() => {
    dispatch(countCompaniesToReview({ clientId }));
  }, [clientId, dispatch]);

  const handlePurgeData = async () => {
    await dispatch(deleteAllCompaniesByTargetGroupId({ targetGroupId }));
    if (targetGroupId && clientId) {
      dispatch(
        getCompaniesForReviewByTargetGroupId({
          targetGroupId,
          clientId,
        }),
      );
      updateCompaniesForReviewCount();
    }
  };

  const handleRowClick = async (event: RowClickedEvent) => {
    const clickedElement = event.event?.target as HTMLElement;
    if (clickedElement?.closest('a')) {
      return;
    }

    await dispatch(
      setCurrentCompanyForReviewParams({
        id: event.data.id,
        name: event.data.company,
      }),
    );
    await dispatch(getCompanyForReview({ clientId, companyTargetGroupId: event.data.id }));
  };

  const handlePurgeButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsPromptedToPurge(true);
  };

  const handlePurgeClose = () => {
    setIsPromptedToPurge(false);
  };

  const handlePurgeAction = async () => {
    await handlePurgeData();
    setIsPromptedToPurge(false);
  };

  const promptModalConfig = {
    isInfo: isPromptedToPurge,
    handleAction: handlePurgeAction,
    handleClose: handlePurgeClose,
    handleCancel: handlePurgeClose,
    title: 'Are you sure you want to remove all companies from this target group?',
    message: "You can't undo this action.",
    isActionLoading: false,
  };

  useEffect(() => {
    if (targetGroupId && clientId) {
      dispatch(
        getCompaniesForReviewByTargetGroupId({
          targetGroupId,
          clientId,
        }),
      );
      dispatch(setCurrentCompanyForReviewParams({ id: '', name: '' }));
      setIsClosing(false);
      updateCompaniesForReviewCount();
    }
  }, [clientId, targetGroupId, dispatch, updateCompaniesForReviewCount]);

  return (
    <ReviewRightSectionFormContainer>
      <RightSectionMain
        isListEntityChosen={!!targetGroupId}
        isDataLoading={isCompaniesForReviewLoading}
        content={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '.25rem',
                alignItems: 'center',
              }}
            >
              <SearchInput
                id="search-companies-review"
                name="search-companies-review"
                type="text"
                values={{ search }}
                handleChange={e => setSearch(e.target.value)}
                placeholder="Search companies"
                sx={theme => ({
                  flex: 1,
                  maxWidth: '324px',
                  input: { borderColor: `${theme.palette.neutrals.gray.low} !important` },
                })}
              />

              <Box sx={{ display: 'flex', gap: '.25rem' }}>
                <ButtonContainer>
                  {roles.includes(UserRole.EMPLOYEE) && (
                    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
                      <ImportButton onClick={handlePurgeButtonClick}>Purge data</ImportButton>
                    </FeedbackUiDecorator>
                  )}
                </ButtonContainer>

                <ButtonContainer>
                  {roles.includes(UserRole.EMPLOYEE) && (
                    <ImportButton onClick={handleOpen}>Import</ImportButton>
                  )}
                </ButtonContainer>

                <ButtonContainer>
                  <ExportButton onClick={() => exportToCSV(companiesForReview.items)}>
                    Export table <img src="/icons/export.svg" alt="export" />
                  </ExportButton>
                </ButtonContainer>
              </Box>
            </Box>

            <TableV2
              rowData={rowData}
              columnDefs={columnDefsRef.current}
              gridOptions={{
                onRowClicked: handleRowClick,
                suppressCellFocus: true,
                rowStyle: {
                  cursor: 'pointer',
                },
                loading: isCompaniesForReviewLoading,
                autoSizeStrategy: {
                  type: 'fitCellContents',
                  skipHeader: false,
                },
                onGridReady: params => {
                  params.api.sizeColumnsToFit();
                  params.api.autoSizeAllColumns();
                },
              }}
            />

            <CompanyDetailsPanel
              isCompanySelected={isCompanySelected}
              isClosing={isClosing}
              handleClose={handleDetailsPanelClose}
              isLoading={isCompanyForReviewLoading}
            />
          </Box>
        }
        noContent={<NoCompanyChosenScreen />}
      />

      <ImportFileDialog open={isOpened} onClose={handleClose} />
    </ReviewRightSectionFormContainer>
  );
}

export default ReviewMain;
