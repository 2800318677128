import { NotificationPreferences } from '../../../../entities/user/domain/user.types';
import { NotificationLabels } from './select-email-preference/select-email-preference.component';

export const DEFAULT_NOTIFICATION_SETTINGS = {
  email: '',
  preference: NotificationPreferences.weekly,
};

export const DROPDOWN_OPTIONS = [
  {
    id: NotificationPreferences.none,
    name: NotificationLabels[NotificationPreferences.none],
    values: {
      id: NotificationPreferences.none,
      name: NotificationLabels[NotificationPreferences.none],
    },
  },
  {
    id: NotificationPreferences.immediate,
    name: NotificationLabels[NotificationPreferences.immediate],
    values: {
      id: NotificationPreferences.immediate,
      name: NotificationLabels[NotificationPreferences.immediate],
    },
  },
  {
    id: NotificationPreferences.daily,
    name: NotificationLabels[NotificationPreferences.daily],
    values: {
      id: NotificationPreferences.daily,
      name: NotificationLabels[NotificationPreferences.daily],
    },
  },
  {
    id: NotificationPreferences.weekly,
    name: NotificationLabels[NotificationPreferences.weekly],
    values: {
      id: NotificationPreferences.weekly,
      name: NotificationLabels[NotificationPreferences.weekly],
    },
  },
];
