import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../app/store/utils/redux.hooks';
import {
  getTargetGroupById,
  setCurrentTargetGroupParams,
} from '../../entities/target-group/store/target-group.slice';

function TargetDefinitionPage() {
  const dispatch = useAppDispatch();
  const { targetGroupId, clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (targetGroupId) {
      dispatch(getTargetGroupById(targetGroupId))
        .unwrap()
        .then(targetGroup => {
          dispatch(setCurrentTargetGroupParams({ id: targetGroupId, name: targetGroup.name }));
          navigate(`/dashboard/pipeline/${clientId}/client-requirements`);
        })
        .catch(error => {
          console.error('Error fetching target group:', error);
          navigate(`/dashboard/pipeline/${clientId}/client-requirements`);
        });
    }
  }, [clientId, dispatch, navigate, targetGroupId]);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        flexDirection: 'column-reverse',
      }}
    >
      <Typography fontFamily="DM Mono" fontSize={20} color="#000000">
        Loading...
      </Typography>
      <CircularProgress color="inherit" size={20} />
    </Box>
  );
}

export default TargetDefinitionPage;
