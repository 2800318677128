import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const DeleteUploadedFileButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  width: '3.125rem',
  height: '2.25rem',
  borderRadius: '0.25rem',
  '> svg': {
    color: theme.palette.neutrals.gray.medium,
  },
}));
