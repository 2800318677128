import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { MuiButtonProps } from './base-button.types';

export const MuiButton = styled(Button, {
  shouldForwardProp: props => props !== 'isCurrentRoute',
})<MuiButtonProps>(({ theme }) => ({
  width: '160px',
  height: '40px',
  fontSize: '13px',

  '> .base-button-loader': {
    color: theme.palette.background.black,
    fontSize: '1rem',
  },
}));
