import { createSlice } from '@reduxjs/toolkit';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface LeftPanelState {
  isOpen: boolean;
}

const initialState: LeftPanelState = {
  isOpen: true,
};

const leftPanelSlice = createSlice({
  name: 'left-panel',
  initialState,
  reducers: {
    openMenu: state => {
      state.isOpen = true;
    },
    closeMenu: state => {
      state.isOpen = false;
    },
    toggleMenu: state => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openMenu, closeMenu, toggleMenu } = leftPanelSlice.actions;
const fieldsToSkip = ['isOpen'];
const excludeTransform = createTransform(
  (inboundState, key: any) => (fieldsToSkip.includes(key) ? undefined : inboundState),
  (outboundState, _) => outboundState,
);
export const leftPanelReducer = persistReducer(
  {
    key: 'left-panel',
    storage,
    transforms: [excludeTransform],
  },
  leftPanelSlice.reducer,
);
