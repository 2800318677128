import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { clientsSelector, userThunksSelector } from '../../../entities/user/store/user.selectors';
import { getAllClients } from '../../../entities/user/store/user.slice';
import { SearchInput } from '../../fetch-target-groups-list/components/fetch-target-groups-list.styles';
import SelectEntity from '../../select-entity/components/select-entity.component';
import { formSelectCardProps } from '../helpers/fetch-all-clients-list.helpers';
import ClientCard from './client-card/client-card.component';
import { FetchClientsListContainer } from './fetch-all-clients-list.styles';

function FetchAllClientsList() {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const clients = useAppSelector(clientsSelector);
  const { isLoading: areClientsLoading } = useAppSelector(userThunksSelector).getAllClients;

  const filteredClients = clients.filter(
    client =>
      client.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.id.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const clientsForSelect = formSelectCardProps(filteredClients);

  useEffect(() => {
    dispatch(getAllClients());
  }, [dispatch]);

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.target.value);
  }

  return (
    <FetchClientsListContainer className="select-client-wrapper">
      <div className="search-clients-wrapper">
        {clients && clients.length > 0 && (
          <SearchInput
            id="search-input"
            name="clients"
            placeholder="Search clients..."
            handleChange={handleSearch}
            values={{ clients: searchQuery }}
            className="search-clients"
            multiline={false}
          />
        )}
      </div>
      <SelectEntity
        entityData={clientsForSelect ?? []}
        isLoading={areClientsLoading}
        className="select-client"
        entityCard={ClientCard}
        noDataMessage="No clients found"
      />
    </FetchClientsListContainer>
  );
}

export default FetchAllClientsList;
