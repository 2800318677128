import { DROPDOWN_OPTIONS_OBJECT } from './add-list-dialog.constants';
import { FormValues, SourceType } from './add-list-dialog.types';

export interface ValidationRule {
  isValid: (values: FormValues, file: File | null, source: SourceType) => boolean;
  errorMessage: string;
}

export const validationRules: ValidationRule[] = [
  {
    isValid: (_values, file, source) => {
      if (source !== DROPDOWN_OPTIONS_OBJECT.APOLLO) {
        return Boolean(file?.name);
      }
      return true;
    },
    errorMessage: 'Please select a CSV file',
  },
  {
    isValid: (values, file, source) => {
      if (source === DROPDOWN_OPTIONS_OBJECT.APOLLO) {
        const url = values.link?.trim();
        try {
          if (!url) return false;
          const parsedUrl = new URL(url);
          return parsedUrl.hostname.includes('apollo.io');
        } catch {
          return false;
        }
      }
      return true;
    },
    errorMessage: 'Please enter a valid A* link',
  },
];

export const validateForm = (values: FormValues, file: File | null, source: SourceType) => {
  return validationRules
    .map(rule => (rule.isValid(values, file, source) ? false : rule.errorMessage))
    .filter((error): error is string => Boolean(error));
};
