import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const CreateTargetGroupWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  flexShrink: 0,

  '& > button ': {
    borderRadius: '.5rem',
    padding: '.75rem',
    fontSize: '0.75rem',

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.5rem',
    },
  },
}));
