import { RootState } from '../../../app/store/utils/redux.types';
import {
  CompanyTargetGroupsAnalyticsSortBy,
  IClientsCompaniesByTargetGroup,
  ICompaniesForResearchListResponse,
  ICompaniesForReviewListResponse,
  ICompanyForResearchState,
  ICompanyForReviewState,
  ICompanyNewsEventsState,
  ICompanyTargetGroupsAnalyticsKpiSet,
  ICompanyTargetGroupsForAnalytics,
  ICompanyTargetGroupSorting,
  ICompanyTargetGroupState,
  ICompanyTargetGroupThunks,
  ICountry,
  ICurrentCompanyForResearchParams,
  ICurrentCompanyForReviewParams,
  ICurrentResearchFilterCriterion,
  ICurrentReviewFilterCriterion,
  ICurrentWeekOfCompaniesCreation,
  IRelevantCompaniesNewsEventsState,
  IWeeklyIntervalsOfCompaniesCreationListResponse,
} from '../domain/company-target-group.types';

export function companyTargetGroupStateSelector(state: RootState): ICompanyTargetGroupState {
  return state.companyTargetGroup;
}

export function companyTargetGroupsForAnalyticsSelector(
  state: RootState,
): ICompanyTargetGroupsForAnalytics {
  return state.companyTargetGroup.companyTargetGroupsForAnalytics;
}

export function companyTargetGroupsAnalyticsKpiSetSelector(
  state: RootState,
): ICompanyTargetGroupsAnalyticsKpiSet {
  return state.companyTargetGroup.companyTargetGroupsAnalyticsKpiSet;
}

export function areCompanyTargetGroupsStatusesUpdatedSelector(state: RootState): boolean {
  return state.companyTargetGroup.areCompanyTargetGroupsStatusesUpdated;
}

export function clientsCompaniesListByTargetGroupSelector(
  state: RootState,
): IClientsCompaniesByTargetGroup {
  return state.companyTargetGroup.clientsCompaniesByTargetGroup;
}

export function companyTargetGroupsAnalyticsSortingSelector(
  state: RootState,
): ICompanyTargetGroupSorting<CompanyTargetGroupsAnalyticsSortBy> {
  return state.companyTargetGroup.companyTargetGroupsForAnalytics.sorting;
}

export function companyNewsEventsSelector(state: RootState): ICompanyNewsEventsState {
  return state.companyTargetGroup.companyNewsEvents;
}

export function relevantCompaniesNewsEventsSelector(
  state: RootState,
): IRelevantCompaniesNewsEventsState {
  return state.companyTargetGroup.relevantCompaniesNewsEvents;
}

export function currentWeekOfCompanyCreationSelector(
  state: RootState,
): ICurrentWeekOfCompaniesCreation {
  return state.companyTargetGroup.currentWeekOfCompanyCreation;
}

export function weeksOfCompaniesCreationSelector(
  state: RootState,
): IWeeklyIntervalsOfCompaniesCreationListResponse {
  return state.companyTargetGroup.weeklyIntervalsOfCompaniesCreation;
}

export function companyTargetGroupThunksSelector(state: RootState): ICompanyTargetGroupThunks {
  return state.companyTargetGroup.thunks;
}

export function currentCompanyForResearchParamsSelector(
  state: RootState,
): ICurrentCompanyForResearchParams {
  return state.companyTargetGroup.currentCompanyForResearchParams;
}

export function companiesForResearchSelector(state: RootState): ICompaniesForResearchListResponse {
  return state.companyTargetGroup.companiesForResearch;
}

export function currentResearchFilterCriterionSelector(
  state: RootState,
): ICurrentResearchFilterCriterion {
  return state.companyTargetGroup.currentResearchFilterCriterion;
}

export function companyForResearchSelector(state: RootState): ICompanyForResearchState {
  return state.companyTargetGroup.companyForResearch;
}

export function companiesToResearchCountSelector(state: RootState): number {
  return state.companyTargetGroup.companiesToResearchCount;
}

export function companiesToReviewCountSelector(state: RootState): number {
  return state.companyTargetGroup.companiesToReviewCount || 0;
}

export function peopleResearchedCompaniesCountSelector(state: RootState): number {
  return state.companyTargetGroup.peopleResearchedCompaniesCount || 0;
}

export function countriesSelector(state: RootState): ICountry[] {
  return state.companyTargetGroup.countries;
}

export function currentReviewFilterCriterionSelector(
  state: RootState,
): ICurrentReviewFilterCriterion {
  return state.companyTargetGroup.currentReviewFilterCriterion;
}

export function companiesForReviewSelector(state: RootState): ICompaniesForReviewListResponse {
  return state.companyTargetGroup.companiesForReview;
}

export function currentCompanyForReviewParamsSelector(
  state: RootState,
): ICurrentCompanyForReviewParams {
  return state.companyTargetGroup.currentCompanyForReviewParams;
}

export function companyForReviewSelector(state: RootState): ICompanyForReviewState {
  return state.companyTargetGroup.companyForReview;
}
