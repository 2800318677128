import { Fragment } from 'react';

import { IBaseMapperProps } from './base-mapper.types';

function BaseMapper<T extends { key: string }>({
  component: Component,
  propsArray,
}: IBaseMapperProps<T>) {
  return (
    <>
      {propsArray.map(propsObject => (
        <Component {...propsObject} />
      ))}
    </>
  );
}

export default BaseMapper;
