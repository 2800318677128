import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BaseButtonV2 } from '../button/button-v2/button-v2.styles';

export const FileUploadDropzoneContainer = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '.5rem',
  outline: 'none',
  height: '100%',

  backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(`
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="100%"
        fill="none"
        rx="8"
        ry="8"
        stroke="#D4D4D4FF"
        stroke-width="3"
        stroke-dasharray="8"
        stroke-dashoffset="8"
        stroke-linecap="butt"
      />
    </svg>
  `)}")`,

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const BrowseButton = styled(BaseButtonV2)(() => ({
  border: 'unset',
  borderRadius: '0.5rem',
  fontSize: '1rem',
}));

export const UploadFormats = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  textAlign: 'center',
  lineHeight: '1.25rem',
  fontWeight: 500,
}));
