import { NotificationPreferences } from '../../../../../entities/user/domain/user.types';
import RadioGroup from '../../../../../shared/components/radio-group/radio-group.component';
import { ISelectEmailPreference } from './select-email-preference.types';

export const NotificationLabels: Record<NotificationPreferences, string> = {
  [NotificationPreferences.none]: 'None',
  [NotificationPreferences.immediate]: 'Immediate',
  [NotificationPreferences.daily]: 'Daily summary',
  [NotificationPreferences.weekly]: 'Weekly summary',
};

function SelectEmailPreference({ selectedOption, onChange }: ISelectEmailPreference) {
  return (
    <RadioGroup
      onChange={onChange}
      options={Object.values(NotificationPreferences).map(option => ({
        id: option,
        name: option,
        label: NotificationLabels[option],
        values: { id: option, name: option },
      }))}
      selectedOption={selectedOption}
    />
  );
}

export default SelectEmailPreference;
