import { SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';

import TargetDefinitionFormInput from '../target-definition-form-input/target-definition-form-input.component';
import { ITargetDefinitionFormInputProps } from '../target-definition-form-input/target-definition-form-input.types';
import { CURRENCIES, ONE_QUADRILLION } from './currency-input.constants';
import { parseRevenue } from './currency-input.helpers';
import {
  CurrencyInputContainer,
  CurrencyInputPreview,
  CurrencySelect,
  CurrencySelectMenuItem,
  StyledCurrencyInput,
} from './currency-input-styles';

interface ICurrencyInputProps extends ITargetDefinitionFormInputProps<Record<string, any>> {
  currency: string;
  setCurrency: (currency: string) => void;
  handleCustomInputChange: (name: string, value: string) => void;
}

export default function CurrencyInput({
  id,
  name,
  label,
  values,
  handleCustomInputChange,
  disabled,
  lastFocusedInput,
  setLastFocusedInput,
  currency,
  setCurrency,
  ...rest
}: ICurrencyInputProps) {
  const [parsedValue, setParsedValue] = useState<number | null>();

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawAmount = event.target.value;
    handleCustomInputChange(name, rawAmount);

    if (!rawAmount) {
      setParsedValue(undefined);
      return;
    }

    try {
      setParsedValue(parseRevenue(rawAmount));
    } catch (error) {
      setParsedValue(null);
    }
  };

  const onCurrencyChange = (event: SelectChangeEvent<string>) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
  };

  return (
    <CurrencyInputContainer>
      <StyledCurrencyInput>
        <TargetDefinitionFormInput
          {...rest}
          id={id}
          name={name}
          placeholder="e.g. 500K, 2M"
          values={values}
          handleChange={onValueChange}
          disabled={disabled}
          lastFocusedInput={lastFocusedInput || ''}
          setLastFocusedInput={setLastFocusedInput || (() => {})}
          isLabelShown
          label={label}
          type="text"
          sx={{ '& .MuiInputBase-input': { paddingRight: '80px' } }}
        />
        <CurrencySelect
          value={currency}
          onChange={onCurrencyChange}
          disabled={disabled}
          inputProps={{ 'aria-label': 'Currency' }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiList-padding': {
                  backgroundColor: 'neutrals.gray.lowest',
                  borderRadius: '.25rem',
                  border: '1px solid',
                  borderColor: 'neutrals.gray.lower',
                  padding: 0,
                  overflow: 'hidden',
                  maxHeight: 'min(10rem, 100vh - 10rem)',
                  overflowY: 'auto',
                },
              },
            },
          }}
        >
          {CURRENCIES.map(cur => (
            <CurrencySelectMenuItem key={cur.code} value={cur.code}>
              {cur.code}
            </CurrencySelectMenuItem>
          ))}
        </CurrencySelect>
      </StyledCurrencyInput>
      {parsedValue !== undefined && (
        <CurrencyInputPreview error={parsedValue === null}>
          {(() => {
            if (parsedValue === null) return 'Invalid revenue format';
            if (parsedValue > ONE_QUADRILLION) return 'MOTHERLODE';
            return (
              <>
                {CURRENCIES.find(c => c.code === currency)?.symbol}
                {parsedValue.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </>
            );
          })()}
        </CurrencyInputPreview>
      )}
    </CurrencyInputContainer>
  );
}
