'use client';

import { Autocomplete } from '@mui/material';

import ValidationErrorsHelper from '../../helper/validation-errors-helper/validation-errors-helper.component';
import { InfoIcon } from '../base-input/base-input.styles';
import {
  AutocompleteContainer,
  Input,
  Label,
  StyledChip,
  StyledDeleteIcon,
  StyledPaper,
} from './autocomplete-with-multiple-choice-with-sublabel.styles';
import { IAutocompleteWithMultipleChoicesProps } from './autocomplete-with-multiple-choice-with-sublabel.types';

export default function AutocompleteWithMultipleChoices<T>({
  id,
  options,
  selectedOptions,
  label,
  name,
  errors,
  className,
  placeholder,
  disabled,
  handleOptionsChange,
  lastFocusedInput,
  setLastFocusedInput,
  noOptionsText,
  showInfoIcon,
}: IAutocompleteWithMultipleChoicesProps<T>) {
  const onFocus = () => {
    setLastFocusedInput(name, id);
  };

  return (
    <AutocompleteContainer className={className}>
      {label && (
        <Label
          sx={{
            marginBottom: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          htmlFor={id}
        >
          {label}

          {showInfoIcon && (
            <InfoIcon
              src={`/icons/info-circle-${lastFocusedInput === name ? 'fill' : 'outline'}.svg`}
            />
          )}
        </Label>
      )}
      <Autocomplete
        id={id}
        disabled={disabled}
        multiple
        options={options}
        getOptionLabel={option => option.value}
        value={selectedOptions}
        onChange={handleOptionsChange}
        onFocus={onFocus}
        disableCloseOnSelect
        popupIcon={null}
        disableClearable
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={(_options, state) =>
          _options.filter(
            option =>
              option.label.toLowerCase().includes(state.inputValue.toLowerCase()) &&
              !selectedOptions.some(selected => selected.value === option.value),
          )
        }
        PaperComponent={StyledPaper}
        renderOption={(props, option) => (
          <li {...props}>
            <span>{option.label}</span>{' '}
            {option.subLabel && <span className="secondary">{option.subLabel}</span>}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <StyledChip
              label={option.value}
              {...getTagProps({ index })}
              deleteIcon={<StyledDeleteIcon />}
            />
          ))
        }
        renderInput={params => (
          <Input {...params} placeholder={placeholder} InputLabelProps={{ shrink: false }} />
        )}
        autoComplete
        noOptionsText={noOptionsText}
      />
      {errors?.[name] && (
        <ValidationErrorsHelper
          errors={errors}
          name={name}
          id={id}
          className="autocomplete-validation-helper"
        />
      )}
    </AutocompleteContainer>
  );
}
