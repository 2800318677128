import { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const TwoSectionsScreenLayoutContainer = styled('div')(({ theme }) => ({
  all: 'unset',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },

  '&.minimized': {
    '& .left-section-container': {
      height: '62px',
      width: '14%',

      '& > div': {
        '.panel-left-header ~ *': {
          animation: 'fadeout 0.3s ease-in-out forwards',
        },
      },
    },
    '& .right-section-container': {
      '& .pipeline-navigation': {
        width: '86%',
        marginLeft: '14%',
      },
      '> div': {
        width: '99%',
        marginLeft: '1%',
      },
    },
  },

  '@keyframes fadeout': {
    from: { opacity: 1 },
    to: { opacity: 0, display: 'none' },
  },
}));

export const LeftSectionContainer = styled('div')<ContainerProps>(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',

  width: '25%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingRight: '2rem',
  position: 'absolute',
  zIndex: 1,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },

  [theme.breakpoints.up('md')]: {
    paddingRight: '1rem',
  },

  '& .panel-left-header': {
    flexShrink: 0,
  },

  '& .fetch-target-groups-list-container, .fetch-companies-list-container': {
    overflow: 'hidden !important',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '0.5rem',

      '& .panel-left-header': {
        gap: '0.5rem',
        '> *:first-child': {
          maxWidth: '70%',
        },
      },
    },
  },
}));

export const RightSectionContainer = styled('div', {
  shouldForwardProp: props => props !== 'customPadding',
})<ContainerProps & { customPadding: string | undefined }>(({ customPadding, theme }) => ({
  transition: 'all 0.3s ease-in-out',

  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  flex: 1,
  overflow: 'hidden',

  color: theme.palette.neutrals.gray.highest,
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    padding: customPadding ? customPadding : '0',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },

  '& .pipeline-navigation': {
    width: '75%',
    marginLeft: '25%',
    transition: 'all 0.3s ease-in-out',
  },

  '> div': {
    width: '75%',
    marginLeft: '25%',
    transition: 'all 0.3s ease-in-out',
  },
}));
