import React from 'react';

import { PipelineStageName } from '@/modules/navigation/components/pipeline-navigation/pipeline-navigation.types';

import { useAppDispatch } from '../../../../app/store/utils/redux.hooks';
import {
  getTargetGroupById,
  setCurrentTargetGroupParams,
} from '../../../../entities/target-group/store/target-group.slice';
import IndicatorChip from '../../../../shared/components/chip/indicator-chip/indicator-chip.component';
import LeftPanelListLink from '../../../../shared/components/link/left-panel-list-link/left-panel-list-link.component';
import { ITargetGroupCardProps } from './target-group-card.types';

function TargetGroupCard({
  isActiveEntity,
  targetGroupId,
  targetGroupName,
  targetGroupDescription, // to check if the target group has been submitted
  companiesCount,
  stageName,
}: ITargetGroupCardProps) {
  const dispatch = useAppDispatch();

  function handleTargetGroupEntityClick() {
    dispatch(setCurrentTargetGroupParams({ id: targetGroupId, name: targetGroupName }));
    dispatch(getTargetGroupById(targetGroupId));
  }

  const isInProgress =
    stageName === PipelineStageName.TARGET_DEFINITION &&
    companiesCount === 0 &&
    targetGroupDescription !== '';

  return (
    <LeftPanelListLink
      title={targetGroupName}
      onClick={handleTargetGroupEntityClick}
      isActive={isActiveEntity}
      sx={theme => ({
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) auto',
        gap: '10px',
        paddingRight: companiesCount !== null ? '.5rem' : '0',

        [theme.breakpoints.down('lg')]: {
          paddingRight: '0',
          gap: '0.025rem',
        },
      })}
    >
      {companiesCount !== null && stageName !== PipelineStageName.TARGET_DEFINITION && (
        <IndicatorChip label={companiesCount} isDimmed={false} sx={{ width: '100%' }} />
      )}
      {isInProgress && (
        <IndicatorChip label="IN PROGRESS" isDimmed={false} sx={{ width: '100%' }} />
      )}
    </LeftPanelListLink>
  );
}

export default React.memo(TargetGroupCard);
