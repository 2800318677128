import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env['VITE_BACKEND_ENDPOINT'],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': true,
  },
  withCredentials: true,
});

export default api;
