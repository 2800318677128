import Stack from '@mui/material/Stack';
import React from 'react';

import {
  BottomLine,
  PipelineNavigationBaseButton,
  UpperLine,
} from './pipeline-navigation-button.styles';
import { IPipelineNavigationButtonProps } from './pipeline-navigation-button.types';

function PipelineNavigationButton({
  upperLine,
  bottomLine,
  handleClick,
  disabled,
  className,
  isLoading,
  isCurrentRoute,
  isPreviousRoute,
  indicator: Indicator,
}: IPipelineNavigationButtonProps) {
  const title = (
    <Stack direction="column" width="100%" alignItems="flex-start" justifyContent="center">
      <Stack
        className="upper-line-text"
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap="0.5rem"
      >
        <UpperLine className="pipeline-btn-upperline">{upperLine}</UpperLine>
        {Indicator && <Indicator />}
      </Stack>
      <BottomLine className="pipeline-btn-bottomline">{bottomLine}</BottomLine>
    </Stack>
  );

  return (
    <PipelineNavigationBaseButton
      handleClick={handleClick}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      isCurrentRoute={isCurrentRoute}
      isPreviousRoute={isPreviousRoute}
    >
      {title}
    </PipelineNavigationBaseButton>
  );
}

export default PipelineNavigationButton;
