import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import { analyticsSelector } from '../../../entities/target-group/store/target-group.selectors';
import Link from '../../../shared/components/link/base-link/base-link.component';

function DownloadAnalytics() {
  const { clientId } = useParams();
  const analytics = useAppSelector(analyticsSelector);

  function downloadAnalyticsInCsvFormat() {
    const headers = [
      'Target Group Name',
      'Companies Found',
      'Companies Pre-Filtered',
      'Companies Scraped',
      'Companies Selected',
      'Companies Verified',
      'People Found',
      'People Verified',
      'Companies At Review',
    ];

    const csvContent = [
      headers.join(','),
      ...analytics.map(row =>
        [
          row.targetGroupName,
          row.companiesFound,
          row.companiesPreFiltered,
          row.companiesScraped,
          row.companiesSelected,
          row.companiesVerified,
          row.peopleFound,
          row.peopleVerified,
          row.companiesAtReview,
        ].join(','),
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `analytics_${clientId}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return <Link onClick={downloadAnalyticsInCsvFormat} title="EXPORT" />;
}

export default DownloadAnalytics;
