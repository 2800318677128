import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { NavigationPaths } from '@/shared/domain/constants/routing.constants';

import { currentClientSelector, userSelector } from '../../entities/user/store/user.selectors';
import { getCurrentClient } from '../../entities/user/store/user.slice';
import { useAppDispatch, useAppSelector } from '../store/utils/redux.hooks';

function RequireAuthRoute({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { clientId } = useParams();
  const currentClient = useAppSelector(currentClientSelector);
  const { id: userId } = useAppSelector(userSelector);
  const [isClientLoading, setIsClientLoading] = useState(false);

  // Fetch client data if needed
  useEffect(() => {
    const fetchClientData = async () => {
      const isCurrentClientSet = currentClient && currentClient.id === clientId;

      if (clientId && !isCurrentClientSet && isAuthenticated) {
        try {
          setIsClientLoading(true);
          await dispatch(getCurrentClient({ clientId })).unwrap();
        } catch (error) {
          console.error('Failed to fetch client:', error);
        } finally {
          setIsClientLoading(false);
        }
      }
    };

    fetchClientData();
  }, [dispatch, clientId, currentClient, isAuthenticated]);

  // Show loading state while auth is being checked
  if (isAuthLoading || isClientLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column',
          gap: 2,
          bgcolor: 'background.default',
        }}
      >
        <CircularProgress color="inherit" sx={{ color: 'text.primary' }} size={30} />
        <Typography fontFamily="DM Mono" fontSize={16} color="text.primary">
          Verifying access...
        </Typography>
      </Box>
    );
  }

  // No authentication, redirect to login
  if (!isAuthenticated) {
    return <Navigate to={NavigationPaths.LOGIN} state={{ from: location }} replace />;
  }

  // No user ID even though authenticated - session may be corrupted
  if (!userId) {
    return <Navigate to="/authentication/success" replace />;
  }

  // If client ID is required but not available, show error
  if (clientId && !currentClient && !isClientLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column',
          padding: 3,
          textAlign: 'center',
          bgcolor: 'background.default',
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          Access Error
        </Typography>
        <Typography variant="body1" paragraph color="text.primary">
          You don't have access to this client or the client doesn't exist.
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <a href="/" style={{ textDecoration: 'underline', color: 'inherit' }}>
            Return to home
          </a>
        </Typography>
      </Box>
    );
  }

  // All checks passed, render the protected content
  return <>{children}</>;
}

export default RequireAuthRoute;
