import { Box } from '@mui/material';
import { RowClickedEvent } from 'ag-grid-enterprise';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  eventsCategoriesSelector,
  eventsFilterByCategories,
  eventsFilterByCompanies,
  newsEventsSelector,
  selectedNewsEventIdsSelector,
} from '../../../entities/company/store/company.selectors';
import {
  getNewsEventsByTargetGroup,
  resetNewsEvents,
  setSelectedNewsEventIds,
} from '../../../entities/company/store/company.slice';
import { currentTargetGroupParamsSelector } from '../../../entities/target-group/store/target-group.selectors';
import TableV2 from '../../../shared/components/table-v2/table-v2.component';
import { PageContentTableContainer } from './intelligence-content.styles';
import { formatDate } from './intelligence-content-detail/helpers/intelligence-content-detail.helpers';
import IntelligenceContentDetail from './intelligence-content-detail/intelligence-content-detail.component';

export const specificTableCellFormatsConfig = {
  eventArticlePublishedAt: ({ eventArticlePublishedAt }: any) =>
    formatDate(eventArticlePublishedAt),
};

function IntelligenceContent({ search }: { search: string }) {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();

  const [isClosing, setIsClosing] = useState(false);

  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const companiesByTargetGroup = useAppSelector(newsEventsSelector);
  const allEventsCategories = useAppSelector(eventsCategoriesSelector);
  const selectedEventsCategories = useAppSelector(eventsFilterByCategories);
  const selectedEventsCompanies = useAppSelector(eventsFilterByCompanies);
  const selectedEventIds = useAppSelector(selectedNewsEventIdsSelector);

  const prevTargetGroupId = useRef(targetGroupId);
  const prevCategoriesFilter = useRef(selectedEventsCategories);

  useEffect(() => {
    prevTargetGroupId.current = targetGroupId;
    prevCategoriesFilter.current = selectedEventsCategories;
  }, [targetGroupId, selectedEventsCategories, selectedEventsCompanies]);

  useEffect(() => {
    const hasSelected = selectedEventsCategories?.length;
    const categories = hasSelected ? selectedEventsCategories : allEventsCategories;
    const companies = selectedEventsCompanies;

    dispatch(getNewsEventsByTargetGroup({ targetGroupId, clientId, categories, companies }));
  }, [
    prevTargetGroupId,
    dispatch,
    prevCategoriesFilter,
    clientId,
    selectedEventsCategories,
    allEventsCategories,
    selectedEventsCompanies,
    targetGroupId,
  ]);

  useEffect(() => {
    dispatch(resetNewsEvents());
    dispatch(setSelectedNewsEventIds(null));
  }, [dispatch, prevTargetGroupId, selectedEventsCategories, selectedEventsCompanies]);

  const handleRowClick = useCallback(
    ({ data }: RowClickedEvent<any, any>) => {
      dispatch(setSelectedNewsEventIds({ companyId: data.companyId, eventId: data.eventId }));
    },
    [dispatch],
  );

  const handleDrawerClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setSelectedNewsEventIds(null));
      setIsClosing(false);
    }, 300);
  };

  const columnDefs = [
    { field: 'companyName', headerName: 'COMPANY NAME', flex: 1 },
    { field: 'eventTitle', headerName: 'NEWS EVENT', flex: 4 },
    {
      field: 'eventArticlePublishedAt',
      headerName: 'DATE',
      valueFormatter: (params: any) => formatDate(params.value),
      flex: 0.6,
    },
  ];

  const filteredCompanies = useMemo(
    () =>
      companiesByTargetGroup?.items?.filter(company =>
        company.companyName.toLowerCase().includes(search.toLowerCase()),
      ),
    [companiesByTargetGroup, search],
  );

  return (
    <PageContentTableContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '100%' }}>
        <TableV2
          rowData={filteredCompanies ?? []}
          columnDefs={columnDefs}
          gridOptions={{
            onRowClicked: handleRowClick,
            suppressCellFocus: true,
            rowStyle: {
              cursor: 'pointer',
            },
            getMainMenuItems: () => ['sortAscending', 'sortDescending'],
            initialState: {
              sort: { sortModel: [{ colId: 'eventArticlePublishedAt', sort: 'desc' }] },
            },
          }}
        />
        <IntelligenceContentDetail
          isEventSelected={selectedEventIds !== null}
          handleClose={handleDrawerClose}
          isClosing={isClosing}
        />
      </Box>
    </PageContentTableContainer>
  );
}

export default IntelligenceContent;
