import { Box, Divider } from '@mui/material';
import React, { useState } from 'react';

import { InfoIcon, Label } from '../../../../shared/components/input/base-input/base-input.styles';
import CurrencyInput from '../currency-input/currency-input.component';
import { CURRENCIES } from '../currency-input/currency-input.constants';
import { TargetGroupFormField } from '../target-definition-form.types';

interface RangeInputProps {
  label: string;
  minFieldId: keyof typeof TargetGroupFormField;
  maxFieldId: keyof typeof TargetGroupFormField;
  minName: string;
  maxName: string;
  lastFocusedInput: string;
  handleInputFocus: (name: string, element: HTMLElement) => void;
  setLastFocusedElement: (element: HTMLElement) => void;
  values: Record<string, any>;
  placeholder: string;
  handleCustomInputChange: (name: string, value: string) => void;
}

function CurrencyRangeInput({
  label,
  minFieldId,
  maxFieldId,
  minName,
  maxName,
  lastFocusedInput,
  handleInputFocus,
  setLastFocusedElement,
  values,
  handleCustomInputChange,
  placeholder,
}: RangeInputProps) {
  const [currency, setCurrencyLocal] = useState(values['revenueCurrency'] || CURRENCIES[0].code);

  const setCurrency = (newCurrency: string) => {
    setCurrencyLocal(newCurrency);
    handleCustomInputChange('revenueCurrency', newCurrency);
  };

  return (
    <Box sx={{ position: 'relative', label: { width: 'max-content' } }}>
      <Label
        sx={{ position: 'absolute', top: '4px', right: 0, zIndex: 1000 }}
        htmlFor={lastFocusedInput === minName ? `8_${maxFieldId}` : `7_${minFieldId}`}
      >
        <InfoIcon
          src={`/icons/info-circle-${
            lastFocusedInput === minName || lastFocusedInput === maxName ? 'fill' : 'outline'
          }.svg`}
        />
      </Label>

      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <CurrencyInput
          id={`7_${minFieldId}`}
          name={minName}
          type="number"
          lastFocusedInput={lastFocusedInput}
          setLastFocusedInput={(name: string, id: string) => {
            const element = document.getElementById(id);
            if (element) {
              handleInputFocus(name, element);
              setLastFocusedElement(element);
            }
          }}
          values={values}
          isLabelShown
          label={`Minimum ${label}`}
          placeholder={`Minimum ${placeholder}`}
          handleCustomInputChange={handleCustomInputChange}
          currency={currency}
          setCurrency={setCurrency}
        />

        <Divider
          sx={{
            position: 'relative',
            top: '.5rem',
            width: '12px',
            borderColor: 'neutrals.gray.low',
          }}
        />

        <CurrencyInput
          id={`8_${maxFieldId}`}
          name={maxName}
          lastFocusedInput={lastFocusedInput}
          setLastFocusedInput={(name, id) => {
            const element = document.getElementById(id);
            if (element) {
              handleInputFocus(name, element);
              setLastFocusedElement(element);
            }
          }}
          values={values}
          isLabelShown
          label={`Maximum ${label}`}
          placeholder={`Maximum ${placeholder}`}
          handleCustomInputChange={handleCustomInputChange}
          currency={currency}
          setCurrency={setCurrency}
        />
      </div>
    </Box>
  );
}

export default CurrencyRangeInput;
