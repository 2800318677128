import { ComponentType } from 'react';

export enum PipelineStageName {
  TARGET_DEFINITION = 'Target Definition',
  MODEL_CALIBRATION = 'Model Calibration',
  SELECTION_ENGINEERING = 'Selection Engineering',
  MARKET_MAPPING = 'Market Mapping',
  COMPANY_RESEARCH = 'Company Research',
  TARGET_RESEARCH = 'Target Research',
  PEOPLE_RESEARCH = 'People Research',
  REVIEW = 'Review',
  INTELLIGENCE = 'Intelligence',
}

export interface IStage {
  stage?: string;
  stageName: PipelineStageName;
  navigateTo: string;
  currentRoute: string;
  key: string;
  indicator?: ComponentType<any>;
}

export interface IPipelineNavigationStrategy {
  [key: string]: IStage;
}
