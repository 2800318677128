import { NotificationPreferences } from '../../../../entities/user/domain/user.types';
import { check } from '../../../../shared/libs/validator/check.factory';
import { FormValues } from './notification-settings.types';

export interface ValidationRule {
  isValid: (values: FormValues, preference: NotificationPreferences) => boolean;
  errorMessage: string;
}

export const validationRules: ValidationRule[] = [
  {
    isValid: values => {
      const { hasError } = check('email', values.email).isString().notEmpty().isEmail().validate();
      return !hasError;
    },
    errorMessage: 'Please enter a valid email',
  },
  {
    isValid: (_values, preference) => {
      return preference !== null && Object.values(NotificationPreferences).includes(preference);
    },
    errorMessage: 'Please select an email preference option',
  },
];

export const validateForm = (values: FormValues, preference: NotificationPreferences) => {
  return validationRules
    .map(rule => (rule.isValid(values, preference) ? false : rule.errorMessage))
    .filter((error): error is string => Boolean(error));
};
