import React from 'react';

import FetchTargetGroupsList from '../../../features/fetch-target-groups-list/components/fetch-target-groups-list.component';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import { IntelligenceLeftSectionContainer } from './intelligence-left-section.styles';

function IntelligenceLeftSection() {
  return (
    <IntelligenceLeftSectionContainer>
      <FetchTargetGroupsList stageName={PipelineStageName.INTELLIGENCE} />
    </IntelligenceLeftSectionContainer>
  );
}

export default IntelligenceLeftSection;
