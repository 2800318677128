import {
  FeedbackUiType,
  IBaseModalProps,
  InfoModalContentType,
  InfoOrErrorFeedbackUiPropsUnion,
} from '../feedback-ui.types';

export const BaseModalPropsDefaults = {
  IS_UI_VISIBLE: false,
  UI_TYPE: FeedbackUiType.MODAL,
  UI_CONTENT_TYPE: InfoModalContentType?.CONFIRM_ACTION,
  TITLE: 'Are you sure?',
  MESSAGE: '',
  ACTION_TITLE: 'Ok',
  CANCEL_TITLE: 'Cancel',
  HANDLE_CLOSE: () => {
    console.info('close');
  },
  HANDLE_ACTION: () => console.info('action'),
  HANDLE_CANCEL: () => console.info('cancel'),
  IS_ACTION_LOADING: false,
};

export const infoFeedbackUiPropsWithDefaults = {
  [FeedbackUiType.MODAL]: (props: InfoOrErrorFeedbackUiPropsUnion) => {
    const propsWithDefaults = {
      isUiVisible: props.isUiVisible || BaseModalPropsDefaults.IS_UI_VISIBLE,
      uiContentType: props.uiContentType || BaseModalPropsDefaults.UI_CONTENT_TYPE,
      title: props.title || BaseModalPropsDefaults.TITLE,
      message: props.message || BaseModalPropsDefaults.MESSAGE,
      actionTitle: props.actionTitle || BaseModalPropsDefaults.ACTION_TITLE,
      cancelTitle: props.cancelTitle || BaseModalPropsDefaults.CANCEL_TITLE,
      handleClose: props.handleClose || BaseModalPropsDefaults.HANDLE_CLOSE,
      handleAction: props.handleAction || BaseModalPropsDefaults.HANDLE_ACTION,
      handleCancel: props.handleCancel || BaseModalPropsDefaults.HANDLE_CANCEL,
      isActionLoading: props.isActionLoading || BaseModalPropsDefaults.IS_ACTION_LOADING,
      checkboxConfig: (props as IBaseModalProps).checkboxConfig,
    };
    return propsWithDefaults;
  },
};
