import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { iconMap } from './toast.constants';
import { ToastItem, ToastTitle } from './toast.styles';
import { ToastProps } from './toast.types';

export function Toast({ title, message, type, onClose }: ToastProps) {
  return (
    <ToastItem
      type={type}
      layout
      initial={{ opacity: 0, y: -20, scale: 0.8, scaleY: 1 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 20, scaleY: 0, transition: { duration: 0.1 } }}
      transition={{ type: 'spring', damping: 20, stiffness: 250, duration: 0.2 }}
    >
      <div className="content">
        <ToastTitle>
          {iconMap[type]}
          {title || message}
        </ToastTitle>
        {title && <span className="description">{message}</span>}
      </div>
      {onClose && (
        <IconButton size="small" onClick={onClose}>
          <CloseIcon className="icon" fontSize="small" />
        </IconButton>
      )}
    </ToastItem>
  );
}
