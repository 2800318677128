import { Dialog, DialogProps, IconButton, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiBackdrop-root.MuiModal-backdrop': {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: '.5rem',
    padding: '1rem',
    border: '1px solid',
    borderColor: theme.palette.neutrals.gray.low,
    boxShadow: 'none',

    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
}));

export const StyledDialogHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  '> svg': {
    transition: 'opacity 0.2s',
    cursor: 'pointer',
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.neutrals.gray.highest,

    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export const StyledDialogTitle = styled('h3')(({ theme }) => ({
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '1.5rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '80%',
  margin: '0',
  color: theme.palette.neutrals.gray.highest,
}));

export const CloseIconButton = styled(IconButton)(() => ({
  padding: 0,
  transition: 'opacity 0.2s ease',

  '&.MuiIconButton-root:hover': {
    backgroundColor: 'transparent',
    opacity: 0.7,
  },

  svg: {
    fontSize: '1rem',
  },
}));

export const StyledDialogContent = styled('div')(({ theme }) => ({
  paddingTop: '0.625rem',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '130%',
  color: theme.palette.neutrals.gray.highest,
}));
