import { Box, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { ISelectedNewsEventIds } from '../../../../entities/company/domain/company.types';
import {
  companyThunksSelector,
  newsEventDetailsSelector,
  selectedNewsEventIdsSelector,
} from '../../../../entities/company/store/company.selectors';
import {
  getNewsEventDetails,
  setSelectedNewsEventIds,
} from '../../../../entities/company/store/company.slice';
import { truncateText } from '../../../../shared/helpers/base.helpers';
import { useToast } from '../../../../shared/hooks/use-toast.hook';
import {
  CloseButton,
  CompanyDetailsPanelContainer,
  LoadingOverlay,
} from '../../../review-right-section/components/review-main/company-details-panel/company-details-panel.styles';
import CompanyDetailsBox from '../../../review-right-section/components/review-main/company-details-panel/components/company-details-box/company-details-box.component';
import AcquiredCompaniesSection from '../../../review-right-section/components/review-main/company-details-panel/components/details-section/acquired-companies-section.component';
import FinancialsSection from '../../../review-right-section/components/review-main/company-details-panel/components/details-section/financials-section.component';
import { formatDate } from './helpers/intelligence-content-detail.helpers';
import {
  DrawerHeader,
  RelatedNewDate,
  SectionTitle,
  Subtitle,
  SummaryContent,
  SummaryLink,
  SummaryLinkContent,
  SummaryLinkTitle,
  Title,
} from './intelligence-content-detail.styles';
import { IIntelligenceContentDetailProps } from './intelligence-content-detail.types';

function IntelligenceContentDetail({
  isEventSelected,
  handleClose,

  isClosing,
}: IIntelligenceContentDetailProps) {
  const dispatch = useAppDispatch();
  const selectedEventIds = useAppSelector(selectedNewsEventIdsSelector);
  const newsEventDetails = useAppSelector(newsEventDetailsSelector);
  const { toast } = useToast();
  const { isLoading } = useAppSelector(companyThunksSelector).getNewsEventDetails;
  const prevOpenRef = useRef(isEventSelected);
  const prevSelectedEventIdsRef = useRef(selectedEventIds);

  useEffect(() => {
    const isFirstOpen = isEventSelected && !prevOpenRef.current;
    const hasNewSelectedIds =
      selectedEventIds && selectedEventIds !== prevSelectedEventIdsRef.current;

    if ((isFirstOpen || hasNewSelectedIds) && selectedEventIds) {
      dispatch(
        getNewsEventDetails({
          companyId: selectedEventIds.companyId,
          eventId: selectedEventIds.eventId,
        }),
      )
        .unwrap()
        .catch(err => {
          toast.error({
            message: err.message || 'Unexpected error',
            duration: 3000,
          });
        });
    }

    prevOpenRef.current = isEventSelected;
    prevSelectedEventIdsRef.current = selectedEventIds;
  }, [isEventSelected, selectedEventIds, dispatch, toast]);

  const handleSelectRelatedNew = ({ companyId, eventId }: ISelectedNewsEventIds) => {
    dispatch(setSelectedNewsEventIds({ companyId, eventId }));
  };

  const availableSectionsLength = [
    newsEventDetails?.acquisitions,
    newsEventDetails?.revenue || newsEventDetails?.profit || newsEventDetails?.revenueEst,
  ].filter(Boolean).length;

  return (
    selectedEventIds && (
      <CompanyDetailsPanelContainer className={isClosing ? 'closing' : ''} loading={isLoading}>
        <LoadingOverlay isLoading={isLoading}>
          <Box>Loading...</Box>
        </LoadingOverlay>
        <CloseButton onClick={handleClose} />

        {newsEventDetails && selectedEventIds ? (
          <Stack spacing={3}>
            <DrawerHeader>
              <Title>{truncateText(newsEventDetails?.targetNewsEvent?.eventTitle ?? '', 80)}</Title>
              <Subtitle>{newsEventDetails.name}</Subtitle>
            </DrawerHeader>

            <Stack
              sx={{
                display: 'flex',
                maxHeight: '50vh',
                flexDirection: 'row',
                gap: '1.5rem',
                overflow: 'hidden',
              }}
            >
              <Stack flex={1} spacing={2}>
                <SectionTitle>Summary</SectionTitle>
                <Divider color="#D4D4D4" />

                <SummaryContent>{newsEventDetails.targetNewsEvent.summary}</SummaryContent>

                <SummaryLink href={newsEventDetails?.targetNewsEvent?.eventUrl} target="_blank">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${newsEventDetails?.targetNewsEvent?.eventUrl}&sz=128`}
                      alt={newsEventDetails?.targetNewsEvent?.source}
                      style={{ width: '1rem', height: '1rem' }}
                    />
                    <SummaryLinkTitle>{newsEventDetails?.targetNewsEvent?.source}</SummaryLinkTitle>
                  </Box>
                  <SummaryLinkContent>
                    {truncateText(newsEventDetails?.targetNewsEvent?.summary ?? '', 70)}
                  </SummaryLinkContent>
                </SummaryLink>
              </Stack>

              <Stack flex={1} spacing={2}>
                <SectionTitle>Related News</SectionTitle>
                <Divider color="#D4D4D4" />

                <Stack sx={{ overflowY: 'auto', paddingRight: '.5rem' }} spacing={1}>
                  {newsEventDetails.otherNewsEvents.map(relatedNew => (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'minmax(0, 1fr) auto',
                        gap: '1rem',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                          display: 'inline-block',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: 'neutrals.gray.high',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            color: 'neutrals.gray.highest',
                            textDecoration: 'none',
                          },
                        }}
                        title={relatedNew.eventTitle}
                        onClick={() =>
                          handleSelectRelatedNew({
                            companyId: selectedEventIds.companyId,
                            eventId: relatedNew.id,
                          })
                        }
                      >
                        {relatedNew.eventTitle}
                      </Typography>
                      <RelatedNewDate>{formatDate(relatedNew.eventFoundAt)}</RelatedNewDate>
                    </Box>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <SectionTitle>Company information</SectionTitle>
              <CompanyDetailsBox
                {...newsEventDetails}
                description={newsEventDetails.businessType}
              />

              {availableSectionsLength > 0 && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${availableSectionsLength}, 1fr)`,
                    gap: '24px',
                  }}
                >
                  <AcquiredCompaniesSection acquisitions={newsEventDetails.acquisitions} />
                  <FinancialsSection
                    revenue={newsEventDetails.revenue}
                    profit={newsEventDetails.profit}
                    revenueEst={newsEventDetails.revenueEst}
                    profitYear={newsEventDetails.profitYear}
                    revenueYear={newsEventDetails.revenueYear}
                    currency={newsEventDetails.currency}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        ) : (
          <Typography variant="body2">No details available</Typography>
        )}
      </CompanyDetailsPanelContainer>
    )
  );
}

export default IntelligenceContentDetail;
