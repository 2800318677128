import React, { Fragment } from 'react';

import ReviewHeader from './review-header/review-header.component';
import ReviewMain from './review-main/review-main.component';

function ReviewRightSection() {
  return (
    <>
      <ReviewHeader />
      <ReviewMain />
    </>
  );
}

export default ReviewRightSection;
