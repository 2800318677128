import { InputBase, styled } from '@mui/material';

import GenericDialog from '../../generic-dialog-v2/generic-dialog.component';

export const InputExpandableContainer = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
}));

export const StyledDialog = styled(GenericDialog)(() => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    minWidth: '60vw',
  },
}));

export const StyledDialogContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));

export const Input = styled(InputBase, {
  shouldForwardProp: props => props !== 'isPasswordField',
})(({ theme }) => ({
  width: '100%',
  backgroundColor: 'transparent',
  boxSizing: 'border-box',

  fontFamily: '"Darker Grotesque", sans-serif',
  fontWeight: 400,

  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.neutrals.gray.lowest,
    color: theme.palette.neutrals.gray.highest,
    borderRadius: '4px',
    minHeight: '36px',
    fontSize: '1rem',

    padding: '6px 12px',
    paddingRight: '2.25rem',
    border: '1px solid',
    borderColor: theme.palette.neutrals.gray.low,
  },

  '&.Mui-error': {
    color: '#ea4c89',
  },
}));

export const StyledResizeIcon = styled('img')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '12px',
  width: '1rem',
  height: '1rem',
}));

export const StyledPopupCloseIcon = styled('img')(() => ({}));

export const DialogActionsButtons = styled('div')(() => ({
  paddingTop: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  gap: '0.375rem',
}));
