import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const AnalyticsContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
}));

export const AnalyticsSidebar = styled(Box)(({ theme }) => ({
  width: '21%',
  minWidth: 'max-content',
  maxWidth: '300px',
  height: '100%',
  position: 'absolute',
  display: 'grid',
  gridTemplateRows: 'auto auto minmax(0, 1fr) auto',
  gap: '1rem',

  border: `1px solid ${theme.palette.neutrals.gray.low}`,
  padding: '.5rem',
  borderRadius: '.5rem',
  overflow: 'hidden',

  transition: 'all 0.3s ease-in-out',

  [theme.breakpoints.up('lg')]: {
    width: '15%',
  },

  '&.collapsed': {
    height: '54px',
    width: '30px',
    maxWidth: '30px',
  },
}));

export const SidebarHeader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '.5rem',
}));

export const SidebarFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '.25rem',
  flexShrink: 0,
  borderTop: `1px solid ${theme.palette.neutrals.gray.low}`,
  padding: '.5rem',
  paddingTop: '1.5rem',

  a: {
    textTransform: 'uppercase',
    span: {
      color: `${theme.palette.neutrals.gray.medium} !important`,
    },
  },
}));

export const AnalyticsContent = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',

  width: 'calc(100% - min(22%, 300px))',
  marginLeft: 'min(22%, 300px)',

  transition: 'all 0.3s ease-in-out',

  [theme.breakpoints.up('lg')]: {
    width: 'calc(100% - min(16%, 300px))',
    marginLeft: 'min(16%, 300px)',
  },

  '&.collapsed': {
    marginLeft: '0',
    width: '100%',
  },
}));

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '2rem',
  fontFamily: '"Darker Grotesque", sans-serif',
  fontWeight: 400,
  color: theme.palette.neutrals.gray.highest,
}));

export const ScrollableTableWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'auto',
  boxSizing: 'border-box',
}));

export const ClientsListContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
}));
