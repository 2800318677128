import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import { FormFieldValueType } from '../components/target-definition-form.types';
import { ITargetDefinitionFormInputProps } from '../components/target-definition-form-input/target-definition-form-input.types';
import { targetDefinitionFormStrategy } from '../domain/target-definition-form.strategy';

export function formCurrentTargetDefinitionFormState(targetGroupFormData: ITargetGroup) {
  const {
    description,
    excludeBusinessTypes,
    locations,
    minHeadcount,
    maxHeadcount,
    ownershipStructures,
    minRevenue,
    maxRevenue,
    revenueCurrency,
  } = targetGroupFormData;

  return {
    name: targetGroupFormData.name ?? '',
    description: description ?? '',
    excludeBusinessTypes: excludeBusinessTypes ?? '',
    locations: locations ?? [],
    minHeadcount: minHeadcount ?? '',
    maxHeadcount: maxHeadcount ?? '',
    ownershipStructures: ownershipStructures ?? null,
    minRevenue: minRevenue ?? '',
    maxRevenue: maxRevenue ?? '',
    revenueCurrency: revenueCurrency ?? 'USD',
  };
}

export function formInputsConfig<T>(
  values: ITargetDefinitionFormInputProps<T>['values'],
  handleChange: ITargetDefinitionFormInputProps<T>['handleChange'],
  handleMultipleInputChange: (name: string, multipleValues: string[]) => void,
  isFormEditable?: boolean,
  focusProps?: {
    lastFocusedInput: string;
    setLastFocusedInput: (name: string, id: string) => void;
  },
) {
  const targetDefinitionsFormFieldsEntries = Object.entries(targetDefinitionFormStrategy);
  const inputsConfigArray = targetDefinitionsFormFieldsEntries.map(([, fieldConfig]) => {
    const { inputValueType, ...restFieldConfig } = fieldConfig;
    const strategy = {
      [FormFieldValueType.SOLO]: {
        ...restFieldConfig,
        ...focusProps,
        handleChange,
        values,
        isFormEditable,
      },
      [FormFieldValueType.MULTIPLE]: {
        ...restFieldConfig,
        ...focusProps,
        handleChange: handleMultipleInputChange,
        values,
        isFormEditable,
      },
    };
    return strategy[inputValueType];
  });
  return inputsConfigArray;
}

export function isTargetGroupFormFilled(formValues: any): boolean {
  const { minHeadcount, maxHeadcount } = formValues;

  return minHeadcount >= 5 || maxHeadcount >= 5;
}
