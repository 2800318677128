import React, { SyntheticEvent } from 'react';

import locations from '../../../data/locations.json';
import { ILocation } from '../../../entities/target-group/domain/target-group.types';
import AutocompleteWithMultipleChoices from '../../../shared/components/input/autocomplete-with-multiple-choice-with-sublabel/autocomplete-with-multiple-choice-with-sublabel.component';
import { IOption } from '../../../shared/components/input/autocomplete-with-multiple-choice-with-sublabel/autocomplete-with-multiple-choice-with-sublabel.types';
import {
  getOptions,
  mapSelectedValuesToOptions,
} from '../helpers/select-locations.with-autocomplete.helpers';
import {
  ILocationsData,
  ISelectLocationsWithAutocompleteProps,
} from './select-locations-with-autocomplete.types';

function SelectLocationsWithAutocomplete({
  className,
  id,
  label,
  name,
  values,
  errors,
  disabled,
  handleChange,
  lastFocusedInput,
  setLastFocusedInput,
  placeholder,
  noOptionsText,
}: ISelectLocationsWithAutocompleteProps) {
  const selectedLocations = values[name] ?? [];

  function handleOptionsChange(
    event: SyntheticEvent<Element, Event>,
    value: readonly IOption<ILocation>[],
  ) {
    const locationValues = value
      .map(location => location.extraData)
      .filter((extraData): extraData is ILocation => extraData !== undefined);

    handleChange(name, locationValues);
  }

  const options = getOptions(locations as ILocationsData[]);

  const mappedSelectedLocations = mapSelectedValuesToOptions(selectedLocations, options);
  return (
    <AutocompleteWithMultipleChoices
      id={id}
      className={className}
      label={label}
      name={name}
      selectedOptions={mappedSelectedLocations}
      options={options}
      handleOptionsChange={handleOptionsChange}
      errors={errors}
      disabled={disabled}
      lastFocusedInput={lastFocusedInput}
      setLastFocusedInput={setLastFocusedInput}
      placeholder={placeholder}
      noOptionsText={noOptionsText}
    />
  );
}

export default SelectLocationsWithAutocomplete;
