import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { useToast } from '../../hooks/use-toast.hook';
import { BrowseButton, FileUploadDropzoneContainer, UploadFormats } from './file-upload.styles';

interface FileUploadProps {
  handleFileChange: React.Dispatch<React.SetStateAction<File>>;
  uploadFormats: string;
  buttonTitle?: string;
}

export const acceptedFileFormats = {
  'text/csv': ['.csv'],
  'application/csv': ['.csv'],
  'text/x-csv': ['.csv'],
  'application/x-csv': ['.csv'],
  'text/comma-separated-values': ['.csv'],
  'text/x-comma-separated-values': ['.csv'],
};

function FileUpload({
  handleFileChange,
  buttonTitle = 'Upload +',
  uploadFormats,
}: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const { toast } = useToast();

  function resetFileStatusesBeforeUpload() {
    setSelectedFile(undefined);
  }

  function handleOnDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    resetFileStatusesBeforeUpload();
    if (fileRejections.length > 0) {
      toast.error({ message: fileRejections[0]?.errors[0].message });
    } else {
      setSelectedFile(acceptedFiles[0]);
      handleFileChange(acceptedFiles[0]);
    }
  }

  const { getRootProps, getInputProps, open, isDragAccept, isFocused, isDragReject } = useDropzone({
    multiple: false,
    accept: acceptedFileFormats,
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop,
  });

  return (
    <FileUploadDropzoneContainer {...getRootProps({ isDragAccept, isFocused, isDragReject })}>
      <Stack justifyContent="center" alignItems="center" height="100%">
        <input {...getInputProps()} />
        <BrowseButton title={buttonTitle} handleClick={open} />
        <UploadFormats>{uploadFormats}</UploadFormats>
        {selectedFile && (
          <Typography sx={{ mt: 1, color: 'text.secondary' }}>{selectedFile.name}</Typography>
        )}
      </Stack>
    </FileUploadDropzoneContainer>
  );
}

export default FileUpload;
