import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColumnHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: '"DM Mono", serif',
  color: theme.palette.neutrals.gray.highest,
  fontSize: '0.75rem',
  marginBottom: theme.spacing(1),
}));

export const ManualMarketMappingBox = styled(Box)<BoxProps>(({ theme }) => ({
  '> button': {
    marginTop: theme.spacing(2),
  },
}));

export const ColumnCellValue = styled('span')(({ theme }) => ({
  color: theme.palette.neutrals.gray.highest,
  fontSize: '1rem',
  wordBreak: 'break-all',
  fontWeight: 400,
}));
