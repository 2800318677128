import { ValueFormatterParams } from 'ag-grid-enterprise';

import { ShortUrlCellRenderer } from '../../../shared/components/table-v2/renderers/table-v2.renderers.component';
import { getObfuscatedSourceType } from '../helpers/source-type.helpers';
import { ActionCellRenderer, NotesCellRenderer } from './market-mapping.table-renderers';

export const columnDefs = [
  {
    field: 'sourceType',
    headerName: 'SOURCE',
    valueFormatter: (params: ValueFormatterParams) => getObfuscatedSourceType(params.value),
    flex: 1,
  },
  {
    field: 'url',
    headerName: 'LINK',
    cellRenderer: ShortUrlCellRenderer,
    flex: 2,
  },
  {
    field: 'notes',
    headerName: 'NOTES',
    cellRenderer: NotesCellRenderer,
    flex: 2,
    editable: true,
  },
  {
    field: 'actions',
    headerName: 'ACTIONS',
    cellRenderer: ActionCellRenderer,
    flex: 1,
  },
  {
    field: 'companiesCount',
    headerName: 'COMPANIES',
    flex: 1,
  },
  {
    field: 'selected',
    headerName: 'SELECTED',
    flex: 1,
  },
];
