import React from 'react';

import BaseButtonLoader from '../../loader/base-button-loader/base-button-loader.component';
import { MuiButton } from './base-button.styles';
import { IBaseButtonProps } from './base-button.types';

function BaseButton({
  title,
  handleClick,
  disabled,
  className,
  isLoading,
  isCurrentRoute,
  children,
  ...rest
}: IBaseButtonProps) {
  return (
    <MuiButton
      onClick={handleClick}
      disabled={disabled || isLoading || false}
      className={className}
      {...rest}
    >
      {isLoading ? (
        <BaseButtonLoader status={isLoading} className="base-button-loader" size={20} />
      ) : (
        (title ?? children)
      )}
    </MuiButton>
  );
}

export default BaseButton;
