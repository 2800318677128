export const CURRENCIES = [
  { code: 'USD', symbol: '$' }, // United States Dollar

  // Tier 1: Most used European currencies
  { code: 'EUR', symbol: '€' }, // Euro (used in 26 countries)
  { code: 'GBP', symbol: '£' }, // Pound Sterling (4th most traded)
  { code: 'CHF', symbol: 'CHF' }, // Swiss Franc (5th most traded)

  // Tier 2: Major non-Euro EU currencies
  { code: 'SEK', symbol: 'kr' }, // Swedish Krona
  { code: 'PLN', symbol: 'zł' }, // Polish Złoty
  { code: 'DKK', symbol: 'kr' }, // Danish Krone
  { code: 'CZK', symbol: 'Kč' }, // Czech Koruna
  { code: 'HUF', symbol: 'Ft' }, // Hungarian Forint
  { code: 'RON', symbol: 'lei' }, // Romanian Leu

  // Tier 3: Other significant European currencies
  { code: 'NOK', symbol: 'kr' }, // Norwegian Krone
  { code: 'TRY', symbol: '₺' }, // Turkish Lira
  { code: 'RUB', symbol: '₽' }, // Russian Ruble
];

export const ONE_QUADRILLION = 1_000_000_000_000 * 1000;
