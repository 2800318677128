import { IWeeklyIntervalOfCompaniesCreation } from '../../entities/company-target-group/domain/company-target-group.types';
import { formatDateToDDMMYYYY } from './base.helpers';

export function generateCompanyCreationWeekName(startDate: string, endDate: string) {
  return `${formatDateToDDMMYYYY(startDate)} - ${formatDateToDDMMYYYY(endDate)}`;
}

export function formSelectWeekDropdownOptions(weeks: IWeeklyIntervalOfCompaniesCreation[]) {
  return (weeks || []).map(week => {
    const { startDate, endDate } = week;
    const name = generateCompanyCreationWeekName(startDate, endDate);
    return {
      id: startDate,
      name,
      values: { ...week, id: startDate, name },
    };
  });
}
