import { ILocation } from '../../../entities/target-group/domain/target-group.types';
import { IOption } from '../../../shared/components/input/autocomplete-with-multiple-choice-with-sublabel/autocomplete-with-multiple-choice-with-sublabel.types';
import { ILocationsData } from '../components/select-locations-with-autocomplete.types';

const mapLocationDataToExtradata = (data: ILocationsData, value: string): ILocation => {
  return {
    name: value,
    state: data.type === 'state' ? data.value : undefined,
    country: data.type === 'country' ? data.value : data.country,
    region: data.type === 'region' ? data.value : undefined,
    continent: data.type === 'continent' ? data.value : data.continent,
    latitude: String(data.latitude),
    longitude: String(data.longitude),
  };
};

export const getOptions = (data: ILocationsData[]): IOption<ILocation>[] => {
  return data.map(location => {
    let value = location.value;
    let subLabel: string | undefined;

    switch (location.type) {
      case 'state':
        value = `${location.value}, ${location.country}`;
        subLabel = `${location.country}, ${location.continent}`;
        break;

      case 'country':
        value = `${location.value}, ${location.continent}`;
        subLabel = `${location.continent}`;
        break;

      case 'region':
        value = `${location.value}, ${location.continent}`;
        subLabel = `${location.continent}`;
        break;

      case 'continent':
        value = location.value;
        break;
      default:
        throw Error('getOptions - data type not supported');
    }

    const extraData = mapLocationDataToExtradata(location, value);

    return {
      label: location.value,
      value,
      subLabel,
      extraData,
    };
  });
};

export const mapSelectedValuesToOptions = (
  selectedValues: ILocation[],
  options: IOption<ILocation>[],
): IOption<ILocation>[] => {
  return selectedValues.map(selectedValue => {
    const matchedOption = options.find(option => option.value === selectedValue.name);

    return (
      matchedOption ?? {
        value: selectedValue.name,
        label: selectedValue.name,
        subLabel: '',
        extraData: selectedValue,
      }
    );
  });
};
