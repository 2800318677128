import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import {
  CloseIconButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from './generic-dialog.styles';
import { IGenericDialogProps } from './generic-dialog.types';

function GenericDialog({
  title,
  children,
  onClose,
  customCloseIcon,
  ...rest
}: IGenericDialogProps) {
  return (
    <StyledDialog {...rest}>
      <StyledDialogHeader>
        <StyledDialogTitle onClick={(e: any) => e.stopPropagation()}>{title}</StyledDialogTitle>

        {onClose && (
          <CloseIconButton aria-label="close" onClick={event => onClose(event, 'escapeKeyDown')}>
            {customCloseIcon || <CloseIcon />}
          </CloseIconButton>
        )}
      </StyledDialogHeader>

      <StyledDialogContent>{children}</StyledDialogContent>
    </StyledDialog>
  );
}

export default GenericDialog;
