import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const MuiChip = styled(Chip, {
  shouldForwardProp: props => props !== 'isDimmed',
})<ChipProps & { isDimmed: boolean }>(({ isDimmed, theme }) => ({
  backgroundColor: isDimmed
    ? theme.palette.neutrals.gray.highest
    : theme.palette.neutrals.gray.higher,
  color: isDimmed ? theme.palette.neutrals.gray.lowest : theme.palette.neutrals.gray.lower,
  height: '16px',
  minWidth: '24px',
  width: 'auto',
  fontSize: '0.5rem',
  fontFamily: '"DM Mono", serif',
  fontWeight: 400,
  borderRadius: '10px',
  padding: '1px 5px',

  '& .MuiChip-label': { padding: 0 },

  [theme.breakpoints.up('xl')]: {
    fontSize: '0.625rem',
  },
}));
