import { KnownBlock } from '@slack/web-api';

import axios from '../../app/services/axios.service';

const API_URL = import.meta.env['VITE_BACKEND_ENDPOINT'];
const DISABLE_SLACK_NOTIFICATIONS = import.meta.env['VITE_DISABLE_SLACK_NOTIFICATIONS'];

export async function sendSlackNotification(
  blocks: KnownBlock[],
  channel: string | undefined = undefined,
  thread_ts: string | undefined = undefined,
): Promise<string | undefined> {
  let channelToUse = channel;
  if (DISABLE_SLACK_NOTIFICATIONS) {
    return;
  }

  if (API_URL?.includes('localhost')) {
    channelToUse = 'notification-testing';
  }
  try {
    const response = await axios.post(`${API_URL}/slack/send-rich-message`, {
      blocks,
      channel: channelToUse,
      thread_ts,
    });
    return response.data?.ts;
  } catch (error) {
    console.error('Error sending Slack notification:', error);
  }
}
