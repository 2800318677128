import { RootState } from '../../../app/store/utils/redux.types';
import {
  ICompaniesFile,
  ICompanyState,
  ICompanyThunks,
  ICurrentCompany,
  INewsEventsListResponse,
  IUploadedCompaniesListResponse,
} from '../domain/company.types';

export function companyStateSelector(state: RootState): ICompanyState {
  return state.company;
}

export function companyThunksSelector(state: RootState): ICompanyThunks {
  return state.company.thunks;
}

export function currentCompanySelector(state: RootState): ICurrentCompany {
  return state.company.currentCompany;
}

export function companiesFileSelector(state: RootState): ICompaniesFile {
  return state.company.companiesFile;
}

export function uploadedCompaniesSelector(state: RootState): IUploadedCompaniesListResponse {
  return state.company.uploadedCompanies;
}

export function newsEventsSelector(state: RootState): INewsEventsListResponse {
  return state.company.newsEvents;
}

export function selectedNewsEventIdsSelector(state: RootState) {
  return state.company.selectedNewsEventIds;
}

export function newsEventDetailsSelector(state: RootState) {
  return state.company.currentNewsEventDetails;
}

export function newsEventsSortingSelector(state: RootState) {
  return state.company.sorting;
}

export function eventsCategoriesSelector(state: RootState) {
  return state.company.newsEventsCategories;
}

export function eventsFilterByCategories(state: RootState) {
  return state.company.newsEventsFilterByCategories;
}
export function eventsFilterByCompanies(state: RootState) {
  return state.company.newsEventsFilterByCompanies;
}
