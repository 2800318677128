import { BaseButtonV2 } from './button-v2.styles';
import { ButtonV2Props } from './button-v2.types';

function ButtonV2({
  title,
  handleClick,
  disabled,
  className,
  isLoading,
  customVariant = 'primary-large',
  sx,
}: ButtonV2Props) {
  return (
    <BaseButtonV2
      title={title}
      handleClick={handleClick}
      customVariant={customVariant}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      sx={sx}
    />
  );
}

export default ButtonV2;
