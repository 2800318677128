import { Box, Link, Typography } from '@mui/material';
import React from 'react';

import { ILead } from '../../../../../../../entities/company-target-group/domain/company-target-group.types';
import DetailsSection from './details-section.component';

export default function LeadershipSection({ leads }: { leads?: ILead[] }) {
  if (!leads || leads.length === 0) return null;

  return (
    <DetailsSection title="Leadership">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {leads.map((lead, index) => (
          <Box
            key={index}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr)) auto',
              gap: '0.5rem',
              alignItems: 'center',
              color: 'neutrals.gray.high',
              fontSize: '16px',
            }}
          >
            <Typography>{lead.name}</Typography>
            <Typography>{lead.jobTitle}</Typography>
            <Link
              href={`mailto:${lead.email}`}
              target="_blank"
              sx={{
                wordBreak: 'break-word',
                color: 'neutrals.gray.high',
                textDecoration: 'none',
                maxWidth: 'max-content',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {lead.email}
            </Link>

            {lead.linkedIn && (
              <Link
                href={lead.linkedIn}
                target="_blank"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <img src="/icons/brand/linkedin.svg" alt="LinkedIn" width={16} height={16} />
              </Link>
            )}
          </Box>
        ))}
      </Box>
    </DetailsSection>
  );
}
