import { useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import UnsupportedScreen from '../../../shared/components/unsupported-screen/unsupported-screen.component';
import { muiThemeOptions } from '../../styles/global/mui/mui-theme-options';
import { IMuiThemeDecoratorPropsTypes } from './mui-theme.types';

export function MuiThemeDecorator({ children }: IMuiThemeDecoratorPropsTypes) {
  const theme = createTheme(muiThemeOptions);
  const isMobile = useMediaQuery(theme.breakpoints.down(720));

  return <ThemeProvider theme={theme}>{isMobile ? <UnsupportedScreen /> : children}</ThemeProvider>;
}
