import React, { createContext, ReactNode, useMemo, useState } from 'react';

import { OverlayContextType } from './overlay.types';

export const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

export function OverlayProvider({ children }: { children: ReactNode }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const showOverlay = () => setIsOverlayVisible(true);
  const hideOverlay = () => setIsOverlayVisible(false);

  const value = useMemo(
    () => ({
      isOverlayVisible,
      showOverlay,
      hideOverlay,
    }),
    [isOverlayVisible],
  );

  return <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>;
}
