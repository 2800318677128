import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { userSelector, userThunksSelector } from '../../../entities/user/store/user.selectors';
import { getUser } from '../../../entities/user/store/user.slice';
import { performFullLogout } from '../../../features/authentication/utils/auth-utils';

export function SuccessAuth() {
  const { isAuthenticated, isLoading: isAuthLoading, logout: auth0Logout } = useAuth0();
  const { id } = useAppSelector(userSelector);
  const { isLoading: isUserLoading } = useAppSelector(userThunksSelector).getUser;
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);
  const [timeoutOccurred, setTimeoutOccurred] = useState(false);
  const [attemptedFetch, setAttemptedFetch] = useState(false);

  useEffect(() => {
    // Only attempt to fetch user data once to prevent loops
    if (isAuthenticated && !id && !attemptedFetch && !isUserLoading) {
      setAttemptedFetch(true);

      // Add a small delay before fetching to ensure auth state is stable
      const fetchTimer = setTimeout(() => {
        dispatch(getUser())
          .unwrap()
          .catch(error => {
            console.error('Failed to fetch user data:', error);
            setError('Failed to load user data. Please try again.');
          });
      }, 500);

      return () => clearTimeout(fetchTimer);
    }
  }, [isAuthenticated, id, dispatch, attemptedFetch, isUserLoading]);

  // Set a timeout for loading
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!id && isAuthenticated && !error) {
        setTimeoutOccurred(true);
      }
    }, 10000); // 10 seconds timeout

    return () => clearTimeout(timeoutId);
  }, [isAuthenticated, id, error]);

  const handleRetry = () => {
    performFullLogout(dispatch, auth0Logout);
  };

  // Show loading state while auth or user data is loading
  if ((isAuthLoading || isUserLoading || (isAuthenticated && !id)) && !timeoutOccurred && !error) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          flexDirection: 'column',
          bgcolor: 'background.default',
        }}
      >
        <CircularProgress color="inherit" sx={{ color: 'text.primary' }} size={30} />
        <Typography fontFamily="DM Mono" fontSize={16} color="text.primary">
          Completing authentication...
        </Typography>
      </Box>
    );
  }

  // Show error message or timeout message
  if (error || timeoutOccurred) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          flexDirection: 'column',
          bgcolor: 'background.default',
        }}
      >
        <Typography fontFamily="DM Mono" fontSize={16} color="error">
          {error || 'Authentication is taking longer than expected.'}
        </Typography>
        <Typography fontFamily="DM Mono" fontSize={16} color="text.primary">
          <a
            href="#"
            onClick={handleRetry}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            Click here
          </a>{' '}
          to log out and try again.
        </Typography>
        <Typography fontFamily="DM Mono" fontSize={16} color="text.secondary">
          If the issue persists, please contact us for support.
        </Typography>
      </Box>
    );
  }

  // Only redirect if we have authenticated user with ID
  if (isAuthenticated && id) {
    return <Navigate to="/" replace />;
  }

  // Fallback to login if not authenticated
  return <Navigate to="/authentication/login" replace />;
}
