import React from 'react';

import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';
import ReviewLeftSection from '../../modules/review-left-section/components/review-left-section.component';
import ReviewRightSection from '../../modules/review-right-section/components/review-right-section.component';

function Review() {
  return (
    <TwoSectionsScreenLayout
      leftSectionComponent={ReviewLeftSection}
      rightSectionComponent={ReviewRightSection}
    />
  );
}

export default Review;
