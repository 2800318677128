import { useLocation } from 'react-router-dom';

export function useIsRouteMatched() {
  const location = useLocation();

  function isRouteMatched(queriedRoute: string): boolean {
    if (location.pathname) {
      const queriedRouteParsed = queriedRoute.split('/');
      const locationParsed = location.pathname.split('/');
      const isCurrentRoute = queriedRouteParsed.every(path => locationParsed.includes(path));
      return Boolean(isCurrentRoute);
    }
    return false;
  }

  return { isRouteMatched };
}
