import { Box, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CurrencyInputContainer = styled(Box)({
  width: '100%',
  maxWidth: 400,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  position: 'relative',
  paddingBottom: '1rem',
});

export const StyledCurrencyInput = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
}));

export const CurrencyInputPreview = styled(Typography)<{ error?: boolean }>(({ theme, error }) => ({
  fontSize: '0.875rem',
  margin: 0,
  position: 'absolute',
  bottom: '-.75rem',
  left: '.5rem',
  color: error ? theme.palette.error.main : theme.palette.neutrals.gray.medium,
}));

export const CurrencySelect = styled(Select<string>)(() => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-4%)',

  '& .MuiSelect-select': {
    padding: '4px',
    paddingRight: '32px !important',
    paddingLeft: '16px !important',
    backgroundColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export const CurrencySelectMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.neutrals.gray.low,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.neutrals.gray.low,
  },
  fontSize: '0.875rem',
}));
