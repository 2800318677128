import React from 'react';

import TwoSectionsScreenLayout from '../../app/layouts/two-sections-screen/two-sections-screen-layout.component';
import MarketMappingLeftSection from '../../modules/market-mapping-left-section/components/market-mapping-left-section.component';
import MarketMappingRightSection from '../../modules/market-mapping-right-section/components/market-mapping-right-section.component';
import { OverlayProvider } from '../../shared/components/overlay/overlay.component';

function MarketMapping() {
  return (
    <OverlayProvider>
      <TwoSectionsScreenLayout
        leftSectionComponent={MarketMappingLeftSection}
        rightSectionComponent={MarketMappingRightSection}
      />
    </OverlayProvider>
  );
}

export default MarketMapping;
