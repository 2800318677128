import React from 'react';

import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import TargetDefinitionForm from '../../target-definition-form/components/target-definition-form.component';
import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';
import { TargetDefinitionRightSectionFormContainer } from './target-definition-right-section.styles';

function TargetDefinitionRightSection() {
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const isTargetGroupChosen = Boolean(targetGroupId);

  const { isLoading: isTargetGroupLoading } =
    useAppSelector(targetGroupThunksSelector).getTargetGroupById;

  return (
    <TargetDefinitionRightSectionFormContainer isTargetGroupChosen={isTargetGroupChosen}>
      <RightSectionMain
        isListEntityChosen={isTargetGroupChosen}
        isDataLoading={isTargetGroupLoading}
        content={<TargetDefinitionForm />}
        noContent={<NoTargetGroupChosenScreen />}
      />
    </TargetDefinitionRightSectionFormContainer>
  );
}

export default TargetDefinitionRightSection;
