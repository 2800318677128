import { Link, Typography } from '@mui/material';

import { ICompanyNewsEvent } from '../../../../../../../entities/company-target-group/domain/company-news-events.types';
import { formatDate } from '../../../../../../intelligence-content/components/intelligence-content-detail/helpers/intelligence-content-detail.helpers';
import DetailsSection from './details-section.component';

export default function NewsEventsSection({ newsEvents }: { newsEvents: ICompanyNewsEvent[] }) {
  if (!newsEvents?.length) return null;

  return (
    <DetailsSection title="News Events">
      {newsEvents?.map(event => (
        <Link
          title={event.title}
          key={event.id}
          href={event.url}
          target="_blank"
          sx={{
            width: '100%',
            textDecoration: 'none',
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            gap: '.75rem',
            alignItems: 'center',
            transition: 'all 0.3s ease',
            color: 'neutrals.gray.high',

            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <img
            src={`https://www.google.com/s2/favicons?domain=${event.url}&sz=128`}
            alt={event.title}
            style={{ width: '1rem', height: '1rem' }}
          />
          <Typography
            color="neutrals.gray.high"
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {event.title}
          </Typography>
          <Typography color="neutrals.gray.high">{formatDate(event.articlePublishedAt)}</Typography>
        </Link>
      ))}
    </DetailsSection>
  );
}
