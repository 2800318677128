import { Box, Divider, Link } from '@mui/material';

import { ICompanyTargetGroupDataForReview } from '@/entities/company-target-group/domain/company-target-group.types';

import { useAppSelector } from '../../../../../app/store/utils/redux.hooks';
import { companyForReviewSelector } from '../../../../../entities/company-target-group/store/company-target-group.selectors';
import {
  CloseButton,
  CompanyAnalysisSource,
  CompanyDescription,
  CompanyDescriptionContainer,
  CompanyDescriptionTitle,
  CompanyDetailsPanelContainer,
  CompanyHeader,
  CompanyName,
  CompanySubtitle,
  DetailsBox,
  LoadingOverlay,
  SocialLinks,
} from './company-details-panel.styles';
import CompanyDetailsBox from './components/company-details-box/company-details-box.component';
import CustomFieldsSection from './components/custom-fields-section/custom-fields-section.component';
import AcquiredCompaniesSection from './components/details-section/acquired-companies-section.component';
import FinancialsSection from './components/details-section/financials-section.component';
import LastFundraiseSection from './components/details-section/last-fundraise-section.component';
import LeadershipSection from './components/details-section/leadership-section.component';
import NewsEventsSection from './components/details-section/news-events-section.component';
import { hasValidFinancialData } from './utils/financial-utils';

export function CompanyDetailsPanel({
  isCompanySelected,
  isClosing,
  handleClose,
  isLoading,
}: {
  isCompanySelected: boolean;
  isClosing: boolean;
  handleClose: () => void;
  isLoading: boolean;
}) {
  const companyForReview = useAppSelector(companyForReviewSelector);
  const { item } = companyForReview;

  const {
    companyName,
    legalName,
    headcount,
    companyNewsEvents,
    foundedYear,
    sixMonthHeadcountGrowth,
    companyLinkedIn,
    website,
    lastFundraise,
    acquisitions,
    revenue,
    profit,
    currency,
    revenueYear,
    profitYear,
    revenueEst,
    location,
    leads,
    businessType,
    PEbackers,
    VCbackers,
    parentCompany,
    founderOwned,
    ownership,
    explanation,
    customFields,
  } = item as unknown as ICompanyTargetGroupDataForReview;

  const companyDetails = {
    description: businessType,
    location,
    foundedYear,
    headcount,
    sixMonthHeadcountGrowth,
    PEbackers,
    VCbackers,
    parentCompany,
    founderOwned,
    ownership,
  };

  const hasFinancialData = hasValidFinancialData({
    revenue,
    profit,
    revenueEst,
  });

  const hasAcquisitionData = !!(acquisitions && acquisitions.length > 0);

  const availableSections: string[] = [];
  if (hasFinancialData) availableSections.push('financials');
  if (hasAcquisitionData) availableSections.push('acquisitions');
  if (lastFundraise) availableSections.push('lastFundraise');

  const availableSectionsLength = availableSections.length;

  return (
    isCompanySelected && (
      <CompanyDetailsPanelContainer className={isClosing ? 'closing' : ''} loading={isLoading}>
        {/* TODO: improve animation */}
        <LoadingOverlay isLoading={isLoading}>
          <Box>Loading...</Box>
        </LoadingOverlay>

        <CloseButton onClick={handleClose} />
        <CompanyHeader>
          <div>
            <CompanyName variant="h3">{companyName}</CompanyName>
            <CompanySubtitle>{legalName}</CompanySubtitle>
          </div>

          <SocialLinks>
            {companyLinkedIn && (
              <Link href={companyLinkedIn} target="_blank">
                <img src="/icons/brand/linkedin.svg" alt="LinkedIn" width={16} height={16} />
              </Link>
            )}
            {website && (
              <Link href={website} target="_blank">
                <img src="/icons/website.svg" alt="Website" width={16} height={16} />
              </Link>
            )}
          </SocialLinks>
        </CompanyHeader>
        <DetailsBox
          sx={theme => ({
            gridTemplateColumns: '1fr',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '-1rem',

            [theme.breakpoints.down('lg')]: {
              display: 'flex',
            },
          })}
        >
          <Divider sx={{ borderColor: 'neutrals.gray.low' }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.75rem',
              marginBottom: '0.5rem',
            }}
          >
            {explanation && (
              <CompanyDescriptionContainer>
                <CompanyDescriptionTitle showBorder={false}>Analysis</CompanyDescriptionTitle>
                <CompanyDescription color="neutrals.gray.higher">
                  {explanation.body}{' '}
                  {explanation.sources.map((source, index) => (
                    <CompanyAnalysisSource key={source} href={source} target="_blank">
                      [source {index + 1}]
                    </CompanyAnalysisSource>
                  ))}
                </CompanyDescription>
              </CompanyDescriptionContainer>
            )}
          </Box>

          <CompanyDetailsBox {...companyDetails} />
        </DetailsBox>

        {availableSectionsLength > 0 && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${availableSectionsLength}, 1fr)`,
              gap: '24px',
              marginBottom: '24px',
            }}
          >
            {hasFinancialData && (
              <FinancialsSection
                revenue={revenue}
                profit={profit}
                revenueEst={revenueEst}
                profitYear={profitYear}
                revenueYear={revenueYear}
                currency={currency}
              />
            )}

            {hasAcquisitionData && <AcquiredCompaniesSection acquisitions={acquisitions} />}

            {lastFundraise && <LastFundraiseSection lastFundraise={lastFundraise} />}
          </Box>
        )}

        <LeadershipSection leads={leads} />

        {customFields && Object.keys(customFields).length > 0 && (
          <CustomFieldsSection customFields={customFields} />
        )}

        <NewsEventsSection newsEvents={companyNewsEvents?.companyNewsEvents?.data} />
      </CompanyDetailsPanelContainer>
    )
  );
}
