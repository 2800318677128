import { CSSProperties } from 'react';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    border: {
      input: string;
      dropzone: string;
      focusedAuthInput: string;
      orangeButton: string;
      orangeDimButton: string;
      hoveredOrangeButton: string;
      orangeDisabledButton: string;
    };
    neutrals: {
      gray: {
        lowest: string;
        lower: string;
        low: string;
        medium: string;
        high: string;
        higher: string;
        highest: string;
      };
    };
  }

  interface TypeBackground {
    black: string;
    blue: string;
    midnightBlue: string;
    lightBlue: string;
    lightPeach: string;
    lightPeachDimmed: string;
    lightGrey: string;
    blueTranslucent: string;
    orange: string;
    orangeLight: string;
    orangeDim: string;
    greenDim: string;
    orangeDisabled: string;
    mediumGrey: string;
    darkGrey: string;
    green: string;
    lightInput: string;
    input: { endAdornment: string };
    table: { oddRow: string; evenRow: string };
    chip: {
      active: string;
      metWith: string;
      positiveResponse: string;
      negativeResponse: string;
      keepInTouch: string;
    };
    editor: string;
  }

  interface TypeText {
    placeholder: string;
    error: string;
    primaryDim: string;
    lightGrey: string;
    darkGrey: string;
    orange: string;
    maroon: string;
    maroonDimmed: string;
    chip: {
      active: string;
      metWith: string;
      positiveResponse: string;
      negativeResponse: string;
      keepInTouch: string;
    };
  }

  interface TypeAction {
    hoverBaseButton: string;
    hoverSelectItem: string;
  }
}

export const muiThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  palette: {
    neutrals: {
      gray: {
        lowest: '#ffffff',
        lower: '#f5f5f5',
        low: '#d4d4d4',
        medium: '#737373',
        high: '#404040',
        higher: '#171717',
        highest: '#000000',
      },
    },
    background: {
      black: '#000000',
      blue: '#0F1B39',
      midnightBlue: '#0D1734',
      lightBlue: '#152040',
      lightPeach: '#FFC5B4',
      lightPeachDimmed: 'rgba(255, 197, 180, 0.30)',
      lightGrey: '#D8DCE0',
      blueTranslucent: 'rgba(15, 27, 57, 0.6)',
      orange: '#F6AE2D',
      orangeLight: 'rgba(246, 174, 45, 0.7)',
      orangeDim: 'rgba(246, 174, 45, 0.5)',
      orangeDisabled: 'rgba(246, 174, 45, 0.4)',
      mediumGrey: '#8F939B',
      darkGrey: '#252525',
      //
      greenDim: '#ccf3d1',
      green: '#3DB25A',
      errorTranslucent: '#FECEC4',
      lightInput: '#FBFCFD',
      input: { endAdornment: '#8F939B' },
      table: { oddRow: '#FFFFFF', evenRow: '#E9EDF1' },
      chip: {
        active: 'rgba(94, 175, 142, 0.50)',
        keepInTouch: 'rgba(246, 174, 45, 0.4)',
        metWith: 'rgba(138, 103, 171, 0.50)',
        positiveResponse: 'rgba(148, 227, 132, 1)',
        negativeResponse: 'rgba(204, 120, 47, 0.50)',
        yes: 'rgba(202, 253, 178, 1)',
        no: 'rgba(253, 178, 178, 1)',
      },
      editor: '#E9EDF1',
    },
    text: {
      primary: '#01071A',
      primaryDim: 'rgba(1, 7, 26, 0.5)',
      lightGrey: '#E9EDF1',
      placeholder: '#252525',
      darkGrey: '#252525',
      error: '#FC5130',
      orange: '#F6AE2D',
      maroon: 'rgba(150, 1, 24, 0.80)',
      maroonDimmed: 'rgba(150, 1, 24, 0.45)',
      chip: {
        active: '#35745A',
        keepInTouch: 'rgba(150, 40, 24, 0.80)',
        metWith: '#7B50A4',
        positiveResponse: '#569e23',
        negativeResponse: '#AC3D35',
        yes: '#66CB36',
        no: '#DA5353',
      },
    },
    border: {
      input: '1.5px solid #E9EDF1',
      dropzone: '1px solid rgba(56, 78, 183, 0.30)',
      focusedAuthInput: '2px solid #F6AE2D',
      orangeButton: '1px solid #F6AE2D',
      orangeDimButton: '1px solid rgba(246, 174, 45, 0.5)',
      orangeDisabledButton: '1px solid rgba(246, 174, 45, 0.4)',
      hoveredOrangeButton: '1px solid #EDA621',
    },
    action: { hoverBaseButton: '#EDA621', hoverSelectItem: '#141E3D' },
  },
  typography: {
    fontFamily: '"Darker Grotesque", sans-serif',
    button: {
      fontFamily: '"Darker Grotesque", sans-serif',
      fontWeight: 400,
    },
    h4: {
      fontFamily: '"Darker Grotesque", sans-serif',
      fontStyle: 'normal',
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#ffffff',
    },
  },
  shape: { borderRadius: 0 },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardError': {
            backgroundColor: '#FECEC4',
            color: '#FC5130',
            svg: { color: '#FC5130' },
            width: '400px',
            position: 'fixed' as CSSProperties['position'],
            top: '15px',
            right: '15px',
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {},
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
      },
    } as any,
    MuiMenu: {
      styleOverrides: {
        paper: { backgroundColor: 'transparent', boxShadow: 'none' },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: '"Darker Grotesque", sans-serif',
          fontSize: '0.8rem',
          fontWeight: 500,
          lineHeight: '19px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
          '@media (min-width: 600px)': {
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // root: {
        //   fontFamily: "'Inter', sans-serif",
        //   fontStyle: 'normal',
        //   fontWeight: 500,
        //   fontSize: '1rem',
        //   lineHeight: '19px',
        //   textTransform: 'none' as CSSProperties['textTransform'],
        //   backgroundColor: '#F6AE2D',
        //   color: '#01071A',
        //   border: '1px solid #F6AE2D',
        //   borderRadius: '10px',
        //   '&:hover': {
        //     color: '#01071A',
        //     backgroundColor: '#EDA621',
        //   },
        // },
      },
    },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiInputLabel-root': {
    //         fontFamily: '"Darker Grotesque", sans-serif',
    //         fontWeight: 400,
    //         lineHeight: '130%',
    //         position: 'relative',
    //         top: '0',
    //         left: '0',
    //         transition: 'none',
    //         transform: 'none',
    //         marginBottom: '7px',
    //       },
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: '0',
    //       padding: '0',
    //       fontFamily: "'Inter', sans-serif",
    //     },
    //   },
    // },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "'Inter', sans-serif",
    //     },
    //   },
    // },
  },
};
