import { Portal } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import { IBaseModalProps } from '../../feedback-ui/feedback-ui.types';
import { modalContentStrategy } from './modal-content.strategy';

function BaseModal({
  isUiVisible,
  uiContentType,
  title,
  message,
  cancelTitle,
  actionTitle,
  handleCancel,
  isActionLoading,
  ...props
}: IBaseModalProps) {
  const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>(false);

  function handleClose(event: SyntheticEvent) {
    event.stopPropagation();
    setIsModalVisible(false);
    if (props.handleClose && typeof props.handleClose === 'function') props.handleClose(event);
  }

  async function handleAction(event: SyntheticEvent) {
    event.stopPropagation();
    if (props.handleAction && typeof props.handleAction === 'function')
      await props.handleAction(event);
    setIsModalVisible(false);
  }

  useEffect(() => {
    if (isUiVisible) setIsModalVisible(true);
  }, [isUiVisible]);

  return isModalVisible ? (
    <Portal>
      {uiContentType &&
        modalContentStrategy?.[uiContentType]({
          title,
          message,
          cancelTitle,
          actionTitle,
          handleAction,
          handleCancel,
          handleClose,
          isActionLoading,
          checkboxConfig: props.checkboxConfig,
        })}
    </Portal>
  ) : null;
}

export default BaseModal;
