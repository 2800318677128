import { RootState } from '../../../app/store/utils/redux.types';
import {
  IPeopleResearchLead,
  IResearchCompany,
} from '../../company-target-group/domain/company-target-group.types';
import {
  IAnalytic,
  IClientsVisibilityKPIsResponse,
  ICurrentTargetGroupParams,
  IExampleCompany,
  IPrompt,
  IPromptAccuracy,
  ITargetGroup,
  ITargetGroupKPIsResponse,
  ITargetGroupsListResponse,
  ITargetGroupThunks,
  TargetGroupState,
} from '../domain/target-group.types';

export function getCompanyCountFilter(state: RootState): string | undefined {
  return state.targetGroup.companyCountFilter;
}

export function targetGroupStateSelector(state: RootState): TargetGroupState {
  return state.targetGroup;
}

export function targetGroupThunksSelector(state: RootState): ITargetGroupThunks {
  return state.targetGroup.thunks;
}

export function targetGroupsForClientsListSelector(state: RootState): ITargetGroupsListResponse {
  return state.targetGroup.allCLientsTGsList;
}

export function targetGroupsWeeklyKPIsSelector(state: RootState): ITargetGroupKPIsResponse {
  return state.targetGroup.targetGroupsWeeklyKPIs;
}

export function clientsVisibilityKPIsSelector(state: RootState): IClientsVisibilityKPIsResponse {
  return state.targetGroup.clientsVisibilityKPIs;
}

export function targetGroupsListSelector(state: RootState): ITargetGroupsListResponse {
  return state.targetGroup.targetGroupsList;
}

export function currentTargetGroupParamsSelector(state: RootState): ICurrentTargetGroupParams {
  return state.targetGroup.currentTargetGroupParams;
}

export function selectedEmailSequenceIndexSelector(state: RootState): number {
  return state.targetGroup.selectedEmailSequenceIndex;
}

export function currentTargetGroupSelector(state: RootState): ITargetGroup {
  return state.targetGroup.currentTargetGroup;
}

export function campaignsSelector(state: RootState): any[] {
  return state.targetGroup.campaigns;
}

export function exampleCompaniesSelector(state: RootState): IExampleCompany[] {
  return state.targetGroup.exampleCompanies;
}

export function promptsSelector(state: RootState): IPrompt[] {
  return state.targetGroup.prompts;
}
export function promptAccuraciesSelector(state: RootState): IPromptAccuracy[] {
  return state.targetGroup.promptAccuracies;
}

export function researchCompaniesListSelector(state: RootState): IResearchCompany[] {
  return state.targetGroup.researchCompanies;
}

export function peopleResearchLeadsSelector(state: RootState): IPeopleResearchLead[] {
  return state.targetGroup.peopleResearchLeads;
}

export function analyticsSelector(state: RootState): IAnalytic[] {
  return state.targetGroup.analytics;
}
