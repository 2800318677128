import { styled } from '@mui/material/styles';

export const LinkWrapper = styled('a', { shouldForwardProp: props => props !== 'isActive' })<{
  isActive: boolean;
}>(({ isActive, theme }) => ({
  minHeight: '1.125rem',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '.5rem',
  transition: 'opacity 0.3s',
  flexShrink: 0,

  '&:hover': {
    opacity: 0.8,
  },

  '& .left-panel-list-link-title': {
    color: isActive ? theme.palette.neutrals.gray.highest : theme.palette.neutrals.gray.medium,
    cursor: 'pointer',
    textTransform: 'uppercase',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '.8rem',
    fontWeight: isActive ? 500 : 400,
    borderLeft: '1px solid',
    padding: '0.25rem 0.5rem',
    width: '100%',
    borderColor: isActive ? theme.palette.neutrals.gray.medium : 'transparent',
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.65rem',
    },

    '&:hover': {
      borderColor: theme.palette.neutrals.gray.medium,
    },
  },

  '& .left-panel-list-link-todo-indicator': {
    color: theme.palette.neutrals.gray.medium,
  },
}));

export const LinkText = styled('span')(() => ({
  fontFamily: '"DM Mono", serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '110%',
  transition: 'transform 0.2s',
  textOverflow: 'ellipsis',
}));
