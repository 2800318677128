import FetchTargetGroupsList from '../../../features/fetch-target-groups-list/components/fetch-target-groups-list.component';
import { PipelineStageName } from '../../navigation/components/pipeline-navigation/pipeline-navigation.types';
import { ReviewLeftSectionContainer } from './review-left-section.styles';

function ReviewLeftSection() {
  return (
    <ReviewLeftSectionContainer>
      <FetchTargetGroupsList stageName={PipelineStageName.REVIEW} />
    </ReviewLeftSectionContainer>
  );
}

export default ReviewLeftSection;
