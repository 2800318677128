import { resetUser } from '../../entities/user/store/user.slice';
import axios from './axios.service';

// Track token refresh attempts to prevent infinite loops
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Add a callback that will be executed once the token is refreshed
function subscribeTokenRefresh(cb: (token: string) => void) {
  refreshSubscribers.push(cb);
}

// Execute all callbacks with the new token
function onTokenRefreshed(token: string) {
  refreshSubscribers.forEach(cb => cb(token));
  refreshSubscribers = [];
}

// Cancel all token refresh callbacks
function onRefreshError() {
  refreshSubscribers = [];
}

export const setUpInterceptor = (
  store: any,
  getAccessTokenSilently: () => Promise<string>,
  logout: () => Promise<void>,
) => {
  // Clear previous interceptors to prevent duplicates
  axios.interceptors.request.eject(0);
  axios.interceptors.response.eject(0);

  axios.interceptors.request.use(async (config: any) => {
    try {
      if (!isRefreshing) {
        const accessToken = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    } catch (error) {
      console.error('Error getting token for request:', error);
      return config;
    }
  });

  axios.interceptors.response.use(
    config => config,
    async error => {
      const originalRequest = error.config;

      // Check for auth errors that require token refresh
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          // Wait for token refresh if already in progress
          try {
            const token = await new Promise<string>((resolve, reject) => {
              subscribeTokenRefresh(token => {
                resolve(token);
              });
              // Set timeout to prevent hanging promises
              setTimeout(() => {
                reject(new Error('Token refresh timeout'));
              }, 10000);
            });

            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } catch (refreshError) {
            console.error('Error waiting for token refresh:', refreshError);
            return Promise.reject(refreshError);
          }
        } else {
          originalRequest._retry = true;
          isRefreshing = true;

          try {
            // Attempt to get a fresh token
            const token = await getAccessTokenSilently();

            // Update the original request authorization header
            originalRequest.headers.Authorization = `Bearer ${token}`;

            // Notify all waiting requests
            onTokenRefreshed(token);

            // Reset refreshing flag
            isRefreshing = false;

            // Retry the original request
            return axios(originalRequest);
          } catch (refreshError) {
            // If refresh fails, clear subscribers and logout
            onRefreshError();
            isRefreshing = false;
            console.error('Token refresh failed:', refreshError);

            // Logout the user
            try {
              await logout();
              store.dispatch(resetUser());
            } catch (logoutError) {
              console.error('Error during logout after token refresh failure:', logoutError);
            }

            return Promise.reject(error);
          }
        }
      }

      // Handle other errors or pass them through
      return Promise.reject(error);
    },
  );
};
