import { useEffect, useRef, useState } from 'react';

export function usePolling(
  pollingFunction: () => Promise<any>,
  checkStopCondition: (status: any) => boolean,
  pollingInterval: number = 3000,
) {
  const [isPolling, setIsPolling] = useState(false);
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  const startPolling = () => {
    if (!isPolling) {
      setIsPolling(true);
    }
  };

  const stopPolling = () => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
    setIsPolling(false);
  };

  useEffect(() => {
    if (isPolling && !pollingRef.current) {
      pollingRef.current = setInterval(async () => {
        try {
          const response = await pollingFunction();
          if (checkStopCondition(response)) {
            stopPolling();
          }
        } catch (error) {
          console.error('Polling failed:', error);
          stopPolling();
        }
      }, pollingInterval);
    }

    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, [isPolling]);

  return { startPolling, stopPolling, isPolling };
}
