import { TypeformFieldType } from '../../../modules/target-definition-form/components/target-definition-form.types';
import {
  BaseItemServerResponse,
  BaseListServerResponse,
} from '../../../shared/domain/types/base.types';
import {
  IPeopleResearchLead,
  IResearchCompany,
} from '../../company-target-group/domain/company-target-group.types';

export interface ITargetGroupEmail {
  subject: string;
  body: string;
}

export interface IApolloSearchOutcome {
  url: string;
  status: 'success' | 'fail';
  date: string;
  companiesParsed: number;
}

export interface Requirement {
  id: string;
  question: string;
  answer: string | number;
  type: TypeformFieldType;
  options?: object;
}

export interface ILocation {
  id?: string;
  state?: string;
  country?: string;
  continent?: string;
  region?: string;
  latitude: string;
  longitude: string;
  name: string;
}

export interface IOwnershipStructures {
  founderOwned?: boolean;
  PEbacked?: boolean;
  VCbacked?: boolean;
  subsidiary?: boolean;
  public?: boolean;
}

export enum CompanySourcesType {
  APOLLO = 'APOLLO',
  FILE = 'FILE',
}

export enum SourceClassificationStatuses {
  running = 'running',
  paused = 'paused',
  pausing = 'pausing',
  done = 'done',
  failed = 'failed',
}

export enum OwnershipStructureType {
  FOUNDER_OWNED = 'Founder Owned',
  PE_BACKED = 'PE Backed',
  VC_BACKED = 'VC Backed',
  SUBSIDIARY = 'Subsidiary',
  PUBLIC = 'Public',
}

export interface CompanySource {
  id: string;
  targetGroupId: string;
  url: string;
  name?: string;
  isRunning?: boolean;
  minHeadcount?: number;
  maxHeadcount?: number;
  locations?: string[];
  keywords?: string[];
  classificationStatus: SourceClassificationStatuses | null;
  sourceType: CompanySourcesType;
  kpi?: Record<string, any>;
  notes?: string;
}

export interface ITargetGroup {
  id: string;
  clientId: string | undefined;
  name: string;
  description: string;
  emailSequences: ITargetGroupEmail[];
  headcount?: string;
  revenue: string;
  regions: string;
  keywords: string[];
  relevantPastDeals: string;
  relevantAcquirers: string;
  otherRelevantInformation: string;
  campaignOwner: { id: string; name: string };
  createdAt: string;
  updatedAt: string;
  countCompanies?: number;
  aiPrompt?: string;
  excludeBusinessTypes?: string;
  maxHeadcount?: number | null;
  minHeadcount?: number | null;
  minRevenue?: string | null;
  maxRevenue?: string | null;
  locations?: ILocation[];
  ownershipStructures?: IOwnershipStructures;
  revenueCurrency?: string;

  industryFilters?: string[];
  apolloSearches?: string[];
  aiPromptOutcome?: any;
  apolloSearchOutcome?: IApolloSearchOutcome[];
  selectionJobToggle?: 'on' | 'off';
  weeklySelectionRate?: number;
  sourceFiles?: string[];
  requirements?: Requirement[];
  favouritePromptId?: string;
  companiesSources: CompanySource[];
  recyclingToggle?: 'on' | 'off';
  peopleResearchStatus?: 'done' | 'running';
  bulkContactStatus?: 'done' | 'running';
  companiesToResearchCount?: number;
  eventsCount?: number;
  leadsCount?: number;
}

export interface ICreateTargetGroupBody
  extends Omit<ITargetGroup, 'id' | 'createdAt' | 'updatedAt'> {}

export interface IUpdateTargetGroupBody extends Omit<ICreateTargetGroupBody, 'clientId'> {}

export interface IUpdateTargetGroupParams {
  targetGroupId: string;
  body: Partial<IUpdateTargetGroupBody>;
}

export interface ITargetGroupsListResponse extends BaseListServerResponse<ITargetGroup> {}

export interface ITargetGroupKPI {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  clientId?: string;
  clientName?: string;
  companyName?: string;
  targetGroupId?: string;
  targetGroupName?: string;
  week?: string;
  uploadBeforeProcessing?: number;
  uploadAfterProcessing?: number;
  researchedTotal?: number;
  repliedTotal?: number;
  researchedFit?: number;
  reviewedTotal?: number;
  reviewedFit?: number;
  researchedRecycledTotal?: number;
  researchedRecycledFit?: number;
  reviewedRecycledTotal?: number;
  reviewedRecycledFit?: number;
}

export interface ITargetGroupKPIsResponse extends BaseListServerResponse<ITargetGroupKPI> {}

export interface IClientsVisibilityKPIsResponse
  extends BaseListServerResponse<IClientVisibilityKPI> {}

export interface IClientVisibilityKPI {
  clientName?: string;
  inRecycling?: number;
  estimatedFitCompaniesLeft?: number;
  selectionWeeksLeft?: number;
  companiesToResearch?: number;
  companiesToReview?: number;
  recyclingScheduleForNext4Weeks?: string;
}

export interface IAnalytic {
  targetGroupId?: string;
  targetGroupName?: string;
  companiesFound?: number;
  companiesPreFiltered?: number;
  companiesScraped?: number | string;
  companiesSelected?: number;
  companiesVerified?: number | string;
  peopleFound?: number;
  peopleVerified?: number | string;
  companiesAtReview?: number;
}

export interface ICurrentTargetGroupParams {
  id: string;
  name: string;
}

export interface IThunkStatuses {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
}

export interface ITargetGroupThunks {
  getClientsVisibilityKPIs: IThunkStatuses;
  getTargetGroupsWeeklyKPIs: IThunkStatuses;
  getAllTargetGroupsByClient: IThunkStatuses;
  getTargetGroupById: IThunkStatuses;
  createTargetGroup: IThunkStatuses;
  updateTargetGroup: IThunkStatuses;
  deleteTargetGroup: IThunkStatuses;
  fetchCampaigns: IThunkStatuses;
  promptExampleCompanies: IThunkStatuses;
  enrichExampleCompanies: IThunkStatuses;
  runSearches: IThunkStatuses;
  runClassification: IThunkStatuses;
  getForAllClientsTargetGroupsList: IThunkStatuses;
  deleteUploadedFile: IThunkStatuses;
  getExampleCompanies: IThunkStatuses;
  getPromptAccuracies: IThunkStatuses;
  modifyExampleCompany: IThunkStatuses & { companyTargetGroupId: string | null };
  prompts: IThunkStatuses;
  runPrompt: IThunkStatuses;
  generateKeywords: IThunkStatuses;
  batchCrawlExampleCompanies: IThunkStatuses;
  fetchResearchCompaniesList: IThunkStatuses;
  updateResearchCompany: IThunkStatuses;
  getCompanySourceStatuses: IThunkStatuses;
  fetchPeopleResearchLeads: IThunkStatuses;
  findPeople: IThunkStatuses;
  pushToReview: IThunkStatuses;
  updateCompanySource: IThunkStatuses;
  fetchTargetGroupAnalytics: IThunkStatuses;
}

export type TargetGroupState = {
  clientsVisibilityKPIs: IClientsVisibilityKPIsResponse;
  targetGroupsWeeklyKPIs: ITargetGroupKPIsResponse;
  allCLientsTGsList: ITargetGroupsListResponse;
  targetGroupsList: ITargetGroupsListResponse;
  currentTargetGroupParams: ICurrentTargetGroupParams;
  currentTargetGroup: ITargetGroup;
  selectedEmailSequenceIndex: number;
  campaigns: any[];
  classificationTriggeredForTGs: Set<string>;
  exampleCompanies: IExampleCompany[];
  thunks: ITargetGroupThunks;
  companyCountFilter: string | undefined;
  prompts: IPrompt[];
  promptAccuracies: IPromptAccuracy[];
  classificationKpi: IClassificationKpi;
  researchCompanies: IResearchCompany[];
  peopleResearchLeads: IPeopleResearchLead[];
  analytics: IAnalytic[];
};

export enum TargetGroupFormField {
  TARGET_GROUP_NAME = 'Target group name',
  SHORT_DESCRIPTION = 'Target description',
  EXCLUDE_BUSINESS_TYPES = 'Are there specific types of businesses in this target group that you are not interested in?',
  SIZE_HEADCOUNT = 'Size (Headcount)', // remove it
  SIZE_REVENUE = 'Size (Revenue)',
  REGIONS = 'Regions', // remove it
  KEYWORDS = 'Keywords to look for',
  EXAMPLE_COMPANIES = 'Examples of good fit companies',
  BAD_EXAMPLE_COMPANIES = 'Example of bad fit companies',
  RELEVANT_PAST_DEALS = 'Relevant Past Deals',
  RELEVANT_ACQUIRERS = 'Relevant Acquirers you know',
  OTHER_RELEVANT_INFORMATION = 'Other relevant information',
  CAMPAIGN_OWNER = 'Campaign Owner',
  EMAIL_1 = 'Email 1',
  EMAIL_2 = 'Email 2',
  AI_PROMPT = 'AI Prompt',
  MIN_HEADCOUNT = 'Minimum headcount',
  MAX_HEADCOUNT = 'Maximum headcount',
  LOCATIONS = 'Headquarters location(s) *',
  MIN_REVENUE = 'Minimum Revenue',
  MAX_REVENUE = 'Maximum Revenue',
  OWNERSHIP_STRUCTURES = 'Ownership Structures',
  INDUSTRY_FILTERS = 'Industry Filters',
  APOLLO_SEARCHES = 'Apollo Searches',
}

export interface IExampleCompanyResponse extends BaseItemServerResponse<IExampleCompany> {}

export enum IClassificationStatus {
  GOOD = 'good',
  BAD = 'bad',
}

export interface ICrawlResult {
  url: string;
  text: string;
  rankedSubpages?: string[];
}

export interface IExampleCompanyPrompt {
  id: string;
  promptId: string;
  result: IClassificationStatus;
}

export enum AppearOnStage {
  STAGE_2 = 'stage-2',
  STAGE_3 = 'stage-3',
}

export interface IExampleCompany {
  id: string;
  name: string;
  comment?: string;
  website: string;
  linkedIn: string;
  isGoodFit: boolean | null;
  companyId: string;
  appearOn: AppearOnStage[];
  crawlResult?: ICrawlResult;
  createdAt?: Date;
  exampleCompanyPrompts?: IExampleCompanyPrompt[];
  promptAccuracies?: IPromptAccuracy[];
}

export interface IPromptAccuracy {
  promptId: string;
  accuracy: IAccuracy;
}

export interface IAccuracy {
  matches: number;
  total: number;
}

export type IUpsertExampleCompanyBody = Omit<IExampleCompany, 'id' | 'companyId'>;

export interface IUpdateExampleCompanyParams {
  body: Partial<IUpsertExampleCompanyBody>;
  companyId: string;
  exampleCompanyId: string;
}

export interface IUpsertExampleCompanyParams {
  body: Partial<IUpsertExampleCompanyBody>;
  targetGroupId: string;
}

export enum PromptModelType {
  GPT_4 = 'gpt-4',
  GPT_4O = 'gpt-4o',
  LLAMA_31 = 'llama-3.1',
}

export interface IPrompt {
  id: string;
  targetGroupId: string;
  input?: string;
  model?: PromptModelType;
}

export interface ICreatePromptParams {
  body: Partial<IPrompt>;
  targetGroupId: string;
}

export interface IUpdatePromptParams {
  body: Partial<IPrompt>;
  promptId: string;
}

export interface IToggleFavouritePrompt {
  targetGroupId: string;
  promptId: string;
}

export interface IRunSearchFormParams {
  name: string;
  minHeadcount: string;
  maxHeadcount: string;
  locations: string[];
  keywords: string[];
}

export enum ClassificationControlActions {
  run = 'run',
  pause = 'pause',
  retry = 'retry',
}

export interface IClassificationKpi {
  processed: number;
  classified: number;
  skipped: number;
  remained: number;
  total: number;
  crawled: number;
  errored: number;
  id: number;
  targetGroupId: string;
  sourceId: number;
  createdAt: string;
  promptId: string;
  prompt: IPrompt;
  conversionPercentage: number;
  processedPercentage: number;
  crowledPercentage: number;
  classifiedPercentage: number;
  erroredPercentage: number;
}
