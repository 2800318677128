import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const TextareaContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  '& .textarea-validation-helper': {
    margin: 0,
    paddingInlineStart: '20px',

    li: {
      p: {
        fontSize: '0.65rem',
        color: theme.palette.text.error,

        [theme.breakpoints.up('xl')]: {
          fontSize: '0.8rem',
        },
      },
    },
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const MuiTextarea = styled(TextField, {
  shouldForwardProp: props => props !== 'hasError',
})<TextFieldProps & { hasError: boolean }>(({ hasError, theme }) => ({
  marginBottom: hasError ? '4px' : '0',
  width: '100%',
  '& .MuiInputLabel-root': {
    color: theme.palette.neutrals.gray.highest,
    fontSize: '1rem',
    '&.Mui-focused': {
      color: theme.palette.neutrals.gray.highest,
    },
    '&.Mui-error': {
      color: theme.palette.common.white,
    },
    '&.Mui-disabled': { color: theme.palette.common.white },
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.neutrals.gray.lowest,
    color: theme.palette.text.primary,
    border: `1px solid ${hasError ? theme.palette.text.error : theme.palette.neutrals.gray.low}`,
    padding: '0.75rem',
    borderRadius: '.5rem',
    minHeight: '36px',
    gap: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      top: 0,
    },
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0 1.3%',
    minWidth: '100px',
    [theme.breakpoints.down(1600)]: {
      padding: '0 1.8%',
    },
  },
  '& .MuiInputBase-input': {
    padding: 0,
    width: '100%',
    color: theme.palette.text.darkGrey,
    fontSize: '1rem',
    lineHeight: '17px',
    fontWeight: 500,
  },
  '&.Mui-error': {
    color: theme.palette.text.error,
  },
}));
