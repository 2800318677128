import React from 'react';

import RightSectionMain from '../../../app/layouts/layout-components/right-section-main/right-section-main.component';
import PageContentHeader from '../../../app/layouts/page-content/page-content-header/page-content-header.component';
import { useAppSelector } from '../../../app/store/utils/redux.hooks';
import {
  currentTargetGroupParamsSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import MarketMappingContent from '../../market-mapping-content/components/market-mapping-content.component';
import { MarketMappingRightSectionContainer } from './market-mapping-right-section.styles';
import NoTargetGroupChosenScreen from './no-target-group-chosen-screen/no-target-group-chosen-screen.component';

function MarketMappingRightSection() {
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const isTargetGroupChosen = Boolean(targetGroupId);

  const { isLoading: isTargetGroupLoading } =
    useAppSelector(targetGroupThunksSelector).getTargetGroupById;

  return (
    <MarketMappingRightSectionContainer isTargetGroupChosen={isTargetGroupChosen}>
      {!isTargetGroupChosen && <PageContentHeader title="Market Mapping" />}
      <RightSectionMain
        isListEntityChosen={isTargetGroupChosen}
        isDataLoading={isTargetGroupLoading}
        content={<MarketMappingContent />}
        noContent={<NoTargetGroupChosenScreen />}
      />
    </MarketMappingRightSectionContainer>
  );
}

export default MarketMappingRightSection;
