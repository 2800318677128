import { useNavigate, useParams } from 'react-router-dom';

import { UserRole } from '@/entities/user/domain/user.types';

import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import {
  currentClientSelector,
  userSelector,
} from '../../../../entities/user/store/user.selectors';
import PipelineNavigationButton from '../../../../shared/components/button/pipeline-navigation-button/pipeline-navigation-button.component';
import BaseMapper from '../../../../shared/components/mapper/base-mapper/base-mapper.component';
import { useIsRouteMatched } from '../../../../shared/hooks/use-is-route-matched.hook';
import {
  pipelineNavigationStrategy,
  userRoleStageSkippingConfig,
} from './pipeline-navigation.strategy';
import {
  PipelineNavigationContainer,
  PipelineNavigationWrapper,
} from './pipeline-navigation.styles';
import { PipelineStageName } from './pipeline-navigation.types';

function PipelineNavigation() {
  const { isRouteMatched } = useIsRouteMatched();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const currentClient = useAppSelector(currentClientSelector);
  const { roles } = useAppSelector(userSelector);

  function handleClick(navigationPath: string) {
    navigate(navigationPath);
  }

  function checkIsCurrentRoute(route: string) {
    return isRouteMatched(route);
  }

  function checkIsPreviousRoute(currentIndex: number, routes: [string, any][]) {
    const currentRouteIndex = routes.findIndex(([, stageParams]) =>
      isRouteMatched(stageParams.currentRoute),
    );
    return currentIndex < currentRouteIndex;
  }

  function isStageAllowed([stage]: [string, any]): boolean {
    const clientSpecificSkippedStages = currentClient?.pipelineConfig?.skipStages || [];
    const userStagesToSkip = roles?.length
      ? roles.includes(UserRole.EMPLOYEE)
        ? userRoleStageSkippingConfig?.[UserRole.EMPLOYEE]
        : userRoleStageSkippingConfig?.[UserRole.CLIENT] || []
      : [];
    const isSkippedForRole = clientSpecificSkippedStages.includes(stage as PipelineStageName);
    const isSkippedForUser = userStagesToSkip.includes(stage as PipelineStageName);
    const isSkipped = isSkippedForRole || isSkippedForUser;
    return !isSkipped;
  }

  function mapToPipelineNavigationButtonsConfig() {
    const pipelineNavigationEntries = Object.entries(
      pipelineNavigationStrategy(clientId as string),
    );
    const allowedEntries = pipelineNavigationEntries.filter(isStageAllowed);

    return allowedEntries.map(([, stageParams], index) => {
      const { stageName, navigateTo, currentRoute, key, indicator } = stageParams;
      return {
        upperLine: `stage ${index + 1}`,
        bottomLine: stageName,
        handleClick: () => handleClick(navigateTo),
        isCurrentRoute: checkIsCurrentRoute(currentRoute),
        isPreviousRoute: checkIsPreviousRoute(index, allowedEntries),
        key,
        indicator,
      };
    });
  }

  return (
    <PipelineNavigationWrapper className="pipeline-navigation">
      <PipelineNavigationContainer
        spacing={{ xs: 0.8, sm: 1.5, md: 2.5 }}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <BaseMapper
          component={PipelineNavigationButton}
          propsArray={mapToPipelineNavigationButtonsConfig()}
        />
      </PipelineNavigationContainer>
    </PipelineNavigationWrapper>
  );
}

export default PipelineNavigation;
