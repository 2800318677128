import React from 'react';

import {
  IOwnershipStructures,
  OwnershipStructureType,
} from '../../../entities/target-group/domain/target-group.types';
import AutocompleteWithMultipleChoices from '../../../shared/components/input/autocomplete-with-multiple-choice-with-sublabel/autocomplete-with-multiple-choice-with-sublabel.component';
import { IOption } from '../../../shared/components/input/autocomplete-with-multiple-choice-with-sublabel/autocomplete-with-multiple-choice-with-sublabel.types';
import { ISelectOwnershipStructuresProps } from './select-ownership-structures.types';

function SelectOwnershipStructures({
  id,
  label,
  name,
  values,
  handleChange,
  lastFocusedInput,
  setLastFocusedInput,
  placeholder,
  showInfoIcon,
  disabled = false,
  errors,
}: ISelectOwnershipStructuresProps) {
  const ownershipMapping: Record<keyof IOwnershipStructures, OwnershipStructureType> = {
    founderOwned: OwnershipStructureType.FOUNDER_OWNED,
    PEbacked: OwnershipStructureType.PE_BACKED,
    VCbacked: OwnershipStructureType.VC_BACKED,
    subsidiary: OwnershipStructureType.SUBSIDIARY,
    public: OwnershipStructureType.PUBLIC,
  };

  const options: IOption<OwnershipStructureType>[] = Object.values(OwnershipStructureType).map(
    type => ({
      label: type,
      value: type,
      extraData: type as OwnershipStructureType,
    }),
  );

  function handleOptionsChange(
    event: React.SyntheticEvent<Element, Event>,
    value: readonly IOption<OwnershipStructureType>[],
  ) {
    const selectedTypes = value
      .map(v => v.extraData)
      .filter((type): type is OwnershipStructureType => type !== undefined);

    const mappedValues = Object.keys(ownershipMapping).reduce((acc, key) => {
      acc[key as keyof IOwnershipStructures] = selectedTypes.includes(
        ownershipMapping[key as keyof IOwnershipStructures],
      );
      return acc;
    }, {} as IOwnershipStructures);

    handleChange(name, mappedValues);
  }

  const defaultValues: IOwnershipStructures = {
    founderOwned: false,
    PEbacked: false,
    VCbacked: false,
    subsidiary: false,
    public: false,
  };

  const currentValues = values[name] ?? defaultValues;
  const selectedOptions = Object.entries(currentValues)

    .filter(([_, value]) => value)
    .map(([key]) => ({
      label: ownershipMapping[key as keyof IOwnershipStructures],
      value: ownershipMapping[key as keyof IOwnershipStructures],
      extraData: ownershipMapping[key as keyof IOwnershipStructures],
    }));

  return (
    <AutocompleteWithMultipleChoices
      id={id}
      label={label}
      name={name}
      selectedOptions={selectedOptions}
      options={options}
      handleOptionsChange={handleOptionsChange}
      errors={errors}
      disabled={disabled}
      lastFocusedInput={lastFocusedInput}
      setLastFocusedInput={setLastFocusedInput}
      placeholder={placeholder}
      showInfoIcon={showInfoIcon}
    />
  );
}

export default SelectOwnershipStructures;
