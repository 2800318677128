import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const MuiChip = styled(Chip)<any>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.125rem 0.25rem 0.125rem 0.5rem',
  gap: '0.25rem',
  borderRadius: '100px',
  background: theme.palette.neutrals.gray.lower,
  margin: 0,
  height: 'auto',

  color: theme.palette.neutrals.gray.high,
  fontFamily: '"DM Mono", serif',
  fontSize: '0.6rem',
  fontWeight: 400,
  lineHeight: '170%',
  textTransform: 'uppercase',
  '&.MuiAutocomplete-tag': { margin: 0 },
  '& .MuiChip-label': { padding: 0 },
  '& .MuiChip-deleteIcon': {
    margin: 0,
    fontSize: '0.75rem',
    color: theme.palette.neutrals.gray.high,
    transition: 'color 0.2s ease',
    '&:hover': { color: theme.palette.neutrals.gray.higher },
  },
}));

export const DeleteIcon = styled(CloseRoundedIcon)(() => ({
  width: '0.75rem',
  height: '0.75rem',
}));
