import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import { companyReducer } from '../../entities/company/store/company.slice';
import { companyTargetGroupReducer } from '../../entities/company-target-group/store/company-target-group.slice';
import { targetGroupReducer } from '../../entities/target-group/store/target-group.slice';
import { userReducer } from '../../entities/user/store/user.slice';
import { authenticationReducer } from '../../features/authentication/store/authentication.slice';
import { leftPanelReducer } from '../../features/fetch-target-groups-list/store/left-panel.slice';
import { toastReducer } from '../../shared/store/toast.slice';
import { setUpInterceptor } from '../services/axios-interceptors.config';

const store = configureStore({
  reducer: {
    user: userReducer,
    authentication: authenticationReducer,
    targetGroup: targetGroupReducer,
    company: companyReducer,
    companyTargetGroup: companyTargetGroupReducer,
    leftPanel: leftPanelReducer,
    toast: toastReducer,
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
});

const persistor = persistStore(store);

export { persistor, setUpInterceptor, store };
