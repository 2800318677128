import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const ClientsListHeaderContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '85px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const SectionHeaderName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '2rem',
  fontFamily: '"Darker Grotesque", sans-serif',
  lineHeight: '80%',
  fontWeight: 400,
  color: theme.palette.neutrals.gray.highest,

  [theme.breakpoints.up('xl')]: {
    fontSize: '3rem',
  },
}));
