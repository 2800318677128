import { FormControlLabel, FormLabel, Radio, RadioGroup, styled } from '@mui/material';

export const Label = styled(FormLabel)(({ theme }) => ({
  width: '100%',
  color: theme.palette.neutrals.gray.highest,
  fontSize: '0.875rem',
  fontFamily: '"Darker Grotesque",sans-serif',
  fontWeight: 400,
  lineHeight: '130%',
  position: 'relative',
  transform: 'none',

  '&.Mui-focused': {
    color: theme.palette.neutrals.gray.highest,
  },

  '&.Mui-error': {
    color: theme.palette.neutrals.gray.highest,
  },
}));

export const MuiRadioGroup = styled(RadioGroup)(() => ({}));

export const MuiFormControlLabel = styled(FormControlLabel)(() => ({}));

export const MuiRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.neutrals.gray.highest,
  },

  '&:hover': {
    backgroundColor: 'rgba(156, 156, 156, 4%)',
  },
}));
