import { CircularProgress } from '@mui/material';
import {
  AllEnterpriseModule,
  GridOptions,
  LicenseManager,
  ModuleRegistry,
  themeBalham,
} from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';

import { StyledTableContainer } from './table-v2.styles';
import { ITableV2Props } from './table-v2.types';

LicenseManager.setLicenseKey(import.meta.env['VITE_AG_GRID_LICENSE_KEY'] || '');

ModuleRegistry.registerModules([AllEnterpriseModule]);

function TableV2({ rowData, columnDefs, gridOptions, className }: ITableV2Props) {
  const defaultGridOptions = {
    theme: themeBalham,
    getMainMenuItems: () => [
      'sortAscending',
      'sortDescending',
      'separator',
      'pinSubMenu',
      'separator',
      'columnChooser',
    ],
    loadingOverlayComponent: CircularProgress,
    ...gridOptions,
  } as GridOptions;

  return (
    <StyledTableContainer className={className}>
      <AgGridReact {...defaultGridOptions} rowData={rowData} columnDefs={columnDefs} />
    </StyledTableContainer>
  );
}

export default TableV2;
