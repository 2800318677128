export const BaseValidationErrorMessages = {
  NOT_EMPTY: 'Cannot be empty',
  MUST_BE_STRING: 'Must be a string',
  EMAIL_FORMAT_INVALID: 'Invalid email format',
  DOES_NOT_MATCH_THE_PATTERN: 'The value provided does not match a required pattern',
  INCORRECT_LENGTH: 'Incorrect length',
  INCORRECT_VALUE: 'Incorrect value provided',
  VALUE_MUST_BE_IN: (e: string[]) => `Must be one of the following values: ${e.join(', ')}`,
  MUST_BE_ARRAY: 'Must be an array',
};
