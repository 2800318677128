import { KnownBlock } from '@slack/web-api';

import { ITargetGroup } from '../../../entities/target-group/domain/target-group.types';
import { IUser } from '../../../entities/user/domain/user.types';
import { sendSlackNotification } from '../../../shared/helpers/slack-notification.helpers';
import { SLACK_IDS } from './target-definition-form.constants';

export const isRangeValid = (min: number | null | undefined, max: number | null | undefined) => {
  if (!min && !max) return true;

  const minNumber = Number(min);
  const maxNumber = Number(max);

  return !!(
    !Number.isNaN(minNumber) &&
    !Number.isNaN(maxNumber) &&
    minNumber >= 0 &&
    minNumber <= maxNumber
  );
};

export const isHeadcountRangeValid = (min: number, max: number) => {
  return !Number.isNaN(min) && !Number.isNaN(max) && min >= 5 && max >= 5 && min <= max;
};

export async function sendTargetDefinitionDetailsInThread(
  targetGroup: ITargetGroup,
  parentMessageTs: string,
  channel: string | undefined = undefined,
): Promise<void> {
  if (!parentMessageTs) {
    console.error('Cannot send thread message: No parent message timestamp provided');
    return;
  }

  const blocks: KnownBlock[] = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: '📋 Target Definition Details',
        emoji: true,
      },
    },
    {
      type: 'divider',
    },
  ];

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Target Group Name:*\n${targetGroup.name || 'N/A'}`,
      },
    ],
  });

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Description:*\n${targetGroup.description || 'N/A'}`,
      },
    ],
  });

  const locationsText =
    targetGroup.locations && targetGroup.locations.length > 0
      ? targetGroup.locations.map(loc => loc.name || loc.country || 'Unknown location').join(', ')
      : 'N/A';

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Locations:*\n${locationsText}`,
      },
    ],
  });

  let ownershipText = 'N/A';
  if (targetGroup.ownershipStructures) {
    const structures = Object.entries(targetGroup.ownershipStructures)
      .filter(([_, value]) => value === true)
      .map(([key]) => key);

    if (structures.length > 0) {
      ownershipText = structures.join(', ');
    }
  }

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Ownership Structures:*\n${ownershipText}`,
      },
    ],
  });

  const minHeadcount = targetGroup.minHeadcount || 'N/A';
  const maxHeadcount = targetGroup.maxHeadcount || 'N/A';
  const headcountRange = `${minHeadcount} - ${maxHeadcount}`;

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Headcount Range:*\n${headcountRange}`,
      },
    ],
  });

  const minRevenue = targetGroup.minRevenue || 'N/A';
  const maxRevenue = targetGroup.maxRevenue || 'N/A';
  const revenueCurrency = targetGroup.revenueCurrency || 'USD';
  const revenueRange = `${minRevenue} - ${maxRevenue} ${revenueCurrency}`;

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Revenue Range:*\n${revenueRange}`,
      },
    ],
  });

  await sendSlackNotification(blocks, channel, parentMessageTs);
}

export const createTargetGroupSlackNotification = async (
  response: ITargetGroup,
  clientName: string,
  user: IUser,
  clientId?: string,
) => {
  const targetGroupLink = new URL(`${window.location.origin}/tg/${clientId}/${response.id}`);

  const blocks: KnownBlock[] = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: '🎯 New Target Group',
        emoji: true,
      },
    },
    {
      type: 'divider',
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Client:*\n🏢 ${clientName || 'N/A'}`,
        },
        {
          type: 'mrkdwn',
          text: `*Name:*\n📋 ${response.name}`,
        },
        {
          type: 'mrkdwn',
          text: `*Link:*\n🔗 <${targetGroupLink.toString()}|View Target Group>`,
        },
        {
          type: 'mrkdwn',
          text: `*Created by:*\n👤 ${user.email || 'Unknown user'}`,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Cc:* 🔔 \n ${Object.values(SLACK_IDS)
            .map(id => `<@${id}>`)
            .join(' ')}`,
        },
      ],
    },
  ];

  // Send the main notification and get the timestamp for threading
  const messageTs = await sendSlackNotification(blocks);

  // If we have a timestamp, send the target definition details in a thread
  if (messageTs) {
    await sendTargetDefinitionDetailsInThread(response, messageTs);
  }
};

export const updateTargetGroupSlackNotification = async (
  response: ITargetGroup,
  clientName: string,
  user: IUser,
  clientId?: string,
  changedFields?: Record<string, any>,
  originalValues?: Record<string, any>,
) => {
  const targetGroupLink = new URL(`${window.location.origin}/tg/${clientId}/${response.id}`);

  const blocks: KnownBlock[] = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: '🔄 Target Group Updated',
        emoji: true,
      },
    },
    {
      type: 'divider',
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Client:*\n🏢 ${clientName || 'N/A'}`,
        },
        {
          type: 'mrkdwn',
          text: `*Name:*\n📋 ${response.name}`,
        },
        {
          type: 'mrkdwn',
          text: `*Link:*\n🔗 <${targetGroupLink.toString()}|View Target Group>`,
        },
        {
          type: 'mrkdwn',
          text: `*Updated by:*\n👤 ${user.email || 'Unknown user'}`,
        },
      ],
    },
    {
      type: 'divider',
    },
  ];

  // Add changes section if changes are provided
  if (changedFields && originalValues) {
    blocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: '*Changes Made:*',
      },
    });

    // Add each change as a separate section block
    Object.entries(changedFields).forEach(([key, newValue]) => {
      const oldValue = originalValues[key];
      const fieldName = key.charAt(0).toUpperCase() + key.slice(1);

      // Format the values for display based on their type
      let displayOldValue;
      let displayNewValue;

      // Handle locations array (array of objects)
      if (key === 'locations') {
        displayOldValue =
          Array.isArray(oldValue) && oldValue.length > 0
            ? oldValue.map(loc => loc.name || loc.country || 'Unknown location').join(', ')
            : 'None';

        displayNewValue =
          Array.isArray(newValue) && newValue.length > 0
            ? newValue.map(loc => loc.name || loc.country || 'Unknown location').join(', ')
            : 'None';
      }
      // Handle ownership structures (object with boolean values)
      else if (key === 'ownershipStructures') {
        displayOldValue = oldValue
          ? Object.entries(oldValue)
              .filter(([_, value]) => value === true)
              .map(([key]) => key)
              .join(', ') || 'None'
          : 'None';

        displayNewValue = newValue
          ? Object.entries(newValue)
              .filter(([_, value]) => value === true)
              .map(([key]) => key)
              .join(', ') || 'None'
          : 'None';
      }
      // Handle arrays
      else if (Array.isArray(oldValue) || Array.isArray(newValue)) {
        displayOldValue =
          Array.isArray(oldValue) && oldValue.length > 0 ? oldValue.join(', ') : 'None';

        displayNewValue =
          Array.isArray(newValue) && newValue.length > 0 ? newValue.join(', ') : 'None';
      }
      // Handle objects
      else if (
        (oldValue && typeof oldValue === 'object') ||
        (newValue && typeof newValue === 'object')
      ) {
        try {
          displayOldValue = oldValue
            ? JSON.stringify(oldValue, null, 2).replace(/[{}"]/g, '')
            : 'None';
          displayNewValue = newValue
            ? JSON.stringify(newValue, null, 2).replace(/[{}"]/g, '')
            : 'None';
        } catch (e) {
          displayOldValue = oldValue ? 'Complex object' : 'None';
          displayNewValue = newValue ? 'Complex object' : 'None';
        }
      }
      // Handle simple values
      else {
        displayOldValue =
          oldValue === undefined || oldValue === null || oldValue === ''
            ? 'None'
            : String(oldValue).trim();

        displayNewValue =
          newValue === undefined || newValue === null || newValue === ''
            ? 'None'
            : String(newValue).trim();
      }

      blocks.push({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*${fieldName}:* _~${displayOldValue}~_ → *${displayNewValue}*`,
        },
      });
    });

    blocks.push({
      type: 'divider',
    });
  }

  blocks.push({
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Cc:* 🔔 \n ${Object.values(SLACK_IDS)
          .map(id => `<@${id}>`)
          .join(' ')}`,
      },
    ],
  });

  sendSlackNotification(blocks);
};

export const deleteTargetGroupSlackNotification = async ({
  targetGroupName,
  clientName,
  userEmail,
}: {
  targetGroupName: string;
  clientName: string;
  userEmail?: string;
}) => {
  const blocks: KnownBlock[] = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: '🗑️ Target Group Deleted',
        emoji: true,
      },
    },
    {
      type: 'divider',
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Client:*\n🏢 ${clientName || 'N/A'}`,
        },
        {
          type: 'mrkdwn',
          text: `*Name:*\n📋 ${targetGroupName}`,
        },
        {
          type: 'mrkdwn',
          text: `*Deleted by:*\n👤 ${userEmail || 'Unknown user'}`,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Cc:* 🔔 \n ${Object.values(SLACK_IDS)
            .map(id => `<@${id}>`)
            .join(' ')}`,
        },
      ],
    },
  ];

  await sendSlackNotification(blocks);
};
