import { Box, SelectChangeEvent, Stack } from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { NotificationPreferences } from '../../../../entities/user/domain/user.types';
import {
  notificationSettingsSelector,
  userSelector,
} from '../../../../entities/user/store/user.selectors';
import { updateNotificationSettings } from '../../../../entities/user/store/user.slice';
import ButtonV2 from '../../../../shared/components/button/button-v2/button-v2.component';
import BaseDropdownV2 from '../../../../shared/components/dropdown/base-dropdown-v2/base-dropdown-v2.component';
import { Label } from '../../../../shared/components/dropdown/base-dropdown-v2/base-dropdown-v2.styles';
import GenericDialog from '../../../../shared/components/generic-dialog-v2/generic-dialog.component';
import { useForm } from '../../../../shared/hooks/use-form.hook';
import { useModal } from '../../../../shared/hooks/use-modal.hook';
import { useToast } from '../../../../shared/hooks/use-toast.hook';
import { DROPDOWN_OPTIONS } from './notification-settings.constants';
import { EmailInput, LabelContent, Title } from './notification-settings.styles';
import { validateForm } from './notification-settings.validation';

const DEFAULT_PREFERENCE = NotificationPreferences.weekly;
const TOAST_DURATION = 3500;

function NotificationSettings() {
  const { clientId } = useParams();
  const { toast } = useToast();

  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const notificationSettings = useAppSelector(notificationSettingsSelector);

  const [preference, setPreference] = useState(
    notificationSettings?.preference || DEFAULT_PREFERENCE,
  );
  const [isLoading, setIsLoading] = useState(false);

  const { formValues, handleInputChange, resetFormState } = useForm({
    email: notificationSettings?.email || user.email,
  });
  const { isOpened, handleClose, handleOpen } = useModal();

  const resetNotificationSettings = useCallback(() => {
    resetFormState({ email: notificationSettings?.email || user.email });
    setPreference(notificationSettings?.preference || DEFAULT_PREFERENCE);
  }, [notificationSettings, user, resetFormState, setPreference]);

  const handleModalClose = useCallback(
    (e: SyntheticEvent) => {
      if (handleClose) {
        handleClose(e);
      }
    },
    [handleClose],
  );

  const handlePreferenceChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setPreference(JSON.parse(e.target.value as string).id as NotificationPreferences);
  }, []);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      setIsLoading(true);
      const errs = validateForm(formValues, preference);
      if (errs.length > 0) {
        errs.forEach(err => {
          toast.error({ message: err, duration: TOAST_DURATION });
        });
        setIsLoading(false);
        return;
      }

      if (clientId) {
        dispatch(updateNotificationSettings({ clientId, email: formValues.email, preference }))
          .unwrap()
          .then(() => {
            toast.success({
              message: 'Notification settings updated successfully',
              duration: TOAST_DURATION,
            });
            handleModalClose(e);
          })
          .catch(err => {
            toast.error({ message: err.message, duration: TOAST_DURATION });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    },
    [formValues, preference, clientId, dispatch, toast, handleModalClose],
  );

  return (
    <>
      <ButtonV2
        customVariant="secondary-small"
        title="Notification Settings"
        handleClick={() => {
          resetNotificationSettings();
          handleOpen();
        }}
      />
      <GenericDialog
        open={isOpened}
        onClose={handleModalClose}
        fullWidth
        sx={{
          display: 'flex',
          padding: '2rem 1rem',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2rem',

          '& .MuiPaper-root.MuiDialog-paper': { width: '32.875rem', gap: 0 },
        }}
      >
        <Stack
          spacing={4}
          sx={{
            width: '26.375rem',
            margin: '0 auto',
          }}
        >
          <Title>Notifications</Title>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              marginTop: '35px !important',
            }}
          >
            <Label htmlFor="email" sx={{ margin: 0 }}>
              <LabelContent>Email address *</LabelContent>
            </Label>
            <EmailInput
              isLabelShown={false}
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email address..."
              values={formValues}
              handleChange={handleInputChange}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              marginTop: '1rem !important',
            }}
          >
            <Label htmlFor="preference" sx={{ margin: 0 }}>
              <LabelContent>Notification frequency</LabelContent>
            </Label>
            <BaseDropdownV2
              selectedOption={DROPDOWN_OPTIONS.find(option => option.id === preference)?.name}
              options={DROPDOWN_OPTIONS}
              handleOptionChange={handlePreferenceChange}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem',
              paddingBottom: '1rem',
              alignSelf: 'center',
            }}
          >
            <ButtonV2
              sx={{ alignSelf: 'center', width: '90px', height: '36px' }}
              title="Cancel"
              handleClick={handleModalClose}
              customVariant="primary-small"
            />
            <ButtonV2
              sx={{ alignSelf: 'center', width: '90px', height: '36px' }}
              title="Submit"
              handleClick={handleSubmit}
              customVariant="secondary-small"
              disabled={
                // no changes or no valid email
                !formValues.email ||
                (formValues.email === notificationSettings?.email &&
                  preference === notificationSettings?.preference)
              }
              isLoading={isLoading}
            />
          </Box>
        </Stack>
      </GenericDialog>
    </>
  );
}

export default NotificationSettings;
