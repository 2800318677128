// New sources are added here
export const DROPDOWN_OPTIONS_OBJECT = {
  APOLLO: 'A*',
  NORTH_DATA: 'ND*',
  PANDA_MATCH: 'PM*',
  FILE: 'Other',
};

export const DROPDOWN_OPTIONS = Object.entries(DROPDOWN_OPTIONS_OBJECT).map(([, id]) => ({
  id,
  name: id,
  values: { id, name: id },
}));

export const DEFAULT_OPTION = DROPDOWN_OPTIONS[DROPDOWN_OPTIONS.length - 1].id;

export const URL_SOURCES = [DROPDOWN_OPTIONS_OBJECT.APOLLO] as const;
