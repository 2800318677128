import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Container, { ContainerProps } from '@mui/material/Container';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const AutocompleteContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .autocomplete-validation-helper': {
    margin: 0,
    paddingInlineStart: '20px',

    li: {
      p: {
        fontSize: '0.65rem',
        color: theme.palette.text.error,

        [theme.breakpoints.up('xl')]: {
          fontSize: '0.8rem',
        },
      },
    },
  },
}));

export const Input = styled(TextField, {
  shouldForwardProp: props =>
    props !== 'isDropdownAboveInput' && props !== 'isOpened' && props !== 'hasError',
})<TextFieldProps & { isDropdownAboveInput: boolean; isOpened: boolean; hasError: boolean }>(
  ({ hasError, theme }) => ({
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      color: theme.palette.common.white,
      fontSize: '0.8rem',

      display: 'flex',
      padding: '0.375rem 0.75rem',
      alignItems: 'center',
      gap: '0.5rem',
      alignSelf: 'stretch',
      borderRadius: '0.25rem',
      border: '1px solid',
      borderColor: hasError ? theme.palette.text.error : theme.palette.neutrals.gray.low,
      background: theme.palette.neutrals.gray.lowest,
      minHeight: 'auto',

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        top: 0,
      },
    },

    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: 0,
      minWidth: '100px',
    },

    '& .MuiInputBase-input': {
      color: theme.palette.neutrals.gray.highest,
      fontFamily: '"Darker Grotesque", sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '130%',
      minWidth: '100px',
      marginTop: '-0.1rem', // fix vertical alignment/input line-height
      padding: 0,
    },

    '&.Mui-error': {
      color: '#ea4c89',
    },
  }),
);

export const AutocompletePaper = styled(Paper, {
  shouldForwardProp: props => props !== 'isDropdownAboveInput' && props !== 'isOpened',
})<PaperProps>(({ theme }) => ({
  display: 'flex',
  padding: '0.25rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.125rem',
  marginTop: '0.25rem',
  background: theme.palette.neutrals.gray.lowest,

  borderRadius: '0.375rem',
  border: '0px solid #F4F4F5',
  boxShadow: '0px 0px 1px 0px rgba(9, 9, 11, 0.10), 0px 7px 22px 0px rgba(9, 9, 11, 0.10)',

  '& > ul': {
    width: '100%',
    padding: 0,

    '& .MuiAutocomplete-option': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.125rem 0.625rem 0.125rem 1.375rem',
      borderRadius: '5px',

      color: theme.palette.neutrals.gray.high,
      fontFamily: '"Darker Grotesque", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '130%',

      gap: '0.625rem',

      '&:hover': { background: theme.palette.neutrals.gray.lower },

      '.secondary': {
        color: theme.palette.neutrals.gray.high,
        textAlign: 'right',
        fontFamily: '"DM Mono", serif',
        fontSize: '0.625rem',
        fontWeight: 400,
        lineHeight: '170%',
        textTransform: 'uppercase',
      },
    },
  },
}));

export const StyledPopupIcon = styled(UnfoldMoreIcon)(() => ({
  width: '0.75rem',
  height: '0.75rem',
}));
