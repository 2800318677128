import { Fragment } from 'react';

import ProtectDecorator from '../../../../app/decorators/protect/protect.decorator';
import { useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { UserRole } from '../../../../entities/user/domain/user.types';
import { userSelector } from '../../../../entities/user/store/user.selectors';
import {
  ComponentsContainer,
  ComponentsRowContainer,
} from './mapper-with-variable-components.styles';
import {
  ConfigWithComponent,
  IMapperWithVariableComponentsProps,
} from './mapper-with-variable-components.types';

function MapperWithVariableComponents({
  config = [],
}: Partial<IMapperWithVariableComponentsProps> = {}) {
  const allRoles = Object.values(UserRole) as UserRole[];
  const getAllowedRoles = (exclude?: UserRole[]) =>
    exclude ? allRoles.filter(e => !exclude.includes(e)) : allRoles;
  const { roles } = useAppSelector(userSelector);

  const getIsReadOnly = (forRoles?: UserRole[]) =>
    roles && forRoles && forRoles.some(role => roles.includes(role));

  const rows: ConfigWithComponent[] = [];
  let currentRow: ConfigWithComponent = [];

  config.forEach((item, index) => {
    currentRow.push(item);

    if (item['source'] === 'searchForm' || index === config.length - 1) {
      rows.push(currentRow);
      currentRow = [];
    } else if (!(item['source'] === 'searchForm')) {
      rows.push(currentRow);
      currentRow = [];
    }
  });

  return (
    <>
      {rows.map((row, rowIndex) => (
        <ComponentsRowContainer key={rowIndex}>
          {row.map(
            (
              {
                component: Component,
                isHiddenFor,
                isReadonlyFor,
                isFormEditable,
                ...componentProps
              },
              colIndex,
            ) => (
              <ProtectDecorator allowedRoles={getAllowedRoles(isHiddenFor)}>
                <ComponentsContainer
                  key={colIndex}
                  componentsPerRow={row.length}
                  isLastRow={rowIndex === rows.length - 1}
                >
                  <Component
                    {...{
                      ...componentProps,
                      disabled: isFormEditable === false || getIsReadOnly(isReadonlyFor),
                    }}
                  />
                </ComponentsContainer>
              </ProtectDecorator>
            ),
          )}
        </ComponentsRowContainer>
      ))}
    </>
  );
}

export default MapperWithVariableComponents;
