import { ReactNode } from 'react';

import BaseModal from '../../modal/base-modal/base-modal.component';
import {
  FeedbackUiType,
  IBaseModalProps,
  InfoOrErrorFeedbackUiPropsUnion,
  InfoOrErrorFeedbackUiType,
} from '../feedback-ui.types';
import { infoFeedbackUiPropsWithDefaults } from './info-feedback-ui-kit.constants';

export const infoFeedbackUiKit: {
  [Property in InfoOrErrorFeedbackUiType]: (props: InfoOrErrorFeedbackUiPropsUnion) => ReactNode;
} = {
  [FeedbackUiType.MODAL]: (props: InfoOrErrorFeedbackUiPropsUnion) => {
    const propsWithDefaults = infoFeedbackUiPropsWithDefaults[FeedbackUiType.MODAL](props);
    return <BaseModal {...(propsWithDefaults as IBaseModalProps)} />;
  },
  [FeedbackUiType.SNACKBAR]: (props: InfoOrErrorFeedbackUiPropsUnion) => null,
};
