import { styled } from '@mui/material/styles';

export const ToastContainer = styled('div')(() => ({
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 10000,
  display: 'flex',
  flexDirection: 'column',
  width: 'minmax(400px,max-content)',
  boxSizing: 'border-box',
  gap: 16,
  overflowY: 'auto',
  height: 'calc(100vh - 16px)',
  padding: 16,

  pointerEvents: 'none',
}));
