import { FIELD_INFO } from '../../domain/target-definition-form.constants';
import {
  ExplanationBox,
  ExplanationDescription,
  ExplanationExample,
  ExplanationTitle,
} from '../target-definition-form.styles';

interface FormFieldExplanationProps {
  fieldName: string;
  top: number;
  smoothScroll: boolean;
}

function FormFieldExplanation({ fieldName, top, smoothScroll }: FormFieldExplanationProps) {
  const info = FIELD_INFO[fieldName as keyof typeof FIELD_INFO];

  if (!info) return null;

  return (
    <ExplanationBox
      className="target-definition-form-explanation-box"
      top={top}
      smoothScroll={smoothScroll}
    >
      <ExplanationTitle>{info.title}</ExplanationTitle>
      <ExplanationDescription>{info.description}</ExplanationDescription>
      {info.examples?.length > 0 && (
        <>
          <ExplanationTitle className="examples-title">Good examples</ExplanationTitle>
          <ul>
            {info.examples.map((example, i) => (
              <ExplanationExample key={example}>
                <b>{i + 1}</b> <span>{example}</span>
              </ExplanationExample>
            ))}
          </ul>
        </>
      )}
    </ExplanationBox>
  );
}

export default FormFieldExplanation;
