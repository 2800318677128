import { DialogProps, SelectChangeEvent, Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import {
  resetUploadCompaniesThunk,
  uploadCompaniesForMarketMapping,
} from '../../../../entities/company/store/company.slice';
import { currentTargetGroupParamsSelector } from '../../../../entities/target-group/store/target-group.selectors';
import { getTargetGroupById } from '../../../../entities/target-group/store/target-group.slice';
import ButtonV2 from '../../../../shared/components/button/button-v2/button-v2.component';
import BaseDropdownV2 from '../../../../shared/components/dropdown/base-dropdown-v2/base-dropdown-v2.component';
import FileUpload from '../../../../shared/components/file-upload/file-upload.component';
import GenericDialog from '../../../../shared/components/generic-dialog-v2/generic-dialog.component';
import { Label } from '../../../../shared/components/input/base-input/base-input.styles';
import { useForm } from '../../../../shared/hooks/use-form.hook';
import { useToast } from '../../../../shared/hooks/use-toast.hook';
import { getFullSourceType } from '../../helpers/source-type.helpers';
import {
  DEFAULT_OPTION,
  DROPDOWN_OPTIONS,
  DROPDOWN_OPTIONS_OBJECT,
} from './add-list-dialog.constants';
import {
  FileUploadContainer,
  FormRow,
  NotesInput,
  SourceLinkInput,
} from './add-list-dialog.styles';
import { SourceType } from './add-list-dialog.types';
import { validateForm } from './add-list-dialog.validation';

function AddListDialog({ onClose, ...props }: DialogProps) {
  const { formValues, handleInputChange } = useForm({ source: '', link: '', notes: '' });
  const [file, setFile] = useState<File>(new File([], ''));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedSource, setSelectedSource] = useState(DEFAULT_OPTION);
  const { clientId } = useParams();
  const { id: targetGroupId } = useAppSelector(currentTargetGroupParamsSelector);
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  function handleOptionChange(event: SelectChangeEvent<unknown>) {
    const selectedOption = event.target.value as string;
    const { id } = JSON.parse(selectedOption);
    setSelectedSource(id);
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(resetUploadCompaniesThunk());
    if (onClose) {
      onClose(e, 'backdropClick');
    }
  };

  function handleAddList(e: React.MouseEvent<HTMLButtonElement>) {
    const errs = validateForm(formValues, file, selectedSource as SourceType);
    if (errs.length > 0) {
      errs.forEach(err => toast.error({ message: err }));
      return;
    }

    setIsUploading(true);
    const sourceType = getFullSourceType(selectedSource);

    const params = {
      clientId,
      targetGroupId,
      file: sourceType === 'APOLLO' ? undefined : file,
      notes: formValues.notes,
      url: formValues.link,
      sourceType,
    };

    dispatch(uploadCompaniesForMarketMapping(params))
      .unwrap()
      .then(() => {
        dispatch(getTargetGroupById(targetGroupId));
        toast.success({ message: 'List uploaded successfully' });
        handleClose(e);
      })
      .catch(err => {
        toast.error({ message: err.message });
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  const isApolloSource = selectedSource === DROPDOWN_OPTIONS_OBJECT.APOLLO;

  return (
    <GenericDialog {...props} title="Add list" onClose={handleClose} fullWidth>
      <Stack spacing={4}>
        <FormRow>
          <BaseDropdownV2
            selectedOption={selectedSource}
            options={DROPDOWN_OPTIONS}
            handleOptionChange={handleOptionChange}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            label="Source *"
            isMenuOpen={isMenuOpen}
          />
          <SourceLinkInput
            isLabelShown
            label="Source link *"
            id="link"
            name="link"
            type="text"
            values={formValues}
            handleChange={handleInputChange}
          />
        </FormRow>
        <FormRow>
          <NotesInput
            id="notes"
            name="notes"
            handleChange={handleInputChange}
            values={formValues}
            minRows={6}
            maxRows={6}
            isLabelShown
            label="Notes *"
          />
          {!isApolloSource && (
            <FileUploadContainer>
              <Label shrink>.csv upload *</Label>
              <FileUpload
                handleFileChange={setFile}
                uploadFormats="Accepted column headers are 'Website' and 'Company'"
              />
            </FileUploadContainer>
          )}
        </FormRow>
        <Stack direction="row" gap={0.85} alignSelf="flex-end" marginTop={3.125}>
          <ButtonV2 customVariant="primary-small" title="Cancel" handleClick={handleClose} />
          <ButtonV2
            title="Add list"
            handleClick={handleAddList}
            customVariant="secondary-small"
            isLoading={isUploading}
          />
        </Stack>
      </Stack>
    </GenericDialog>
  );
}

export default AddListDialog;
