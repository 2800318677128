import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { resetCompanyState } from '../../../entities/company/store/company.slice';
import { resetCompanyTargetGroupState } from '../../../entities/company-target-group/store/company-target-group.slice';
import {
  currentTargetGroupParamsSelector,
  currentTargetGroupSelector,
  targetGroupsListSelector,
  targetGroupThunksSelector,
} from '../../../entities/target-group/store/target-group.selectors';
import {
  getAllTargetGroupsByClient,
  resetTargetGroupState,
} from '../../../entities/target-group/store/target-group.slice';
import { UserRole } from '../../../entities/user/domain/user.types';
import { userSelector } from '../../../entities/user/store/user.selectors';
import Link from '../../../shared/components/link/base-link/base-link.component';
import { NavigationPaths } from '../../../shared/domain/constants/routing.constants';
import { scrollToListBottom } from '../../../shared/helpers/base.helpers';
import { useGenericElementRef } from '../../../shared/hooks/use-generic-element-ref.hook';
import Logout from '../../authentication/components/logout/logout.component';
import CreateTargetGroup from '../../create-target-group/components/create-target-group.component';
import DownloadClientData from '../../download-client-data/components/download-file.component';
import SelectEntity from '../../select-entity/components/select-entity.component';
import {
  checkIsModificationAllowed,
  formTargetGroupsEntityData,
} from '../helpers/fetch-target-groups-list.helpers';
import { toggleMenu } from '../store/left-panel.slice';
import {
  FetchTargetGroupsHeader,
  FetchTargetGroupsListActionBottomWrapper,
  FetchTargetGroupsListActionTopWrapper,
  FetchTargetGroupsListContainer,
  FetchTargetGroupsListWrapper,
  LeftPanelIcon,
  LogoImage,
  SearchInput,
} from './fetch-target-groups-list.styles';
import { IFetchTargetGroupsListProps } from './fetch-target-groups-list.types';
import TargetGroupCard from './target-group-card/target-group-card.component';

function FetchTargetGroupsList({ stageName }: IFetchTargetGroupsListProps) {
  const { clientId } = useParams();

  const navigate = useNavigate();

  const { roles } = useAppSelector(userSelector);
  const firstRender = useRef(true);

  const dispatch = useAppDispatch();

  const { id: selectedTargetGroupId, name: selectedTargetGroupName } = useAppSelector(
    currentTargetGroupParamsSelector,
  );

  const { isLoading: isTargetGroupsListLoading } =
    useAppSelector(targetGroupThunksSelector).getAllTargetGroupsByClient;

  const { name: currentTargetGroupName } = useAppSelector(currentTargetGroupSelector);

  const targetGroupsList = useAppSelector(targetGroupsListSelector);

  const listRef = useGenericElementRef<HTMLDivElement>();

  const [searchTargetGroupInput, setSearchTargetGroupInput] = useState('');

  const { isSuccess } = useAppSelector(targetGroupThunksSelector).createTargetGroup;

  const handleClientNavigation = () => {
    navigate(NavigationPaths.CLIENTS);
    dispatch(resetTargetGroupState());
    dispatch(resetCompanyState());
    dispatch(resetCompanyTargetGroupState());
  };

  const handleAnalyticsNavigation = () => {
    navigate(NavigationPaths.ANALYTICS(clientId));
  };

  useEffect(() => {
    if (isSuccess) scrollToListBottom(listRef);
  }, [isSuccess, listRef]);

  useEffect(() => {
    if (selectedTargetGroupName !== currentTargetGroupName || firstRender.current) {
      dispatch(getAllTargetGroupsByClient({ clientId }));
      firstRender.current = false;
    }
  }, [clientId, currentTargetGroupName, dispatch, selectedTargetGroupName]);

  useEffect(() => {
    const wrapper = document.querySelector('.select-target-group-wrapper');
    const container = document.querySelector('.select-target-group');

    if (!container || !wrapper) return;

    const updateGradients = () => {
      const { scrollTop, scrollHeight, clientHeight } = container as HTMLElement;
      const atTop = scrollTop === 0;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 1; // add 1 more pixel to prevent from detecting issues

      if (atTop) {
        wrapper.classList.add('no-gradient-top');
      } else {
        wrapper.classList.remove('no-gradient-top');
      }

      if (atBottom) {
        wrapper.classList.add('no-gradient-bottom');
      } else {
        wrapper.classList.remove('no-gradient-bottom');
      }
    };

    updateGradients();

    container.addEventListener('scroll', updateGradients);

    return () => {
      container.removeEventListener('scroll', updateGradients);
    };
  }, [isTargetGroupsListLoading]);

  const handleToggleMenu = () => {
    dispatch(toggleMenu());
  };

  const filteredTargetGroups = useMemo(() => {
    if (!searchTargetGroupInput.trim()) {
      return targetGroupsList?.items;
    }
    return targetGroupsList?.items.filter(group =>
      group.name.toLowerCase().includes(searchTargetGroupInput.toLowerCase()),
    );
  }, [targetGroupsList?.items, searchTargetGroupInput]);

  const targetGroupsEntityData = formTargetGroupsEntityData(
    filteredTargetGroups,
    selectedTargetGroupId,
    stageName,
  );

  return (
    <FetchTargetGroupsListContainer className="fetch-target-groups-list-container" ref={listRef}>
      <FetchTargetGroupsHeader className="panel-left-header">
        <LogoImage className="panel-left-logo-image" src="/revi-logo-black.svg" />
        <LeftPanelIcon
          onClick={handleToggleMenu}
          src="/icons/left-panel-icon.svg"
          className="left-panel-icon"
        />
      </FetchTargetGroupsHeader>
      <FetchTargetGroupsListActionTopWrapper className="panel-left-action-top">
        {checkIsModificationAllowed(stageName) && <CreateTargetGroup />}
        <SearchInput
          id="search-tg-input"
          name="targetGroup"
          placeholder="SEARCH TARGET GROUPS..."
          multiline={false}
          values={{ targetGroup: searchTargetGroupInput }}
          handleChange={e => setSearchTargetGroupInput(e.target.value)}
        />
      </FetchTargetGroupsListActionTopWrapper>

      <FetchTargetGroupsListWrapper className="select-target-group-wrapper">
        <SelectEntity
          entityData={targetGroupsEntityData ?? []}
          isLoading={isTargetGroupsListLoading}
          className="select-target-group"
          entityCard={TargetGroupCard}
          noDataMessage="No target groups found"
        />
      </FetchTargetGroupsListWrapper>

      <FetchTargetGroupsListActionBottomWrapper className="left-panel-action-bottom">
        {clientId && <DownloadClientData clientId={clientId} />}
        {roles.includes(UserRole.EMPLOYEE) && clientId && (
          <Link onClick={handleAnalyticsNavigation} title="ANALYTICS" icon />
        )}
        {roles.includes(UserRole.EMPLOYEE) && (
          <Link onClick={handleClientNavigation} title="CLIENT LIST" icon />
        )}
        <Logout />
      </FetchTargetGroupsListActionBottomWrapper>
    </FetchTargetGroupsListContainer>
  );
}

export default FetchTargetGroupsList;
