import { Box, Typography } from '@mui/material';

import { IAcquisition } from '../../../../../../../entities/company/domain/company.base.types';
import DetailsSection from './details-section.component';

const formatDate = (dateStr: string) => {
  let d = new Date(dateStr);
  const numericDate = dateStr.match(/^\d{2}[-/]\d{2}[-/]\d{4}$/);
  if (numericDate) {
    const [day, month, year] = dateStr.split(/[-/]/);
    const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    d = date;
  }

  return d.toLocaleString('en-US', { month: 'short', year: 'numeric' });
};

export default function AcquiredCompaniesSection({
  acquisitions,
}: {
  acquisitions?: IAcquisition[];
}) {
  if (!acquisitions) return null;

  const sortedAcquisitions = [...acquisitions]
    .map(acquisition => ({
      ...acquisition,
      date: formatDate(acquisition.date),
    }))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <DetailsSection title="Acquired companies" count={acquisitions.length}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {sortedAcquisitions.map((acquisition, index) => (
          <Box
            key={acquisition.name + index}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography color="neutrals.gray.high">{acquisition.name}</Typography>
            <Typography color="neutrals.gray.medium">{acquisition.date}</Typography>
          </Box>
        ))}
      </Box>
    </DetailsSection>
  );
}
