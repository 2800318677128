import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/store/utils/redux.hooks';
import { targetGroupThunksSelector } from '../../../../entities/target-group/store/target-group.selectors';
import {
  deleteUploadedFile,
  getTargetGroupById,
} from '../../../../entities/target-group/store/target-group.slice';
import FeedbackUiDecorator from '../../../../shared/decorators/feedback-ui/feedback-ui.decorator';
import { ButtonClickEvent } from '../../../../shared/domain/types/html-elements.types';
import { DeleteUploadedFileButton } from './delete-uploaded-file.styles';
import { IDeleteUploadedFileProps } from './delete-uploaded-file.types';

function DeleteUploadedFile({ targetGroupId, fileName, sourceId }: IDeleteUploadedFileProps) {
  const [isPromptedToDelete, setIsPromptedToDelete] = useState(false);

  const { isLoading: isUploadedFileDeleting } =
    useAppSelector(targetGroupThunksSelector).deleteUploadedFile;

  const dispatch = useAppDispatch();

  function handleDeleteButtonClick(event: ButtonClickEvent) {
    event.stopPropagation();
    setIsPromptedToDelete(true);
  }

  async function handleAction() {
    await dispatch(deleteUploadedFile({ sourceId })).unwrap();
    setIsPromptedToDelete(false);
    dispatch(getTargetGroupById(targetGroupId));
  }

  function handleClose() {
    setIsPromptedToDelete(false);
  }

  function handleCancel() {
    setIsPromptedToDelete(false);
  }

  const promptModalConfig = {
    isInfo: isPromptedToDelete,
    handleAction,
    handleClose,
    handleCancel,
    actionTitle: 'Remove',
    title: `Remove '${fileName}' file?`,
    message: `You can't undo this action`,
    isActionLoading: isUploadedFileDeleting,
  };

  return (
    <FeedbackUiDecorator infoConfig={promptModalConfig} isChildrenVisible>
      <DeleteUploadedFileButton onClick={handleDeleteButtonClick} size="small">
        <DeleteOutlinedIcon fontSize="small" />
      </DeleteUploadedFileButton>
    </FeedbackUiDecorator>
  );
}

export default DeleteUploadedFile;
