import { Box, styled, Typography } from '@mui/material';

import { formatCurrencyValue, isMeaningfulValue } from '../../utils/financial-utils';
import DetailsSection from './details-section.component';

const FinancialItem = styled(Box)({
  marginBottom: '-8px',
});

const FinancialText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.5,
}));

type FinancialsSectionProps = {
  revenue?: string;
  profit?: string;
  revenueEst?: string;
  revenueYear?: string;
  profitYear?: string;
  currency?: string;
};

export default function FinancialsSection({
  revenue,
  profit,
  revenueEst,
  revenueYear,
  profitYear,
  currency,
}: FinancialsSectionProps) {
  // Check if values are meaningful (not null, undefined, empty, N/A, or zero)
  const isRevenueValid = isMeaningfulValue(revenue) && Number(revenue) !== 0;
  const isProfitValid = isMeaningfulValue(profit) && Number(profit) !== 0;
  const isRevenueEstValid = isMeaningfulValue(revenueEst) && Number(revenueEst) !== 0;

  // Format values only if they are valid
  const formattedRevenueActual = isRevenueValid ? formatCurrencyValue(revenue, currency) : '';
  const formattedRevenueEst = isRevenueEstValid ? formatCurrencyValue(revenueEst, currency) : '';
  const formattedProfitActual = isProfitValid ? formatCurrencyValue(profit, currency) : '';

  const hasRevenueData = !!formattedRevenueActual || !!formattedRevenueEst;
  const hasProfitData = !!formattedProfitActual;

  // If no meaningful data, don't render anything
  if (!hasRevenueData && !hasProfitData) {
    return null;
  }

  return (
    <DetailsSection title="Financials">
      {formattedRevenueActual && (
        <FinancialItem>
          <FinancialText>
            Revenue: {formattedRevenueActual}
            {revenueYear && ` (${revenueYear})`}
          </FinancialText>
        </FinancialItem>
      )}

      {formattedRevenueEst && (
        <FinancialItem>
          <FinancialText>Estimated revenue: {formattedRevenueEst}</FinancialText>
        </FinancialItem>
      )}

      {formattedProfitActual && (
        <FinancialItem>
          <FinancialText>
            Profit: {formattedProfitActual}
            {profitYear && ` (${profitYear})`}
          </FinancialText>
        </FinancialItem>
      )}
    </DetailsSection>
  );
}
