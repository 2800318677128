import { CellValueChangedEvent } from 'ag-grid-enterprise';
import { useMemo } from 'react';

import PageContentHeader from '../../../app/layouts/page-content/page-content-header/page-content-header.component';
import { PageContentContainer } from '../../../app/layouts/page-content/page-content-header/page-content-header.styles';
import { useAppDispatch, useAppSelector } from '../../../app/store/utils/redux.hooks';
import { currentTargetGroupSelector } from '../../../entities/target-group/store/target-group.selectors';
import { updateCompanySource } from '../../../entities/target-group/store/target-group.slice';
import ButtonV2 from '../../../shared/components/button/button-v2/button-v2.component';
import TableV2 from '../../../shared/components/table-v2/table-v2.component';
import { useModal } from '../../../shared/hooks/use-modal.hook';
import AddListDialog from './add-list-dialog/add-list-dialog.component';
import { columnDefs } from './market-mapping-content.constants';

function MarketMappingContent() {
  const targetGroup = useAppSelector(currentTargetGroupSelector);
  const { isOpened, handleClose, handleOpen } = useModal();
  const dispatch = useAppDispatch();

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    dispatch(updateCompanySource({ sourceId: event.data.id, notes: event.newValue }));
  };

  const mutableRowData = useMemo(
    () => targetGroup?.companiesSources?.map(source => ({ ...source })) || [],
    [targetGroup?.companiesSources],
  );

  return (
    <PageContentContainer>
      <PageContentHeader
        title="Market Mapping"
        description={targetGroup.name}
        rightComponent={
          <ButtonV2 title="Add list" customVariant="secondary-small" handleClick={handleOpen} />
        }
      />

      <TableV2
        columnDefs={columnDefs}
        rowData={mutableRowData}
        gridOptions={{ onCellValueChanged }}
      />

      <AddListDialog open={isOpened} onClose={handleClose} />
    </PageContentContainer>
  );
}

export default MarketMappingContent;
