import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const IntelligenceRightSectionContainer = styled(Container, {
  shouldForwardProp: props => props !== 'isTargetGroupChosen',
})<ContainerProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,

  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const IntelligenceRightSectionHeaderComponents = styled('div')(({ theme }) => ({
  marginLeft: '4rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  gap: '1rem',
  flex: 1,
  '& .select-events-categories-autocomplete': {
    flex: 1,
    maxWidth: '324px',
  },

  button: {
    flexShrink: 0,
  },

  [theme.breakpoints.down('lg')]: {
    marginLeft: '1rem',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'end',

    button: {
      gridColumn: '1 / 3',
    },
  },
}));
