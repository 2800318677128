import { Navigate } from 'react-router-dom';

import { UserRole } from '../../entities/user/domain/user.types';
import { userSelector } from '../../entities/user/store/user.selectors';
import { NavigationPaths } from '../../shared/domain/constants/routing.constants';
import { useAppSelector } from '../store/utils/redux.hooks';

function RootRedirect() {
  const { roles, assignedClientId } = useAppSelector(userSelector);

  // Redirect based on user role
  if (roles && roles.includes(UserRole.EMPLOYEE)) {
    return <Navigate to={NavigationPaths.CLIENTS} replace />;
  }

  // For client users, redirect to their target requirements
  if (assignedClientId) {
    return <Navigate to={NavigationPaths.TARGET_REQUIREMENTS(assignedClientId)} replace />;
  }

  // If we can't determine the role, redirect to login page
  return <Navigate to={NavigationPaths.LOGIN} replace />;
}

export default RootRedirect;
